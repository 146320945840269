import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HistoryItem } from '@reflact/ai-types';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { BotCardType } from 'src/app/shared/services/iframe-communication/iframe-communication.service';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  standalone: true,
  styleUrl: './message-card.component.scss',
  imports: [CommonModule, FormsModule, SafePipe]
})
export class MessageCardComponent {
  @Input({ required: true }) public message!: HistoryItem;
  @Output() public deleteMessage = new EventEmitter<HistoryItem>();

  public deleting: boolean = false;

  public getCard(): { type: BotCardType, data: unknown } {
    return JSON.parse(this.message.content) as { type: BotCardType, data: unknown };
  }

  public getIframeCard(): { type: BotCardType, data: { url: string, cssClass: string } } {
    return JSON.parse(this.message.content) as { type: BotCardType, data: { url: string, cssClass: string } };
  }

  public getVimeoCard(): { type: BotCardType, data: { id: string, hash: string } } {
    return JSON.parse(this.message.content) as { type: BotCardType, data: { id: string, hash: string } };
  }

  public getImageCard(): { type: BotCardType, data: { src: string, altText: string, cssClass: string } } {
    return JSON.parse(this.message.content) as { type: BotCardType, data: { src: string, altText: string, cssClass: string } };
  }

  public deleteMessageAndLoad(msg: HistoryItem) {
    this.deleting = true;
    this.deleteMessage.emit(msg);
  }
}
