<h3 i18n="@@feedback">Feedback</h3>

<div class="card border-0">
  <div class="card-body">
    <app-form-for-boolean
      i18n-label="@@active-human-feedback"
      [label]="'Human Feedback aktivieren'"
      [(value)]="this.rss.botConfig.enableMessageFeedback"
      (valueChange)="this.rss.saveBotConfig()"
    ></app-form-for-boolean>
  </div>
</div>

<div
  class="btn-group btn-group-sm mb-3 mt-5"
  *ngIf="this.feedback.length !== 0"
>
  <button
    type="button"
    class="btn"
    [ngClass]="{'btn-primary': this.filter === 'all', 'btn-light': this.filter !== 'all'}"
    (click)="this.filter = 'all'"
    i18n="@@all"
  >
    Alle
  </button>
  <button
    type="button"
    class="btn"
    [ngClass]="{'btn-primary': this.filter === 'thumbsup', 'btn-light': this.filter !== 'thumbsup'}"
    (click)="this.filter = 'thumbsup'"
    i18n="@@positive"
  >
    Positiv
  </button>
  <button
    type="button"
    class="btn"
    [ngClass]="{'btn-primary': this.filter === 'thumbsdown', 'btn-light': this.filter !== 'thumbsdown'}"
    (click)="this.filter = 'thumbsdown'"
    i18n="@@negative"
  >
    Negativ
  </button>
</div>

<div
  class="alert alert-primary mt-5"
  *ngIf="this.feedback.length === 0"
  i18n="@@no-feedbacks-found"
>
  Es liegt noch kein Feedback vor.
</div>
<div *ngFor="let feedback of this.feedback.reverse()">

  <div
    class="card card-light px-0 mb-3 shadow-sm"
    [ngClass]="{'card-light-danger': feedback.remark === 'thumbsdown'}"
    *ngIf="feedback.remark === this.filter || this.filter === 'all'"
  >
    <div class="card-header text-end justify-content-between d-flex">
      <i
        class="me-1 ri-2x "
        [ngClass]="{
                'ri-thumb-up-fill text-primary': feedback.remark === 'thumbsup',
                'ri-thumb-down-fill text-danger': feedback.remark === 'thumbsdown'
              }"
      ></i>
      {{feedback.created | date}}
    </div>
    <div class="card-body mt-0 pt-0">
      <div *ngFor="let message of feedback.messages; index as i">

        <app-message-bubble
          *ngIf="message.role !== 'botcard'"
          [message]="message"
          [bot]="this.rss.botConfig"
          [botChatMsgSourceOption]="this.rss.botConfig.chatMsgSourceOption"
          [voiceOutputActive]="false"
          [isThinking]="false"
        ></app-message-bubble>
      </div>
    </div>
  </div>

</div>