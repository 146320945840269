import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss']
})
export class DisclaimerModalComponent {
  public dontShowAgain: boolean = false;
  public text: string = '';

  constructor(public bsModalRef: BsModalRef) { }

}
