import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { LoginService, logout } from '../services/login.service';

export const authGuard: CanActivateFn = async () => {
  const loginService = inject(LoginService);
  await loginService.loadMyUser();
  if (loginService.loggedInUser && loginService.hasBotAdmin()) {
    return true;
  } else {
    logout();
    return false;
  }
};
