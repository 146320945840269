import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';
import { BotQuickinfoComponent } from '../../utils/bot-quickinfo/bot-quickinfo.component';

import { BotImageReloadComponent } from "../../../bot-image-reload/bot-image-reload.component";

@Component({
  selector: 'app-bot-admin-card-body-content',
  templateUrl: './bot-admin-card-body-content.component.html',
  styleUrl: './bot-admin-card-body-content.component.scss',
  standalone: true,
  imports: [CommonModule, BotQuickinfoComponent, BotImageReloadComponent]
})
export class BotAdminCardBodyContentComponent {
  @Input({ required: true }) public botConfig!: ChatbotConfig;
  constructor(public router: Router) {



  }
  public getContrastColor = getContrastColor;

}
