import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CounterService {
  private fileCounterMap: Map<string, number> = new Map<string, number>();
  private toolCounterMap: Map<string, number> = new Map<string, number>();
  private trunkCounterMap: Map<string, number> = new Map<string, number>();
  private offlineWebsitesCounterMap: Map<string, number> = new Map<string, number>();
  private wennDannCounterMap: Map<string, number> = new Map<string, number>();

  public getTrunks(botId: string) {
    return this.trunkCounterMap.get(botId) ?? 0;
  }

  public setTrunks(botId: string, count: number) {
    this.trunkCounterMap.set(botId, count);
  }

  public addTrunk(botId: string) {
    const current = this.trunkCounterMap.get(botId);
    if (current) {
      this.trunkCounterMap.set(botId, current + 1);
    } else {
      this.trunkCounterMap.set(botId, 1);
    }
  }

  public removeTrunk(botId: string) {
    const current = this.trunkCounterMap.get(botId);
    if (current) {
      this.trunkCounterMap.set(botId, current - 1);
    } else {
      this.trunkCounterMap.set(botId, 0);
    }
  }

  public getWennDann(botId: string) {
    return this.wennDannCounterMap.get(botId) ?? 0;
  }

  public setWennDann(botId: string, count: number) {
    this.wennDannCounterMap.set(botId, count);
  }

  public addWennDann(botId: string) {
    const current = this.wennDannCounterMap.get(botId);
    if (current) {
      this.wennDannCounterMap.set(botId, current + 1);
    } else {
      this.wennDannCounterMap.set(botId, 1);
    }
  }

  public removeWennDann(botId: string) {
    const current = this.wennDannCounterMap.get(botId);
    if (current) {
      this.wennDannCounterMap.set(botId, current - 1);
    } else {
      this.wennDannCounterMap.set(botId, 0);
    }
  }

  public getFiles(botId: string) {
    return this.fileCounterMap.get(botId) ?? 0;
  }

  public setFiles(botId: string, count: number) {
    this.fileCounterMap.set(botId, count);
  }

  public addFile(botId: string) {
    const current = this.fileCounterMap.get(botId);
    if (current) {
      this.fileCounterMap.set(botId, current + 1);
    } else {
      this.fileCounterMap.set(botId, 1);
    }
  }

  public removeFile(botId: string) {
    const current = this.fileCounterMap.get(botId);
    if (current) {
      this.fileCounterMap.set(botId, current - 1);
    } else {
      this.fileCounterMap.set(botId, 0);
    }
  }

  public getTools(botId: string) {
    return this.toolCounterMap.get(botId) ?? 0;
  }

  public setTools(botId: string, count: number) {
    this.toolCounterMap.set(botId, count);
  }

  public addTool(botId: string) {
    const current = this.toolCounterMap.get(botId);
    if (current) {
      this.toolCounterMap.set(botId, current + 1);
    } else {
      this.toolCounterMap.set(botId, 1);
    }
  }

  public removeTool(botId: string) {
    const current = this.toolCounterMap.get(botId);
    if (current) {
      this.toolCounterMap.set(botId, current - 1);
    } else {
      this.toolCounterMap.set(botId, 0);
    }
  }

  public getOfflineWebsiteCount(botId: string) {
    return this.offlineWebsitesCounterMap.get(botId) ?? 0;
  }

  public setOfflineWebsite(botId: string, count: number) {
    this.offlineWebsitesCounterMap.set(botId, count);
  }

  public addOfflineWebsite(botId: string) {
    const current = this.offlineWebsitesCounterMap.get(botId);
    if (current) {
      this.offlineWebsitesCounterMap.set(botId, current + 1);
    } else {
      this.offlineWebsitesCounterMap.set(botId, 1);
    }
  }

  public removeOfflineWebsite(botId: string) {
    const current = this.offlineWebsitesCounterMap.get(botId);
    if (current) {
      this.offlineWebsitesCounterMap.set(botId, current - 1);
    } else {
      this.offlineWebsitesCounterMap.set(botId, 0);
    }
  }
}
