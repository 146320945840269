<h3 i18n="@@knowlede-databases">Bot Wissensdatenbanken</h3>
<div
  class="callout callout-info"
  i18n="@@knowlede-databases-description"
>
  Bot-Wissensdatenbanken enthalten spezifische Informationen und Antworten, die der Bot bei Anfragen verwenden kann.
</div>
<div class="card border-0">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <span i18n="@@use-of-database">Verwendungszweck der Datenbank</span>
      </div>
      <div class="col">
        <input
          class="form-control rag-aitext"
          placeholder=""
          [(ngModel)]="this.newDatabaseDescription"
          [ngModelOptions]="{updateOn: 'blur'}"
          (keyup.enter)="!isValidDatabaseNameAndDesc(this.newDatabase,this.newDatabaseDescription) ? '' : this.addDatabase();"
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <span i18n="@@database-function-name">Datenbank (Funktionsname)</span>
      </div>
      <div class="col-6">
        <app-function-name-suggest
          mode="addon"
          [descriptionString]="this.newDatabaseDescription"
          (finish)="this.newDatabase = $event; "
        >
          <input
            style="width: 300px;"
            class="form-control rag-aitext"
            [(ngModel)]="this.newDatabase"
            maxlength="30"
            (keyup.enter)="!isValidDatabaseNameAndDesc(this.newDatabase,this.newDatabaseDescription) ? '' : this.addDatabase();"
          >
        </app-function-name-suggest>
        <small
          class="text-muted"
          i18n="@@database-function-name-description"
        >
          sprechender Name für die Wissensdatenbanken, z.b. <b>gloassar</b>, <b>produktinformationen</b> oder
          <b>faq</b>.
        </small>
        <br>
        <small
          [ngClass]="!isValidDatabaseName(this.newDatabase) && this.newDatabase !== '' ? 'text-danger' : 'text-muted'"
          i18n="@@database-function-name-length"
        >
          Die maximale Länge für den Namen beträgt <b>30 Zeichen</b>.
          Die erlaubten Zeichen sind <b>a-z</b>, <b>A-Z</b>, <b>0-9</b>, <b>-</b> und <b>_</b>.
        </small>
        <br>
        <div
          class="btn btn-primary btn-sm mt-2"
          [ngClass]="{'disabled': !isValidDatabaseNameAndDesc(this.newDatabase,this.newDatabaseDescription)}"
          (click)="this.addDatabase()"
        ><i class="ri-add-line"></i><ng-container i18n="@@create-database">Datenbank anlegen</ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<h5
  class="mt-5"
  *ngIf="this.rss.datatrunks.length > 0"
  i18n="@@configured-databases-overview"
>Konfigurierte KI-Datenbanken im Überblick</h5>
<div class="full-width-table-wrapper">
  <table class="table table-hover table-striped">
    <tbody>
      @for (trunk of this.rss.datatrunks; track $index) {
      <tr>
        <td style="width: 4rem; text-align: center; vertical-align: middle;">
          <i
            class="ri-circle-fill text-primary"
            *ngIf="trunk.active"
          ></i>
          <i
            class="ri-circle-line text-secondary"
            *ngIf="!trunk.active"
          ></i>
        </td>
        <td style="vertical-align: middle;">
          <span
            class="cursor-pointer text-decoration-underline text-primary"
            [routerLink]="[trunk._id]"
          >{{ trunk.trunkDescription }}</span> <span class="text-muted ms-1">{{ trunk.placeholder }}</span>
        </td>
        <td style="vertical-align: middle;width:250px">
          <app-skill-id-input
            [value]="trunk.skillId"
            (changed)="trunk.skillId = $event; this.updateDatabase(trunk)"
          ></app-skill-id-input>
        </td>
        <td style="vertical-align: middle; width: 2rem;">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              [(ngModel)]="trunk.active"
              (ngModelChange)="this.updateDatabase(trunk); trunk.active ? this.counter.addTrunk(this.rss.botConfig._id) : this.counter.removeTrunk(this.rss.botConfig._id)"
            >
          </div>
        </td>
        <td
          style="vertical-align: middle; width: 2rem;"
          class="text-end"
        >
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  [routerLink]="[trunk._id]"
                >
                  <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-danger"
                  (click)="this.removeDatabase(trunk._id)"
                >
                  <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>