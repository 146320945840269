import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatbotConfig } from '@reflact/ai-types';
import { jwtDecode } from 'jwt-decode';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { AdminService } from 'src/app/shared/services/admin.service';
import { MessageData, PostType } from 'src/app/shared/services/iframe-communication/iframe-communication.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { BotComponent } from "../bot/bot.component";
import { BotApi } from './botapi-v1.1';

@Component({
  selector: 'app-multi-bot',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SafePipe,
    NgSelectModule,
    HintComponent
  ],
  providers: [BotComponent],
  templateUrl: './multi-bot.component.html',
  styleUrls: ['./multi-bot.component.scss']
})
export class MultiBotComponent implements AfterViewInit, OnInit {
  @ViewChildren('bot') public bots?: QueryList<BotComponent & HTMLElement>;
  public botConfigs: ChatbotConfig[] = [];
  public botApis = new Map<string, { botId: string, api?: BotApi }>();
  public botApisKeys: string[] = [];
  public promptInput: string = '';
  public isThinkingCount = 0;
  public selectedBotId: string = '';
  public selectedBotCount = 1;
  public addBotsModalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {
    this.clearTokens();
  }

  public async ngOnInit(): Promise<void> {
    const configs = (await this.adminService.getBots());
    if (configs.length > 0) {
      this.botConfigs = configs;
    }
  }


  public ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src = 'https://ai.reflact.info/api/botapi-v1.1.js';
    document.body.appendChild(script);

    if (this.route.snapshot.queryParamMap.has('id')) {
      this.selectedBotId = this.route.snapshot.queryParamMap.get('id') ?? '';
      this.selectedBotCount = 3;
      void this.addBots();
    }
  }

  public async addBots() {
    if (this.selectedBotId) {
      for (let i = 0; i < this.selectedBotCount; i++) {
        const botApiId = this.getBotApiId(this.selectedBotId);
        this.botApis.set(botApiId, { botId: this.selectedBotId, api: undefined });
        await this.loginService.loginAsGuest(botApiId, this.getSessionTokenKey(botApiId));
        setTimeout(() => {
          const bot = new BotApi(botApiId);
          bot.onEvent = this.globalEventHandler.bind(this);
          const botinfo = this.botApis.get(botApiId);
          if (botinfo) {
            botinfo.api = bot;
          }
        }, 1000);
        this.botApisKeys = [...this.botApis.keys()];
      }
    }

    this.addBotsModalRef?.hide();
    this.selectedBotId = '';
    this.selectedBotCount = 1;
  }

  public removeBot(botApiId: string) {
    localStorage.removeItem(this.getSessionTokenKey(botApiId));
    this.botApisKeys.splice(this.botApisKeys.findIndex(val => val == botApiId), 1);
    this.botApis.delete(botApiId);
  }

  public getSessionTokenKey(botApiId: string) {
    return 'token_' + botApiId;
  }

  public globalEventHandler(event: MessageData) {
    if (event.type === PostType.runComplete) {
      this.isThinkingCount--;
    }
  }

  private getBotApiId(id: string) {
    let botApiId = '';
    while (botApiId == '') {
      const randomIndex = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
      if (!this.botApis.has(id + '_' + randomIndex.toString()) && localStorage.getItem(this.getSessionTokenKey(id + '_' + randomIndex.toString())) == null) {
        botApiId = id + '_' + randomIndex.toString();
      }
    }
    return botApiId;
  }

  public addMessage() {
    if (this.bots) {
      this.botApis.forEach(botApi => {
        this.isThinkingCount++;
        botApi.api?.userMessage(this.promptInput);
      });
      this.promptInput = '';
    }
  }

  public onKey(e: KeyboardEvent) {
    if (e.key === "Enter") {
      this.addMessage();
    }
  }

  public openAddBots(content: TemplateRef<unknown>) {
    this.addBotsModalRef = this.modalService.show(content);
  }

  public clearTokens() {
    const ts = new Date().getTime() / 1000;
    const keys = Object.keys(localStorage).filter(key => key.startsWith('token_'));
    keys.forEach(key => {
      const token = localStorage.getItem(key);
      if (token) {
        const decoded = jwtDecode('' + token);
        if (decoded.exp && ts > decoded.exp) {
          localStorage.removeItem(key);
        }
      }
    });
  }

  public onChangeBotCount() {
    if (this.selectedBotCount < 1) {
      this.selectedBotCount = 1;
    }

    if (this.selectedBotCount > 5) {
      this.selectedBotCount = 5;
    }

    if (this.selectedBotCount + this.botApis.size > 5) {
      this.selectedBotCount = 5 - this.botApis.size;
    }
  }
}