import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msFormat',
  standalone: true
})
export class MSFormatPipe implements PipeTransform {

  public transform(seconds: number): string {
    if (seconds == 0) return "< 1s";
    return Math.floor(seconds / 1000).toString() + "s " + this.fD(seconds % 1000) + "ms";
  }

  //? Fix Digits
  private fD(n: number): string {
    return n > 9 ? "" + n.toString() : "0" + n.toString();
  }

}
