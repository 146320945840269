import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DraganddropDirective } from 'src/app/shared/directives/draganddrop.directive';
import { ConvertFileSizePipe } from 'src/app/shared/pipes/convertFileSize.pipe';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [DraganddropDirective, ConvertFileSizePipe, FormsModule, CommonModule, TooltipModule],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input({ required: true }) public botId!: string;
  @Input({ required: true }) public allowedFileTypes!: { prefix: string, mimetype: string }[];
  @Input({ required: true }) public typeOfUpload!: string;
  @Input() public isDisabled: boolean = false;
  @Output() public loadAvailableFiles: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public base64Images: EventEmitter<{ name: string, base64: string }[]> = new EventEmitter<{ name: string, base64: string }[]>();
  public selectedFiles: File[] = [];
  public tempPath: string = "";
  public hasUploadBeenClicked: boolean = false;

  constructor(private fileUploadService: FileUploadService, private toastr: ToastrService) { }

  private pushFilesToStorage(files: File[]) {
    const tempArray: File[] = [];
    for (const file of files) {
      if (this.allowedFileTypes.some((type) => file.name.endsWith(type.prefix))) {
        tempArray.push(file);
      } else {
        this.toastr.error(ToastErrorTitle.SAVED, 'Dateityp nicht erlaubt: ' + file.name);
      }
    }
    if (files.length > 0 && this.selectedFiles.length === 0) {
      this.selectedFiles = tempArray;
    } else {
      this.selectedFiles = this.selectedFiles.concat(tempArray);
    }
  }

  public showSelectedFile(event: Event) {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.hasUploadBeenClicked = false;
      const files: File[] = Array.from(fileList);
      this.pushFilesToStorage(files);
    }
  }

  public onFileDropped(files: FileList) {
    if (this.isDisabled) return;
    this.hasUploadBeenClicked = false;
    this.pushFilesToStorage(Array.from(files));
  }

  public async uploadSelectedFiles() {
    if (this.selectedFiles.length > 0) {
      this.hasUploadBeenClicked = true;
      if (this.typeOfUpload === "admin") {
        for (const file of this.selectedFiles) {
          const uploadResult = await this.fileUploadService.uploadDocument(file, this.botId);
          if (uploadResult.response !== "nok") {
            this.selectedFiles = this.selectedFiles.filter((f) => f !== uploadResult.file);
            this.loadAvailableFiles.emit(true);
            this.tempPath = "";
          }
        }

      } else if (this.typeOfUpload === "user") {
        const base64ImageArray: { name: string, base64: string }[] = [];
        for (const file of this.selectedFiles) {
          const reader = new FileReader();

          reader.onloadend = () => {
            base64ImageArray.push({ name: file.name, base64: reader.result as string });
          };

          reader.readAsDataURL(file);
        }
        this.hasUploadBeenClicked = false;
        this.selectedFiles = [];
        this.base64Images.emit(base64ImageArray);
      }
    }
  }

  public removeSelectedFile(file: File) {
    if (this.selectedFiles.length) {
      if (this.selectedFiles.some((f) => f === file)) {
        this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
        this.tempPath = "";
      }
    }
  }
}
