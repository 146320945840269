import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CodeEditorAiComponent } from 'src/app/shared/components/ai-bot/utils/code-editor-ai/code-editor-ai.component';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { IcoWizardComponent } from './ico-wizard/ico-wizard.component';

@Component({
  templateUrl: './bot-task-tab.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, HintComponent, CodeEditorAiComponent]
})
export class BotTaskTabComponent {

  private icoWizardModalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private adminService: AdminService,
    public toastr: ToastrService,
    public loginService: LoginService,
    public rss: RouteShareService
  ) { }

  public openICOWizard() {
    const options: ModalOptions = {
      class: 'modal-lg',
      initialState: {
        botConfig: { ...this.rss.botConfig }
      }
    };
    this.icoWizardModalRef = this.modalService.show(IcoWizardComponent, options);
  }

  protected async enableBotPw() {
    if ((this.rss.botConfig.editMode === "creator" && this.loginService.loggedInUser?._id == this.rss.botConfig.creator) || this.rss.botConfig.editMode === "everyone") {
      const result = await this.adminService.setRandomPassword(this.rss.botConfig._id);
      this.rss.botConfig.botPassword = result.pw;
    }
    void this.rss.saveBotConfig();
  }

  protected saveCustomPw() {
    if ((this.rss.botConfig.editMode === "creator" && this.loginService.loggedInUser?._id == this.rss.botConfig.creator) || this.rss.botConfig.editMode === "everyone") {
      if (this.rss.botConfig.botPassword.trim() === '') {
        this.toastr.error(ToastErrorTitle.GENERAL, 'Bitte ein Passwort eingeben');
      } else if (this.rss.botConfig.botPassword.length < 4) {
        this.toastr.error(ToastErrorTitle.GENERAL, 'Das Passwort muss mindestens 4 Zeichen lang sein');
      } else {
        void this.rss.saveBotConfig();
      }
    }
  }

  protected async saveAutoDeleteTime() {
    if (this.rss.botConfig.secondsToWaitForThreadDeleting < 1) {
      await new Promise(r => setTimeout(r, 25));
      this.rss.botConfig.secondsToWaitForThreadDeleting = 5;
    }
    void this.rss.saveBotConfig();
  }
}
