<h3 i18n="@@if">Wenn</h3>
<div
  class="callout callout-info"
  i18n="@@if-then-description"
>
  Mit Wenn-Dann-Regeln können Sie festlegen, welche Aktionen der Bot basierend auf bestimmten Bedingungen ausführen
  soll. Wenn eine festgelegte Bedingung erfüllt ist, wird automatisch die entsprechende Aktion ausgeführt.
</div>
<div class="card border-0">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <span i18n="@@trigger">Auslöser</span>
      </div>
      <div class="col">
        <input
          type="text "
          class="form-control rag-aitext"
          i18n-placeholder="@@describe-if"
          placeholder="Beschreiben Sie wann die KI reagieren soll"
          [(ngModel)]="this.customToolKitDescription"
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <span i18n="@@optional-functionname">Funktionsname (optional)</span>
      </div>
      <div class="col-6">
        <app-function-name-suggest
          [descriptionString]="this.customToolKitDescription"
          (finish)="this.customToolKitName = $event"
        >
          <input
            type="text"
            class="form-control"
            i18n-placeholder="@@name"
            placeholder="Name"
            maxlength="30"
            [(ngModel)]="this.customToolKitName"
          >
        </app-function-name-suggest>
      </div>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <div
          class="btn btn-primary btn-sm "
          (click)="this.addCustomToolKit()"
        >
          <i class="ri-add-line"></i> <ng-container i18n="@@add">Hinzufügen</ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<h5
  class="mt-5"
  *ngIf="this.customToolKits.length > 0"
  i18n="@@if-then-overview"
>Wenn-Dann-Regeln im Überblick</h5>
<div class="full-width-table-wrapper">
  <table class="table table-hover">
    <tbody>
      @for (custom of this.customToolKits; track $index) {
      <tr>
        <td style="min-width: 4rem; text-align: left; vertical-align: middle;">

          <ng-container
            *ngFor="let i of [].constructor(this.skillService.getSkillDepth(custom.skillId)); let idx = index; let count = count"
          >
            <i
              class="ri-corner-down-right-line text-secondary ms-2"
              [ngStyle]="{'opacity': idx === count - 1 ? 1 : 0}"
            ></i>
          </ng-container>
          <i
            class="ri-circle-fill text-primary me-2"
            *ngIf="custom.enabled"
          ></i>
          <i
            class="ri-circle-line text-secondary me-2"
            *ngIf="!custom.enabled"
          ></i>




          <app-in-place-validator
            [validators]="requiredValidator"
            [text]="custom.description"
          ></app-in-place-validator>
          <span
            class="fakelink"
            [routerLink]="[custom.id]"
          >{{custom.description}}</span>

          <span class="ps-3 text-muted"><i>{{custom.name}}</i></span>

        </td>
        <td style="vertical-align: middle;width:250px">

          <app-skill-id-input
            [value]="custom.skillId"
            [readonly]="false"
            (changed)="custom.skillId = $event; this.rss.saveBotConfig()"
          ></app-skill-id-input>

        </td>
        <td style="vertical-align: middle; width: 0rem;">

          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              [(checked)]="custom.enabled"
              (change)="custom.enabled=!custom.enabled; this.rss.saveBotConfig()"
            >
          </div>
        </td>
        <td
          style="vertical-align: middle; width: 2rem;"
          class="text-end"
        >
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  [routerLink]="[custom.id]"
                >
                  <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  (click)="this.copyCustomToolKit(custom.id)"
                >
                  <i class="ri-file-copy-fill"></i> <ng-container i18n="@@copy">Duplizieren</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-danger"
                  (click)="this.removeCustomToolKit(custom.id)"
                >
                  <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>