import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse, Org, User } from '@reflact/ai-types';
import { jwtDecode } from "jwt-decode";
import { firstValueFrom } from 'rxjs';
import { SocketAdminService } from './socketadmin.service';

export const getToken = (key = 'token') => {
  const currentToken = localStorage.getItem(key) ?? '';
  try {
    const decoded = jwtDecode("" + currentToken);
    if (decoded.exp != null && Date.now() < decoded.exp * 1000) {
      return currentToken;
    }
  } catch (e) {
    console.warn("token invalid", e);
  }
  localStorage.removeItem(key);
  return null;
};

export const getTokenContents = (key = 'token'): { _id: string } => {
  const currentToken = localStorage.getItem(key) ?? '';
  try {
    const decoded = jwtDecode("" + currentToken);
    if (decoded.exp != null && Date.now() < decoded.exp * 1000) {
      return decoded as unknown as { _id: string };
    }
  } catch (e) {
    console.warn("token invalid", e);
  }
  return { _id: "" };
};

export const logout = () => {
  localStorage.removeItem('token');
  if (window.location.origin.startsWith("http://localhost") || window.location.origin.startsWith("https://dev-ki-toolbox.reflactportal.com")) {
    window.location.href = 'https://portal-dev.reflact.info/portal/de/login';
  } else {
    window.location.href = 'https://reflactportal.com/portal/de/login';
  }
};

type SystemConf = "kitoolbox" | "devkitoolbox" | "stagingkitoolbox"
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loggedInUser: null | User<string, string> = null;

  public myOrgs: Org[] = [];

  public systemConf: SystemConf | "loading" = "loading";

  constructor(private httpClient: HttpClient, public socketAdmin: SocketAdminService) {
    const token = this.getToken();

    if (token != null && token != "") {
      void this.loadMyUser();
    }
    void this.loadSystemConf();
  }

  public async loadSystemConf() {
    this.systemConf = (await firstValueFrom(this.httpClient.get<DefaultResponse & { appIdentifier: SystemConf }>('/api/conf'))).appIdentifier;
  }



  public async loginFromPortal(token: string): Promise<boolean> {
    const tokenResult = await firstValueFrom<{ token: string }>(this.httpClient.post<{ token: string }>('/api/toolbox/auth/loginbyportaltoken', null, { headers: { portaltoken: token } }));
    if (tokenResult.token) {
      this.setToken(tokenResult.token);
      await this.loadMyUser();
      return true;
    } else {
      return false;
    }
  }

  public hasBotAdmin() {
    return this.loggedInUser?.admin === true;
  }

  public async loginAsGuest(userId: string = '', customToken = '') {
    //TODO: wenn es einen get parameter mit user id gibt dann keine randm id generieren
    if (userId == '') {
      userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    const tokenResult = await firstValueFrom(this.httpClient.post<{ token: string }>('/api/toolbox/auth/loginasguest', { userId }));
    this.setToken(tokenResult.token, customToken == '' ? 'token' : customToken);

  }

  public async loadMyUser() {
    if (getToken() == null) {
      console.warn("user not logged in for loadMyUser");
      this.loggedInUser = null;
      return;
    }
    try {
      const loaded = await firstValueFrom(this.httpClient.get<User<string, string>>('/api/toolbox/auth/myuser'));

      this.loggedInUser = {
        ...loaded
      };
      await this.loadMyOrgs();
      this.socketAdmin.connect("" + getToken());
    } catch (e) {
      console.warn("user not logged in for loadMyUser", e);
      this.loggedInUser = null;
    }
  }

  public async loadMyOrgs() {
    const orgs = await firstValueFrom(this.httpClient.get<Org[]>('/api/toolbox/auth/myorgs'));
    this.myOrgs = orgs;
  }

  private setToken(token: string, key = 'token') {
    localStorage.setItem(key, token);
  }

  public getToken(): string | null {
    return getToken();
  }
}