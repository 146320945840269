import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { BotService } from 'src/app/shared/services/bot.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { BotWordReplaceComponent } from './bot-word-replace/bot-word-replace.component';
import { BotWordStopComponent } from './bot-word-stop/bot-word-stop.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HintComponent,
    BotWordStopComponent,
    BotWordReplaceComponent
  ],
  templateUrl: './privacy-policy-tab.component.html',
  styleUrl: './privacy-policy-tab.component.scss'
})
export class PrivacyPolicyTabComponent {

  constructor(private bot: BotService, public rss: RouteShareService) { }

  public async reset() {
    this.rss.botConfig.complianceText = await this.bot.getDefaultComplianceText();
    void this.rss.saveBotConfig();
  }

  public deleteText() {
    this.rss.botConfig.complianceText = '';
    void this.rss.saveBotConfig();
  }
}