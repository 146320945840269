<app-disconnect-overlay></app-disconnect-overlay>
<div
  #content
  class="d-flex align-items-center justify-content-around flex-column p-3"
  style="height: 100vh; width: 100vw;"
>
  <div class="flex-grow-1"></div>
  <div
    class="w-100"
    [ngStyle]="{'max-width': this.getHeaderFooterWidth(this.getContentMaxHeight(promptElement.offsetHeight + header.offsetHeight)) + 'px'}"
  >
    <div
      class="row justify-content-between pb-3"
      #header
    >
      <div class="col-auto d-flex align-content-center">
        <ng-container *ngIf="this.viewState === 'INITIAL'">{{this.botConfig.name}}</ng-container>
        <ng-container *ngIf="this.viewState !== 'CONNECTED'">
          <i
            class="ri-circle-fill"
            [ngStyle]="{'visibility': this.lastStatusMesage !== '' ? 'visible' : 'hidden'}"
          ></i> {{this.lastStatusMesage}}
        </ng-container>
        <ng-container *ngIf="this.viewState ==='CONNECTED'">
          <i class="ri-circle-fill text-primary"></i>Online
          <span
            *ngIf="this.botState !== 'idle'"
            class=""
          >&nbsp;Nachricht wird verarbeitet
          </span>
        </ng-container>
      </div>
      <div class="col-auto">
        <button
          *ngIf="this.viewState ==='CONNECTED'"
          class="btn btn-danger btn-sm"
          (click)="this.closeConnectionHandler()"
        >
          <i class="ri-video-off-fill"></i> Video beenden
        </button>
        <div
          *ngIf="this.viewState==='INITIAL'"
          class="btn btn-danger btn-sm invisible"
        >
          <i class="ri-video-off-fill"></i> Abbrechen
        </div>
        <div
          *ngIf="this.viewState==='CONNECTING'"
          class="btn btn-danger btn-sm"
          (click)="this.layoutInitial()"
        >
          <i class="ri-video-off-fill"></i> Abbrechen
        </div>
      </div>
    </div>

    <div
      #contentElement
      [class]="'inner-content ' + this.viewState"
      [ngStyle]="{
        'background-image': (this.viewState === 'INITIAL' || this.viewState === 'CONNECTING') ? 'url('+this.avatarPreviewUrl+')' : 'none',
        'max-width': this.getContentWidth() + 'px',
        'max-height': this.getContentMaxHeight(promptElement.offsetHeight + header.offsetHeight) + 'px'
        }"
      style="aspect-ratio: 16 / 9;"
    >
      <div
        class="content-overlay"
        [ngStyle]="{
          'width': contentElement.offsetWidth + 'px',
          'height': (mediaElement.offsetHeight === 0 ? contentElement.offsetHeight : mediaElement.offsetHeight) + 'px'
        }"
      >
        <ng-container *ngIf="this.viewState === 'INITIAL'">
          <button
            class=" btn btn-danger bg-white px-3 py-3 position-absolute text-danger border-0 connect-video-avatar"
            style="bottom: 30px;"
            (click)="createNewSession()"
          >
            <i class="ri-vidicon-fill ri-2x"></i><br>
            Video Verbinden

          </button>
        </ng-container>
        <ng-container *ngIf="this.viewState === 'CONNECTING'">
          <div>

            <div
              class="spinner-border "
              role="status"
            >
              <span class="sr-only"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.viewState === 'CONNECTED'">
          <div
            style="bottom: 30px;position: absolute"
            *ngIf="!this.hasMsgBeenSend"
          >
            <app-push-to-talk-record-button
              [botState]="this.botState"
              (startRecording)="this.killAllBotCommunicationImidiatly()"
              (resultRecording)="this.onResultRecording($event)"
              (doneRecordingAfterClick)="this.sendMsg($event)"
              (doneRecordingAfterHold)="this.sendMsg($event)"
            ></app-push-to-talk-record-button>
          </div>
          <div
            style="bottom: 30px;position: absolute"
            *ngIf="this.hasMsgBeenSend"
          >
            <button
              class="stfu-button bg-danger text-white"
              (click)="this.killAllBotCommunicationImidiatly();"
            >
              <i class="ri-stop-large-fill ri-xl"></i>
            </button>
          </div>
        </ng-container>
      </div>
      <video
        #mediaElement
        class="video-elem"
        [ngClass]="{'d-none': this.viewState !== 'CONNECTED' }"
        style="height: 100%;"
        [ngStyle]="{
          'max-width': this.getContentWidth() + 'px',
          'max-height': this.getContentMaxHeight(promptElement.offsetHeight + header.offsetHeight) + 'px'
        }"
        autoplay
      ></video>
    </div>
    <div
      class="chat-input w-100 pt-3"
      #promptElement
      [ngClass]="{'pointer-events-none': this.viewState !== 'CONNECTED'}"
    >
      <app-bot-prompt
        *ngIf="this.botConfig.promptbarAvatarActive"
        [botConfig]="this.botConfig"
        [isThinking]="this.botState !== 'idle'"
        [cancelThinking]="this.botConfig.cancelBotThinkingAvatarActive"
        [voiceInput]="this.botConfig.voiceInputAvatarActive"
        [switchToChat]="this.botConfig.switchToChatFromAvatar"
        [switchToVoice]="this.botConfig.switchToVoiceFromAvatar"
        [(promptInput)]="this.myWords"
        (sendPrompt)="this.sendMsg(this.myWords)"
        (stfu)="this.cancelThinking()"
      ></app-bot-prompt>
      <ng-container *ngIf="!this.botConfig.promptbarAvatarActive">
        <div class="text-center">
          &nbsp;
          {{this.myWords}}
          &nbsp;
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex-grow-1"></div>
</div>