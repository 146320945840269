<ng-container *ngIf="!preMode">
  <div
    class="btn btn-primary w-100 btn-sm "
    (click)="
    this.toggleView()"
    i18n="@@open-raw"
  >Öffne Rohtext
  </div>
  <markdown [data]="this.contents"></markdown>
</ng-container>

<ng-container *ngIf="preMode">
  <div
    class="btn btn-primary w-100 btn-sm"
    (click)="
    this.toggleView()"
    i18n="@@open-markdown"
  >Öffne Markdown</div>
  <pre>{{this.contents}}</pre>
</ng-container>
