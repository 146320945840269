<div class="d-flex align-items-center justify-content-between flex-column  h-100">
  <div></div>
  <div class="card border-0">
    <div class="card-header text-start d-none">
      <h4 class="card-title">Login für Bot "<i>{{ this.botConfig.name }}</i>"</h4>
    </div>
    <div class="card-body">
      <p>Bitte geben Sie das Passwort für diesen Bot ein:</p>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="botProtect"
          placeholder="123456"
          [(ngModel)]="this.botPw"
        >
        <label for="botProtect">Bot Passwort</label>
      </div>
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="this.sendBotPw()"
      >Senden</button>
    </div>
  </div>




  <div>
    <a
      href="https://reflact.com/datenschutz/"
      target="_blank"
      class="text-decoration-none text-muted"
    >
      <small>Datenschutzhinweis</small>
    </a>
  </div>



</div>