<div
  class="d-inline-block"
  style="padding-left: 17px;"
  *ngIf="getCard().type === 'youtubeCard'"
>
  <iframe
    width="auto"
    [src]="('https://www.youtube-nocookie.com/embed/' + getCard().data) | safe"
    title="YouTube video player"
    frameborder="0"
    allow="display-capture;camera;microphone;geolocation; fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
  <div class="text-end">
    <i
      *ngIf="!this.deleting"
      class="ri-close-line text-danger ms-1"
      (click)="deleteMessageAndLoad(this.message)"
    ></i>
    <div
      *ngIf="this.deleting"
      class="spinner-border spinner-border-sm text-danger"
      role="status"
    >
      <span
        class="visually-hidden"
        i18n="@@loading"
      >Loading...</span>
    </div>
  </div>
</div>

<div
  [class]="'message-card-iframe ' + this.getIframeCard().data.cssClass"
  style="padding-left: 17px;"
  *ngIf="getCard().type === 'iframeCard'"
>
  <iframe
    width="auto"
    [src]="this.getIframeCard().data.url | safe"
    style="border: 1px solid black;"
    allow="display-capture;camera;microphone;geolocation; fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
  ></iframe>
  <div class="text-end">
    <i
      *ngIf="!this.deleting"
      class="ri-close-line text-danger ms-1"
      (click)="deleteMessageAndLoad(this.message)"
    ></i>
    <div
      *ngIf="this.deleting"
      class="spinner-border spinner-border-sm text-danger"
      role="status"
    >
      <span
        class="visually-hidden"
        i18n="@@loading"
      >Loading...</span>
    </div>
  </div>
</div>

<div
  class="d-inline-block"
  style="padding-left: 17px;"
  *ngIf="getVimeoCard().type === 'vimeoCard'"
>
  <iframe
    [src]="('https://player.vimeo.com/video/' + this.getVimeoCard().data.id + '?h=' + this.getVimeoCard().data.hash) | safe"
    frameborder="0"
  ></iframe>
  <div class="text-end">
    <i
      *ngIf="!this.deleting"
      class="ri-close-line text-danger ms-1"
      (click)="deleteMessageAndLoad(this.message)"
    ></i>
    <div
      *ngIf="this.deleting"
      class="spinner-border spinner-border-sm text-danger"
      role="status"
    >
      <span
        class="visually-hidden"
        i18n="@@loading"
      >Loading...</span>
    </div>
  </div>
</div>

<div
  [class]="'message-card-image ' + this.getImageCard().data.cssClass"
  style="padding-left: 17px;"
  *ngIf="getImageCard().type === 'imageCard'"
>
  <img
    [src]="this.getImageCard().data.src| safe"
    [alt]="this.getImageCard().data.altText"
  >
  <div class="text-end">
    <i
      *ngIf="!this.deleting"
      class="ri-close-line text-danger ms-1"
      (click)="deleteMessageAndLoad(this.message)"
    ></i>
    <div
      *ngIf="this.deleting"
      class="spinner-border spinner-border-sm text-danger"
      role="status"
    >
      <span
        class="visually-hidden"
        i18n="@@loading"
      >Loading...</span>
    </div>
  </div>
</div>
