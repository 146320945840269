/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RagaibotviewhelperService {
  public showOverlay(text: string, onClose?: () => void) {
    const existingOverlay = document.getElementById('custom-overlay');
    if (existingOverlay) existingOverlay.remove();

    const overlay = document.createElement('div');
    overlay.id = 'custom-overlay';
    overlay.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);color:white;display:flex;align-items:center;justify-content:center;z-index:2000;font-size:1.5em;padding:20px;box-sizing:border-box;';

    const content = document.createElement('div');
    content.style.cssText = 'position:relative;text-align:left;max-width:80%;max-height:80%;overflow:auto;';

    const closeButton = document.createElement('span');
    closeButton.id = 'custom-overlay-close';
    closeButton.innerHTML = '&times;';
    closeButton.style.cssText = 'position:fixed;top:20px;right:20px;font-size:2em;font-weight:bold;color:white;cursor:pointer;z-index:2001;';
    closeButton.onclick = () => {
      overlay.remove();
      if (onClose) onClose();
    };

    const textElement = document.createElement('p');
    textElement.style.cssText = 'white-space:pre-wrap;text-align:left;';
    textElement.innerHTML = text.replace(/\n/g, '<br>');

    content.appendChild(textElement);
    overlay.appendChild(closeButton);
    overlay.appendChild(content);
    document.body.appendChild(overlay);
  }

  public closeWindow() {
    try {
      let currentWindow = window;
      let depth = 0;
      // Iterativ zum obersten schließbaren Fenster navigieren
      while (currentWindow !== currentWindow.parent) {
        depth++;
        if (depth > 10) { // Sicherheitsgrenze für unendliche Loops
          console.error("Zu viele Parent-Ebenen gefunden. Abbruch.");
          return;
        }
        //@ts-ignore
        currentWindow = currentWindow.parent;
      }
      // Versuchen, das Fenster zu schließen
      console.log("Versuche das Fenster zu schließen...");
      currentWindow.close();
    } catch (error) {
      console.error("Fehler beim Schließen des Fensters:", error);

    }
  }

  public copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(function () {
      alert("Text wurde in die Zwischenablage kopiert!");
    }).catch(function (error) {
      alert("Fehler beim Kopieren in die Zwischenablage: " + error);
    });

  }

  public downloadTextAsFile(text: string, filename: string) {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  public showOverlayWithTextAndButtons(text: string, buttons: { text: string, onClick: () => void, className?: string }[], onClose?: () => void) {
    const existingOverlay = document.getElementById('custom-overlay');
    if (existingOverlay) existingOverlay.remove();

    const overlay = document.createElement('div');
    overlay.id = 'custom-overlay';
    overlay.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);color:white;display:flex;align-items:center;justify-content:center;z-index:1000;font-size:1.5em;padding:20px;box-sizing:border-box;';

    const content = document.createElement('div');
    content.style.cssText = 'position:relative;text-align:center;max-width:80%;max-height:80%;overflow:auto;';

    const closeButton = document.createElement('span');
    closeButton.id = 'custom-overlay-close';
    closeButton.innerHTML = '&times;';
    closeButton.style.cssText = 'position:fixed;top:20px;right:20px;font-size:2em;font-weight:bold;color:white;cursor:pointer;z-index:1001;';
    closeButton.onclick = () => {
      overlay.remove();
      if (onClose) onClose();
    };

    const textElement = document.createElement('p');
    textElement.style.cssText = 'white-space:pre-wrap;text-align:left;margin-bottom:20px;';
    textElement.innerHTML = text.replace(/\n/g, '<br>');

    content.appendChild(textElement);

    buttons.forEach(buttonData => {
      const button = document.createElement('button');
      button.innerText = buttonData.text;
      button.style.cssText = 'margin:10px;padding:10px 20px';
      button.className = 'btn btn-primary';
      if (buttonData.className) {
        button.className = buttonData.className;
      }
      button.onclick = buttonData.onClick;
      content.appendChild(button);
    });

    overlay.appendChild(closeButton);
    overlay.appendChild(content);
    document.body.appendChild(overlay);
  }



  public showOverlayWithButtons(buttons: { text: string, onClick: () => void, className?: string }[], onClose?: () => void) {
    const existingOverlay = document.getElementById('custom-overlay');
    if (existingOverlay) existingOverlay.remove();

    const overlay = document.createElement('div');
    overlay.id = 'custom-overlay';
    overlay.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);color:white;display:flex;align-items:center;justify-content:center;z-index:1000;font-size:1.5em;padding:20px;box-sizing:border-box;';

    const content = document.createElement('div');
    content.style.cssText = 'position:relative;text-align:center;max-width:80%;max-height:80%;overflow:auto;';

    const closeButton = document.createElement('span');
    closeButton.innerHTML = '&times;';
    closeButton.style.cssText = 'position:fixed;top:20px;right:20px;font-size:2em;font-weight:bold;color:white;cursor:pointer;z-index:1001;';
    closeButton.onclick = () => {
      overlay.remove();
      if (onClose) onClose();
    };

    buttons.forEach(buttonData => {
      const button = document.createElement('button');
      button.innerText = buttonData.text;
      button.style.cssText = 'margin:10px;padding:10px 20px';
      button.className = 'btn btn-primary';
      if (buttonData.className) {
        button.className = buttonData.className;
      }
      button.onclick = buttonData.onClick;
      content.appendChild(button);
    });

    overlay.appendChild(closeButton);
    overlay.appendChild(content);
    document.body.appendChild(overlay);
  }

}
