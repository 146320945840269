<div
  class="row m-0 align-items-center"
  tooltip="Speicherbelegung"
  i18n-tooltip="@@tooltipUserChunks"
>
  <div class="col-auto">
    <i class="ri-hard-drive-3-line text-black"></i>
  </div>
  <div class="col px-0 mx-0 pe-2">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped"
        role="progressbar"
        aria-label="User"
        [ngStyle]="{'width': this.percentage()  * 100  + '%'}"
      >{{this.percentage() | percent}}</div>
    </div>
  </div>
</div>
