/* eslint-disable */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export const limitApiPaths = ["/api/toolbox/membot/user"];


@Injectable()
export class HttpConcurrencyInterceptor implements HttpInterceptor {
  private pendingRequests: HttpRequest<any>[] = [];
  private pendingRequestCount = 0;
  private pendingRequestsQueue: { reqID: string, request: HttpRequest<any> }[] = [];

  private responseBehvaiorSubjectObject: any = {};

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if there are 10 pending requests
    if (!limitApiPaths.some(p => request.url.startsWith(p))) {
      return next.handle(request);
    }
    if (this.pendingRequestCount >= 1) {
      // Push the new request into the pending queue
      const reqID: string = "" + Math.floor(Math.random() * 10000) + 1;
      this.pendingRequestsQueue.push({
        reqID,
        request: request
      });
      let responseBehaviorSubject: BehaviorSubject<HttpEvent<any>> = new BehaviorSubject<any>(null);
      this.responseBehvaiorSubjectObject[reqID] = responseBehaviorSubject;
      return this.responseBehvaiorSubjectObject[reqID].asObservable();
    } else
      this.responseBehvaiorSubjectObject = {};
    // Increment the pending request count
    this.pendingRequestCount++;
    // Push the request into the array of pending requests
    this.pendingRequests.push(request);

    return next.handle(request).pipe(
      tap(
        (event: any) => {
          if (event instanceof HttpResponse)
            this.sendRequestFromQueue(next);
          return event;
        }
      ),
      catchError((err: any) => {
        this.sendRequestFromQueue(next);
        return throwError(() => new Error(err))
      })
    );
  }

  private processNextRequest(request: HttpRequest<any>, next: HttpHandler, reqID: string): void {
    next.handle(request).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          this.sendRequestFromQueue(next);
        }
        if (event) {
          this.responseBehvaiorSubjectObject[reqID].next(event);
        }
      },
      (error: any) => {
        this.responseBehvaiorSubjectObject[reqID].error({
          name: 'Guru',
          url: 'ERROR'
        });
      }
    );
  }

  // Function to Send Checks Queu and Send REQUEST FROM Queue
  private sendRequestFromQueue(next: HttpHandler) {
    // If request is successful, decrement the pending request count
    this.pendingRequestCount--;
    // Process the pending queue if there are any requests
    if (this.pendingRequestsQueue.length > 0) {
      const nextOne = this.pendingRequestsQueue.shift();
      const reqID: any = nextOne?.reqID;
      const nextRequest: any = nextOne?.request;
      this.processNextRequest(nextRequest, next, reqID);
    }
  }
}