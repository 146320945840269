import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DialogMessage } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BotChatDialogComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/bot-chat-dialog.component';
import { FormForBooleanComponent } from 'src/app/shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BotService } from 'src/app/shared/services/bot.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { BotMemoryBrowserFilterComponent } from './bot-memory-browser-filter/bot-memory-browser-filter.component';

@Component({
  standalone: true,
  templateUrl: './memory-tab.component.html',
  styleUrl: './memory-tab.component.scss',
  imports: [
    NgSelectModule,
    CommonModule,
    FormsModule,
    BotChatDialogComponent,
    NgbDatepickerModule,
    BotMemoryBrowserFilterComponent,
    FormForBooleanComponent,
    HintComponent
  ]
})
export class MemoryTabComponent implements OnInit {
  @ViewChild('myDialog') public myDialog?: BotChatDialogComponent & HTMLElement;
  @ViewChild('deleteFilter') public deleteFilter!: BotMemoryBrowserFilterComponent;
  @ViewChild('messageFilter') public messageFilter!: BotMemoryBrowserFilterComponent;
  public promptInput: string = '';
  public isThinking = false;
  public bsModalRef?: BsModalRef;

  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private bot: BotService,
    public rss: RouteShareService,
    private botMessages: BotMessagesService
  ) { }

  public ngOnInit() {
    this.botMessages.addMessage({
      _id: "",
      openAiMessageId: "",
      context: {
        botid: this.rss.botConfig._id,
        groupid: "",
        user: ""
      },
      openAiThreadId: "",
      memoryEnabled: this.rss.botConfig.memoryEnabled,
      created: new Date(),
      role: 'assistant',
      content: $localize`:@@memory-browser-initialmessage:<p>Hallo, ich bin das Gedächnis von <i>${this.rss.botConfig.name}</i>.</p><p>Ich beantworte Ihre Fragen rund um mein gesammeltes Wissen.<br>Mit den Filtern etwas weiter oben können Sie spezifizieren, auf welches Wissen ich zugreifen soll.</p>`
    });
  }

  public getBotConfig() {
    return { ...this.rss.botConfig };
  }

  public onKey(e: KeyboardEvent) {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        void this.add();
      }
    }
  }

  public async add() {
    let fromDate;
    let toDate;

    if (this.messageFilter.multiCopyFromDate) {
      fromDate = dayjs();
      fromDate = fromDate.set('date', this.messageFilter.multiCopyFromDate.day);
      fromDate = fromDate.set('month', this.messageFilter.multiCopyFromDate.month - 1);
      fromDate = fromDate.set('year', this.messageFilter.multiCopyFromDate.year);
      fromDate = fromDate.startOf('day');
    }

    if (this.messageFilter.multiCopyToDate) {
      toDate = dayjs();
      toDate = toDate.set('date', this.messageFilter.multiCopyToDate.day);
      toDate = toDate.set('month', this.messageFilter.multiCopyToDate.month - 1);
      toDate = toDate.set('year', this.messageFilter.multiCopyToDate.year);
      toDate = toDate.endOf('day');
    }

    this.isThinking = true;
    this.botMessages.addMessage({
      _id: "",
      openAiMessageId: "",
      context: {
        botid: this.rss.botConfig._id,
        groupid: "",
        user: ""
      },
      openAiThreadId: "",
      memoryEnabled: this.rss.botConfig.memoryEnabled,
      created: new Date(), role: 'user', content: this.promptInput
    });
    void this.bot.scrollDown();
    this.promptInput = '';
    const result = await this.bot.askSummary(this.rss.botConfig._id, {
      prompts: this.botMessages.getMessages(),
      groupIds: this.messageFilter.selectedGroupIds,
      fromDate,
      toDate
    });
    if (result.choices[0]) {
      this.botMessages.addMessage({
        ...(result.choices[0].message as DialogMessage),
        _id: "",
        openAiMessageId: "",
        context: {
          botid: this.rss.botConfig._id,
          groupid: "",
          user: ""
        },
        memoryEnabled: this.rss.botConfig.memoryEnabled,
        openAiThreadId: "",
        created: new Date()
      });
    }
    void this.bot.scrollDown();
    this.isThinking = false;
  }

  public openConfirmDeleteHistory(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public async deleteHistory() {
    let fromDate;
    let toDate;

    if (this.deleteFilter.multiCopyFromDate) {
      fromDate = dayjs();
      fromDate = fromDate.set('date', this.deleteFilter.multiCopyFromDate.day);
      fromDate = fromDate.set('month', this.deleteFilter.multiCopyFromDate.month - 1);
      fromDate = fromDate.set('year', this.deleteFilter.multiCopyFromDate.year);
      fromDate = fromDate.startOf('day');
    }

    if (this.deleteFilter.multiCopyToDate) {
      toDate = dayjs();
      toDate = toDate.set('date', this.deleteFilter.multiCopyToDate.day);
      toDate = toDate.set('month', this.deleteFilter.multiCopyToDate.month - 1);
      toDate = toDate.set('year', this.deleteFilter.multiCopyToDate.year);
      toDate = toDate.endOf('day');
    }

    const result = await this.bot.deleteHistoryItems(this.rss.botConfig._id, {
      groupIds: this.deleteFilter.selectedGroupIds,
      fromDate,
      toDate
    });

    this.modalService.hide(this.bsModalRef?.id);
    this.toastr.success(ToastSuccessTitle.HISTORY_DELETED, `${result.deletedCount.toString()} History-Einträge wurden gelöscht`);
    void this.deleteFilter.updateDeleteCount();
  }
}