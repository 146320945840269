<h3 i18n="@@online-sources">Online-Quellen</h3>
<div
  class="callout callout-info"
  i18n="@@online-sources-description"
>
  Online-Quellen sind Websites, die direkt von der KI abgerufen werden können. Dadurch können aktuelle Daten oder
  spezifische Informationen in Echtzeit in Ihre Anwendung integriert werden.
</div>
<div class="card border-0">
  <div class="card-body">
    <div class="row ">
      <div class="col-3">
        <span i18n="@@source-usage-description">Beschreiben Sie, wofür diese Quelle verwendet wird</span>
      </div>
      <div class="col"><input
          class="form-control rag-aitext"
          i18n-placeholder="@@description"
          placeholder="Beschreibung"
          [(ngModel)]="this.toolDescription"
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <span i18n="@@url">URL</span>
      </div>
      <div class="col">
        <input
          class="form-control "
          placeholder="https://"
          [(ngModel)]="this.toolUrl"
        >
        <div
          class="btn btn-primary btn-sm mt-2"
          [ngClass]="{'disabled': !this.isConfigValid()}"
          (click)="this.addToolConfig()"
        >
          <i class="ri-add-line"></i> <ng-container i18n="@@add">Hinzufügen</ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<h5
  class="mt-5"
  *ngIf="toolConfigs.length > 0"
  i18n="@@knowledge-from-online-sources"
>Wissen aus Online-Quellen im Überblick</h5>
<div class="full-width-table-wrapper">
  <table class="table table-hover">
    <tbody>
      @for (toolConfig of this.toolConfigs; track $index) {
      <tr>
        <td style=" vertical-align: middle; width: 0px;">
          <i
            class="ri-circle-fill text-primary"
            *ngIf="toolConfig.enabled"
          ></i>
          <i
            class="ri-circle-line  text-secondary"
            *ngIf="!toolConfig.enabled"
          ></i>
        </td>
        <td
          class="text-truncate"
          style=" vertical-align: middle;"
        >
          <a
            class="cursor-pointer text-decoration-underline text-primary"
            [routerLink]="[toolConfig.id]"
          >{{ toolConfig.url }}</a>
          <i class="text-muted ps-3">{{ toolConfig.description }}</i>
        </td>
        <td style="vertical-align: middle;width:250px">
          <app-skill-id-input
            [value]="toolConfig.skillId"
            (changed)="toolConfig.skillId = $event; this.saveToolConfig(toolConfig)"
          ></app-skill-id-input>
        </td>
        <td style="vertical-align: middle; width: 2rem;">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              [(checked)]="toolConfig.enabled"
              (change)="toolConfig.enabled = !toolConfig.enabled; this.updateToolConfigItem(toolConfig.id)"
            >
          </div>
        </td>
        <td
          style="vertical-align: middle; width: 2rem;"
          class="text-end"
        >
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  [routerLink]="[toolConfig.id]"
                >
                  <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                </button>
              </li>
              <li *ngIf="toolConfig.tool === 'websitesearch'">
                <button
                  type="button"
                  class="dropdown-item cursor-pointer"
                  (click)="this.showMDPreview(toolConfig.id)"
                >
                  <i class="ri-eye-line"></i> <ng-container i18n="@@md-preview">Markdown-Vorschau</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-danger"
                  (click)="this.removeToolConfig(toolConfig.id)"
                >
                  <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>