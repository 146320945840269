import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Event, NavigationEnd, NavigationSkipped, NavigationStart, Router } from '@angular/router';
import { ServiceStatus } from '@reflact/ai-types';
import dayjs from 'dayjs';
import locale_de from 'dayjs/locale/de';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { LanguageService } from './shared/services/language.service';
import { LoginService } from './shared/services/login.service';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public title = 'ki-toolbox-app';
  public route: string = "";
  public status: ServiceStatus = "waiting";
  public isFullScreenLayout = false;
  public subscriptions: Subscription[] = [];

  constructor(public router: Router, private modalService: BsModalService, public loginService: LoginService, private langService: LanguageService) {

    if (this.langService.language === "/de" || this.langService.language == "") {
      dayjs.locale(locale_de);
      dayjs.extend(updateLocale);
      dayjs.updateLocale('de', {
        calendar: {
          lastDay: '[Gestern um] HH:mm',
          sameDay: '[Heute um] HH:mm',
          nextDay: '[Morgen um] HH:mm',
          lastWeek: '[Letzten] dddd [um] HH:mm',
          nextWeek: '[Nächsten] dddd [um] HH:mm',
          sameElse: 'DD.MM.YYYY'
        },
        relativeTime: {
          future: "in %s",
          past: "vor %s",
          s: 'ein paar Sekunden',
          m: "einer Minute",
          mm: "%d Minuten",
          h: "einer Stunde",
          hh: "%d Stunden",
          d: "einem Tag",
          dd: "%d Tagen",
          M: "einem Monat",
          MM: "%d Monaten",
          y: "einem Jahr",
          yy: "%d Jahren"
        }
      });
      dayjs.extend(CustomParseFormat);
    }
    dayjs.extend(relativeTime);


    this.subscriptions.push(router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart || e instanceof NavigationSkipped) {
        this.route = e.url;
        if (this.route === '/') {
          this.route = '/quiz';
        }
      }

      if (e instanceof NavigationEnd) {
        const routeData: Data = this.getRouteData(this.router.routerState.root);
        this.isFullScreenLayout = !!routeData['isFullScreenLayout'];
      }
    }));
  }

  private getRouteData(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }
}