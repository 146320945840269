<div class="my-3">
  <div *ngIf="!this.isThisBotUnused">
    <app-statistics-month-selector
      [minMonth]="this.firstMonth"
      [maxMonth]="this.lastMonth"
      [(value)]="this.selectedMonth"
      (valueChange)="this.computeFilteredUsage()"
    ></app-statistics-month-selector>
  </div>
  <div
    class="alert alert-primary"
    *ngIf="this.isThisBotUnused"
  >Bei diesem Bot sind noch keine Token verwendet worden.</div>
</div>
<div *ngIf="this.filteredUsageStats !== undefined">
  <div
    class="card mb-3 shadow-sm"
    style="border-top: 4px solid #CCEDEB;"
  >
    <div class="card-body">
      <app-usage-statistic-chart
        [model]="'gpt-4-turbo'"
        [usageStats]="this.filteredUsageStats.GPT4TURBO"
        [selectedMonth]="this.selectedMonth"
      ></app-usage-statistic-chart>
    </div>
  </div>
  <div
    class="card mb-3 shadow-sm"
    style="border-top: 4px solid #CCEDEB;"
  >
    <div class="card-body">
      <app-usage-statistic-chart
        [model]="'gpt-4o'"
        [usageStats]="this.filteredUsageStats.GPT4O"
        [selectedMonth]="this.selectedMonth"
      ></app-usage-statistic-chart>
    </div>
  </div>
  <div
    class="card mb-3 shadow-sm"
    style="border-top: 4px solid #CCEDEB;"
  >
    <div class="card-body">
      <app-usage-statistic-chart
        [model]="'gpt-4o-mini'"
        [usageStats]="this.filteredUsageStats.GPT4OMINI"
        [selectedMonth]="this.selectedMonth"
      ></app-usage-statistic-chart>
    </div>
  </div>
  <div
    class="card mb-3 shadow-sm"
    style="border-top: 4px solid #CCEDEB;"
  >
    <div class="card-body">
      <app-usage-statistic-chart
        [model]="'tts-1'"
        [usageStats]="this.filteredUsageStats.TTS"
        [selectedMonth]="this.selectedMonth"
      ></app-usage-statistic-chart>
    </div>
  </div>
  <div
    class="card shadow-sm"
    style="border-top: 4px solid #CCEDEB;"
  >
    <div class="card-body">
      <app-usage-statistic-chart
        [model]="'heygen_streaming_avatar'"
        [usageStats]="this.filteredUsageStats.HEYGEN"
        [selectedMonth]="this.selectedMonth"
      ></app-usage-statistic-chart>
    </div>
  </div>
</div>
