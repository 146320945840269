<ng-container *ngIf="this.rss.document">

  <app-admin-back-button i18n="@@documents-overview">Dokumente im Überblick</app-admin-back-button>
  <div class="row align-items-baseline">
    <div class="col">
      <h3 class="mt-4">
        {{ this.documentFileName(this.rss.document.filename) }}
      </h3>
    </div>
    <div class="col-auto text-end">
      <app-skill-id-input
        [value]="this.rss.document.metadata.skillId"
        (changed)="this.rss.document.metadata.skillId = $event; this.saveDocument()"
      ></app-skill-id-input>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-3">
      <span i18n="@@trigger">Auslöser</span>
    </div>
    <div class="col">
      <input
        type="text "
        class="form-control rag-aitext"
        i18n-placeholder="@@describe-if"
        placeholder="Beschreiben Sie wann die KI reagieren soll"
        [(ngModel)]="this.rss.document.metadata.description"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="this.saveDocument()"
      >
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3">
      <span i18n="@@functionname">Funktionsname</span>
    </div>
    <div class="col">
      <app-function-name-suggest
        [descriptionString]="this.rss.document.metadata.description"
        (finish)="this.rss.document.metadata.name = $event; this.saveDocument()"
      >
        <input
          type="text"
          class="form-control"
          i18n-placeholder="@@name"
          placeholder="Name"
          maxlength="30"
          [(ngModel)]="this.rss.document.metadata.name"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.saveDocument()"
        >
      </app-function-name-suggest>
    </div>
  </div>
  <div
    class="accordion"
    id="accordionExample"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        id="headingOne"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
          i18n="@@document-view"
        >
          <i class="ri-file-text-fill me-2"></i> <b>textbasierte Dokumentenvorschau (KI-optimiert)</b>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
      >
        <div class="accordion-body">
          <div style="white-space: pre-wrap;">
            {{ this.rss.documentContent }}
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>