import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BotService } from 'src/app/shared/services/bot.service';

@Component({
  selector: 'app-function-name-suggest',
  templateUrl: './function-name-suggest.component.html',
  styleUrl: './function-name-suggest.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class FunctionNameSuggestComponent {
  @Input() public isSuggestingName: boolean = false;
  @Input() public descriptionString: string = '';
  @Input() public mode: 'standalone' | 'addon' = 'addon';
  @Output() public finish: EventEmitter<string> = new EventEmitter<string>();
  constructor(public botService: BotService) {
  }

  public async suggestName() {
    if (!this.isSuggestingName) {
      this.isSuggestingName = true;
      const answer: string = await this.botService.suggestName(this.descriptionString);
      this.finish.emit(answer);
      this.isSuggestingName = false;
    }
  }
}
