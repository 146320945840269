// TODO: FIX THIS FILE FOR LINTING
/* eslint-disable */
import { EventEmitter, Injectable } from '@angular/core';
import { PlayAudio } from './playaudio.service';
declare let webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class SpeechService {
  public speechInputEnabled = true;
  private webkitRecognition: any;
  private shouldAutoReconnectToWebSpeechRecognition: boolean = false;
  public voiceAutoPlayIsEnabled: boolean = false;
  public recognisedTextChanged: EventEmitter<string> = new EventEmitter<string>();
  public textShouldBeSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  public botIsThinkking: boolean = false
  public isListening: boolean = false;
  public isInConversationMode: boolean = false;
  public autoSendendTimeOutId!: ReturnType<typeof setInterval>;
  public autosendDelayInSeconds = 4;
  public autodelayIsrunning = false;
  private currentTimer = 4;

  constructor(private playService: PlayAudio) {
    if (typeof webkitSpeechRecognition === 'function') {
      this.webkitRecognition = new webkitSpeechRecognition();
    } else {
      this.speechInputEnabled = false;
    }
  }

  public init(lang: string = 'de-DE') {
    if (this.webkitRecognition) {
      this.webkitRecognition.interimResults = true;
      this.webkitRecognition.continuous = false;
      this.webkitRecognition.lang = lang;
      this.addListeners();
    }
  }

  public setContinuous(continuous: boolean) {
    this.webkitRecognition.continuous = continuous;
  }
  public isContinuous(continuous: boolean) {
    return this.webkitRecognition.continuous;
  }

  public start() {
    this.shouldAutoReconnectToWebSpeechRecognition = true;
    this.webkitRecognition.start();
    clearInterval(this.autoSendendTimeOutId);
  }

  public stop() {
    this.isListening = false;
    this.shouldAutoReconnectToWebSpeechRecognition = false;
    this.webkitRecognition.stop();
    clearInterval(this.autoSendendTimeOutId);
  }

  public getAutoSendPecentage() {
    return (this.currentTimer / this.autosendDelayInSeconds) * 100;
  }

  private addListeners() {
    this.webkitRecognition.addEventListener('result', (e: any) => {
      // WORTE HOLEN
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      // FALL UNTERSCHEIDUNG
      const stopWords = ["stop", "stopp", "beenden", "ende", "aufhören", "aufhoeren", "stoppen", "stopp"];
      if (this.isInConversationMode
        && stopWords.includes(transcript.toLocaleLowerCase()) &&
        this.playService.anyAudioIsPlaying()
      ) {
        this.playService.stopAudio();
      }

      if (this.isInConversationMode) {
        if (this.botIsThinkking) {
          return
        }
        if (this.playService.anyAudioIsPlaying()) {
          return;
        }
        if (this.playService.anyAudioIsLoading()) {
          return;
        }
      }

      this.recognisedTextChanged.emit(transcript);


      this.currentTimer = this.autosendDelayInSeconds;
      clearInterval(this.autoSendendTimeOutId);
      if (this.isInConversationMode) {
        this.autoSendendTimeOutId = setInterval(() => {
          this.currentTimer--;
          this.autodelayIsrunning = true;
          if (this.currentTimer < 0) {
            this.textShouldBeSend.emit(true);
            this.autodelayIsrunning = false;
            clearInterval(this.autoSendendTimeOutId);
          }
        }, 1000);
      }
    });

    this.webkitRecognition.addEventListener('speechend', (e: any) => {
      if (!this.voiceAutoPlayIsEnabled) {
        return;
      }
    });

    // wenn die recognition stoppt dann wird sie hier wieder neu initiieet
    this.webkitRecognition.addEventListener('end', () => {
      if (this.shouldAutoReconnectToWebSpeechRecognition) {
        this.webkitRecognition.start();
      } else {
        this.webkitRecognition.stop();
      }
    });
  }
}
