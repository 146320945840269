<ng-template #tooltip>
  <div class="text-center">
    SkillID kopieren
    <br>
    <b>
      {{this.value}}</b>
  </div>
</ng-template>


@if (this.readonly) {
<span
  (click)="this.copyToClipboard(this.value)"
  class="readonly py-2"
  [tooltipHtml]="tooltip"
><i class="text-muted  ps-1 ">{{this.value}}</i></span>
}

@if (!this.readonly) {
<input
  maxlength="20"
  style="max-width: 250px;background-color: #eee !important;"
  type="text"
  [ngClass]="{'filled': this.value.trim() !== ''}"
  [(ngModel)]="this.value"
  (ngModelChange)="change(this.value)"
  [ngModelOptions]="{updateOn: 'blur'}"
  placeholder="SkillID"
  class="form-control form-control-plaintext"
>
}
