import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormForBooleanComponent } from 'src/app/shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { Avatar, AvatarService } from 'src/app/shared/services/avatar.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormForBooleanComponent, FormsModule],
  templateUrl: './avatarbot-tab.component.html',
  styleUrl: './avatarbot-tab.component.scss'
})
export class AvatarbotTabComponent implements OnInit, AfterViewInit {
  public editApiKey = false;
  //@ViewChild('avatarbot') public avatarbot!: ElementRef<HTMLIFrameElement>;

  constructor(
    private avatarService: AvatarService,
    private toastr: ToastrService,
    public rss: RouteShareService
  ) { }

  public avatars: Avatar[] = [];

  public async ngOnInit(): Promise<void> {
    this.avatars.push(...(await this.avatarService.getAvatars()));
  }


  public ngAfterViewInit(): void {
    setTimeout(() => {
      const index = this.avatars.findIndex(a => a.id == this.rss.botConfig.heyGenAvatarId) + "";
      document.querySelector(`.outer div:nth-child(${index})`)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
    }, 500);
  }

  protected reloadIframe(): void {
    //this.avatarbot.nativeElement.src = 'bot/' + this.rss.botConfig._id + '/avatar';
  }
}
