<h3 i18n="@@chat-bot">Chat-Bot</h3>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@show-prompt"
          [label]="'Chateingabezeile anzeigen'"
          [(value)]="this.rss.botConfig.promptbarChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>
    <h3
      class="mt-4"
      i18n="@@prompt-options"
    >Eingabezeile</h3>
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@voice-input"
          [label]="'Spracheingabe'"
          [(value)]="this.rss.botConfig.voiceInputChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-avatar"
          [label]="'Wechseln zu Avatar'"
          [(value)]="this.rss.botConfig.switchToAvatarFromChat"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-voice"
          [label]="'Wechseln zu Voice'"
          [(value)]="this.rss.botConfig.switchToVoiceFromChat"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@cancel-thinking"
          [label]="'Bot-Denken abbrechen'"
          [(value)]="this.rss.botConfig.cancelBotThinkingChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>
    <h3
      class="mt-4"
      i18n="@@message-options"
    >Nachrichten</h3>
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@active-human-feedback"
          [label]="'Human Feedback aktivieren'"
          [(value)]="this.rss.botConfig.enableMessageFeedback"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@copy-message"
          [label]="'Nachricht kopieren'"
          [(value)]="this.rss.botConfig.messageCopyChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@delete-message"
          [label]="'Nachricht löschen'"
          [(value)]="this.rss.botConfig.messageDeleteChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@voice-output"
          [label]="'Sprachausgabe'"
          [(value)]="this.rss.botConfig.voiceOutputChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <div class="row align-items-center mb-2">
          <div
            class="col-6"
            [ngClass]="{'text-muted': !this.rss.botConfig.voiceOutputChatActive}"
          >
            <span i18n="@@voice">Stimme</span>
          </div>
          <div class="col-auto">
            <select
              class="form-select"
              [(ngModel)]="this.rss.botConfig.voiceChatbot"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="this.rss.saveBotConfig()"
            >
              <option
                *ngFor="let model of this.gptVoiceModels"
                [value]="model"
              >{{ model }}</option>
            </select>
          </div>
          <div class="col-auto">
            <div
              tooltip="Dies ist ein Demotext für die gewählte Stimme"
              i18n-tooltip="@@voice-demo"
            >
              <app-audio-btn
                i18n-msg="@@voice-demo"
                [msg]="'Dies ist ein Demotext für die gewählte Stimme'"
                [botId]="this.rss.botConfig._id"
                [isVoiceTester]="true"
                [unit]="'chat'"
              >
              </app-audio-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <iframe
      #chatbot
      [src]="'bot/' + this.rss.botConfig._id | safe"
      class="w-100 h-100 border"
      style="overflow: hidden; pointer-events: none;"
    ></iframe>
  </div>
</div>