import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { LoginService } from "../services/login.service";

@Injectable()
export class MyReflactLoginResolver {
  constructor(public loginService: LoginService) { }

  public async resolve(route: ActivatedRouteSnapshot) {
    // const request = inject(Request, { optional: true });
    //hier besser nciht über query
    const token = route.queryParamMap.get('token');
    return !token || await this.loginService.loginFromPortal(token);
  }
}