<div class="row align-items-center mb-2">
  <div [ngClass]="this.colClass"><span>{{label}}</span><ng-content></ng-content></div>
  <div class="col-auto">
    <div class="btn-group d-block btn-group-sm">
      <button
        class="btn "
        [ngClass]="{
          'btn-light':this.value,
          'btn-primary':!this.value
        }"
        [disabled]="this.isDisabled"
        (click)="this.value = false ;this.valueChange.emit(value)"
      >{{falseLable}}</button>
      <button
        class="btn "
        [ngClass]="{
          'btn-light':!this.value,
          'btn-primary':this.value
        }"
        [disabled]="this.isDisabled"
        (click)="this.value = true ;this.valueChange.emit(value)"
      >{{trueLable}}</button>
    </div>

  </div>
</div>