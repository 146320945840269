<span class="message-bubble">
  <ng-container *ngIf="this.message.role !== 'botcard'">
    <div
      class="row message px-3 pt-5"
      [ngClass]="{
      'text-end': this.message.role === 'user',
      'text-start': this.message.role === 'assistant',
      'highlight': this.isHighlighted()
    }"
    >
      <div class="col">
        <span
          #bubble
          class="animate__animated animate__fadeInUp animate__faster bubble bubble-{{this.message.role}}  text-start"
          [ngClass]="{'bubble-bypass': this.message.bypass}"
        >
          <ng-container *ngIf="this.message.role === 'user'">{{ cleanMessageContent() }}</ng-container>
          <div
            *ngIf="this.message.role === 'assistant'"
            class="position-relative"
          >
            <markdown [data]="this.messageContentWithLinks"></markdown>
          </div>
        </span>
      </div>
    </div>
    <div
      class="text-end"
      style="padding-right: 28px"
      *ngIf="this.message.role==='user' && this.enableDeletion"
    >
      <i
        class="ri-bug-2-line"
        style="cursor: pointer;"
        (click)="this.highlightEntry()"
        *ngIf="this.debugMode"
      ></i>
      <i
        *ngIf="!this.deleting"
        class="ri-close-line text-danger ms-1"
        (click)="deleteMessageAndLoad(this.message)"
        style="cursor: pointer;"
      ></i>
      <div
        *ngIf="this.deleting"
        class="spinner-border spinner-border-sm text-danger"
        role="status"
      >
        <span
          class="visually-hidden"
          i18n="@@loading"
        >Loading...</span>
      </div>
    </div>
    <div
      class="row mt-1 align-items-center animate__animated animate__fadeInUp animate__faster  bubble-addons"
      style="margin-left: 28px; min-width: 87px;"
      [ngStyle]="{'width.px': bubble.offsetWidth - 20 }"
      *ngIf="this.message.role==='assistant'"
    >
      <div
        class="col-auto p-0"
        *ngIf="voiceOutputActive"
      >
        <app-audio-btn
          [msg]="cleanMessageContent()"
          [botId]="this.bot._id"
          [unit]="'chat'"
        >
        </app-audio-btn>
      </div>
      <div
        class="col-auto px-1"
        *ngIf="this.bot.messageCopyChatActive"
      >
        <span
          class="feedback"
          (click)="this.copyMsgToClipboard(cleanMessageContent())"
        >
          <i class="ri-file-copy-line cursor-pointer"></i>
        </span>
      </div>
      <div
        class="col-auto px-1"
        *ngIf="!this.isThinking"
      >
        <span
          *ngIf="this.enableFeedback"
          class="feedback "
        >
          <i
            [ngClass]="{
  'ri-thumb-up-line': this.remark !== 'thumbsup',
  'ri-thumb-up-fill': this.remark === 'thumbsup'
}"
            class="cursor-pointer"
            (click)="this.sendFeedback.emit({remark: this.remark === 'thumbsup' ? '' : 'thumbsup',  msgId: this.message._id})"
          ></i>
          <i
            [ngClass]="{
            'ri-thumb-down-line': this.remark !== 'thumbsdown',
            'ri-thumb-down-fill': this.remark === 'thumbsdown'
          }"
            class="cursor-pointer ms-1"
            (click)="this.sendFeedback.emit({remark: this.remark === 'thumbsdown' ? '' : 'thumbsdown', msgId: this.message._id})"
          ></i>
        </span>
      </div>
      <div class="col p-0"></div>
      <div
        class="col-auto p-0"
        *ngIf="this.enableDeletion && !this.isThinking"
      >
        <i
          class="ri-bug-2-line"
          style="cursor: pointer;"
          (click)="this.highlightEntry()"
          *ngIf="this.debugMode"
        ></i>
        <i
          *ngIf="!this.deleting"
          class="ri-close-line text-danger ms-1"
          (click)="deleteMessageAndLoad(this.message)"
        ></i>
        <div
          *ngIf="this.deleting"
          class="spinner-border spinner-border-sm text-danger"
          role="status"
        >
          <span
            class="visually-hidden"
            i18n="@@loading"
          >Loading...</span>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="this.hasButtonsAtEnd"
    class="ms-2 animate__animated animate__fadeInUp animate__faster animate__delay-1s"
  >
    <span class="more-options-text">Mögliche Optionen:</span>
    <div
      class="row mt-2"
      style="max-width: 90%"
    >
      <div
        class="col-auto pe-0 mb-2"
        *ngFor="let button of this.buttons"
      ><span class="btn btn-light text-primary btn-sm message-button text-start">{{button}}</span></div>
    </div>
  </div>

</span>