<ng-container *ngIf="this.selectedTool">
  <ng-template
    #formular
    let-data
  >
    <div class="row align-items-center ">
      <div class="col-auto">
        <span i18n="@@name-title">Name:</span>
        <input
          class="form-control form-control-sm"
          style="max-width: 240px;"
          placeholder="Name"
          [(ngModel)]="data.key"
          (ngModelChange)="formularModelChange()"
          [ngModelOptions]="{updateOn:  'blur'}"
        >
      </div>
      <div class="col-auto">
        <span i18n="@@is-optional-title">Ist optional:</span>
        <div class="btn-group d-block btn-group-sm">
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':!data.required,
            'btn-primary':data.required
          }"
            (click)="data.required = true"
            i18n="@@no"
          >Nein</button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':data.required,
            'btn-primary':!data.required
          }"
            (click)="data.required = false"
            i18n="@@yes"
          >Ja</button>
        </div>
      </div>
      <div class="col-auto">
        <span i18n="@@type-title">Typ:</span>
        <div class="btn-group d-block btn-group-sm">
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':data.type !== 'string',
            'btn-primary':data.type === 'string'
          }"
            (click)="data.type = 'string'"
            i18n="@@text"
          >Text</button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':data.type !== 'number',
            'btn-primary':data.type === 'number'
          }"
            (click)="data.type = 'number'"
            i18n="@@number"
          >Zahl</button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':data.type !== 'boolean',
            'btn-primary':data.type === 'boolean'
          }"
            (click)="data.type = 'boolean'"
            i18n="@@true-false"
          >wahr / falsch</button>
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{
            'btn-light':data.type !== 'enum',
            'btn-primary':data.type === 'enum'
          }"
            (click)="data.type = 'enum'; this.changeParamType()"
            i18n="@@list-selection"
          >Auswahl aus Liste</button>
        </div>
      </div>
      <div
        class="col"
        *ngIf="data.type === 'enum'"
      >
        <span i18n="@@selection-title">Auswahl:</span>
        <input
          type="text"
          class="form-control form-control-sm "
          placeholder="wert_1,wert_2,wert_3, ..."
          [value]="data.values.join(',')"
          (change)="enumValChanged(data,$event)"
        >
      </div>
    </div>
    <span i18n="@@parameter-description">
      Beschreiben Sie der KI wofür dieser Parameter verwendet wird z.B.:<b><br>Das Wort, wonach gesucht werden
        soll</b>,
      <b>eine Person, die gefunden werden soll</b>, <b>Firma, zu der mehrere informationen geladen werden soll</b>
    </span>
    <input
      class="form-control border-2 rag-aitext"
      i18n-placeholder="@@description"
      placeholder="Beschreibung"
      [(ngModel)]="data.description"
      (ngModelChange)="formularModelChange()"
      [ngModelOptions]="{updateOn: 'blur'}"
    >
    <button
      class="btn btn-primary btn-sm mt-2"
      (click)="this.addParamToList();"
      *ngIf="this.getSelectedParamState() === 'new'"
    > <i class="ri-add-fill"></i>
      <ng-container i18n="@@add">Hinzufügen</ng-container>
    </button>
  </ng-template>


  <app-admin-back-button i18n="@@if-then-overview">Wenn-Dann-Regeln im Überblick</app-admin-back-button>
  <div class="row align-items-bottom">
    <div class="col">
      <h3
        class="mt-4"
        i18n="@@if-title"
      >
        Wenn:
      </h3>
    </div>

    <div class="col-auto">

    </div>
    <div class="col-auto text-end pe-0">
      <div class=" form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          [(checked)]="this.selectedTool.enabled"
          (change)="this.selectedTool.enabled=!this.selectedTool.enabled; this.rss.saveBotConfig()"
        >
      </div>

      <app-skill-id-input
        [value]="this.selectedTool.skillId"
        (changed)="this.selectedTool.skillId = $event; this.rss.saveBotConfig()"
      ></app-skill-id-input>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col">
      <input
        type="text"
        class="form-control rag-aitext"
        [(ngModel)]="this.selectedTool.description"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="this.rss.saveBotConfig()"
      >


    </div>

    <div class="col-auto px-0">
      <i
        class="ri-arrow-left-circle-fill text-primary ri-2x cursor-pointer"
        (click)="selectPrevRule()"
      ></i>
    </div>
    <div class="col-auto px-0">
      {{ this.customToolKits.indexOf(this.selectedTool) + 1 }} /
      {{this.customToolKits.length}}
    </div>
    <div class="col-auto px-0">
      <i
        class="ri-arrow-right-circle-fill text-primary ri-2x cursor-pointer"
        (click)="selectNextRule()"
      ></i>
    </div>

  </div>
  <div class="row mt-3">
    <div class="col-3">
      <span i18n="@@optional-functionname">Funktionsname (optional)</span>
    </div>
    <div class="col-3">
      <app-function-name-suggest
        [descriptionString]="this.selectedTool.description"
        (finish)="this.selectedTool.name = $event; this.rss.saveBotConfig()"
      >
        <input
          type="text"
          class="form-control"
          maxlength="30"
          [(ngModel)]="this.selectedTool.name"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.rss.saveBotConfig()"
        >
      </app-function-name-suggest>
    </div>
  </div>

  <h3
    class="mt-4"
    i18n="@@then-title"
  >Dann:</h3>

  <div class="mt-2">
    <ul
      class="nav nav-tabs mb-2"
      id="ThenTab"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          class="nav-link active"
          id="classicAnswers-tab"
          data-bs-toggle="tab"
          data-bs-target="#classicAnswers"
          type="button"
          role="tab"
          aria-controls="classicAnswers"
          aria-selected="true"
        >
          <i class="ri-chat-ai-line"></i>
          Standardantworten
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          class="nav-link"
          id="codeAction-tab"
          data-bs-toggle="tab"
          data-bs-target="#codeAction"
          type="button"
          role="tab"
          aria-controls="codeAction"
          aria-selected="false"
        >
          <i class="ri-code-box-fill"></i>
          Code-Aktion ausführen
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          class="nav-link"
          id="variables-tab"
          data-bs-toggle="tab"
          data-bs-target="#variablesTab"
          type="button"
          role="tab"
          aria-controls="variablesTab"
          aria-selected="false"
        >
          Variablen
        </button>
      </li>
    </ul>
  </div>
  <div
    class="tab-content"
    id="ThenTabContent"
  >
    <div
      class="tab-pane active"
      id="classicAnswers"
      role="tabpanel"
      aria-labelledby="classicAnswers"
    >
      <!--! Hier classic answers einfügen-->
      <div class="row mb-2">
        <div class="col-auto">
          <div
            class="dropdown"
            style="min-width: 300px;"
          >
            <button
              class="btn btn-sm btn-outline-secondary dropdown-toggle lock-insensitive"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                style="min-width: 300px;display:inline-block"
                class="text-start"
              >
                # ({{this.toolCallAnswerShowIndex+1}} / {{this.selectedTool.toolCallAnswer.length}}) |

                {{ (this.selectedTool.toolCallAnswer[this.toolCallAnswerShowIndex]!.length >30)?
                (this.selectedTool.toolCallAnswer[this.toolCallAnswerShowIndex] |
                slice:0:30)+'...':(this.selectedTool.toolCallAnswer[this.toolCallAnswerShowIndex]) }}
              </span>
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <ng-container *ngFor="let item of this.selectedTool.toolCallAnswer; let index = index">
                <li><a
                    class="dropdown-item"
                    (click)="this.toolCallAnswerShowIndex = index"
                    type="button"
                    [ngClass]="{'bg-primary text-white': this.toolCallAnswerShowIndex === index}"
                  >#{{index+1}}
                    {{ (this.selectedTool.toolCallAnswer[index]!.length >30)?
                    (this.selectedTool.toolCallAnswer[index] |
                    slice:0:30)+'...':(this.selectedTool.toolCallAnswer[index]) }}

                  </a></li>


              </ng-container>

              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a
                  (click)="this.selectedTool.toolCallAnswer.push('');this.rss.saveBotConfig(); this.toolCallAnswerShowIndex = this.selectedTool.toolCallAnswer.length - 1"
                  class="dropdown-item text-primary lock-sensitive"
                  type="button"
                  i18n="@@new-entry"
                >
                  <i class="ri-add-line"></i>
                  Neuer Eintrag
                </a>

              </li>



            </ul>
          </div>
        </div>
        <div class="col p-0">
          <div
            class="btn btn-sm text-danger lock-sensitive"
            *ngIf="this.selectedTool.toolCallAnswer.length > 1"
            (click)="this.deleteToolAnswerAtIndex(this.toolCallAnswerShowIndex)"
            i18n="@@delete"
          >
            <i class="ri-close-large-fill"></i>
            Löschen
          </div>
        </div>
        <ng-container *ngIf="this.selectedTool.toolCallAnswer.length > 1">
          <div
            class="col-auto pt-1 "
            i18n="@@selection-mode"
          >Auswahlmodus</div>
          <div class="col-auto">

            <select
              class="form-select form-select-sm lock-sensitive"
              [(ngModel)]="this.selectedTool.answerMode"
              (ngModelChange)="this.rss.saveBotConfig()"
            >
              <option
                value="sequence"
                i18n="selection-seq"
              >Nacheinander bis zum letzten</option>
              <option
                value="sequenceloop"
                i18n="selection-seqloop"
              >Nacheinander (dann wieder von vorne)</option>
              <option
                value="random"
                i18n="selection-random"
              >Zufällige Reihenfolge</option>
            </select>
          </div>
        </ng-container>
      </div>
      <app-code-editor-ai
        [instruction]="this.selectedTool.toolCallAnswer[this.toolCallAnswerShowIndex] || ''"
        i18n-placeholder="@@example-placeholder"
        [placeholder]="'Beispielsweise:\n ‣ Sage dem user dass...\n ‣ Reagiere auf die Frage des Users mit...\n ‣ sei freundlich und hilfreich...'"
        [rows]="13"
        (finish)="this.selectedTool.toolCallAnswer[this.toolCallAnswerShowIndex] = $event; this.rss.saveBotConfig()"
      ></app-code-editor-ai>
    </div>

    <div
      class="tab-pane"
      id="codeAction"
      role="tabpanel"
      aria-labelledby="codeAction"
    >

      <!--! Hier die code Action eifügen-->
      <app-code-editor-toolbar
        [skills]="this.getSkillNames()"
        [variables]="this.getToolbarVariables()"
        (insertInEditor)="codeEditor.insertInEditor($event)"
      ></app-code-editor-toolbar>
      <app-code-editor-dann
        #codeEditor
        [skills]="this.getSkillNames()"
        [variables]="this.getToolbarVariables()"
        [code]="this.selectedTool.content"
        [rows]="13"
        (finish)="this.selectedTool.content = $event; this.rss.saveBotConfig()"
      ></app-code-editor-dann>
    </div>

    <div
      class="tab-pane"
      id="variablesTab"
      role="tabpanel"
      aria-labelledby="variablesTab"
    >

      <!--! Hier die variables eifügen-->
      <div class="">
        <table class="table table-hover">
          <tbody>
            @for (param of this.selectedTool.params; track $index) {
            <tr [ngClass]="{'bg-selected-row': this.selectedParam === param}">
              <ng-container *ngIf="this.selectedParam !== param">
                <td style="vertical-align: middle;">
                  <b i18n="@@variable-title">Variable:</b> vars.{{ param.key }}
                  ({{param.type}}
                  <span *ngIf="param.type ==='enum' && param.values">
                    | {{param.values.join(', ')}}
                  </span>)


                  <span *ngIf="!param.required">optional</span>
                </td>
                <td style="vertical-align: middle;">
                  <span><i class="text-muted">{{ param.description }}</i></span>
                </td>
              </ng-container>
              <ng-container *ngIf="this.selectedParam === param">
                <td
                  colspan="2"
                  class="p-5"
                >
                  <ng-template
                    [ngTemplateOutlet]="formular"
                    [ngTemplateOutletContext]="{ $implicit: this.selectedParam }"
                  ></ng-template>
                </td>
              </ng-container>
              <td class="text-end">
                <div
                  *ngIf="this.selectedParam === param"
                  class="btn btn-primary"
                  (click)="this.selectedParamIndex = -1 "
                >
                  <i class="ri-checkbox-fill"></i>
                </div>
                <div
                  class="dropdown"
                  *ngIf="this.selectedParam !== param"
                >
                  <button
                    class="btn dropdown-toggle"
                    (click)="this.selectedParamIndex = -1; this.paramForNewForm = undefined"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="ri-more-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        type="button"
                        class="dropdown-item cursor-pointer text-primary"
                        (click)="this.selectedParamIndex = $index;this.paramForNewForm = undefined"
                      >
                        <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        class="dropdown-item cursor-pointer text-danger"
                        (click)="this.deleteParamFromList($index)"
                      >
                        <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                      </button>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      <div
        class="callout callout-info"
        *ngIf="this.selectedTool.params.length === 0"
        i18n="@@variables-description"
      >
        Variablen dienen dazu, von der KI automatisch erfasst und anschließend an
        die festgelegte Dann-Bedingung
        übergeben zu werden. So können dynamische Werte flexibel in den Wenn-Dann-Regeln verarbeitet werden.
      </div>
      <div class="row ">
        <ng-container *ngIf="this.paramForNewForm">
          <div
            class="col p-5"
            style="border-left: 4px solid #00A599;"
          >
            <ng-template
              [ngTemplateOutlet]="formular"
              [ngTemplateOutletContext]="{ $implicit: this.paramForNewForm }"
            >
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="!this.paramForNewForm">
          <div class="col">
            <button
              class="btn btn-primary btn-sm"
              (click)="this.selectEmptyParam()"
            >
              <i class="ri-add-fill"></i> <ng-container i18n="@@add">Neue Variable</ng-container>
            </button>
          </div>
        </ng-container>
      </div>

      <!-- END VARIABLEN BLOCK-->

    </div>
  </div>
</ng-container>