import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FileUploadComponent } from "../../file-upload/file-upload.component";

@Component({
  selector: 'app-upload-modal',
  standalone: true,
  templateUrl: './upload-modal.component.html',
  styleUrl: './upload-modal.component.scss',
  imports: [FileUploadComponent]
})
export class UploadModalComponent {
  public botID: string = '';
  public allowedFileTypes: { prefix: string, mimetype: string }[] = [];
  public fileList: Subject<{ name: string, base64: string }[]> = new Subject<{ name: string, base64: string }[]>();

  constructor(public bsModalRef: BsModalRef) { }

  public returnFileList(fileList: { name: string, base64: string }[]) {
    this.fileList.next(fileList);
    this.bsModalRef.hide();
  }
}
