import { CommonModule, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';
import dayjs from 'dayjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { ChunkContingentBotComponent } from "../../chunk-contingent-bot/chunk-contingent-bot.component";
import { HintComponent } from '../hint/hint.component';

@Component({
  selector: 'app-bot-admin-dashboard-table',
  standalone: true,
  imports: [CommonModule, NgFor, RouterModule, HintComponent, ChunkContingentBotComponent],
  templateUrl: './bot-admin-dashboard-table.component.html',
  styleUrl: './bot-admin-dashboard-table.component.scss'
})
export class BotAdminDashboardTableComponent {
  @Input({ required: true }) public bots!: ChatbotConfig[];
  @Input() public searchString: string = "";
  @Output() public duplicateTrigger: EventEmitter<void> = new EventEmitter<void>();
  @Output() public reloadTrigger: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deleteBot: EventEmitter<string> = new EventEmitter<string>();
  @Output() public tagClickTrigger: EventEmitter<string> = new EventEmitter<string>();
  public getContrastColor = getContrastColor;
  public dayjs = dayjs;

  constructor(
    private router: Router,
    public adminService: AdminService,
    private toastr: ToastrService,
    public loginService: LoginService,
    private langService: LanguageService
  ) { }

  public openBot(bot: ChatbotConfig) {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.langService.language + '/bot/' + bot._id]));
    window.open(url, '_blank');
  }

  public isTagSelectedBySearchString(tag: string) {
    return this.searchString.includes(`tag:'${tag}'`);
  }

  public async trashBot(id: string) {
    await this.adminService.trashBot(id);
    const bot = this.bots.find(bot => bot._id === id);
    if (bot) {
      bot.thisIsTrash = true;
    }
    this.reloadTrigger.emit();
  }

  public async untrashBot(id: string) {
    await this.adminService.untrashBot(id);
    const bot = this.bots.find(bot => bot._id === id);
    if (bot) {
      bot.thisIsTrash = false;
    }
    this.reloadTrigger.emit();
  }

  public async archiveBot(id: string) {
    await this.adminService.archiveBot(id);
    const bot = this.bots.find(bot => bot._id === id);
    if (bot) {
      bot.archived = true;
    }
    this.reloadTrigger.emit();
  }

  public async unarchiveBot(id: string) {
    await this.adminService.unarchiveBot(id);
    const bot = this.bots.find(bot => bot._id === id);
    if (bot) {
      bot.archived = false;
    }
    this.reloadTrigger.emit();
  }

  public async duplicateBot(id: string) {
    await this.adminService.copyBot(id);
    this.toastr.success(ToastSuccessTitle.CREATED);
    this.duplicateTrigger.emit();
  }

  public getBotDeeplink(bot: ChatbotConfig): "task" | "information" {
    if (bot.notes == "") {
      return "task";
    } else {
      return "information";
    }
  }

  public UserCanEditBotConfig(bot: ChatbotConfig): boolean {
    if (bot.editMode === "everyone") { return true; }
    if (bot.editMode === "none") { return false; }
    return (this.loginService.loggedInUser?._id === bot.creator);
  }
}