import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { RouteShareService } from '../../services/route-share.service';
import { TrunkService } from '../../services/trunk.service';

export const datatrunkResolver: ResolveFn<boolean> = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const trunkService: TrunkService = inject(TrunkService);
  const rss: RouteShareService = inject(RouteShareService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  const id = route.parent?.params['id'] as string;

  rss.datatrunks = (await trunkService.getTrunks(id)).trunks.reverse();
  const trunkid = route.firstChild?.params['trunkid'] as string;
  if (trunkid) {
    rss.datatrunk = rss.datatrunks.find(trunk => trunk._id === trunkid);
  } else {
    rss.datatrunk = undefined;
  }
  return true;
};
