<h3 i18n="@@memory">Gedächtnis</h3>
<div class="card border-0">
  <div class="card-body">
    <app-form-for-boolean
      i18n-label="@@memory-active"
      [label]="'Gedächtnis aktiv'"
      [(value)]="this.rss.botConfig.memoryEnabled"
      (valueChange)="this.rss.saveBotConfig()"
    >
      <app-hint
        *ngIf="this.rss.botConfig.memoryEnabled"
        i18n="@@memory-enabled-hint"
      >
        Die vom Bot gespeicherten Dialoge können für eine spätere Suche verwendet werden.
      </app-hint>
      <app-hint
        *ngIf="!this.rss.botConfig.memoryEnabled"
        i18n="@@memory-disabled-hint"
      >
        Die vom Bot gespeicherten Dialoge werden <b>nicht</b> in einer späteren Suche verwendet.
      </app-hint>
    </app-form-for-boolean>
  </div>
</div>

<div class="row my-2">
  <div class="col">
    <div class="card">
      <div class="card-header alert alert-primary ">
        <div class="row">
          <div class="col">
            <app-bot-memory-browser-filter
              #messageFilter
              [botConfig]="this.rss.botConfig"
            ></app-bot-memory-browser-filter>
          </div>
          <div class="col-auto">
            <div
              (click)="this.openConfirmDeleteHistory(deleteHistoryModal)"
              class="btn btn-primary lock-insensitive"
              i18n="@@manage-history"
            >History verwalten </div>
          </div>
        </div>
      </div>
      <div
        class="card-body"
        style="max-height: 50vh; overflow: auto;"
      >
        <app-bot-chat-dialog
          #myDialog
          id="myDialog"
          [isThinking]="this.isThinking"
          [botConfig]="this.getBotConfig()"
          [enableDeletionOverride]="false"
        ></app-bot-chat-dialog>
      </div>
      <div class="card-footer alert alert-primary mb-0">
        <div
          [ngClass]="{'disabled': this.isThinking || this.promptInput.trim().length === 0}"
          class="btn btn-primary send-prompt"
          (click)="this.add()"
        ><i class="ri-send-plane-2-fill "></i></div>
        <input
          id="promptInput"
          [disabled]="this.isThinking"
          class="prompt w-100 form-control lock-insensitive"
          (keyup)="this.onKey($event)"
          [(ngModel)]="this.promptInput"
        />
      </div>
    </div>
  </div>
</div>
<ng-template #deleteHistoryModal>
  <div class="modal-header">
    <h4
      class="modal-title pull-left"
      i18n="@@manage-history"
    >History verwalten</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="this.bsModalRef?.hide()"
    >
      <span
        aria-hidden="true"
        class="visually-hidden"
        i18n="@@loading"
      >Loading...</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex align-items-center justify-content-center">
      <app-bot-memory-browser-filter
        #deleteFilter
        [botConfig]="this.getBotConfig()"
      ></app-bot-memory-browser-filter>
    </div>
    <hr>
    <div
      class="text-center"
      i18n="@@delete-history-filter"
    >
      Deine Filterauswahl betrifft <span class="badge bg-primary">{{this.deleteFilter.deleteCount}}</span> History
      Einträge.<br>
      <b>Sollen diese unwiderruflich gelöscht werden?</b>
    </div>
  </div>
  <div class="modal-footer text-end">
    <div
      class="btn btn-danger"
      (click)="this.deleteHistory()"
      i18n="@@delete"
    >Löschen</div>
  </div>
</ng-template>