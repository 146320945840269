import StreamingAvatar, { StreamingEvents, TaskMode, TaskType } from "@heygen/streaming-avatar";
import { waitSeconds } from "@reflact/tsutil";
import { BehaviorSubject } from "rxjs";

export const ICON_PAUSE = "🕓";

export class SpeakWithPause {

  public contents: string[] = [];
  public currentIndex: number = -1;
  public isDestroyed: boolean = false;
  public initialSpeakComplete = new BehaviorSubject(false);

  constructor(public avatar: StreamingAvatar, public content: string) {
    this.contents = this.content.split(ICON_PAUSE);
    this.avatar.on(StreamingEvents.AVATAR_STOP_TALKING, this.playNextIndex.bind(this));
    void this.playNextIndex();
  }

  public async playNextIndex() {
    if (this.isDestroyed) {
      return;
    }
    this.currentIndex++;
    const c = this.contents[this.currentIndex];
    if (c == null) {
      this.destroy();
      return;
    }
    if (c == "") {
      console.log("Pause");
      await waitSeconds(0.5);
      void this.playNextIndex();
      return;
    }
    console.log("Speaking", c);
    await this.avatar.speak(
      {
        text: c,
        taskMode: TaskMode.ASYNC,
        taskType: TaskType.REPEAT
      });
  }

  public destroy() {
    this.avatar.off(StreamingEvents.AVATAR_STOP_TALKING, this.playNextIndex.bind(this));
    this.isDestroyed = true;
    this.initialSpeakComplete.next(true);
    console.log("Speakwithpause destroy");
  }

}