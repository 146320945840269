import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CompletionStat, HeygenStat, Model, TextToSpeechStat, UsageStat } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { ApexAxisChartSeries, ApexTooltip, ApexXAxis, NgApexchartsModule } from 'ng-apexcharts';
import { StatisticService } from '../statistic.service';
import { MonthOfYear } from '../statistics-month-selector/statistics-month-selector.component';

@Component({
  selector: 'app-usage-statistic-chart',
  standalone: true,
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  templateUrl: './usage-statistic-chart.component.html',
  styleUrl: './usage-statistic-chart.component.scss'
})
export class UsageStatisticChartComponent implements OnChanges {
  @Input({ required: true }) public model!: Model;
  @Input({ required: true }) public usageStats!: UsageStat[];
  @Input({ required: true }) public selectedMonth!: MonthOfYear;
  public totalRequests: number = 0;
  public totalValue: number = 0;
  public requestSeries: ApexAxisChartSeries = [];
  public valueSeries: ApexAxisChartSeries = [];
  public xaxis: ApexXAxis = {
    type: 'category',
    categories: [],
    labels: {
      rotate: -45,
      formatter: (val: string) => {
        return val + " " + this.statService.getMonth(this.selectedMonth.month);
      }

    },
    tickAmount: 2,
    tooltip: {
      enabled: false
    }
  };
  public requestTooltip: ApexTooltip = {
    y: {
      formatter: (val: number) => {
        return val + " requests";
      }
    }
  };
  public tokenTooltip: ApexTooltip = {
    y: {
      formatter: (val: number) => {
        return val + " tokens";
      }
    }
  };

  constructor(private statService: StatisticService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.requestSeries = [];
    this.valueSeries = [];
    this.totalRequests = this.usageStats.length;
    this.totalValue = this.usageStats.map((stat) => {
      if (stat.model == 'gpt-4o' || stat.model == 'gpt-4o-mini' || stat.model == 'gpt-4-turbo') {
        return stat.usage.total_tokens;
      }
      if (stat.model === 'heygen_streaming_avatar') {
        return Math.round(stat.conversionMS / 1000);
      } else if (stat.model === 'tts-1') {
        return stat.characters;
      } else {
        return 0;
      }
    }).reduce((a, b) => { return a + b; }, 0);
    this.computeChartData();
  }

  private computeChartData() {
    const gpt4TurboRequest = {
      name: "GPT-4-turbo",
      data: this.getDataPerDay("gpt-4-turbo", "requests"),
      color: "#A300D6"
    };
    const gpt4oRequest = {
      name: "GPT-4o",
      data: this.getDataPerDay("gpt-4o", "requests"),
      color: "#ff0000"
    };
    const gpt4ominiRequest = {
      name: "GPT-4o-mini",
      data: this.getDataPerDay("gpt-4o-mini", "requests"),
      color: "#00ff00"
    };
    const heygenRequest = {
      name: "Heygen",
      data: this.getDataPerDay("heygen_streaming_avatar", "requests"),
      color: "#775DD0"
    };
    const ttsRequest = {
      name: "TTS-1",
      data: this.getDataPerDay("tts-1", "requests"),
      color: "#662E9B"
    };
    const gpt4TurboTokens = {
      name: "GPT-4",
      data: this.getDataPerDay("gpt-4-turbo", "token"),
      color: "#A300D6"
    };
    const gpt4oTokens = {
      name: "GPT-4o",
      data: this.getDataPerDay("gpt-4o", "token"),
      color: "#ff0000"
    };
    const gpt4ominiTokens = {
      name: "GPT-4o-mini",
      data: this.getDataPerDay("gpt-4o-mini", "token"),
      color: "#00ff00"
    };
    const heygenSeconds = {
      name: "Heygen",
      data: this.getDataPerDay("heygen_streaming_avatar", "time"),
      color: "#775DD0"
    };
    const ttsCharacters = {
      name: "TTS-1",
      data: this.getDataPerDay("tts-1", "characters"),
      color: "#662E9B"
    };

    if (this.model === "gpt-4-turbo") {
      this.requestSeries.push(gpt4TurboRequest);
      this.valueSeries.push(gpt4TurboTokens);
    }
    if (this.model === "gpt-4o") {
      this.requestSeries.push(gpt4oRequest);
      this.valueSeries.push(gpt4oTokens);
    }
    if (this.model === "gpt-4o-mini") {
      this.requestSeries.push(gpt4ominiRequest);
      this.valueSeries.push(gpt4ominiTokens);
    }
    if (this.model === "heygen_streaming_avatar") {
      this.requestSeries.push(heygenRequest);
      this.valueSeries.push(heygenSeconds);
    }
    if (this.model === "tts-1") {
      this.requestSeries.push(ttsRequest);
      this.valueSeries.push(ttsCharacters);
    }
  }

  private getDataPerDay(model: Model, type: "requests" | "token" | "time" | "characters"): number[] {
    const usagePerDay: number[] = [];
    this.xaxis.categories = [];
    for (let i = 0; i < dayjs().set('year', this.selectedMonth.year).set('month', this.selectedMonth.month - 1).daysInMonth(); i++) {
      usagePerDay.push(0);
      (this.xaxis.categories as number[]).push((i + 1));
    }
    this.usageStats.forEach((stat) => {
      if (stat.model !== model) return;
      const dayIndex = dayjs(stat.created).get('date') - 1;
      if (usagePerDay[dayIndex] == undefined) return;
      if (type === "requests") {
        usagePerDay[dayIndex] += 1;
      } else if (type === "token") {
        usagePerDay[dayIndex] += (stat as CompletionStat).usage.total_tokens;
      } else if (type === "time") {
        usagePerDay[dayIndex] += Math.round((stat as HeygenStat).conversionMS / 1000);
      } else {
        usagePerDay[dayIndex] += (stat as TextToSpeechStat).characters;
      }
    });
    return usagePerDay;
  }

  protected calculateRequestsToTickAmount() {
    return Math.min(this.totalRequests, 6);
  }
}