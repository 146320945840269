import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChatbotConfig, SpfBucketFileFrontend } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';
import { ToastErrorTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { BotQuickinfoComponent } from '../../../shared/components/ai-bot/utils/bot-quickinfo/bot-quickinfo.component';
import { SharetokenService } from '../../../shared/services/sharetoken.service';

export type ShareBot = {
  name: string;
  icon: string;
  color: string;
};

@Component({
  standalone: true,
  templateUrl: './import.component.html',
  styleUrl: './import.component.scss',
  imports: [CommonModule, BotQuickinfoComponent, RouterModule]
})
export class ImportComponent implements OnInit {
  private shareToken: string = '';
  public invalidToken: boolean = false;
  public botConfig: ChatbotConfig | undefined;
  public botDocs: SpfBucketFileFrontend[] = [];
  public sharedBot: ShareBot = {
    name: '',
    icon: '',
    color: ''
  };
  public importing: boolean = false;

  public getContrastColor = getContrastColor;

  constructor(
    private route: ActivatedRoute,
    private SharetokenService: SharetokenService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  public async ngOnInit(): Promise<void> {
    this.shareToken = this.route.snapshot.params['token'] as string;
    await this.getShareTokenInfo(this.shareToken);
  }

  public async importBot() {
    this.importing = true;
    const res = await this.SharetokenService.ImportBot(this.shareToken);
    if (!res) {
      this.toastr.error(ToastErrorTitle.IMPORTED);
      this.importing = false;
      return;
    }
    this.toastr.success(ToastSuccessTitle.IMPORTED);
    await this.router.navigate(['/bot-admin/']);
  }

  public async getShareTokenInfo(token: string) {
    const config = await this.SharetokenService.getShareTokenInfo(token);
    if (config) {
      this.botConfig = config.botconfig;
      this.botDocs = config.docs;
      this.sharedBot.name = config.botconfig.name;
      this.sharedBot.icon = config.botconfig.icon ?? "";
      this.sharedBot.color = config.botconfig.color;
    } else {
      this.invalidToken = true;
    }
  }
}
