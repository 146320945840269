<div class="modal-header">
  <h4 class="modal-title pull-left">Upload</h4>
  <div
    class="btn"
    (click)="bsModalRef.hide()"
  >
    <i class="ri-close-line"></i>
  </div>
</div>
<div class="modal-body">
  <app-file-upload
    [botId]="this.botID"
    [allowedFileTypes]="this.allowedFileTypes"
    [typeOfUpload]="'user'"
    (base64Images)="this.returnFileList($event)"
  ></app-file-upload>
</div>