<h3 i18n="@@misc">Sonstiges</h3>
<div class="card border-0">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <span i18n="@@notes">Notizen</span>
        <app-hint i18n="@@notes-hint">
          Hier können Sie <b>Kommentare und Informationen</b> zum Bot hinterlegen, welche anschließend in einem
          <b>eigenen Menüpunkt präsenter</b> darstellt werden. Sie können in diesem Textfeld auch in der
          <b>Markdown-Notation</b> arbeiten.
        </app-hint>
      </div>
      <div class="col">
        <textarea
          rows="2"
          class="form-control"
          [(ngModel)]="this.rss.botConfig.notes"
          (ngModelChange)="this.rss.saveBotConfig()"
          [ngModelOptions]="{updateOn: 'blur'}"
        ></textarea>
      </div>
    </div>
  </div>
</div>