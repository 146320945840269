import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BotMessageFeedback } from '@reflact/ai-types';
import { MarkdownComponent } from 'ngx-markdown';
import { BotService } from 'src/app/shared/services/bot.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { MessageBubbleComponent } from '../../../../../../shared/components/ai-bot/utils/bot-chat-dialog/message-bubble/message-bubble.component';
import { FormForBooleanComponent } from '../../../../../../shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { HintComponent } from '../../../../../../shared/components/ai-bot/utils/hint/hint.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MarkdownComponent,
    FormForBooleanComponent,
    FormsModule,
    HintComponent,
    MessageBubbleComponent
  ],
  templateUrl: './feedback-tab.component.html',
  styleUrl: './feedback-tab.component.scss'
})
export class FeedbackTabComponent implements OnInit {
  public feedback: BotMessageFeedback[] = [];
  public filter: "thumbsup" | "thumbsdown" | "all" = "all";
  constructor(
    private bot: BotService,
    public rss: RouteShareService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.feedback = (await this.bot.getFeedback(this.rss.botConfig._id)).feedbacks;
  }
}
