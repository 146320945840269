<code-editor
  #codeEditor
  id="code-editor-css"
  class="form-control p-0 lock-sensitive"
  [extensions]="this.extensions"
  [(ngModel)]="this.instruction"
  [ngModelOptions]="{updateOn: 'blur'}"
  (ngModelChange)="this.saveToolConfig()"
  [theme]="'dark'"
  [setup]="'basic'"
  [disabled]="false"
  [readonly]="!this.rss.userCanEditBotConfig()"
  [placeholder]="this.placeholder"
  [indentWithTab]="true"
  [indentUnit]="''"
  [lineWrapping]="true"
  [highlightWhitespace]="false"
  [languages]="this.languages"
  [language]="'css'"
></code-editor>
