/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { ChatbotConfig } from '@reflact/ai-types';

import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import { LanguageService } from '../language.service';

export const getExportFileName = (botName: string, type: string, fileEnding: string) => {
  const fileName = botName + "-ragAI-" + type + "bot";
  let sanitizedFileName = fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  sanitizedFileName = sanitizedFileName.replace(/_+/g, '_');
  if (sanitizedFileName.endsWith('_')) {
    sanitizedFileName = sanitizedFileName.slice(0, -1);
  }
  return `${sanitizedFileName}.${fileEnding}`;
};

export type ZipExportType = "chat" | "audio" | "avatar";

@Injectable({ providedIn: 'root' })


export class ZipexportService {
  constructor(private langService: LanguageService) { }

  public exportChatBot(botConfig: ChatbotConfig) {
    this.buildZip("chat", botConfig);
  }

  public exportAudioBot(botConfig: ChatbotConfig) {
    this.buildZip("audio", botConfig);
  }

  public exportAvatarBot(botConfig: ChatbotConfig) {
    this.buildZip("avatar", botConfig);
  }

  private buildZip(exportType: ZipExportType, botConfig: ChatbotConfig) {

    const zip = new (JSZip as any)();


    const baseUrl = `${window.location.protocol}//${window.location.host}${this.langService.language}/bot/${botConfig._id}`;
    const baseBotName = botConfig.name || "default";

    let frameUrl = baseUrl;
    let botName = baseBotName;
    if (exportType !== "chat") {
      frameUrl += "/" + exportType;
      botName += " (" + exportType + ")";


    }
    zip.file("imsmanifest.xml", this.fileContentManifest(botName));


    zip.file("index.html", this.fileContentIndexHtml(frameUrl, botName));

    zip.generateAsync({ type: "blob" })
      .then((content: Blob) => {
        saveAs(content, getExportFileName(baseBotName, exportType, "zip"));
        //      this.toastr.success(ToastSuccessTitle.ZIP_GENERATED);
      })
      .catch((error: any) => {
        //        this.toastr.error(ToastErrorTitle.ZIP_GENERATION_FAILED);
        console.error("Error generating zip file:", error);
      });



  }

  private fileContentIndexHtml(frameUrl: string, botName: string) {
    return `<!DOCTYPE html>
    <html style="height:100%">
    <head>
    <title>${botName}</title>
    <script src="${window.location.protocol}//${window.location.host}/files/scorm12.min.js" defer></script>
    <script src="${window.location.protocol}//${window.location.host}/files/botScorm12.js" defer></script>

    </head>
    <body style="height:100%;margin:0px">
              <iframe
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-modals allow-downloads"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; microphone; download *; fullscreen; camera; speaker; geolocation"
            referrerpolicy="strict-origin-when-cross-origin"
            src="${frameUrl || ''}"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>

    </body>
    </html>`;
  }

  private fileContentManifest(botName: string) {
    return `<?xml version="1.0" encoding="UTF-8"?>
              <manifest identifier="lernbot_manifest" version="1.2"
                  xmlns="http://www.imsproject.org/xsd/imscp_rootv1p1p2"
                  xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_rootv1p2"
                  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                  xsi:schemaLocation="http://www.imsproject.org/xsd/imscp_rootv1p1p2 imscp_v1p1.xsd
                                      http://www.adlnet.org/xsd/adlcp_rootv1p2 adlcp_rootv1p2.xsd">
                  <metadata>
                      <schema>ADL SCORM</schema>
                      <schemaversion>1.2</schemaversion>
                  </metadata>
                  <organizations default="lernbot_org">
                      <organization identifier="lernbot_org">
                          <title>${botName}</title>
                          <item identifier="lernbot_item" identifierref="lernbot_resource">
                              <title>${botName}</title>
                          </item>
                      </organization>
                  </organizations>
                  <resources>
                      <resource identifier="lernbot_resource" type="webcontent" adlcp:scormtype="sco" href="index.html">
                          <file href="index.html" />
                      </resource>
                  </resources>
              </manifest>`;
  }


}




