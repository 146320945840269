<ng-container *ngIf="this.loading">
  <div class="btn disabled border-0 ">
    <span
      class="spinner-border spinner-border-sm ms-1"
      *ngIf="loading"
    ></span>
  </div>
</ng-container>
<ng-container *ngIf="!this.loading">
  <div
    class="btn  border-0 "
    *ngIf="!this.rss.botConfig.hasImage"
    (click)="this.reloadImage()"
  >
    <i class="ri-image-ai-line"></i>
  </div>
  <div
    *ngIf="this.rss.botConfig.hasImage"
    class="btn border-0 "
    (click)="this.deleteImage()"
  >
    <i class="ri-image-edit-fill"></i>
  </div>
</ng-container>
