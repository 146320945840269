<div class="form-control">
  <!--span class="position-absolute">
    <ng-container *ngFor="let block of splittedSearchBlocks; let i = index">
      <ng-container *ngIf="i > 0">&nbsp;</ng-container>
      <ng-container *ngIf="block.filter">
        <span class="filter">{{block.filter}}:</span>
        <span class="filtered-value">{{block.value}}</span>
      </ng-container>
      <ng-container *ngIf="!block.filter">
        <span class="value">{{block.value}}</span>
      </ng-container>
    </ng-container>
  </span-->
  <input
    placeholder="Suche"
    [(ngModel)]="search"
    (keyup)="this.onSearchChange()"
  >
</div>
