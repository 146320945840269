import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';
import { BotService } from '../../../services/bot.service';
import { EndBotOptions, EndbotService } from '../../../services/endbot.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bot-end.component.html',
  styleUrl: './bot-end.component.scss'
})
export class BotEndComponent {

  protected getContrastColor = getContrastColor;

  public botConfig!: ChatbotConfig;
  constructor(public endBotService: EndbotService, route: ActivatedRoute, public botService: BotService) {
    route.data.subscribe(data => {
      this.botConfig = data['botConfig'] as ChatbotConfig;
    });
    const opts = route.snapshot.queryParams as EndBotOptions;
    this.endBotService.endPageComponentLoaded(opts);
  }

  public getColorFromConfig(background: boolean = false): string {
    return this.botService.getColorFromConfig(this.botConfig, background);
  }
}
