import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatbotConfig, SpfBucketFileFrontend } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';

type ShareTokenResponse = {
  botconfig: ChatbotConfig & { vectorStoreDirty: boolean },
  docs: SpfBucketFileFrontend[]
}
@Injectable({
  providedIn: 'root'
})
export class SharetokenService {

  constructor(private httpClient: HttpClient) { }

  public async getShareToken(botId: string) {
    try {
      return await firstValueFrom(this.httpClient.get<{ shareToken: string }>(`/api/toolbox/bot/admin/sharetoken/${botId}`));
    } catch (error) {
      console.error('Error while fetching share token', error);
      return '';
    }
  }

  public async getShareTokenInfo(token: string): Promise<ShareTokenResponse | undefined> {
    try {
      return (await firstValueFrom(this.httpClient.get<{ shareTokenInfo: ShareTokenResponse }>(`/api/toolbox/bot/admin/sharetokeninfo/${token}`))).shareTokenInfo;
    } catch (error) {
      console.error('Error while fetching share token info', error);
      return undefined;
    }
  }

  public async ImportBot(token: string) {
    try {
      return await firstValueFrom(this.httpClient.post(`/api/toolbox/bot/admin/importfromsharetoken/${token}`, {}));
    } catch (error) {
      console.error('Error while fetching share token info', error);
      return '';
    }
  }
}
