import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GptVoiceArrWithRandom } from '@reflact/ai-types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AudioBtnComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/audio-btn/audio-btn.component';
import { FormForBooleanComponent } from 'src/app/shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, FormForBooleanComponent, AudioBtnComponent, TooltipModule, SafePipe],
  templateUrl: './chatbot-tab.component.html',
  styleUrl: './chatbot-tab.component.scss'
})
export class ChatbotTabComponent {
  public gptVoiceModels = GptVoiceArrWithRandom;
  @ViewChild('chatbot') public chatbot!: ElementRef<HTMLIFrameElement>;

  constructor(public rss: RouteShareService) { }

  protected reloadIframe(): void {
    this.chatbot.nativeElement.src = 'bot/' + this.rss.botConfig._id;
  }
}
