import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { AdminService } from '../services/admin.service';
import { LoginService } from '../services/login.service';
import { RouteShareService } from '../services/route-share.service';

export const botconfigadminResolver: ResolveFn<ChatbotConfig | null> = async (route) => {
  const loginService: LoginService = inject(LoginService);
  const adminService: AdminService = inject(AdminService);
  const rss: RouteShareService = inject(RouteShareService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  const id = route.params['id'] as string;
  const botConfig = await adminService.loadBotConfig(id);
  if (botConfig) {
    rss.botConfig = botConfig;
    rss.botOwnerContingent = await adminService.getBotOwnersContingent(botConfig._id);
  }
  return botConfig;
};
