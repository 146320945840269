import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-in-place-validator',
  templateUrl: './in-place-validator.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class InPlaceValidatorComponent implements OnChanges {
  public isValid = true;
  public formControl = new FormControl();
  @Input({ required: true }) public text!: string;
  @Input({ required: true }) public validators!: ValidatorFn[];


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['validators']) {
      this.formControl.setValidators(this.validators);
    }
    if (changes['text']) {
      this.formControl.setValue(this.text);
      this.isValid = this.formControl.status == "VALID";
    }
  }
}
