<ng-template #featureList>
  <div
    id="iconDiv"
    class="text-center"
    [ngStyle]="{'opacity':this.botConfig.hasImage ? '0' : '1'}"
    *ngIf="this.showIcon"
  >
    <i [class]="this.botConfig.icon +' ri-5x'"></i>
  </div>
  @if(!this.hideFeatures){
  <span class="quickinfo-text"><i class="ri-terminal-box-fill"></i> {{this.botConfig.model}}</span>
  @if(this.botConfig.isPublic){<span class="quickinfo-text"><i class="ri-share-line"></i> Public</span>}
  @if(!this.botConfig.isPublic){<span class="quickinfo-text"><i class="ri-lock-2-fill"></i> Privat</span>}

  @if(this.botConfig.voiceInputChatActive){<span class="quickinfo-text"><i class="ri-user-voice-fill"></i> Voice
    In</span>}
  @if(this.botConfig.voiceOutputChatActive){<span class="quickinfo-text"><i class="ri-user-voice-fill"></i> Voice
    Out</span>}
  @if(this.botConfig.memoryEnabled){<span class="quickinfo-text"><i class="ri-database-2-fill"></i> Memory</span>}
  @if(this.botConfig.heyGenEnabled){<span class="quickinfo-text"><i class="ri-account-box-line"></i> Avatar</span>}
  }
</ng-template>


@if(this.colorMode){
<div [ngStyle]="{'background-color': this.botConfig.color, 'color':getContrastColor(this.botConfig.color)}">
  <ng-container *ngTemplateOutlet="featureList"></ng-container>
</div>
}

@if(!this.colorMode){
<div>
  <ng-container *ngTemplateOutlet="featureList"></ng-container>
</div>
}