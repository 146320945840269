import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastInfoTitle, ToastrService } from 'src/app/shared/services/toastr.service';



@Component({
  selector: 'app-skill-id-input',
  templateUrl: './skill-id-input.component.html',
  styleUrl: './skill-id-input.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipModule]
})
export class SkillIdInputComponent {
  constructor(private toastr: ToastrService) { }

  @Input() public readonly: boolean = false;
  @Input() public value: string = '';
  @Output() public changed: EventEmitter<string> = new EventEmitter<string>();

  public copyToClipboard(text: string) {
    void navigator.clipboard.writeText(text);
    this.toastr.info(ToastInfoTitle.SKILLID_COPIED);
  }

  public change(value: string): void {
    value = value.split(' ').join('');
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.replace(/^\d+/, ''); // Remove leading digits


    /* Hier könnten wir Pattern ansetzen welche
    die skillId validieren und ggf. korrigieren.
    value = value.replace(/\s+/g, '');

    value = value.replace(/^\d+/, '');
    */
    this.changed.emit(value);
  }
}
