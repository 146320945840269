import { CodeEditorAiComponent } from './../../components/ai-bot/utils/code-editor-ai/code-editor-ai.component';
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogMessage, HistoryItem, Voice } from '@reflact/ai-types';
// eslint-disable-next-line no-restricted-imports
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { BotService } from '../bot.service';
import { EndBotOptions, EndbotService } from '../endbot.service';
import { SkillService } from '../skill.service';
import { SocketService } from '../socket.service';
// @ts-ignore
import StreamingAvatar, { TaskMode, TaskType } from '@heygen/streaming-avatar';
import { KeyValEntry } from '@reflact/ai-types/dist/KeyValStore';
import { waitMinutes, waitSeconds } from '@reflact/tsutil';
import confetti from 'canvas-confetti';
import { ConnectCallback, PostMessagePayload, PostMessageResponse, startListening } from 'postmessage-promise';
import qs from "qs";
import { KeyValService } from '../../key-val.service';
import { InactiveBotServiceService } from '../inactive-bot-service.service';
import { RagaibotviewhelperService } from '../ragaibotviewhelper/ragaibotviewhelper.service';
import { RouteShareService } from '../route-share.service';
import { ScoreService } from '../score/score.service';
import { ChartService } from '../chart/chart.service';


export enum PostType {
  // other messages
  resetSession = 'resetSession',

  confetti = 'confetti',

  // normal messages
  runMessage = 'runMessage', // deliver chatmessage to open ai
  runComplete = 'runComplete', // recieved chatmessage and post to iframe parent

  // bypass messages
  addMessage = 'addMessage', // bypass message to open ai as user
  userBypassComplete = "userBypassComplete", // bypass message to open ai as user completed
  addMessageBot = 'addMessageBot', // bypass message to open ai as bot
  botBypassComplete = "botBypassComplete", // bypass message to open ai as bot completed

  // simulate messages
  botMessage = 'botMessage', // send message as bot
  botMessageComplete = "botMessageComplete", // recieved message from iframe as bot
  userMessage = 'userMessage', // send message as user

  // audio player
  botSayMessage = "botSayMessage", // say message as bot
  botSayLoad = "botSayLoad", // bot is generating mp3 file
  botSayLoadComplete = "botSayLoadComplete", // bot has generated mp3 file
  botSayEnded = "botSayEnded", // mp3 file has played

  // card messages
  botcard = "botcard",
  scormMessage = "scormMessage",
  scormHook = "scormHook",
  callExternalFunction = "callExternalFunction",
  getRegisteredFunctionNames = "getRegisteredFunctionNames"



  // show youtube card from video id
}
// BOT CARD TYPES
export enum BotCardType {
  youtubeCard = "youtubeCard",
  iframeCard = "iframeCard",
  vimeoCard = "vimeoCard",
  imageCard = "imageCard",
}

export enum ScormPayloadType {
  LMSSetValue = "LMSSetValue",
  LMSGetValue = "LMSGetValue"
}

export type MessageData<PayloadType = unknown> = {
  type: PostType
  payload: PayloadType
}


@Injectable(
  { providedIn: 'root' }
)
export class IframeCommunicationService {

  public userMessageEmitter: EventEmitter<string> = new EventEmitter<string>();
  public runCompleteEmitter: EventEmitter<string> = new EventEmitter<string>();
  public runRequestEmitter: EventEmitter<string> = new EventEmitter<string>();

  public addMessageEmitter: EventEmitter<string> = new EventEmitter<string>();
  public addMessageBotEmitter: EventEmitter<string> = new EventEmitter<string>();
  public botMessageEmitter: EventEmitter<string> = new EventEmitter<string>();
  //public botCardEmitter: EventEmitter<HistoryItem> = new EventEmitter<HistoryItem>();
  public resetSessionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _postMessage?: ConnectCallback["postMessage"];
  private _listenMessage?: ConnectCallback["listenMessage"];
  private _destroy?: ConnectCallback["destroy"];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private botService: BotService,
    private score: ScoreService,
    private charService: ChartService,
    private helper: RagaibotviewhelperService,
    private skillService: SkillService,
    public endBotService: EndbotService,
    public inActiveService: InactiveBotServiceService,
    private toastr: ToastrService,
    public keyValService: KeyValService,
    public rss: RouteShareService
  ) {
    void this.init();
  }

  public async postMessage<T>(method: string, payload: PostMessagePayload) {
    if (this._postMessage == null) {
      console.log("No Frame has registered yet for postmessage");
      return;
    }
    return await this._postMessage(method, payload) as Promise<T>;
  }
  public get listenMessage() {
    if (this._listenMessage != null) {
      return this._listenMessage;
    }
    throw new Error("listenMessage is not initialized.");
  }



  public async init() {

    const r = this.RAGAI;
    console.log("Init  RagAi", r);
    /*   window.addEventListener('message', (e) => {
         if (e.data.identity_key != 'postmessage-promise_client') {
           console.warn("we have an old msg", e.data);
           const data: { type: PostType, payload: any } = e.data as { type: PostType, payload: any };
           this.depricatedRecivevedMsg(data.type, data.payload);
         }
         // Hier Könnte man versuchen das in die listenMessage zu packen
         // oder Leute zwingen auf die neue version zu updaten
       }, false);*/


    console.log("init iframe communication startListening");

    const e = await startListening({ timeout: 50000 });
    console.log("init iframe communication startListening done");

    this._postMessage = e.postMessage;
    this._listenMessage = e.listenMessage;
    this._destroy = e.destroy;
    console.log("init iframe communication");

    this.listenMessage(async (method: string, payload, response) => {

      switch (method as PostType) {
        case PostType.confetti:
          await this.RAGAI.confetti({});
          response("Habe party gemacht");
          break;
        case PostType.userMessage:
          this.RAGAI.userMessage(payload as string);
          response("ok");
          break;
        case PostType.runMessage:
          this.RAGAI.runMessage(payload as string);
          response("ok");
          break;
        case PostType.addMessage: // admessage byoass to open ai
          response(await this.RAGAI.addMessage(payload as string));
          break;
        case PostType.addMessageBot: // admessage byoass to open ai
          response(await this.RAGAI.addMessageBot(payload as string));
          break;
        case PostType.botMessage: // simulate the bot
          this.RAGAI.botMessage(payload as string);
          response("ok");
          break;
        case PostType.botSayMessage:
          const { text, voice } = this.typePlayload<{ text: string, voice: Voice }>(payload);
          response(await this.RAGAI.sayMessage(text, voice));
          break;
        case PostType.botcard:
          const { data, type } = this.typePlayload<{ type: string, data: unknown }>(payload);
          response(await this.RAGAI.addBotCard({ data, type }));
          break;
        case PostType.resetSession:
          await this.RAGAI.resetBot();
          response("ok");
          break;
        case PostType.scormHook:
          const { hook, params } = this.typePlayload<{ hook: string, params: unknown }>(payload);
          this.RAGAI.SCORM.scormHook(hook, params);
          break;


        default:
          console.warn("Unknown message type", data);
      }
    });
  }



  public depricatedRecivevedMsg(method: PostType, payload: unknown) {
    switch (method) {
      case PostType.confetti:
        void this.RAGAI.confetti({});
        break;
      case PostType.userMessage:
        this.RAGAI.userBypass(payload as string);
        break;
      case PostType.runMessage:
        this.RAGAI.runMessage(payload as string);
        break;
      case PostType.addMessage: // admessage byoass to open ai
        break;
      case PostType.addMessageBot: // admessage byoass to open ai
        break;
      case PostType.botMessage: // simulate the bot
        this.RAGAI.botMessage(payload as string);
        break;
      case PostType.botSayMessage:
        const { text, voice } = this.typePlayload<{ text: string, voice: Voice }>(payload);
        break;
      case PostType.botcard:
        const { data, type } = this.typePlayload<{ type: string, data: unknown }>(payload);
        break;
      case PostType.resetSession:
        void this.RAGAI.resetBot();
        break;
      case PostType.scormHook:
        const { hook, params } = this.typePlayload<{ hook: string, params: unknown }>(payload);
        this.RAGAI.SCORM.scormHook(hook, params);
        break;
      default:
        console.warn("Unknown message type", { method, payload });
    }
  }

  public typePlayload<T>(payload: unknown): T {
    return payload as T;
  }

  public get RAGAI(): RagAI {
    //@ts-ignore
    if (window.RAGAI != null) {
      //@ts-ignore
      return window.RAGAI as RagAI;
    }

    //@ts-ignore
    window.RAGAI = {
      callExternalFunction: async (name: string, ...args: unknown[]) => {
        return await this.postMessage(PostType.callExternalFunction, { name, args });
      },
      getRegisteredFunctionNames: async () => {
        return await this.postMessage(PostType.getRegisteredFunctionNames, {});
      },
      beforeWelcome: async () => {
        console.log("RagAi Runs beforcewelcome ");
        return new Promise<void>((resolve, reject) => { resolve(); });
      },
      runWelcome: async () => {
        console.log("RagAi Runs RunWelcome ");
        await this.RAGAI.beforeWelcome();
        return await this.botService.runWelcome(this.rss.botConfig._id);
      },
      qs: qs,
      dialogStore: {
        setItem: (key: string, value: string) => { console.log("key", "value"); },
        getItem: (key: string) => { return "key"; }
      },
      CHARTS: this.charService,

      EVENTS: {
        eventHook: async (hook: string, params: any) => {
          //console.debug(hook, params);
        },
        inActiveHook: (seconds: number) => {
          return this.inActiveService.get(seconds);
        }
      },
      WAITFOR: {
        varsInit: () => { return this.score.waitForInitials(); },
        seconds: waitSeconds,
        minutes: waitMinutes
      },
      KEYVAL: {
        get: (key: string) => {
          return this.keyValService.getKeyValEntry(key);
        },
        set: (key: string, value: unknown, type: string = "custom") => { return this.keyValService.storeKeyValEntry(key, value, type); },
        getByType: (type: string) => { return this.keyValService.getKeyValByType(type); }
      },
      SCORM: {
        LMSSetValue: async (key: string, value: string) => {
          return await this.postMessage(PostType.scormMessage, { type: ScormPayloadType.LMSSetValue, key, value });
        },
        LMSGetValue: async (key: string) => {
          const result = await this.postMessage<string | number>(PostType.scormMessage, { type: ScormPayloadType.LMSGetValue, key });
          if (result == undefined) {
            throw new Error("Could not get LMS value. Maybe not initialized");
          } else {
            return result;
          }
        },
        scormHook: (hook: string, params: any) => { console.debug("RAGAI.SCORM.LMSHook not used "); }
      },
      score: this.score,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      confetti: confetti,
      toastr: this.toastr,
      downloadTextAsFile: this.helper.downloadTextAsFile,
      copyToClipboard: this.helper.copyToClipboard,
      closeWindow: this.helper.closeWindow,
      showOverlay: this.helper.showOverlay,
      showOverlayWithButtons: this.helper.showOverlayWithButtons,
      showOverlayWithTextAndButtons: this.helper.showOverlayWithTextAndButtons,
      lastRunId: "",
      lastThreadId: "",

      cancel: async () => {
        const lastRun = this.RAGAI.lastRunId;
        const lastThread = this.RAGAI.lastThreadId;
        const botId = this.rss.botConfig._id;
        if (!botId) { return; }
        if (lastRun === "") { return; }
        if (lastThread === "") { return; }
        console.log("BRECHE AB", lastRun, lastThread, botId);
        await this.botService.sendBotThinkingCancel(botId, lastRun, lastThread);
      },
      addMessage: async (payload: string) => {
        const backendPayload: DialogMessage = { content: payload, role: 'user', bypass: true };
        console.log("ADD MESSAGEHTTP", backendPayload);
        const res = await this.RAGAI.addMessagesToThread([backendPayload]);
        void this.postMessage(PostType.userBypassComplete, [res]);
        if (res[0] != null) {
          this.addMessageEmitter.emit(payload);
          return res[0];
        }
        throw new Error("Failed to add message");

      },
      addMessagesToThread: async (messages: DialogMessage[]) => {
        const botId = this.rss.botConfig._id;
        const res = await firstValueFrom(this.http.post<HistoryItem[]>('/api/toolbox/membot/user/createmessagesinthread/' + botId, { messages }));
        return res;
      },
      addDialogMessages: async (messages: DialogMessage[]) => {
        return await this.RAGAI.addMessagesToThread(messages);
      },
      addBotCard: async (payload: { type: string, data: unknown }) => {
        const backendPayload: DialogMessage = { content: JSON.stringify(payload), role: 'botcard', bypass: false };
        const res = await this.RAGAI.addMessagesToThread([backendPayload]);
        if (res[0] != null) {
          return res[0];
        }
        throw new Error("Failed to add bot card");
      },
      iframeCard: async (url: string, cssClass: string = '') => {
        await this.RAGAI.addBotCard({ type: BotCardType.iframeCard, data: { url, cssClass } });
      },
      userMessage: (text: string) => {
        this.userMessageEmitter.emit(text);
      },
      userBypass: async (text: string) => {
        await this.RAGAI.addMessage(text);
      },
      addMessageBot: async (payload: string) => {
        const backendPayload: DialogMessage = { content: payload, role: 'assistant', bypass: true } as const;
        const res = await this.RAGAI.addMessagesToThread([backendPayload]);
        if (res[0] != null) {
          this.addMessageBotEmitter.emit(payload);
          return res[0];
        }
        throw new Error("Failed to addMessageBot");
      },
      botBypass: async (text: string) => {
        return await this.RAGAI.addMessageBot(text);
      },
      DOM: {
        body: () => { return document.getElementById("bot-container"); },
        header: () => { return document.getElementById("bot-header"); },
        content: () => { return document.getElementById("bot-content"); },
        footer: () => { return document.getElementById("bot-footer"); },
      },
      botMessage: (text: string) => {
        console.log("botMessage wird  nun emmited with " + text, this.botMessageEmitter);
        this.botMessageEmitter.emit(text);
        void this.postMessage(PostType.botMessageComplete, text);
      },
      botSay: async (text: string, voice: Voice | null = null) => {
        const avatar = this.getWindowedAavatar();
        if (avatar != null) {
          void avatar.speak({
            text: text,
            taskMode: TaskMode.ASYNC,
            taskType: TaskType.REPEAT
          });
        } else {
          console.log("playing audio")
          const blobparts = await this.RAGAI.sayMessage(text, voice);
          document.getElementById('audioplayer')?.remove();
          const audio = document.createElement('audio');
          audio.id = 'audioplayer';
          audio.autoplay = true;
          const blob = new Blob([blobparts], { type: 'audio/mp3' });
          const blobUrl = URL.createObjectURL(blob);
          audio.src = blobUrl;
          audio.onended = () => {
            void this.postMessage(PostType.botSayEnded, "");
          };
          document.body.appendChild(audio);
        }
      },
      runMessage: (text: string) => {
        this.runRequestEmitter.emit(text);
      },
      youtubeCard: async (id: string) => {
        await this.RAGAI.addBotCard(
          {
            type: BotCardType.youtubeCard,
            data: id
          });
      },
      vimeoCard: async (id: string, hash: string) => {
        await this.RAGAI.addBotCard(
          {
            type: BotCardType.vimeoCard,
            data: {
              id,
              hash
            }
          });
      },
      imageCard: async (src: string, altText: string = '', cssClass: string = '') => {
        await this.RAGAI.addBotCard(
          {
            type: BotCardType.imageCard,
            data: {
              src,
              altText,
              cssClass
            }
          });
      },
      sayMessage: async (payload: string, voice: null | Voice = null) => {
        const botId = this.rss.botConfig._id;
        await this.postMessage(PostType.botSayLoad, "");
        if (voice == null) { // default voice laden
          const res = await firstValueFrom(this.http.get<{ voice: Voice }>("/api/toolbox/audio/getvoice/" + botId));
          voice = res.voice;
        }
        const body = { text: payload, botId, voice };
        const content = await firstValueFrom(this.http.post("/api/toolbox/audio/create", body, { responseType: 'arraybuffer' }));
        await this.postMessage(PostType.botSayLoadComplete, content);
        return content;
      },
      resetBot: async () => {
        this.score.reset();
        await this.botService.resetBot(this.rss.botConfig._id);
      },
      getCurrentDialog: async () => {
        const botId = this.rss.botConfig._id;
        return (await this.botService.getHistoryMessages(botId)).messageHistory;
      },
      storeDialogToLocalStorage: async (key: string) => {
        const id = key ? key : "dialog_" + new Date().getTime() + "_" + Math.random() * 10000;
        localStorage.setItem(id, JSON.stringify(await this.RAGAI.getCurrentDialog()));
        return id;
      },
      // removeExisting: if true, all messages in the current dialog will be deleted, before restoring
      // ignoreExisting: if true, messages that are already in the dialog will not be restored
      restoreDialogFromLocalStorage: async (dialogId: string, removeExisting: boolean = false, ignoreExisting: boolean = true) => {
        if (removeExisting) {
          const dialog = await this.RAGAI.getCurrentDialog();
          const botId = this.rss.botConfig._id;
          if (botId && dialog.length > 0) {
            await this.botService.deleteHistoryMessages(botId, dialog.map(m => m.openAiMessageId));
          }
        }
        const str = localStorage.getItem(dialogId);
        if (str == null) {
          console.warn("No Dialog to restore found with id " + dialogId);
          return null;
        }
        const historyItems: HistoryItem[] = JSON.parse(str) as HistoryItem[];
        const addedHistoryItems = await this.RAGAI.addDialogMessagesFromHistoryItems(historyItems, ignoreExisting);
        return addedHistoryItems;
      },

      createDialogMessageFromHistoryItem: (item: HistoryItem): DialogMessage => {
        return {
          content: item.content,
          role: item.role,
          bypass: item.bypass
        };
      },

      addDialogMessagesFromHistoryItems: async (historyItems: HistoryItem[], ignoreExisting: boolean) => {
        if (ignoreExisting) {
          const currentHistoryItems = await this.RAGAI.getCurrentDialog();
          const currentIds = currentHistoryItems.map(m => m.openAiMessageId);
          historyItems = historyItems.filter(m => !currentIds.includes(m.openAiMessageId));
        }
        const messages = historyItems.map(item => this.RAGAI.createDialogMessageFromHistoryItem(item));
        return await this.RAGAI.addMessagesToThread(messages);
      },

      overrideDialogFromLocalStorage: async (dialogId: string) => {
        return await this.RAGAI.restoreDialogFromLocalStorage(dialogId, true, false);
      },
      appendDialogFromLocalStorage: async (dialogId: string) => {
        return await this.RAGAI.restoreDialogFromLocalStorage(dialogId, false, true);
      },
      appendDialogFromLocalStorageIgnoreExisting: async (dialogId: string) => {
        return await this.RAGAI.restoreDialogFromLocalStorage(dialogId, false, false);
      },
      enableSkills: async (skillIds: string[]) => {
        console.log("enable in comservice", skillIds);
        return await this.skillService.enableSkills(this.rss.botConfig._id, skillIds);
      },
      disableSkills: async (skillIds: string[]) => {
        console.log("disable in comservice", skillIds);
        return await this.skillService.disableSkills(this.rss.botConfig._id, skillIds);
      },
      enableSkillAndChildren: async (skillId: string, depth: number = Number.MAX_SAFE_INTEGER) => {
        return await this.skillService.enableSkillAndChildren(this.rss.botConfig._id, skillId, depth);
      },
      disableSkillAndChildren: async (skillId: string, depth: number = Number.MAX_SAFE_INTEGER) => {
        return await this.skillService.disableSkillAndChildren(this.rss.botConfig._id, skillId, depth);
      },
      initialMessages: async () => { return new Promise<DialogMessage[]>((resolve) => { resolve([]) }); },

      endBot: (opts: Partial<EndBotOptions> = {}) => {
        if (opts.autoRedirectSeconds == null) {
          opts.autoRedirectSeconds = -1;
        }
        if (opts.message == null) {
          opts.message = "Bot wurde beendet";
        }
        if (opts.title == null) {
          opts.title = "Bot beendet";
        }
        this.endBotService.endBot(this.rss.botConfig._id, opts as EndBotOptions);
      }
    };

    console.log("SETT RAG AI WINDOW OBJECT");
    //@ts-ignore eslint-disable-next-line @typescript-eslint/ban-ts-comment @ts-expect-error  // eslint-disable-next-line
    return window.RAGAI;

  }

  public getWindowedAavatar() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    return window.avatar as StreamingAvatar | null;
  }
  public runComplete(payload: string) {
    this.RAGAI.EVENTS.eventHook("runComplete", payload);
    //? skip event if it's not from parent (skip if the frame is it's own parent)
    if (window.parent == window) return;
    void this.postMessage(PostType.runComplete, payload);
  }
}

export type RagAI = {
  callExternalFunction: (name: string, ...args: unknown[]) => Promise<unknown>,
  getRegisteredFunctionNames: () => Promise<string>,


  beforeWelcome: () => Promise<void>,
  runWelcome: () => Promise<{ status: string }>,
  dialogStore: {
    setItem: (key: string, value: string) => void,
    getItem: (key: string) => string,
  },
  EVENTS: {
    eventHook: (hook: string, params: unknown) => void,
    inActiveHook: InactiveBotServiceService["get"],
  },
  CHARTS: ChartService,
  WAITFOR: {
    varsInit: () => Promise<void>,
    seconds: (s: number) => Promise<void>,
    minutes: (s: number) => Promise<void>,
  },
  KEYVAL: {
    get: (key: string) => KeyValEntry | undefined,
    set: (key: string, value: unknown, type: string) => void,
    getByType: (type: string) => KeyValEntry[],
  },
  SCORM: {
    LMSSetValue: (key: string, value: string) => Promise<PostMessageResponse>,
    LMSGetValue: (key: string) => Promise<string | number>
    scormHook: (hook: string, params: unknown) => void,
  },
  initialMessages: () => Promise<DialogMessage[]>
  score: ScoreService,
  confetti: (opts: confetti.Options) => Promise<undefined> | null,
  toastr: ToastrService,
  downloadTextAsFile: RagaibotviewhelperService["downloadTextAsFile"],
  copyToClipboard: RagaibotviewhelperService["copyToClipboard"],
  closeWindow: RagaibotviewhelperService["closeWindow"],
  showOverlay: RagaibotviewhelperService["showOverlay"],
  showOverlayWithButtons: RagaibotviewhelperService["showOverlayWithButtons"]; // @tiro hat gesagt ist egal was am ende om ;, oder nix
  showOverlayWithTextAndButtons: RagaibotviewhelperService["showOverlayWithTextAndButtons"],
  lastRunId: string,
  lastThreadId: string,
  qs: typeof qs,
  iframeCard: (url: string, cssClass: string) => Promise<void>,
  imageCard: (url: string, altText: string, cssClass: string) => void
  addMessage: (payload: string) => Promise<HistoryItem>
  addMessagesToThread: (messages: DialogMessage[]) => Promise<HistoryItem[]>
  addMessageBot: (payload: string) => Promise<HistoryItem>
  userMessage: (text: string) => void
  userBypass: (text: string) => void
  botBypass: (text: string) => void
  botMessage: (text: string) => void
  background: (string: string) => void
  botSay: (text: string, voice: Voice | null) => void
  sayMessage: (payload: string, voice: Voice | null) => Promise<ArrayBuffer>
  runMessage: (text: string) => void
  addBotCard: (payload: { type: string, data: unknown }) => Promise<HistoryItem>,
  youtubeCard: (id: string) => void
  vimeoCard: (id: string, hash: string) => void
  resetBot: () => Promise<void>
  getCurrentDialog: () => Promise<HistoryItem[]>,
  addDialogMessages: (messages: DialogMessage[]) => Promise<HistoryItem[]>,
  createDialogMessageFromHistoryItem: (item: HistoryItem) => DialogMessage
  storeDialogToLocalStorage: (key: string) => Promise<string>
  overrideDialogFromLocalStorage: (dialogId: string) => Promise<HistoryItem[] | null>
  appendDialogFromLocalStorage: (dialogId: string) => Promise<HistoryItem[] | null>
  appendDialogFromLocalStorageIgnoreExisting: (dialogId: string) => Promise<HistoryItem[] | null>
  addDialogMessagesFromHistoryItems(historyItems: HistoryItem[], ignoreExisting: boolean): Promise<HistoryItem[] | null>
  restoreDialogFromLocalStorage: (dialogId: string, removeExisting: boolean, ignoreExisting: boolean) => Promise<HistoryItem[] | null>
  enableSkills: (skillIds: string[]) => ReturnType<SkillService["enableSkills"]>;
  disableSkills: (skillIds: string[]) => ReturnType<SkillService["disableSkills"]>;
  enableSkillAndChildren: (skillId: string) => ReturnType<SkillService["enableSkillAndChildren"]>;
  disableSkillAndChildren: (skillId: string) => ReturnType<SkillService["enableSkillAndChildren"]>;
  DOM: {
    body: () => HTMLElement
    header: () => HTMLElement,
    content: () => HTMLElement,
    footer: () => HTMLElement,

  },
  endBot: (opts: Partial<EndBotOptions>) => void
};
