import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ChatbotConfig } from '@reflact/ai-types';
import { AdminService } from '../../services/admin.service';
import { RouteShareService } from '../../services/route-share.service';

@Component({
  selector: 'app-bot-image-reload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bot-image-reload.component.html'
})
export class BotImageReloadComponent {
  public loading = false;

  constructor(private adminService: AdminService, public rss: RouteShareService) { }

  public async deleteImage() {
    const newConfig: ChatbotConfig = await this.adminService.deleteImage(this.rss.botConfig._id);
    this.rss.botConfig = newConfig;
  }

  public async reloadImage() {
    let tmpInstr = this.rss.botConfig.botInstruction;
    if (tmpInstr.length > 500) {
      tmpInstr = tmpInstr.substring(0, 500);
    }
    this.loading = true;
    let p = 'Eine Visualisierung zu einem BOT mit dem Name "' + this.rss.botConfig.name + '". Er hat die folgende Funktion: ' + tmpInstr;
    const newConfig: ChatbotConfig = await this.adminService.reloadImage(this.rss.botConfig._id, p);
    this.loading = false;
    this.rss.botConfig = newConfig;
  }
}