<div class="modal-header"><i class="ri ri-customer-service-2-line me-1"></i>Prompt-Wiz</div>

<div
  id="scrollContainer"
  class="modal-body"
>
  <app-bot-chat-dialog
    #myDialog
    id="myDialog"
    [isThinking]="this.isThinking"
    [botConfig]="this.botConfig"
  ></app-bot-chat-dialog>
</div>

<div class="modal-footer">
  <div
    [ngClass]="{'disabled': this.isThinking || this.promptInput.trim().length === 0}"
    class="btn btn-primary send-prompt"
    (click)="this.add()"
  >
    <i class="ri-send-plane-2-fill "></i>
  </div>
  <input
    id="promptInput"
    [disabled]="this.isThinking"
    class="prompt w-100 form-control"
    (keyup)="this.onKey($event)"
    [(ngModel)]="this.promptInput"
  />
</div>