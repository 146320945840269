import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { AdminBackButtonComponent } from '../../../../../../../shared/components/ai-bot/utils/admin-back-button/admin-back-button.component';
import { FunctionNameSuggestComponent } from '../../../../../../../shared/components/ai-bot/utils/function-name-suggest/function-name-suggest.component';
import { SkillIdInputComponent } from '../../../../../../../shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AdminBackButtonComponent,
    FunctionNameSuggestComponent,
    SkillIdInputComponent
  ],
  templateUrl: './documents-tab-detail.component.html',
  styleUrl: './documents-tab-detail.component.scss'
})
export class DocumentsTabDetailComponent {
  constructor(
    private fileUploadService: FileUploadService,
    private toastr: ToastrService,
    public rss: RouteShareService
  ) {

  }



  protected documentFileName(name: string): string {
    const filenameArr = name.split('.');
    if (filenameArr.length === 2) return name.trim();
    return filenameArr.slice(0, filenameArr.length - 1).join('.').trim();
  }



  protected async saveDocument(id: string = '') {
    if (id === '') id = this.rss.document?._id ?? '';
    const doc = this.rss.documents.find(doc => doc._id === id);
    if (!doc) return;
    await this.fileUploadService.changeFileProperties(id, doc.metadata.enabledForKnowledge, doc.metadata.skillId, doc.metadata.name, doc.metadata.description, this.rss.botConfig._id);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }
}
