import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse, Webscrape } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { CounterService } from './counter.service';
import { RouteShareService } from './route-share.service';


export type RunningStatus = { _id: string, pageCount: number };

@Injectable({
  providedIn: 'root'
})
export class WebscrapeService {

  constructor(protected http: HttpClient, private toastr: ToastrService, public counterService: CounterService, public rss: RouteShareService) { }

  public async getWebscrapesForBot(botId: string) {
    const res = await firstValueFrom(this.http.get<{ status: "ok", webscrapes: Webscrape[] }>(`/api/toolbox/embedding/scrape/${botId}`));
    this.rss.webscrapes = res.webscrapes;
    this.counterService.setOfflineWebsite(botId, this.rss.webscrapes.length);
    return res;
  }

  public async getRunningStatus(botId: string) {
    return await firstValueFrom(this.http.get<{ status: "ok", running: RunningStatus[] }>(`/api/toolbox/embedding/scrape/${botId}/running`));
  }

  public async postWebscrapeBody(botId: string, body: { url: string, depth: number, active: boolean, skillId: string }): Promise<{ status: "ok", scrape: Webscrape }> {
    return await firstValueFrom(this.http.post<{ status: "ok", scrape: Webscrape }>(`/api/toolbox/embedding/scrape/${botId}`, body));
  }

  public async deleteWebscrapeId(botId: string, scrapeId: string): Promise<DefaultResponse> {
    return await firstValueFrom(this.http.delete<DefaultResponse>(`/api/toolbox/embedding/scrape/${botId}/${scrapeId}`));
  }

  public async deleteWebscrapePageId(botId: string, scrapeId: string, scrapepageIds: string[]): Promise<DefaultResponse> {
    return await firstValueFrom(this.http.post<DefaultResponse>(`/api/toolbox/embedding/scrape/${botId}/${scrapeId}/scrapepage`, { scrapepageIds }));
  }

  public async updateWebscrapeConfig(webscrape: Webscrape): Promise<DefaultResponse> {
    const body = {
      description: webscrape.description,
      active: webscrape.active,
      skillId: webscrape.skillId,
      name: webscrape.name
    };
    this.toastr.success(ToastSuccessTitle.SAVED);
    return await firstValueFrom(this.http.post<DefaultResponse>(`/api/toolbox/embedding/scrape/${webscrape.botId}/${webscrape._id}`, body));
  }

  public async getWebscrape(botId: string, scrapeId: string) {
    return await firstValueFrom(this.http.get<{ status: "ok", scrape: Webscrape }>(`/api/toolbox/embedding/scrape/${botId}/${scrapeId}`));
  }
}