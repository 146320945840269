<app-disconnect-overlay> </app-disconnect-overlay>

<div class="h-100 text-white audio-bg video-background">
  <video
    id="wave-video"
    class="waves"
    [ngClass]="{'visible':  this.getLayoutName() === 'recording'}"
    loop
    muted
    playsinline
  >
    <source
      src="/files/Audio_Waveform.mp4"
      type="video/mp4"
    >
  </video>
  <div
    class="row h-100"
    style="z-index: 9;position: relative;"
  >
    <div class="col d-flex justify-content-center align-items-center">
      <!-- Dein zentriertes Element -->
      <div>
        <ng-container *ngIf="this.getLayoutName() === 'landing'">
          <div
            class="start-call-btn d-flex justify-content-center align-items-center"
            (click)="this.startVoiceBot()"
          >
            <i class="ri-phone-fill ri-3x "></i>
          </div>
        </ng-container>
        <ng-container *ngIf="this.getLayoutName() === 'speaking'">
          <div class="row">
            <div class="col">
              <video
                [ngClass]="{'pulse': this.isSpeaking}"
                id="ballvideo"
                autoplay
                muted
                loop
                width="300px"
              >
                <source
                  src="/files/Audio_Animation.mp4"
                  type="video/mp4"
                >
              </video>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-center">
              <button
                class="stfu-button bg-danger text-white"
                (click)="this.killAllBotCommunicationImidiatly();"
              >
                <i class="ri-stop-large-fill ri-xl"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.getLayoutName() === 'recording'">
          <div style="z-index: 9;">
            <app-push-to-talk-record-button
              #recordButton
              [botState]="this.botState"
              (startRecording)="this.startRecording();"
              (resultRecording)="this.onResultRecording($event)"
              (doneRecordingAfterClick)="this.sendMsg($event)"
              (doneRecordingAfterHold)="this.sendMsg($event)"
            ></app-push-to-talk-record-button>
          </div>
        </ng-container>
      </div>
      <div
        class="top-third"
        *ngIf="this.getLayoutName() === 'recording'"
      >
        <div
          class="bottext"
          *ngIf="this.botWords || this.myWords"
        >
          <span
            class="botwords"
            *ngIf="this.botWords"
          >
            {{this.botWords}}
          </span>
          <br>
          <!-- <span
            style=" max-width: 90vw; "
            class="mt-3"
            *ngIf="this.myWords"
          >
            {{this.myWords}} <i
              *ngIf="
            this.myWords"
              class="ri-close-circle-fill text-danger cursor-pointer"
            ></i>
          </span> -->
        </div>
      </div>
      <div
        class="bottom-third "
        *ngIf="this.getLayoutName() === 'recording'"
      >
        <app-bot-prompt
          *ngIf="this.botConfig.promptbarAudioActive"
          [botConfig]="this.botConfig"
          [isThinking]="false"
          [cancelThinking]="this.botConfig.cancelBotThinkingVoiceActive"
          [voiceInput]="this.botConfig.voiceInputVoiceActive"
          [switchToAvatar]="this.botConfig.switchToAvatarFromVoice"
          [switchToChat]="this.botConfig.switchToChatFromVoice"
          (sendPrompt)="this.sendMsg(this.myWords)"
          [(promptInput)]="this.myWords"
        ></app-bot-prompt>
        <span *ngIf="!this.botConfig.promptbarAudioActive">
          {{ this.myWords}}
        </span>
      </div>
    </div>
  </div>
</div>