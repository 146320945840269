<div class="row  align-items-center align-items-end">
  <div class="col-auto ">
    <input
      class="invisible p-0 border-0"
      type="text"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      [autoClose]="'outside'"
      [displayMonths]="2"
      outsideDays="hidden"
      (dateSelect)="this.onMultiCopyDateSelection($event)"
      [dayTemplate]="multiCopyDayTemplate"
      [startDate]="this.multiCopyFromDate!"
    >
    <div
      class="btn btn-sm btn-primary"
      (click)="datepicker.toggle()"
    ><i class="ri ri-calendar-2-line cursor-pointer"></i>
      <ng-container *ngIf="startEqualsEnd() && this.multiCopyFromDate && this.multiCopyToDate">
        {{this.multiCopyFromDate.day}}.{{this.multiCopyFromDate.month}}.{{this.multiCopyFromDate.year}}
      </ng-container>

      <ng-container *ngIf="!startEqualsEnd()">
        <ng-container *ngIf="this.multiCopyFromDate">
          {{this.multiCopyFromDate.day}}.{{this.multiCopyFromDate.month}}.{{this.multiCopyFromDate.year}}
        </ng-container>
        bis
        <ng-container *ngIf="this.multiCopyToDate">
          {{this.multiCopyToDate.day}}.{{this.multiCopyToDate.month}}.{{this.multiCopyToDate.year}}
        </ng-container>
        <ng-container *ngIf="!this.multiCopyToDate">
          Heute
        </ng-container>

      </ng-container>
    </div>
  </div>
  <div
    class="col-auto"
    i18n="@@group"
  >Gruppen</div>
  <div class="col-auto text-left">
    <ng-select
      i18n-placeholder="@@select-groups"
      placeholder="Gruppen auswählen..."
      [items]="this.groupIds"
      [(ngModel)]="this.selectedGroupIds"
      [multiple]="true"
      i18n-notFoundText="@@no-groups"
      [notFoundText]="'Keine Gruppen vorhanden'"
      (change)="this.updateDeleteCount()"
    >
    </ng-select>
  </div>
</div>

<ng-template
  #multiCopyDayTemplate
  let-date
  let-focused="focused"
>
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="this.multiCopyIsRange(date)"
    [class.faded]="this.multiCopyIsHovered(date) || this.multiCopyIsInside(date)"
    [class.dayHasData]="this.dayHasData(date)"
    (mouseenter)="this.multiCopyHoveredDate = date"
    (mouseleave)="this.multiCopyHoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>