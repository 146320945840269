import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatbotConfig } from '@reflact/ai-types';

@Component({
  selector: 'app-bot-word-replace',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './bot-word-replace.component.html',
  styleUrl: './bot-word-replace.component.scss'
})
export class BotWordReplaceComponent {

  @Input({ required: true }) public botConfig!: ChatbotConfig;
  @Output() public botConfigChange: EventEmitter<ChatbotConfig> = new EventEmitter<ChatbotConfig>();

  public wordInput: string = '';
  public replacementInput: string = '';

  public addWordReplace() {
    this.botConfig.wordReplacements.push({ word: this.wordInput.trim(), replacement: this.replacementInput.trim() });
    this.botConfigChange.emit(this.botConfig);
    this.wordInput = '';
    this.replacementInput = '';
  }

  public removeWordReplace(index: number) {
    this.botConfig.wordReplacements.splice(index, 1);
    this.botConfigChange.emit(this.botConfig);
  }

  public isConfigInValid(): boolean {
    return this.wordInput.trim() == ''
      || this.replacementInput.trim() == ''
      || this.botConfig.wordReplacements.some((wr) => wr.word.toLowerCase().trim() == this.wordInput.toLowerCase().trim());
  }

}
