<div class="px-3 rounded-3 bg-white">
  <b>{{this.model}}</b>
  <div *ngIf="this.usageStats.length > 0 else notEnoughData">
    <div class="row">
      <div class="col-12 col-lg-6">
        <span i18n="@@api-requests">API-Requests</span>&nbsp;<small class="text-muted">{{this.totalRequests}}</small>
        <apx-chart
          [series]="this.requestSeries"
          [chart]="{
            type: 'area',
            height: 350,
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          }"
          [xaxis]="this.xaxis"
          [yaxis]="{
            tickAmount: this.calculateRequestsToTickAmount()
          }"
          [dataLabels]="{
            enabled: false
          }"
          [tooltip]="this.requestTooltip"
        ></apx-chart>
      </div>
      <div class="col-12 col-lg-6">
        <span [ngSwitch]="this.model">
          <span
            *ngSwitchCase="'gpt-4-turbo'"
            i18n="@@token"
          >Token</span>
          <span
            *ngSwitchCase="'gpt-4o'"
            i18n="@@token"
          >Token</span>
          <span
            *ngSwitchCase="'gpt-4o-mini'"
            i18n="@@token"
          >Token</span>
          <span
            *ngSwitchCase="'tts-1'"
            i18n="@@chars"
          >Buchstaben</span>
          <span
            *ngSwitchCase="'tts-1-hd'"
            i18n="@@chars"
          >Buchstaben</span>
          <span
            *ngSwitchCase="'heygen_streaming_avatar'"
            i18n="@@time-in-seconds"
          >Zeit in Sekunden</span>
          <span
            *ngSwitchDefault
            i18n="@@model-not-found"
          >Model not found</span>
        </span>&nbsp;
        <small class="text-muted">{{this.totalValue}}</small>
        <apx-chart
          [series]="this.valueSeries"
          [chart]="{
            type: 'bar',
            height: 350,
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          }"
          [xaxis]="xaxis"
          [dataLabels]="{
            enabled: false
          }"
          [tooltip]="this.tokenTooltip"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>

<ng-template #notEnoughData>
  <div
    class="alert alert-info"
    i18n="@@not-enough-data"
  >Nicht genügend Daten zum auswerten!</div>
</ng-template>