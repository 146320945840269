import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Trunk, TrunkItem } from '@reflact/ai-types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CounterService } from 'src/app/shared/services/counter.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { TrunkService } from 'src/app/shared/services/trunk.service';
import { FunctionNameSuggestComponent } from '../../../../../../shared/components/ai-bot/utils/function-name-suggest/function-name-suggest.component';
import { SkillIdInputComponent } from "../../../../../../shared/components/ai-bot/utils/skill-id-input/skill-id-input.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    FunctionNameSuggestComponent,
    SkillIdInputComponent,
    RouterModule
  ],
  styleUrl: './databases-tab.component.scss',
  templateUrl: './databases-tab.component.html'
})
export class DatabasesTabComponent {
  public newDatabase: string = '';
  public newDatabaseDescription: string = '';
  public selectedItemIndex: number = -1;

  public get selectedItem(): TrunkItem | undefined {
    return this.rss.datatrunk?.items[this.selectedItemIndex];
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private trunkService: TrunkService,
    public counter: CounterService,
    public rss: RouteShareService
  ) { }

  public async addDatabase() {
    const trunk: Omit<Trunk, "_id"> = {
      botId: this.rss.botConfig._id,
      skillId: '',
      placeholder: this.newDatabase,
      trunkDescription: this.newDatabaseDescription,
      keyDescription: "",
      active: true
    };
    const res = await this.trunkService.createTrunk(trunk);
    this.rss.datatrunks.unshift({ ...res.trunk, items: [] });
    this.newDatabase = '';
    this.newDatabaseDescription = '';
    this.toastr.success(ToastSuccessTitle.CREATED);
  }

  public async removeDatabase(trunkId: string) {
    await this.trunkService.deleteTrunk(trunkId);
    this.counter.removeTrunk(this.rss.botConfig._id);
    if (this.rss.datatrunk?._id == trunkId) {
      void this.router.navigate(['../']);
    }
    this.rss.datatrunks.splice(this.rss.datatrunks.findIndex(trunk => trunk._id == trunkId), 1);
    this.toastr.success(ToastSuccessTitle.DELETED);
  }

  public async updateDatabase(trunk: Trunk) {
    await this.trunkService.updateTrunk(trunk);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public isValidDatabaseName(name: string) {
    if (name.trim() == "") return false;
    if (name.length > 30) return false;
    const regex = /^[a-zA-Z0-9-_]+$/;
    return regex.test(name);
  }

  public isValidDatabaseNameAndDesc(name: string, desc: string): boolean {
    if (desc.trim() == "") return false;
    return this.isValidDatabaseName(name);

  }
}