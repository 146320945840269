<h3 i18n="@@voice-bot">Voice-Bot</h3>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@show-prompt"
          [label]="'Chateingabezeile anzeigen'"
          [(value)]="this.rss.botConfig.promptbarAudioActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>
    <h3
      class="mt-4"
      i18n="@@prompt-options"
    >Eingabezeile</h3>
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@voice-input"
          [label]="'Spracheingabe'"
          [(value)]="this.rss.botConfig.voiceInputVoiceActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-avatar"
          [label]="'Wechseln zu Avatar'"
          [(value)]="this.rss.botConfig.switchToAvatarFromVoice"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-chat"
          [label]="'Wechseln zu Chat'"
          [(value)]="this.rss.botConfig.switchToChatFromVoice"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@cancel-thinking"
          [label]="'Bot-Denken abbrechen'"
          [(value)]="this.rss.botConfig.cancelBotThinkingChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>
    <h3
      class="mt-4"
      i18n="@@message-options"
    >Nachrichten</h3>
    <div class="card border-0">
      <div class="card-body">
        <div class="row align-items-center mb-2">
          <div class="col-6">
            <span i18n="@@voice">Stimme</span>
          </div>
          <div class="col-auto">
            <select
              class="form-select"
              [(ngModel)]="this.rss.botConfig.voiceVoicebot"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="this.rss.saveBotConfig()"
            >
              <option
                *ngFor="let model of this.gptVoiceModels"
                [value]="model"
              >{{ model }}</option>
            </select>
          </div>
          <div class="col-auto">
            <div
              tooltip="Dies ist ein Demotext für die gewählte Stimme"
              i18n-tooltip="@@voice-demo"
            >
              <app-audio-btn
                i18n-msg="@@voice-demo"
                [msg]="'Dies ist ein Demotext für die gewählte Stimme'"
                [botId]="this.rss.botConfig._id"
                [isVoiceTester]="true"
                [unit]="'voice'"
              >
              </app-audio-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <!-- FRAME INPUT -->
  </div>
</div>