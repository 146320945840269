import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Webscrape } from '@reflact/ai-types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChunkContigentIndividualComponent } from 'src/app/shared/components/ai-bot/chunk-contigent-individual/chunk-contigent-individual.component';
import { HintMemoryRequiredComponent } from 'src/app/shared/components/ai-bot/utils/hint-memory-required/hint-memory-required.component';
import { SkillIdInputComponent } from 'src/app/shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';
import { CounterService } from 'src/app/shared/services/counter.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { RunningStatus, WebscrapeService } from 'src/app/shared/services/webscrape.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HintMemoryRequiredComponent,
    TooltipModule,
    SkillIdInputComponent,
    RouterModule,
    ChunkContigentIndividualComponent
  ],
  templateUrl: './offline-websites-tab.component.html',
  styleUrl: './offline-websites-tab.component.scss'
})
export class OfflineWebsitesTabComponent implements OnInit, OnDestroy {
  private runningInterval: number;
  public offlineWebUrl: string = '';
  public offlineWebDescription: string = '';
  public offlineWebDepth: number = 0;
  public runningStatus: RunningStatus[] = [];

  constructor(
    private toastr: ToastrService,
    public service: WebscrapeService,
    private counterService: CounterService,
    public wss: WebscrapeService,
    public rss: RouteShareService
  ) {
    this.runningInterval = window.setInterval(() => {
      void this.updateRunningStatus();
    }, 10000);
  }

  public async ngOnInit(): Promise<void> {
    await this.updateRunningStatus();
  }

  public ngOnDestroy(): void {
    if (this.runningInterval) {
      window.clearInterval(this.runningInterval);
    }
  }


  private async updateRunningStatus() {
    const oldRunningStatus = [...this.runningStatus];
    this.runningStatus = (await this.service.getRunningStatus(this.rss.botConfig._id)).running;
    oldRunningStatus.filter(oldStatus => {
      return this.runningStatus.findIndex(newStatus => newStatus._id == oldStatus._id) < 0;
    }).forEach(async (status) => {
      const newScrape = await this.service.getWebscrape(this.rss.botConfig._id, status._id);
      const index = this.rss.webscrapes.findIndex(scrape => scrape._id == newScrape.scrape._id);
      this.rss.webscrapes[index] = newScrape.scrape;
    });


  }

  public scrapeIsRunning(scrapeId: string): boolean {
    return this.runningStatus.findIndex(status => status._id == scrapeId) >= 0;
  }

  public getRunningCount(scrapeId: string): number {
    const runningStatus = this.runningStatus.find(status => status._id == scrapeId);
    if (runningStatus) {
      return runningStatus.pageCount;
    } else {
      return 0;
    }
  }

  public isConfigValid(): boolean {
    return this.offlineWebDepth >= 0 && this.offlineWebDepth <= 10 && this.offlineWebUrl.trim() != '' && this.isUrlValid(this.offlineWebUrl) && this.offlineWebDescription.trim() != '';
  }

  public async addOfflineWeb() {
    if (!this.isConfigValid()) return;
    const body: { url: string, depth: number, description: string, active: boolean, skillId: string } = {
      url: this.offlineWebUrl.trim(),
      depth: this.offlineWebDepth,
      description: this.offlineWebDescription.trim(),
      active: true,
      skillId: ''
    };
    const res = (await this.service.postWebscrapeBody(this.rss.botConfig._id, body)).scrape;
    if (this.rss.webscrapes.every(scrape => scrape.url != body.url || scrape.botId != this.rss.botConfig._id)) {
      this.rss.webscrapes.push(res);
      this.counterService.addOfflineWebsite(this.rss.botConfig._id);
    }
    this.runningStatus.push({ _id: res._id, pageCount: 0 });

    this.offlineWebDepth = 0;
    this.offlineWebUrl = '';
    this.offlineWebDescription = '';
    this.toastr.success(ToastSuccessTitle.SAVED, "Offline Webseite hinzugefügt und wird geladen");
    await this.updateRunningStatus();
  }

  public async deleteWebscrapes(offlineWeb: Webscrape) {
    await this.service.deleteWebscrapeId(offlineWeb.botId, offlineWeb._id);
    this.counterService.removeOfflineWebsite(this.rss.botConfig._id);
    this.rss.webscrapes = this.rss.webscrapes.filter((scrape: Webscrape) => {
      if (offlineWeb._id == scrape._id) {
        return false;
      } else {
        return true;
      }
    });
    this.toastr.success(ToastSuccessTitle.SAVED, "Offline Webseite wurde gelöscht");
  };

  public isUrlValid(url: string): boolean {
    if (url == '') return true;
    return /(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/.test(url);
  }
}