import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatbotConfig, GptChatModelTypeArr } from '@reflact/ai-types';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './bot-config-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule
  ],
  styleUrl: './bot-config-modal.component.scss'
})
export class BotConfigModalComponent {
  public botConfig!: ChatbotConfig;
  public gptModels = GptChatModelTypeArr;

  constructor(private bsModalRef: BsModalRef) { }

  public updateConfig() {
    this.bsModalRef.hide();
  }
}