import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChatbotConfig, HistoryItem, Model, SessionState, WordReplacement } from '@reflact/ai-types';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DisclaimerModalComponent } from 'src/app/shared/components/ai-bot/utils/disclaimer-modal/disclaimer-modal.component';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BOTEVENT, BotService } from 'src/app/shared/services/bot.service';
import { IframeCommunicationService } from 'src/app/shared/services/iframe-communication/iframe-communication.service';
import { getTokenContents, LoginService } from 'src/app/shared/services/login.service';
import { PlayAudio } from 'src/app/shared/services/playaudio.service';
import { SpeechService } from 'src/app/shared/services/speech.service';
import { ToastErrorTitle, ToastInfoTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { SocketService } from '../../../shared/services/socket.service';
import { StatisticService } from '../admin/tabs/insights/statistics-tab/statistic.service';


@Component({
  template: "",
  selector: 'app-bot-based',
  standalone: true,
  imports: [CommonModule, FormsModule, BsDropdownModule],
  providers: []
})
export class BotBasedComponent implements OnInit, OnDestroy {
  public botConfig!: ChatbotConfig;
  public isThinking: boolean = false;
  public prevBotState: SessionState = 'unknown';
  public botState: SessionState = 'unknown';
  public promptInput: string = '';
  protected currentThreadRun: { runId: string, threadId: string } = { runId: '', threadId: '' };
  protected subscriptions: Subscription[] = [];

  constructor(
    public route: ActivatedRoute,
    public loginService: LoginService,
    public speechService: SpeechService,
    public statisticsService: StatisticService,
    public iframeCommunication: IframeCommunicationService,
    public playService: PlayAudio,
    public bot: BotService,
    public socketService: SocketService,
    public toastr: ToastrService,
    public modalService: BsModalService,
    protected botMessages: BotMessagesService
  ) {
    route.data.subscribe(data => {
      const botConfig = data['botConfig'] as ChatbotConfig | null;
      if (!botConfig) return;
      this.botConfig = botConfig;
    });

    this.subscriptions.push(
      this.socketService.onCreateMessagesInThread.subscribe(data => {
        for (const message of data.messages) {
          if (!message.bypass) {
            this.isThinking = true;
            this.speechService.botIsThinkking = true;
          }
          this.botMessages.addMessage(message);
        }
      }),
      this.bot.resetBotDoneEmitter.subscribe(() => {
        this.botMessages.setMessages([]);
      }),
      this.socketService.onAddUserMessage.subscribe(data => {
        this.isThinking = true;
        this.speechService.botIsThinkking = true;
        this.botMessages.addMessage(data.message);
      }),
      this.socketService.onRunUpdate.subscribe(this.onRunUpdate.bind(this)),
      this.socketService.onStateUpdate.subscribe(this.onStateUpdate.bind(this))
    );
  }


  public async ngOnInit() {
    this.botMessages.setMessages((await this.bot.getHistoryMessages(this.botConfig._id)).messageHistory);

    this.speechService.textShouldBeSend.subscribe((send: boolean) => {
      if (send) {
        void this.add();
        this.promptInput = '';
        this.playService.stopAudio();
      }
    });

    this.playService.currentAudioStatus.subscribe((status: [string, string]) => {
      if (status[0] === 'idle') {
        this.promptInput = '';
      }
    });

    this.speechService.recognisedTextChanged.subscribe((text: string) => {
      if (this.isThinking || this.playService.anyAudioIsLoading() || this.playService.anyAudioIsPlaying()) return;
      this.promptInput = text;
    });

    this.initIFrameService();

    const userToken = this.loginService.getToken();
    if (userToken == null) {
      await this.loginService.loginAsGuest();
    }

    if (this.botConfig as ChatbotConfig | null) {
      this.injectJs(this.botConfig._id);
      if (this.botConfig.voiceInputChatActive || this.botConfig.voiceOutputChatActive) {
        this.speechService.init();
      }
      await this.loginService.loadMyUser().then(() => {
        let localStorageKey = 'token';
        if (this.route.snapshot.queryParamMap.has('uniqueSession')) {
          localStorageKey = this.route.snapshot.queryParamMap.get('uniqueSession') ?? '';
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const token = getTokenContents(localStorageKey);
        this.socketService.connectToThread(this.botConfig._id, "" + localStorage.getItem(localStorageKey));
      });
      this.showGDPR();
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => { sub.unsubscribe(); });
  }

  public onRunUpdate(data: HistoryItem & { deltaStr: string; }) {
    this.isThinking = true;
    this.speechService.botIsThinkking = true;
    let lastIndex = this.botMessages.getMessageCount() - 1;
    const current = this.botMessages.getMessage(lastIndex);
    if (current) {
      if (current.openAiMessageId != data.openAiMessageId) {
        this.botMessages.addMessage(data);
        const newCurrent = this.botMessages.getMessage(lastIndex + 1);
        if (newCurrent) {
          lastIndex += 1;
        }
      }
      this.botMessages.updateContent(lastIndex, data.content);
    } else {
      this.botMessages.addMessage(data);
    }
  }

  /** der socket hat nen neuen state */
  public async onStateUpdate(data: { sessionState: SessionState }) {
    this.botState = data.sessionState;
    this.updateBrowserTitle();
    if (this.botState == "openaierror") {
      this.onOpenAIError();
    }

    console.log("botstats", { prevstate: this.prevBotState, data });
    if (this.botState === "idle") {
      const isFirst = this.prevBotState == "unknown" || this.prevBotState == "init_assistant";
      if (isFirst) { // hier mal scaun
        await this.onInitialIdle();
      }
      await this.onMessagReceived(isFirst);
    }
    this.prevBotState = data.sessionState;
  }

  public async onInitialIdle() {
    console.log("OnInitialIdle", this.botMessages);
    const initialMessages = await this.iframeCommunication.RAGAI.initialMessages();
    if (initialMessages.length > 0) {
      await this.iframeCommunication.RAGAI.addMessagesToThread(initialMessages);
    }

    if (this.botMessages.findLatestAssistantMessage() == null && this.botConfig.welcomeType != "None") {
      console.log("No Initial Messages start with welcome and Wait for next idle");
      const mySubscription = this.socketService.onStateUpdate.subscribe((event) => {
        if (event.sessionState === "idle") {
          console.log("We Habe an idle emit WelcomeAndHistoryFinished");
          this.bot.botEvents.emit({ type: BOTEVENT.WelcomeAndHistoryFinished, data: {} });
          mySubscription.unsubscribe();
        }
      });
      console.log("runWelcome");
      await this.iframeCommunication.RAGAI.runWelcome();

    } else {
      console.log("We Have Old messages so Welcomandhistoryfinished");
      this.bot.botEvents.emit({ type: BOTEVENT.WelcomeAndHistoryFinished, data: {} });
    }
  }

  public async onMessagReceived(isFirst: boolean) {
    if (!isFirst) {
      const lastIndex = this.botMessages.getMessageCount() - 1;
      const current = this.botMessages.getMessage(lastIndex);
      if (current) {
        await this.broadCastMSG(current);
        this.iframeCommunication.runComplete(current.content);
      }
    }
  }

  public onOpenAIError() {
    this.toastr.error(ToastErrorTitle.SENT, "Bitte versuchen Sie es erneut.");
    // nachdem der toaster flog, soll debot wieder idle sein dammit der user es neu probieren kann
    this.botState = "idle";
  }

  public updateBrowserTitle() {
    window.document.title = "(" + this.botState + ") " + this.botConfig.name;
  }

  public removePassThrough() {
    if (this.loginService.loggedInUser?._id !== this.botConfig.creator
      && this.loginService.myOrgs.find(org => org.short == this.botConfig.orgShort) == null
    ) {
      localStorage.removeItem("passthrough");
    }
  }

  public initIFrameService() {
    this.iframeCommunication.runRequestEmitter.subscribe((prompt: string) => {
      void this.add(prompt, true);
    });

    this.iframeCommunication.resetSessionEmitter.subscribe(() => {
      if (this.isThinking) {
        this.cancelThinking().then(
          () => {
            void this.loginService.loginAsGuest();
            this.botMessages.setMessages([]);
          },
          () => { console.log('sendBotThinkingCancel rejected'); }
        );
      } else {
        void this.loginService.loginAsGuest();
        this.botMessages.setMessages([]);
      }
    });

    this.iframeCommunication.botMessageEmitter.subscribe((message: string) => {
      console.log("Habe botMeedahge", message);
      this.botMessages.addMessage({
        _id: "",
        openAiMessageId: "",
        context: {
          botid: this.botConfig._id,
          groupid: "",
          user: ""
        },
        created: new Date(), role: 'assistant',
        content: message,
        memoryEnabled: this.botConfig.memoryEnabled,
        openAiThreadId: ""
      });
    });

    this.iframeCommunication.userMessageEmitter.subscribe((message: string) => {
      this.promptInput = message;
      void this.add();
    });
  }

  protected injectJs(botId: string) {
    const url = window.location.origin + "/api/general/js/" + botId + "/custom.js";

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.head.appendChild(script);
  }

  public deleteHistory() {
    void this.bot.resetBot(this.botConfig._id);
    this.toastr.info(ToastInfoTitle.CHAT_DELETING);
  }

  //nonPomtInput wenn über BotApi Prompt gesendet wird dann das Input feld in ruhe lassen
  public async add(nonPromtInput: string | null = null, bypass: boolean = false) {
    let pInput = nonPromtInput ?? this.promptInput;
    if (pInput === '') return;
    //? Check for stop words
    const foundStopWords = this.promptContainsStopWord(pInput, this.botConfig.stopWords);
    if (foundStopWords.length > 0) {
      this.toastr.error(ToastErrorTitle.SENT, "Unerwünschte Wörter gefunden: " + foundStopWords.join(", "));
      return;
    }
    //? Check for word replacements
    pInput = this.runWordReplacer(pInput);
    this.isThinking = true;
    this.speechService.botIsThinkking = true;
    if (nonPromtInput == null) {
      this.promptInput = "";
    }

    const body: {
      message: string,
      overrideConfig?: boolean,
      temperature?: number,
      model?: Model,
      bypass: boolean
    } = { message: pInput, bypass: bypass };

    const threadAndRun: { runId: string, threadId: string } = await this.bot.membotUserChat(this.botConfig._id, body);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.RAGAI.lastRunId = threadAndRun.runId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.RAGAI.lastThreadId = threadAndRun.threadId;
    this.currentThreadRun = threadAndRun;
  }

  protected promptContainsStopWord(prompt: string, stopWords: string[]): string[] {
    const splittedPrompt = prompt.split(new RegExp("[^a-zA-Z0-9äöüÄÖÜß]+"));
    return stopWords.filter((word: string) => {
      return splittedPrompt.some((stopWord: string) => word.toLowerCase().trim() == stopWord.toLowerCase().trim());
    });
  }

  protected runWordReplacer(str: string) {
    this.botConfig.wordReplacements.forEach((wr: WordReplacement) => {
      str = str.replace(new RegExp("\\b" + wr.word + "\\b", 'gi'), wr.replacement);
    });
    return str;
  }

  public async cancelThinking() {
    await this.bot.sendBotThinkingCancel(this.botConfig._id, this.currentThreadRun.runId, this.currentThreadRun.threadId);
  }

  protected async broadCastMSG(msg: HistoryItem) {
    if (this.botConfig.voiceAutoPlay) {
      if (this.speechService.isInConversationMode) {
        await this.playService.playAudio(msg.content, this.botConfig._id);
      }
    }
    parent.postMessage(msg, '*');
  }

  public showGDPR() {
    if (this.botConfig.complianceText !== '' && localStorage.getItem('disclaimerDontShowAgain_' + this.botConfig._id) !== 'true') {
      const modalRef = this.modalService.show(DisclaimerModalComponent, {
        class: 'modal-lg',
        keyboard: false,
        ignoreBackdropClick: true,
        initialState: {
          text: this.botConfig.complianceText
        }
      });
      modalRef.onHide?.subscribe(() => {
        const dontShowAgain = modalRef.content?.dontShowAgain;
        if (dontShowAgain) {
          localStorage.setItem('disclaimerDontShowAgain_' + this.botConfig._id, 'true');
        }
      });
    }
  }
}