import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-for-boolean',
  templateUrl: './form-for-boolean.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class FormForBooleanComponent implements OnChanges {
  @Input() public falseLable?: string = $localize`:@@off:Aus`;
  @Input() public trueLable?: string = $localize`:@@on:An`;
  @Input({ required: true }) public value?: boolean;
  @Input({ required: true }) public label!: string;
  @Input() public isDisabled?: boolean = false;
  @Input() public colClass?: string = "col-3";
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      if (!changes["value"].currentValue) {
        this.value = false;
      }
    }
  }
}
