<h3 i18n="@@avatar-bot">Avatar-Bot</h3>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@show-prompt"
          [label]="'Chateingabezeile anzeigen'"
          [(value)]="this.rss.botConfig.promptbarAvatarActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>

    <!-- Wer hier was anpasst, muss im AfterViewInit prüfen obs noch klappt-->
    <h3
      class="mt-4"
      i18n="@@prompt-options"
    >Eingabezeile</h3>
    <div class="card border-0">
      <div class="card-body">
        <app-form-for-boolean
          i18n-label="@@voice-input"
          [label]="'Spracheingabe'"
          [(value)]="this.rss.botConfig.voiceInputAvatarActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-chat"
          [label]="'Wechseln zu Chat'"
          [(value)]="this.rss.botConfig.switchToChatFromAvatar"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@redirect-voice"
          [label]="'Wechseln zu Voice'"
          [(value)]="this.rss.botConfig.switchToVoiceFromAvatar"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
        <app-form-for-boolean
          i18n-label="@@cancel-thinking"
          [label]="'Bot-Denken abbrechen'"
          [(value)]="this.rss.botConfig.cancelBotThinkingChatActive"
          [colClass]="'col-6'"
          (valueChange)="this.rss.saveBotConfig(); this.reloadIframe()"
        ></app-form-for-boolean>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="outer">
      <div
        *ngFor="let avatar of this.avatars; let i = index"
        (click)="this.rss.botConfig.heyGenAvatarId = avatar.id; this.rss.botConfig.heyGenVoiceId = avatar.voiceId; this.rss.saveBotConfig()"
        class="card rounded-3 overflow-hidden cursor-pointer bg-white d-inline-block mx-2"
        style="height: 200px; width: 200px;"
        [ngClass]="{
              'bg-primary text-white border-primary': this.rss.botConfig.heyGenAvatarId === avatar.id,
              'ms-0': (i === 0),
              'me-0': (i === this.avatars.length-1),
            }"
        [ngStyle]="{'background': 'url(' + avatar.image + ') no-repeat bottom center', 'background-size': 'cover'}"
      >
        <span
          class="text-center bg-white position-absolute bottom-0 w-100"
          [ngClass]="{
                      'bg-primary text-white': this.rss.botConfig.heyGenAvatarId === avatar.id,
                      'bg-white': this.rss.botConfig.heyGenAvatarId !== avatar.id
                    }"
        >{{avatar.name}}</span>
      </div>
    </div>
  </div>
</div>