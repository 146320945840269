import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { AdminService } from '../services/admin.service';
import { LoginService } from '../services/login.service';
import { RouteShareService } from '../services/route-share.service';

export const allbotconfigResolver: ResolveFn<ChatbotConfig[] | null> = async (route) => {
  const loginService: LoginService = inject(LoginService);
  const adminService: AdminService = inject(AdminService);
  const rss: RouteShareService = inject(RouteShareService);

  await loginService.loadMyUser();
  if (loginService.loggedInUser && loginService.hasBotAdmin()) {

    const botConfigs = await adminService.getBots();
    if (botConfigs) {
      rss.botConfigs = botConfigs;
    }
    return botConfigs;
  } else {
    return [];
  }
};
