import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CompletionStat, HeygenStat, TextToSpeechStat, UsageStat } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { StatisticService } from './statistic.service';
import { Month, MonthOfYear, StatisticsMonthSelectorComponent } from './statistics-month-selector/statistics-month-selector.component';
import { UsageStatisticChartComponent } from './usage-statistic-chart/usage-statistic-chart.component';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    StatisticsMonthSelectorComponent,
    UsageStatisticChartComponent
  ],
  templateUrl: './statistics-tab.component.html',
  styleUrl: './statistics-tab.component.scss'
})
export class StatisticsTabComponent implements OnInit {

  public isThisBotUnused: boolean = false;
  public usageStats: UsageStat[] = [];
  public filteredUsageStats?: {
    all: UsageStat[]
    GPT4TURBO: CompletionStat[]
    GPT4O: CompletionStat[]
    GPT4OMINI: CompletionStat[]
    HEYGEN: HeygenStat[]
    TTS: TextToSpeechStat[]
  };



  public firstMonth: MonthOfYear = {
    month: dayjs().get("month") + 1 as Month,
    year: dayjs().get("year")
  };
  public lastMonth: MonthOfYear = {
    month: dayjs().get("month") + 1 as Month,
    year: dayjs().get("year")
  };
  public selectedMonth: MonthOfYear = {
    month: dayjs().get("month") + 1 as Month,
    year: dayjs().get("year")
  };

  constructor(
    public statService: StatisticService,
    public rss: RouteShareService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.usageStats = await this.statService.getStatistics(this.rss.botConfig._id);
    const lastIndex = this.usageStats.length - 1;
    if (this.usageStats.length === 0 || !this.usageStats[0] || !this.usageStats[lastIndex]) {
      this.isThisBotUnused = true;
      return;
    }
    this.usageStats.sort((a, b) => {
      return dayjs(a.created).isAfter(dayjs(b.created)) ? 1 : -1;
    });
    this.firstMonth = {
      year: dayjs(this.usageStats[0].created).get("year"),
      month: dayjs(this.usageStats[0].created).get("month") + 1 as Month
    };
    this.lastMonth = {
      year: dayjs(this.usageStats[lastIndex].created).get("year"),
      month: dayjs(this.usageStats[lastIndex].created).get("month") + 1 as Month
    };
    this.computeFilteredUsage();
  }

  public computeFilteredUsage() {
    const monthFiltered = this.usageStats.filter((stat) => dayjs(stat.created).get("month") + 1 === this.selectedMonth.month && dayjs(stat.created).get("year") === this.selectedMonth.year);
    this.filteredUsageStats = {
      all: monthFiltered,
      GPT4TURBO: monthFiltered.filter((stat) => stat.model === 'gpt-4-turbo') as CompletionStat[],
      GPT4O: monthFiltered.filter((stat) => stat.model === 'gpt-4o') as CompletionStat[],
      GPT4OMINI: monthFiltered.filter((stat) => stat.model === 'gpt-4o-mini') as CompletionStat[],
      HEYGEN: monthFiltered.filter((stat) => stat.model === 'heygen_streaming_avatar') as HeygenStat[],
      TTS: monthFiltered.filter((stat) => stat.model === 'tts-1') as TextToSpeechStat[]
    };
  }
}
