<div
  id="infoContent"
  class="text-center pt-0 mt-0"
  [ngStyle]="{


    'color':getContrastColor(this.botConfig.color),

    }"
>
  <div class="row mt-0">
    <div class="col text-end">
      <app-bot-image-reload></app-bot-image-reload>
    </div>
  </div>



  <div class="row justify-content-between">


    <div class="col text-white mx-2 my-3">

      <app-bot-quickinfo
        [colorMode]="false"
        [hideFeatures]="true"
        id="botQuickInfo"
        [botConfig]="this.botConfig"
      ></app-bot-quickinfo>
    </div>
  </div>
</div>
