import { CompletionContext } from '@codemirror/autocomplete';
import { CodeEditor } from '@acrodata/code-editor';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { languages } from '@codemirror/language-data';
import { cssLanguage } from "@codemirror/lang-css";
import { EditorView } from "@codemirror/view";
import { Route } from '@angular/router';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  selector: 'app-code-editor-css',
  templateUrl: './code-editor-css.component.html',
  // styles: [':host{display:contents}'], // Makes component host as if it was not there, can offer less css headaches. Use @HostBinding class approach for easier overrides.
  standalone: true,
  imports: [CodeEditor, FormsModule]
})



export class CodeEditorCssComponent implements OnInit {
  @Input() public placeholder: string = '';
  @Input() public rows: number = 5;
  @Input({ required: true }) public instruction: string = '';
  @Output() public finish: EventEmitter<string> = new EventEmitter<string>();
  protected languages = languages;
  public extensions = [];
  constructor(public rss: RouteShareService) {

  }

  public ngOnInit(): void {
    //Erste Codezeile ist immer 30,39px hoch (Zeile -> 22,3906px + Space -> 8px)
    //Jede weitere Zeile fügt 22,3906px hinzu
    const heightToRows = 8 + this.rows * 22.3906;
    //@ts-ignore
    this.extensions = [EditorView.theme({
      ".cm-content": {
        minHeight: heightToRows + "px"
      },
      ".cm-gutter": {
        minHeight: heightToRows + "px"
      }
    })];
    //@ts-ignore
    this.extensions.push(cssLanguage.data.of({ autocomplete: this.myCompletions.bind(this) }));
  }
  public myCompletions(context: CompletionContext) {
    const word = context.matchBefore(/[\w.]*$/);
    if (word?.from == word?.to && !context.explicit) {
      return null;
    }
    const knownSelectors: string[] = [
      "#chatbot-wrapper",
      "#bot-header",
      "#bot-content",
      "#bot-footer"

    ];
    return {
      from: word?.from,
      options: [
        ...knownSelectors.map(selector => ({ label: selector, apply: selector.substring(1), info: "🪄" })),
        {
          label: "🪄 CSS Gerüst Einfügen (CHATBOT)",
          apply: `/* CSS GERÜST (CHATBOT) */
#chatbot-wrapper {
  #bot-header {
  }
  #bot-content {
    #messages {
      .message-row {
        .bubble {
        }
        .bubble-addons {
        }
      }
    }
  }
  #bot-footer{
 }
}`
        },
        {
          label: "🎨 CSS Farbwerte einfügen (CHATBOT)",
          apply: `/* CSS Farbwerte (CHATBOT) */
* {
  --bubble-assistant-bg-color: #E5E5EA;
  --bubble-assistant-text-color: #000000;
  --bubble-user-bg-color: #0B93F6;
  --bubble-user-text-color: white;
  --chatbot-bg-color: #fff;
},
`

        },
        {
          label: "🎨 Header Ausblenden",
          apply: `/* Header Ausblenden */
  #bot-header { display: none;}
  #chat-container{ grid-template-rows: 1fr 100px !important; }
`

        }





      ]
    };

  }

  public saveToolConfig() {
    this.finish.emit(this.instruction);
  }
}

