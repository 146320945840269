import { EventEmitter, Injectable } from '@angular/core';
import { SocketService } from './services/socket.service';
import { isKeyValEntryScoreData, isKeyValEntryUnkownData, KeyValEntry } from '@reflact/ai-types/dist/KeyValStore';

@Injectable({
  providedIn: 'root'
})
export class KeyValService {

  public onKeyValUpdate: EventEmitter<KeyValEntry> = new EventEmitter<KeyValEntry>();


  public keyValMap = new Map<string, KeyValEntry>();



  constructor(public socketService: SocketService) {
    void this.init();
  }

  public async init() {
    const io = await this.socketService.getIo();
    io.on('keyValUpdate', (entry: KeyValEntry) => {
      this.onKeyValUpdate.emit(entry);
      if (isKeyValEntryUnkownData(entry)) {
        this.keyValMap.set(entry.key, entry);
      }
    });
    const items = await this.getInitialValues();
    for (const item of items) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (isKeyValEntryUnkownData(item)) {
        this.keyValMap.set(item.key, item);
      }
    }
  }

  public async getInitialValues() {
    const io = await this.socketService.getIo();
    return new Promise<KeyValEntry[]>((resolve, reject) => {
      io.emit('getInitialKeyVals', (entries: KeyValEntry[]) => {
        resolve(entries);
      });
    });
  }

  public async storeKeyValEntry(key: string, value: unknown, type: string = "custom") {
    const io = await this.socketService.getIo();
    io.emit('storeKeyValUpdate', { key: key, value: value, type });
  }

  public getKeyValEntry(key: string) {
    return this.keyValMap.get(key);
  }


  public getKeyValByType(type: string) {
    const results: KeyValEntry[] = [];
    this.keyValMap.forEach((val, key) => {
      if (val.type === type) {
        results.push(val);
      }
    });
    return results;
  }


}
