import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoginService } from '../services/login.service';
import { BotService } from './../services/bot.service';

export const botprotectResolver: ResolveFn<boolean> = async (route) => {
  const loginService: LoginService = inject(LoginService);
  const botService: BotService = inject(BotService);
  const token = loginService.getToken();
  if (token == null) {
    return false;
  } else {
    const id = route.params['id'] as string;
    const botConfig = await botService.getBotConfigAsUser(id);
    if (loginService.loggedInUser?._id !== botConfig?.creator
      && loginService.myOrgs.find(org => org.short == botConfig?.orgShort) == null
    ) {
      return false;
    }
    return true;
  }
};
