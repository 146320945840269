import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BotMessageFeedback, ChatbotConfig, HistoryItem, isIdleState, SessionState } from '@reflact/ai-types';
import { Subscription } from 'rxjs';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BotService } from 'src/app/shared/services/bot.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { ToastInfoTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { ThinkingAnimationComponent } from "../../../thinking-animation/thinking-animation.component";
import { AiAnnotation, MessageBubbleComponent, Remark } from './message-bubble/message-bubble.component';
import { MessageCardComponent } from './message-card/message-card.component';

@Component({
  selector: 'app-bot-chat-dialog',
  templateUrl: './bot-chat-dialog.component.html',
  styleUrls: ['./bot-chat-dialog.component.scss'],
  imports: [CommonModule, MessageBubbleComponent, MessageCardComponent, ThinkingAnimationComponent],
  standalone: true
})
export class BotChatDialogComponent implements OnChanges, OnDestroy {
  @Input() public annotations: AiAnnotation[] = [];
  @Input() public isThinking: boolean = false;
  @Input({ required: true }) public botConfig!: ChatbotConfig;
  @Input() public botState: SessionState = "idle";
  @Input() public enableDeletionOverride: boolean = false;
  @Input() public enableFeedbackOverride: boolean = false;
  @Input() public showHistory: boolean = false;
  @Input() public debugMode: boolean = false;

  public wasIdle = false;
  public msgIdToFeedbackMap: Map<string, BotMessageFeedback> = new Map<string, BotMessageFeedback>();
  private subscriptions: Subscription[] = [];

  constructor(
    private botService: BotService,
    public botMessagesService: BotMessagesService,
    private toastr: ToastrService,
    private socketService: SocketService
  ) {
    this.subscriptions.push(
      this.socketService.onFeedbackUpdate.subscribe(data => {
        const message = data.feedback.messages[data.feedback.messages.length - 1];
        if (message) {
          this.msgIdToFeedbackMap.set(message._id, data.feedback);
          this.toastr.info(ToastInfoTitle.ADD_FEEDBACK);
        }
      }),

      this.socketService.onFeedbackDeletion.subscribe(data => {
        const message = data.feedback.messages[data.feedback.messages.length - 1];
        if (message) {
          this.msgIdToFeedbackMap.delete(message._id);
          this.toastr.info(ToastInfoTitle.REM_FEEDBACK);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe(); });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['botConfig'] && this.showHistory) {
      void this.loadHistory();
    }
    if (changes['botState']) {
      if (isIdleState(this.botState) && !this.wasIdle && this.showHistory) {
        this.wasIdle = true;
        void this.loadHistory();
      }
    }
  }

  public async loadFeedback() {
    const feedbacks = (await this.botService.getMyFeedback(this.botConfig._id)).feedbacks;
    this.msgIdToFeedbackMap.clear();
    feedbacks.forEach(feedback => {
      const message = feedback.messages[feedback.messages.length - 1];
      if (message) {
        this.msgIdToFeedbackMap.set(message._id, feedback);
      }
    });
  }

  public async loadHistory(): Promise<void> {
    if (!this.showHistory) return;
    if (this.botMessagesService.getMessageCount() > 0) return;
    this.botMessagesService.setMessages((await this.botService.getHistoryMessages(this.botConfig._id)).messageHistory);
    await this.loadFeedback();
    void this.botService.scrollDown('instant');
  }

  public async deleteMessage(message: HistoryItem): Promise<void> {
    await this.botService.deleteHistoryMessages(this.botConfig._id, [message.openAiMessageId]);
    this.botMessagesService.deleteMessage(message.openAiMessageId);
  }

  public async sendFeedback(rawFeedback: { remark: Remark, msgId: string }) {
    const index = this.botMessagesService.findMessageIndex(rawFeedback.msgId);
    if (index < 0) return;
    const message = this.botMessagesService.getMessage(index);
    if (!message) return;
    if (rawFeedback.remark === '') {
      const feedback = this.msgIdToFeedbackMap.get(message._id);
      if (!feedback) return;
      await this.botService.deleteFeedback(feedback._id);
    } else {
      const messages = [message];
      if (index > 0) {
        const previousMessage = this.botMessagesService.getMessage(index - 1);
        if (previousMessage?.role === 'user' && !previousMessage.bypass) {
          messages.unshift(previousMessage);
        }
      }
      const feedbackId = this.msgIdToFeedbackMap.get(message._id)?._id;
      await this.botService.sendFeedback(feedbackId, { botId: this.botConfig._id, remark: rawFeedback.remark, messages });
    }
  }

  public enableFeedback(messageIndex: number): boolean {
    //Disabled for the first assistant message (welcome message)
    if (
      !this.botConfig.enableMessageFeedback ||
      (messageIndex == 0 && this.botMessagesService.getMessage(messageIndex)?.role == 'assistant') ||
      ((messageIndex == 1 || messageIndex == 2) && this.botMessagesService.getMessage(messageIndex)?.role == 'assistant' && this.botMessagesService.getMessage(messageIndex - 1)?.role == 'system')
    ) {
      return false;
    }
    return true;
  }
}
