<div class="row m-0">
  <div
    class="col iframe-wrapper position-relative"
    *ngFor="let botApiKey of this.botApisKeys"
  >
    <i
      class="ri ri-close-fill text-danger cursor-pointer position-absolute float-end"
      (click)="this.removeBot(botApiKey)"
    ></i>
    <iframe
      #bot
      [id]="botApiKey"
      [src]="'bot/' + this.botApis.get(botApiKey)!.botId + '?debug=true&multiBotMode=true&uniqueSession=' + this.getSessionTokenKey(botApiKey) | safe"
      class="position-relative"
    ></iframe>
  </div>
  @if (this.botApisKeys.length === 0) {
  <div class="text-center mt-5">
    <h2>Es sind noch keine Bots anwesend!</h2>
    <p>
      Starten Sie jetzt mit dem Multi-Bot-Tester, indem Sie über "+" unten rechts Bots zum testen hinzufügen!
    </p>
  </div>
  }
</div>
<div class="row m-0 align-items-center">
  <div class="col-4"></div>
  <div class="col">
    <input
      id="promptInput"
      [disabled]="this.isThinkingCount > 0"
      class="prompt w-100 form-control"
      (keyup)="this.onKey($event)"
      [(ngModel)]="promptInput"
    />
  </div>
  <div class="col-4 text-end pe-4">
    <div
      class="btn btn-secondary"
      (click)="this.openAddBots(addBotsModal)"
    >+</div>
  </div>
</div>

<ng-template #addBotsModal>
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-basic-title"
      i18n="@@add-bots"
    >Bots hinzufügen</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="this.addBotsModalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label
        class="form-label"
        for="botSelect"
        i18n="@@botSelect"
      >Bot</label>
      <ng-select
        [(ngModel)]="this.selectedBotId"
        name="botSelect"
        [labelForId]="'botSelect'"
        [items]="this.botConfigs"
        [bindValue]="'_id'"
        [bindLabel]="'name'"
      ></ng-select>
    </div>

    <div>
      <label
        class="form-label"
        for="botCount"
        i18n="@@botCount"
      >Anzahl</label>
      <app-hint i18n="@@botCountHint">
        Die Anzahl ist auf 5 beschränkt.
      </app-hint>

      <input
        #botCount
        [(ngModel)]="this.selectedBotCount"
        (change)="this.onChangeBotCount()"
        id="botCount"
        name="botCount"
        type="number"
        class="form-control"
        min="1"
        max="5"
        step="1"
      >
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="this.addBots()"
      [disabled]="this.selectedBotId === '' || this.selectedBotCount === 0"
      i18n="@@supplement"
    >Ergänzen</button>
  </div>
</ng-template>