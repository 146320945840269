import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public get language() {
    if (window.location.origin.startsWith("http://localhost")) {
      return "";
    }
    const urlLanguage = window.location.pathname.split('/')[1];
    if (urlLanguage) {
      return "/" + urlLanguage;
    }
    return '/de';
  }
}
