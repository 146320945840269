import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Trunk, TrunkItem, TrunkItemCondition } from '@reflact/ai-types';
import { ExcelExportComponent, ExcelImportComponent } from '@reflact/ngx-xlsx';
import { ButtonSettings, XLSXImportConfig, XlsxImportResult } from '@reflact/ngx-xlsx/lib/types';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { EnumTestEntry, TrunkService } from 'src/app/shared/services/trunk.service';
import { AdminBackButtonComponent } from "../../../../../../../shared/components/ai-bot/utils/admin-back-button/admin-back-button.component";
import { SkillIdInputComponent } from "../../../../../../../shared/components/ai-bot/utils/skill-id-input/skill-id-input.component";

@Component({
  standalone: true,
  imports: [
    AdminBackButtonComponent,
    CommonModule,
    FormsModule,
    ExcelImportComponent,
    ExcelExportComponent,
    SkillIdInputComponent
  ],
  templateUrl: './databases-tab-detail.component.html',
  styleUrl: './databases-tab-detail.component.scss'
})
export class DatabasesTabDetailComponent {
  public paramForNewForm?: TrunkItem;
  public selectedItemIndex: number = -1;
  public importing: boolean = false;
  public enumTestIsRunning: boolean = false;
  public importedDataList: { key: string, value: string }[] = [];
  public enumTestResult = new Map<string, { bewertung: string, grund: string }>();
  public mode: 'edit' | 'import' = 'edit';
  public conditionTypes: TrunkItemCondition['condition'][] = ['used-1', 'unused-1'];

  protected xlsxConfig: XLSXImportConfig[] = [
    { fieldName: 'key', type: 'string' },
    { fieldName: 'value', type: 'string' }
  ];
  protected importButton: ButtonSettings = {
    label: 'Import',
    icon: true,
    class: 'ri-import-fill'
  };
  protected downloadButton: ButtonSettings = {
    label: 'Demo',
    icon: true,
    class: 'ri-download-2-fill'
  };
  protected exportButton: ButtonSettings = {
    label: 'Export',
    icon: true,
    class: 'ri-export-fill'
  };

  public get selectedItem(): TrunkItem | undefined {
    return this.rss.datatrunk?.items[this.selectedItemIndex];
  }

  constructor(
    private toastr: ToastrService,
    private trunkService: TrunkService,
    public rss: RouteShareService
  ) { }

  public async updateDatabase(trunk: Trunk) {
    await this.trunkService.updateTrunk(trunk);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public isItemValid(): boolean {
    if (this.selectedItem?.key.trim() == '') return false;
    if (this.selectedItem?.value.trim() == '') return false;
    return true;
  }

  public async formularModelChange() {
    if (this.getSelectedItemState() === "new") return;
    if (!this.isItemValid()) { return; }
    if (!this.selectedItem) return;
    await this.trunkService.updateTrunkItem(this.selectedItem);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public selectEmptyParam() {
    this.selectedItemIndex = -1;
    this.paramForNewForm = { _id: "", trunkId: "", key: "", value: "", conditions: [] };
  }

  public getSelectedItemState() {
    if (this.rss.datatrunk && this.selectedItem) {
      if (this.rss.datatrunk.items.includes(this.selectedItem)) {
        return "existing";
      } else {
        return "new";
      }
    }
    return "new";
  }

  public async importGuestList() {
    if (!this.rss.datatrunk) return;
    this.importing = true;
    const trunkId = this.rss.datatrunk._id;
    const trunkItems: TrunkItem[] = this.importedDataList.map(({ key, value }) => (
      {
        _id: "",
        trunkId: trunkId,
        key: key,
        value: value,
        key_embedding: [],
        conditions: []
      }
    ));
    this.rss.datatrunk.items.push(...(await this.trunkService.createTrunkItems(this.rss.datatrunk._id, trunkItems)).trunkItems as TrunkItem[]);
    this.importing = false;
    this.mode = 'edit';
  }

  public onExcelInput(event: XlsxImportResult<{ key: string; value: string; }>) {
    if (event.status === 'success') {
      this.mode = 'import';
      this.importedDataList = event.data;
    } else {
      console.error(event.errors);
    }
  }

  public async addItemToList() {
    if (this.paramForNewForm === undefined) return;
    this.rss.datatrunk?.items.unshift(this.paramForNewForm);
    if (!this.rss.datatrunk) return;
    await this.trunkService.createTrunkItems(this.rss.datatrunk._id, [this.paramForNewForm]);
    this.paramForNewForm = undefined;
    this.toastr.success(ToastSuccessTitle.SAVED, 'Parameter wurde gespeichert');
  }

  public possibleTrunkItemsForConditionSelection(item: TrunkItem): TrunkItem[] {
    const itemKeys: TrunkItem[] = [];
    this.rss.datatrunks.forEach(trunk => {
      trunk.items.forEach(trunkItem => {
        if (!this.isSelected(item, trunkItem)) {
          itemKeys.push(trunkItem);
        }
      });
    });
    return itemKeys;
  }
  public isSelected(item: TrunkItem, trunkItem: TrunkItem): boolean {
    return item.conditions.findIndex(condition => {
      return condition.trunkItemId == trunkItem._id;
    }) >= 0;
  }

  public removeImportedDatabaseItem(item: { key: string, value: string }) {
    this.importedDataList.splice(this.importedDataList.findIndex(trunk => trunk.key == item.key && trunk.value == item.value), 1);
    if (this.importedDataList.length == 0) {
      this.mode = "edit";
    }
  }

  public getCondtionIndex(item: TrunkItem, conditionId: string): number {
    const index = item.conditions.findIndex(condition => {
      return condition.trunkItemId == conditionId;
    });
    if (index >= 0) {
      return index;
    }
    return -1;
  }

  public getTrunkItemById(trunkItemId: string): TrunkItem | undefined {
    return this.rss.datatrunks.flatMap(trunk => trunk.items).find(item => item._id === trunkItemId);
  }

  public conditionTypeToName(conditionType: string): string {
    if (conditionType == "used-1") {
      return "wurde verwendet";
    }
    if (conditionType == "unused-1") {
      return "wurde noch nicht verwendet";
    }
    return conditionType;
  }

  public async changeConditionEntryType(item: TrunkItem, conditionEntry: TrunkItemCondition, conditionType: 'used-1' | 'unused-1') {
    const conditionIndex = item.conditions.findIndex(condition => condition.trunkItemId == conditionEntry.trunkItemId);
    if (!item.conditions[conditionIndex]) return;
    item.conditions[conditionIndex].condition = conditionType;
    await this.trunkService.updateTrunkItem(item);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public async removeCondition(item: TrunkItem, conditionId: string) {
    const conditionIndex = this.getCondtionIndex(item, conditionId);
    if (conditionIndex >= 0) {
      item.conditions.splice(conditionIndex, 1);
    }
    await this.trunkService.updateTrunkItem(item);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public async activateCondition(item: TrunkItem, currItem: TrunkItem) {
    item.conditions.push({ trunkId: currItem.trunkId, trunkItemId: currItem._id, condition: 'used-1' });
    await this.trunkService.updateTrunkItem(item);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }

  public async toggleCondition(item: TrunkItem, currItem: TrunkItem) {

    const conditionIndex = this.getCondtionIndex(item, currItem._id);
    if (conditionIndex >= 0) {
      await this.removeCondition(item, currItem._id);
    } else {
      await this.activateCondition(item, currItem);
    }
  }

  public async deleteItemFromList(index: number) {
    const trunkItem = this.rss.datatrunk?.items[index];
    if (!trunkItem || !this.rss.datatrunk) return;
    this.rss.datatrunk.items.splice(index, 1);
    await this.trunkService.deleteTrunkItem(trunkItem._id);
    this.rss.datatrunks = this.rss.datatrunks.map(trunk => {
      if (trunk._id == this.rss.datatrunk?._id) {
        return this.rss.datatrunk;
      }
      return trunk;
    });
    this.toastr.success(ToastSuccessTitle.SAVED);
  }
  public async enumTest(enumTestString: string) {
    if (this.rss.datatrunk && !this.enumTestIsRunning) {
      this.enumTestIsRunning = true;
      const result: EnumTestEntry[] = await this.trunkService.enumTest(this.rss.datatrunk.botId, this.rss.datatrunk._id, enumTestString);
      this.enumTestResult.clear();
      result.forEach(entry => {
        this.enumTestResult.set(entry.name, { bewertung: entry.bewertung, grund: entry.grund });
      });
      this.enumTestIsRunning = false;
    }
  }
}
