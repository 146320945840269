import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SessionState } from '@reflact/ai-types';
import { waitSeconds } from '@reflact/tsutil';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SpeechRecognition, SpeechRecognitionEvent } from 'src/app/routes/ai-bot/avatar/avatar.component.types';
declare let webkitSpeechRecognition: { prototype: SpeechRecognition; new(): SpeechRecognition };
@Component({
  selector: 'app-push-to-talk-record-button',
  templateUrl: './push-to-talk-record-button.component.html',
  standalone: true,
  styleUrls: ['./push-to-talk-record-button.component.scss'],
  imports: [CommonModule, TooltipModule],
})

export class PushToTalkRecordButtonComponent {
  public holdTimeout: undefined | NodeJS.Timeout | number;
  @Output() public startRecording: EventEmitter<void> = new EventEmitter<void>();
  @Output() public stopRecording: EventEmitter<void> = new EventEmitter<void>();
  @Output() public resultRecording: EventEmitter<string> = new EventEmitter<string>();
  @Output() public doneRecordingAfterHold: EventEmitter<string> = new EventEmitter<string>();
  @Output() public doneRecordingAfterClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() public botState: SessionState = 'unknown';
  @Input() public holdDuration = 300; // Dauer für das Halten (z. B. 1 Sekunde)
  private webkitRecognition?: SpeechRecognition;
  public isRecordingByHold = false;
  public browserHasWebkitSpeechRecognition = false;
  public isMicrophonePermissionGranted = false;
  public isRecording = false;
  private myWords = '';
  // @HostBinding('class') protected readonly class = 'contents'; // Makes component host as if it was not there, can offer less css headaches. Assumes .contents{display:contents} css class exits
  constructor() {
    if (typeof webkitSpeechRecognition === 'function') {
      this.browserHasWebkitSpeechRecognition = true;
      this.webkitRecognition = new webkitSpeechRecognition();
      this.webkitRecognition.interimResults = true;
      this.webkitRecognition.continuous = true;
      this.webkitRecognition.lang = 'de-DE';
      this.webkitRecognition.addEventListener('speechend', async () => {
        if (this.isRecordingByHold) {
          await waitSeconds(0.1);
          this.getWebkitSpeechRecognition().start();
          this.startRecording.emit();
        } else {
          //          this.doneRecording.emit(this.myWords);
        }
      });
      this.webkitRecognition.addEventListener('result', (e: SpeechRecognitionEvent) => {
        const transcript = Array.from(e.results).map((result: SpeechRecognitionResult) => result[0]?.transcript).join('');
        this.myWords = transcript;
        this.resultRecording.emit(this.myWords);
      });
    } else {
      this.browserHasWebkitSpeechRecognition = false;
      alert("Ihr Browser unterstützt keine Spracherkennung");
    }
    void this.ensureHasMicrophonePermission();
  }


  public getWebkitSpeechRecognition(): SpeechRecognition {
    if (this.webkitRecognition === undefined) {
      throw new Error("WebRtcSpeechRecognition is not initialized");
    }
    return this.webkitRecognition;
  }

  private ngOnChanges(changes: SimpleChanges): void {
    /* wenn von aussen der state geändert wird, soll der button wieder normal aussehen
    aber ___ NICHT ___ das ended event triggern */

    if (changes["botState"]) {
      if (this.botState === 'thinking') {
        this.isRecording = false;
        this.isRecordingByHold = false;
        this.webkitRecognition?.stop();


      }
    }
  }
  public async onMouseDown() {
    const result = await this.ensureHasMicrophonePermission();
    if (result != "already granted") {
      // wenn der nutzer gerade die permission erteilt hat ist die Maus wohl nicht mehr
      // auf dem knopf und der Nutzer sollte nochmal drücken
      return;
    }

    if (this.isRecording && !this.isRecordingByHold) {
      this.isRecording = false;
      this.webkitRecognition?.stop();
      this.doneRecordingAfterClick.emit(this.myWords);
      return;
    } else {
      this.isRecording = true;
      this.webkitRecognition?.start();
      this.startRecording.emit();
    }

    this.holdTimeout = setTimeout(() => {
      this.isRecording = true;
      this.isRecordingByHold = true;
    }, this.holdDuration);
  }



  public async ensureHasMicrophonePermission() {
    if (this.isMicrophonePermissionGranted) {
      return "already granted";
    }
    try {
      await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      this.isMicrophonePermissionGranted = true;
      console.log("requesting mic permission, granted");
      return "granted after user interaction";
    } catch (e) {
      this.isMicrophonePermissionGranted = false;
      console.log("requesting mic permission, not granted");
      alert("microphone permission not granted");
      return "not granted";
    }

  }


  public onMouseUp() {
    if (this.isRecording && !this.isRecordingByHold) {
      clearTimeout(this.holdTimeout);
    }
    if (this.isRecordingByHold) {
      this.isRecordingByHold = false;
      this.isRecording = false;
      this.webkitRecognition?.stop();
      this.doneRecordingAfterHold.emit(this.myWords);
    }



  }



}
