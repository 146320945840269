import { CommonModule } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PlayAudio } from "../../../../../services/playaudio.service";

@Component({
  selector: 'app-audio-btn',
  templateUrl: './audio-btn.component.html',
  styleUrls: ['./audio-btn.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class AudioBtnComponent implements OnChanges {
  @Input({ required: true }) public msg!: string;
  @Input({ required: true }) public botId!: string;
  @Input({ required: true }) public unit!: 'chat' | 'voice';
  @Input() public isDisabled: boolean = false;
  @Input() public isVoiceTester: boolean = false;
  public hash: string = '';

  constructor(public playService: PlayAudio) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["msg"]) {
      this.hash = this.playService.getHashFromText(this.msg);
    }
  }

  public getState() {
    if (this.isPlaying()) {
      return "play";
    }
    if (this.isLoading()) {
      return "load";
    }
    return "idle";
  }

  public isLoading() {
    if (this.playService.currentLoadingHash == "") {
      return false;
    }
    return this.playService.currentLoadingHash == this.hash;
  }

  public isPlaying() {
    if (this.playService.currentPlayingHash == "") {
      return false;
    }
    return this.playService.currentPlayingHash == this.hash;
  }

  public async playAudio() {
    if (this.isVoiceTester) {
      this.playService.removeHashFromDict(this.hash);
      this.hash = this.playService.getHashFromText(this.msg);
      this.playService.isVoiceTester = true;
    }
    this.playService.unit = this.unit;
    await this.playService.playAudio(this.msg, this.botId);
  }

  public stopAudio() {
    this.playService.stopAudio();
  }
}
