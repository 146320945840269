import { Injectable } from '@angular/core';
import { HistoryItem } from '@reflact/ai-types';

@Injectable({
  providedIn: 'root'
})
export class BotMessagesService {
  //Das bleibt privat
  private messages: HistoryItem[] = [];

  public getMessages(): HistoryItem[] {
    return this.messages;
  }

  public setMessages(messages: HistoryItem[]) {
    this.messages = messages;
  }

  public addMessage(message: HistoryItem) {
    this.messages.push(message);
  }

  public getMessageCount() {
    return this.messages.length;
  }

  public getMessage(index: number) {
    return this.messages[index];
  }

  public updateContent(index: number, content: string) {
    if (this.messages[index]) {
      this.messages[index].content = content;
    }
  }

  public findMessageIndex(id: string) {
    return this.messages.findIndex(m => m._id === id);
  }

  public deleteMessage(openAiMessageId: string) {
    this.messages = this.messages.filter(m => m.openAiMessageId !== openAiMessageId);
  }

  public deleteMessages(openAiMessageId: string[]) {
    this.messages = this.messages.filter(m => !openAiMessageId.includes(m.openAiMessageId));
  }

  public findLatestAssistantMessage() {
    return this.messages.findLast(m => m.role == 'assistant');
  }
}