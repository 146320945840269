<nav
  class="navbar"
  [ngStyle]="{
    'background-color': this.getColorFromConfig(true),
    'color': this.getContrastColor(this.getColorFromConfig(true))
    }"
  style="height: 9cap;"
  id="endbotNavbar"
>
  <div class="container">
    <div class="row align-items-center mx-1">
      <div class="col-auto">
        <div
          class="border-1 border-white border rounded-circle position-absolute"
          style="height: 10px; width: 10px; background-color: #b33030; margin-left: 32px"
        ></div>
        <i
          [ngStyle]="{
            'background-color': this.getColorFromConfig(),
            'color': this.getContrastColor(this.getColorFromConfig())
            }"
          class="ri-2x p-2 rounded-circle"
          [class]="this.botConfig.icon"
        >
        </i>
      </div>
      <div class="col align-middle text-black">
        <a class="navbar-brand fs-6 fw-bold">{{this.botConfig.name}}</a>
        <small class="d-block text-muted">
          <div
            class="d-inline-block border-1 border-white border rounded-circle"
            style="height: 10px; width: 10px; background-color: #b33030;"
          ></div> offline
        </small>
      </div>
    </div>
  </div>
</nav>

<div
  class="card border-0"
  id="endbotBody"
>
  <div class="card-body">
    <h1 style="text-align: center;">{{this.endBotService.endOpts.title}}</h1>
    <h3 style="text-align: center;">{{this.endBotService.endOpts.message}}</h3>

    <div style="text-align: center;">
      <div
        *ngIf="this.endBotService.endOpts.restartUrl"
        class="btn btn-primary"
        (click)="this.endBotService.restart()"
      >Neustart</div>
    </div>

    <div style="text-align: center;">
      <div
        *ngIf="this.endBotService.endOpts.redirectUrl"
        class="btn btn-primary"
        (click)="this.endBotService.redirect()"
        style="text-align: center;"
      >Jetzt weiterleiten</div>

      <ng-container *ngIf="this.endBotService.endOpts.redirectUrl">
        <div
          *ngIf="this.endBotService.remainingSecondsBeforeRedirect() !== -1"
          style="text-align: center;"
          id="endbotRedirect"
        >Weiterleitung in {{this.endBotService.remainingSecondsBeforeRedirect()}} Sekunden</div>
      </ng-container>
    </div>
  </div>
</div>