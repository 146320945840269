import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientToServerEvents, HistoryItem, ServerToClientEvents, SessionState } from '@reflact/ai-types';
import { waitSeconds } from '@reflact/tsutil';
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { InactiveBotServiceService } from './inactive-bot-service.service';

export type MySocket = Socket<ServerToClientEvents, ClientToServerEvents>;

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private ioSocket?: MySocket;
  public onAddUserMessage: Subject<{ message: HistoryItem }> = new Subject<{ message: HistoryItem }>();
  public onRunUpdate: Subject<HistoryItem & {
    deltaStr: string
  }> = new Subject<HistoryItem & {
    deltaStr: string
  }>();
  public onStateUpdate: Subject<{ sessionState: SessionState }> = new Subject<{ sessionState: SessionState }>();
  public onMessageDeletion: Subject<Parameters<ServerToClientEvents["deleteMessages"]>[0]> = new Subject<Parameters<ServerToClientEvents["deleteMessages"]>[0]>();
  public onCreateMessagesInThread: Subject<Parameters<ServerToClientEvents["createMessagesInThread"]>[0]> = new Subject<Parameters<ServerToClientEvents["createMessagesInThread"]>[0]>();
  public onFeedbackUpdate: Subject<Parameters<ServerToClientEvents["updateFeedback"]>[0]> = new Subject<Parameters<ServerToClientEvents["updateFeedback"]>[0]>();
  public onFeedbackDeletion: Subject<Parameters<ServerToClientEvents["deleteFeedback"]>[0]> = new Subject<Parameters<ServerToClientEvents["deleteFeedback"]>[0]>();
  public onDisconnect: Subject<undefined> = new Subject<undefined>();

  private prevState: SessionState | "" = "";
  constructor(public route: ActivatedRoute,
    public inActiveService: InactiveBotServiceService
  ) { }

  public async getIo(): Promise<MySocket> {

    if (this.ioSocket) {
      return this.ioSocket;
    }
    await waitSeconds(1);
    return this.getIo();
  }

  public connectToThread(botid: string, token: string) {
    if (this.ioSocket != null) {
      return;
    }

    this.ioSocket = io({
      autoConnect: true, path: '/api/socket.io', extraHeaders: {
        token,
        groupid: this.route.snapshot.queryParamMap.get('groupid') ?? '',
        botid
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.ioSocket.on('connect', () => { });

    this.ioSocket.on('disconnect', () => {
      this.onDisconnect.next(undefined);
    });

    // Wird emitted, wenn eine neue Message von User geschickt wurde
    this.ioSocket.on("addUserMessage", (data) => {
      this.inActiveService.botWasActive();
      this.onAddUserMessage.next(data);
    });

    // Wird emitted, wenn der Server einen neuen Teil der Antwort bekommen hat
    this.ioSocket.on("runUpdate", (data) => {
      this.inActiveService.botWasActive();
      this.onRunUpdate.next(data);
    });

    // Wird emitted, wenn der Server den State des Bots aktualisiert
    this.ioSocket.on("stateUpdate", (data) => {

      if (this.prevState === data.sessionState) {
        return;
      }
      this.prevState = data.sessionState;
      this.onStateUpdate.next(data);
    });

    // Wird emitted, wenn der Server einen Custom ToolKit ausführt
    this.ioSocket.on("customToolKitExec", (data) => {
      switch (data.type) {
        case 'js':
          eval(data.content);
          break;
      }
    });

    this.ioSocket.on("createMessagesInThread", (data) => {
      this.onCreateMessagesInThread.next(data);
    });

    this.ioSocket.on("deleteMessages", (data) => {
      this.onMessageDeletion.next(data);
    });

    this.ioSocket.on("updateFeedback", (data) => {
      this.onFeedbackUpdate.next(data);
    });

    this.ioSocket.on("deleteFeedback", (data) => {
      this.onFeedbackDeletion.next(data);
    });
  }
}