import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { provideMarkdown } from 'ngx-markdown';
// eslint-disable-next-line no-restricted-imports
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './routes/login/login.component';
import { DisclaimerModalComponent } from './shared/components/ai-bot/utils/disclaimer-modal/disclaimer-modal.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { MyReflactLoginResolver } from './shared/resolver/MyReflactLoginResolver';
import { PortalLoginResolver } from './shared/resolver/PortalLogin.resolver';
import { HttpConcurrencyInterceptor } from './shared/interceptors/http-concurrency.service';

@NgModule({
  declarations: [
    AppComponent,
    DisclaimerModalComponent,
    LoginComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      includeTitleDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      timeOut: 2000
    }),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    NgxLayoutModule
  ],
  providers: [
    provideMarkdown(),
    PortalLoginResolver,
    MyReflactLoginResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConcurrencyInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
