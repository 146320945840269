import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { BotLoginService } from 'src/app/shared/services/bot-login.service';
import { ToastErrorTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';

@Component({
  selector: 'app-bot-login',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './bot-login.component.html',
  styleUrl: './bot-login.component.scss'
})
export class BotLoginComponent {
  protected botConfig!: ChatbotConfig;
  protected botPw: string = '';
  protected botOrigin: string = '';
  protected botQueryParams = {};

  constructor(
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private botLogin: BotLoginService
  ) {
    route.data.subscribe(data => {
      const botConfig = data['botConfig'] as ChatbotConfig | null;
      const botOwner = data['botOwner'] as boolean;
      this.botOrigin = data['botOrigin'] as string;
      this.botQueryParams = route.snapshot.queryParams;
      if (!botConfig) return;
      this.botConfig = botConfig;
      if (botOwner) this.skipLoginForOwner();
    });
  }

  protected skipLoginForOwner() {
    localStorage.setItem("passthrough", this.botConfig._id);
    this.botNavigator();
  }

  protected async sendBotPw() {
    const result = await this.botLogin.loginBot(this.botConfig._id, this.botPw);
    if (result.status === 'ok') {
      this.toastr.success(ToastSuccessTitle.GENERAL, 'Bot erfolgreich eingeloggt');
      localStorage.setItem("passthrough", this.botConfig._id);
      this.botNavigator();
    } else {
      this.toastr.error(ToastErrorTitle.GENERAL, 'Fehler beim Bot-Login');
      this.botPw = '';
    }
  }

  private botNavigator() {
    if (this.botOrigin === '') {
      void this.router.navigate(['/bot', this.botConfig._id], { queryParams: this.botQueryParams });
    } else {
      void this.router.navigate(['/bot', this.botConfig._id, this.botOrigin], { queryParams: this.botQueryParams });
    }
  }

}
