<div class="w-auto">
  <div class="row w-auto align-items-center">
    <div class="col">
      <input
        class="form-control"
        i18n-placeholder="@@word"
        placeholder="Wort"
        [(ngModel)]="this.wordInput"
      >
    </div>
    <div class="col">
      <input
        class="form-control"
        i18n-placeholder="@@replacement"
        placeholder="Ersatz"
        [(ngModel)]="this.replacementInput"
      >
    </div>
  </div>
  <div
    class="btn btn-primary btn-sm mt-2"
    [ngClass]="{'disabled': this.isConfigInValid()}"
    (click)="this.addWordReplace()"
  ><i class="ri-add-line"></i> <span i18n="@@add">Hinzufügen</span></div>

  <div
    class="row align-items-center"
    *ngFor="let wordReplacement of this.botConfig.wordReplacements; let i = index"
    [ngClass]="{'row-bottom-border': i < this.botConfig.wordReplacements.length - 1}"
  >
    <div class="col ps-3">
      <span>{{wordReplacement.word}}</span>
    </div>
    <div class="col ps-3">
      <span>{{wordReplacement.replacement}}</span>
    </div>
    <div class="col-auto pt-2">
      <div
        class="btn btn-outline-danger btn-sm"
        (click)="this.removeWordReplace(i)"
      ><i class="ri-close-line"></i></div>
    </div>
  </div>
</div>