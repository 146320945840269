import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

export type ToolInfoObject = {
  codeTemplate: string;
  description: string;

};

@Component({
  selector: 'app-code-editor-toolbar',
  templateUrl: './code-editor-toolbar.component.html',
  styleUrls: ['./code-editor-toolbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TooltipModule]
})
export class CodeEditorToolbarComponent {
  // @HostBinding('class') protected readonly class = 'contents'; // Makes component host as if it was not there, can offer less css headaches. Assumes .contents{display:contents} css class exits
  // constructor() {}
  @Output() public insertInEditor: EventEmitter<string> = new EventEmitter<string>();
  @Input() public variables: string[] = [];
  @Input() public skills: string[] = [];


  public ragAiFunctions: Map<string, ToolInfoObject> = new Map<string, ToolInfoObject>([
    ["Bot Nachricht",
      {
        codeTemplate: "RAGAI.botMessage('')",
        description: "Sendet eine Nachricht als bot an den Benutzer"
      }],
    ["User Nachricht", {
      codeTemplate: "RAGAI.userMessage('')",
      description: "Sendet eine Nachricht als User an den Bot"
    }],
    ["runMessage(str)", {
      codeTemplate: "RAGAI.runMessage('Nachricht direkt an ChatGPT')",
      description: "Sendet eine Nachricht an ChatGPT, diese wird verarbeitet und die Antwort wird an den Dialog weitergeleitet"
    }],
    ["Verstecke Nachricht (BOT)",
      {
        codeTemplate: "RAGAI.botBypass('')",
        description: "Sendet eine 'versteckte' nachricht. Aus perspektive des Bots"
      }],
    ["Verstecke Nachricht (USER)", {
      codeTemplate: "RAGAI.userBypass('Hallo BOT -> USER sieht die Nachricht nicht')",
      description: "Sendet eine 'versteckte' nachricht. Aus perspektive des Users"
    }],
    ["Bot Sprachausgabe", {
      codeTemplate: "RAGAI.botSay('Hallo, hier spricht der BOT')",
      description: "Lässt den Bot eine Nachricht sprechen"
    }],
    ["Website Einbetten", {
      codeTemplate: "RAGAI.iframeCard('https://reflact.com', 'cssClass')",
      description: "Bindet eine Website in den Chat ein"
    }],
    ["Youtube Video Einbetten", {
      codeTemplate: "RAGAI.youtubeCard('dQw4w9WgXcQ')",
      description: "Bindet ein Youtube Video in den Chat ein"
    }],
    ["Vimeo Video Einbetten", {
      codeTemplate: "RAGAI.vimeoCard('1020980807', 'b9bcdca38f')",
      description: "Bindet ein Vimeo Video in den Chat ein"
    }],
    ["Bild Einbetten", {
      codeTemplate: "RAGAI.imageCard('https://ki-toolbox.reflactportal.com/de/assets/AI_Logo.svg',  'cssClass')",
      description: "Bindet ein Bild in den Chat ein"
    }],
    ["Skills Aktivieren",
      {
        codeTemplate: "RAGAI.enableSkills([])",
        description: "Aktiviert die angegebenen Skills"
      }],
    ["Skills Deaktivieren",
      {
        codeTemplate: "RAGAI.disableSkills([])",
        description: "Deaktiviert die angegebenen Skills"
      }],
    ["Standard Ausgabe unterdrücken", {
      codeTemplate: "RAGAI.cancel()",
      description: "Bricht die aktuelle verarbeitung druch ChatGPT ab"
    }],
    ["Bot zurücksetzen", {
      codeTemplate: "RAGAI.resetBot()",
      description: "Setzt den Bot zurück"
    }]
  ]);


}
