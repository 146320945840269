import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BotService } from '../services/bot.service';
import { LoginService } from '../services/login.service';

export const botprotect: CanActivateFn = async (route) => {
  const router = inject(Router);
  const loginService: LoginService = inject(LoginService);
  const botService: BotService = inject(BotService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  const id = route.params['id'] as string;
  const botConfig = await botService.getBotConfigAsUser(id);
  let origin: string = '';
  if (botConfig?.isPwProtected) {
    if (route.url[2]) origin = route.url[2].path;
    const passthroughToken = localStorage.getItem("passthrough") ?? '';
    if (passthroughToken === '') {
      void router.navigate(['botlogin', id, origin], { queryParams: route.queryParams });
      return false;
    } else if (passthroughToken !== id) {
      void router.navigate(['botlogin', id, origin], { queryParams: route.queryParams });
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
