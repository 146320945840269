<div
  appDnd
  (fileDropped)="onFileDropped($event)"
>
  <ng-template #supportedFiles>
    <b i18n="@@supported-formats-title">Unterstützte Dateiformate:</b>
    <span *ngFor="let type of this.allowedFileTypes; last as isLast">
      <ng-container *ngIf="isLast">{{ type.prefix }}</ng-container>
      <ng-container *ngIf="!isLast">{{ type.prefix }}, </ng-container>
    </span>
  </ng-template>
  <button
    [tooltip]="supportedFiles"
    class="upload btn btn-sm"
    [ngClass]="{
          'btn-outline-primary': this.selectedFiles.length > 0,
          'btn-primary': this.selectedFiles.length === 0
          }"
    (click)="fileInput.click()"
    [disabled]="this.isDisabled"
  >
    <i class="ri-file-upload-line "></i> <ng-container i18n="@@choose-file">Datei wählen</ng-container>
    <input
      type="file"
      class="file-input"
      multiple
      (change)="this.showSelectedFile($event)"
      [(ngModel)]="this.tempPath"
      [multiple]="true"
      #fileInput
    />
  </button>
  <span
    class="text-danger"
    *ngIf="this.isDisabled"
  >
    <i class="ri-alert-fill text-danger ms-2"></i>
    Ihr Kontigent ist voll.
  </span>
</div>
<div *ngIf="this.selectedFiles.length">
  <div>
    <table class="table  m-0 w-100 mt-4">
      <tbody>
        <tr
          *ngFor="let file of selectedFiles"
          style="vertical-align: middle;"
        >
          <td>{{file.name}}</td>
          <td>{{file.size | convSize}}</td>
          <td class="text-end">
            <ng-container *ngIf="!this.hasUploadBeenClicked">
              <div
                class="btn btn-outline-danger btn-sm mt-2 mb-2"
                (click)="this.removeSelectedFile(file)"
              >
                <i class="ri-close-large-fill"></i>
              </div>
            </ng-container>
            <ng-container *ngIf="this.hasUploadBeenClicked">
              <div
                class="rotate"
                style="display: inline-block;"
              >
                <i class="ri-loader-4-line ri-xl"></i>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="this.selectedFiles.length">
    <div
      class="btn btn-primary mt-3 mb-3"
      (click)="this.uploadSelectedFiles()"
      i18n="@@upload-now"
    >Jetzt hochladen</div>
  </div>
</div>