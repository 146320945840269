import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChatbotConfig } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';

@Component({
  selector: 'app-bot-quickinfo',
  templateUrl: './bot-quickinfo.component.html',
  styleUrl: './bot-quickinfo.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class BotQuickinfoComponent {
  @Input({ required: true }) public botConfig!: ChatbotConfig;
  @Input({ required: false }) public showIcon: boolean = true;
  @Input({ required: false }) public colorMode: boolean = true;
  @Input({ required: false }) public featuresOnly: boolean = false;
  @Input({ required: false }) public hideFeatures: boolean = false;
  public getContrastColor = getContrastColor;

}
