import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { blockedStatesArr, ChatbotConfig, HistoryItem, Model } from '@reflact/ai-types';
import { getContrastColor } from '@reflact/tsutil';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BotConfigModalComponent } from 'src/app/shared/components/ai-bot/bot-config-modal/bot-config-modal.component';
import { BotChatDialogComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/bot-chat-dialog.component';
import { BotPromptComponent } from 'src/app/shared/components/ai-bot/utils/bot-prompt/bot-prompt.component';
import { DisclaimerModalComponent } from 'src/app/shared/components/ai-bot/utils/disclaimer-modal/disclaimer-modal.component';
import { DisconnectOverlayComponent } from 'src/app/shared/components/disconnect-overlay/disconnect-overlay.component';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BotService } from 'src/app/shared/services/bot.service';
import { IframeCommunicationService } from 'src/app/shared/services/iframe-communication/iframe-communication.service';
import { getTokenContents, LoginService } from 'src/app/shared/services/login.service';
import { PlayAudio } from 'src/app/shared/services/playaudio.service';
import { SpeechService } from 'src/app/shared/services/speech.service';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { BotDebugComponent } from '../../../shared/components/ai-bot/bot-debug/bot-debug.component';
import { ScoreboardComponent } from "../../../shared/components/scoreboard/scoreboard.component";
import { SocketService } from '../../../shared/services/socket.service';
import { StatisticService } from '../admin/tabs/insights/statistics-tab/statistic.service';
import { BotBasedComponent } from '../bot-based/bot-based.component';


@Component({
  templateUrl: './bot.component.html',
  standalone: true,
  styleUrls: ['./bot.component.scss'],
  imports: [CommonModule, BotChatDialogComponent, FormsModule, BotPromptComponent, BotDebugComponent, BsDropdownModule, ScoreboardComponent, DisconnectOverlayComponent],
  providers: []
})
export class BotComponent extends BotBasedComponent implements OnInit, OnDestroy {
  protected currentDebugState: "overlay" | "fullsize" | "none" = "none";
  protected customCssUrl!: SafeResourceUrl;
  protected customJsUrl!: SafeResourceUrl;
  protected debugMode: boolean = false;

  protected multiBotMode: boolean = false;
  protected getContrastColor = getContrastColor;
  private botConfigModalRef?: BsModalRef;

  constructor(
    public override loginService: LoginService,
    public override speechService: SpeechService,
    public override statisticsService: StatisticService,
    public override iframeCommunication: IframeCommunicationService,
    public override playService: PlayAudio,
    public override route: ActivatedRoute,
    public override toastr: ToastrService,
    public override bot: BotService,
    public override socketService: SocketService,
    public override modalService: BsModalService,
    public domSanatizer: DomSanitizer,
    protected override botMessages: BotMessagesService
  ) {
    super(route, loginService, speechService, statisticsService, iframeCommunication, playService, bot, socketService, toastr, modalService, botMessages);
    route.data.subscribe(data => {
      const botConfig = data['botConfig'] as ChatbotConfig | null;
      if (!botConfig) return;
      this.botConfig = botConfig;
      this.updateBrowserTitle();
      this.removePassThrough();
    });

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
      this.multiBotMode = params['multiBotMode'] === 'true';
    });

    this.subscriptions.push(
      this.socketService.onMessageDeletion.subscribe(data => {
        const delOpenAiMsgIds = data.messages.map((msg: HistoryItem) => msg.openAiMessageId);
        this.botMessages.deleteMessages(delOpenAiMsgIds);
      }),
      this.socketService.onCreateMessagesInThread.subscribe(() => {
        void this.bot.scrollDown();
      }),
      this.socketService.onAddUserMessage.subscribe(() => {
        void this.bot.scrollDown();
      })
    );
  }

  public override onRunUpdate(data: HistoryItem & { deltaStr: string; }) {
    super.onRunUpdate(data);
    void this.bot.scrollDown();
  }

  public override async ngOnInit() {
    if (this.debugMode) this.toggleDebugLog();

    this.speechService.textShouldBeSend.subscribe((send: boolean) => {
      if (send) {
        void this.add();
        this.promptInput = '';
        this.playService.stopAudio();
      }
    });

    this.playService.currentAudioStatus.subscribe((status: [string, string]) => {
      if (status[0] === 'idle') {
        this.promptInput = '';
      }
    });
    this.speechService.recognisedTextChanged.subscribe((text: string) => {
      if (this.isThinking || this.playService.anyAudioIsLoading() || this.playService.anyAudioIsPlaying()) return;
      this.promptInput = text;
    });

    this.iframeCommunication.runRequestEmitter.subscribe((prompt: string) => {
      void this.add(prompt, true);
    });

    this.iframeCommunication.resetSessionEmitter.subscribe();

    this.iframeCommunication.botMessageEmitter.subscribe((message: string) => {
      this.botMessages.addMessage({
        _id: "",
        openAiMessageId: "",
        context: {
          botid: this.botConfig._id,
          groupid: "",
          user: ""
        },
        created: new Date(), role: 'assistant',
        content: message,
        memoryEnabled: this.botConfig.memoryEnabled,
        openAiThreadId: ""
      });
      void this.bot.scrollDown();
    });

    this.iframeCommunication.userMessageEmitter.subscribe((message: string) => {
      this.promptInput = message;
      void this.add();
    });

    const userToken = this.loginService.getToken();
    if (userToken == null) {
      await this.loginService.loginAsGuest();
    }

    if (this.botConfig as ChatbotConfig | null) {
      this.injectJs(this.botConfig._id);
      this.customCssUrl = this.getCustomCssUrl(this.botConfig._id);
      if (this.botConfig.complianceText !== '' && localStorage.getItem('disclaimerDontShowAgain_' + this.botConfig._id) !== 'true') {
        const modalRef = this.modalService.show(DisclaimerModalComponent, {
          class: 'modal-lg',
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            text: this.botConfig.complianceText
          }
        });
        modalRef.onHide?.subscribe(() => {
          const dontShowAgain = modalRef.content?.dontShowAgain;
          if (dontShowAgain) {
            localStorage.setItem('disclaimerDontShowAgain_' + this.botConfig._id, 'true');
          }
        });
      }

      if (this.botConfig.voiceInputChatActive || this.botConfig.voiceOutputChatActive) {
        this.speechService.init();
      }

      await this.loginService.loadMyUser().then(() => {
        let localStorageKey = 'token';
        if (this.route.snapshot.queryParamMap.has('uniqueSession')) {
          localStorageKey = this.route.snapshot.queryParamMap.get('uniqueSession') ?? '';
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const token = getTokenContents(localStorageKey);
        this.socketService.connectToThread(this.botConfig._id, "" + localStorage.getItem(localStorageKey));
        if (this.botConfig.creator !== this.loginService.loggedInUser?._id
          && this.loginService.myOrgs.find(org => org.short == this.botConfig.orgShort) == null
        ) {
          this.debugMode = false;
        } else {
          void this.statisticsService.startDebug();
        }
      });
    }
  }

  @HostListener('window:click', ['$event'])
  public async autoMessage(event: Event) {
    if ((event.target as HTMLElement).className.includes('message-button')) {
      this.promptInput = (event.target as HTMLElement).innerText;
      await this.add();
    }
  }

  //nonPomtInput wenn über BotApi Prompt gesendet wird dann das Input feld in ruhe lassen
  public override async add(nonPromtInput: string | null = null, bypass: boolean = false) {
    this.botState = 'thinking';
    let pInput = nonPromtInput ?? this.promptInput;
    if (pInput === '') return;
    //? Check for stop words
    const foundStopWords = this.promptContainsStopWord(pInput, this.botConfig.stopWords);
    if (foundStopWords.length > 0) {
      this.toastr.error(ToastErrorTitle.SENT, "Unerwünschte Wörter gefunden: " + foundStopWords.join(", "));
      return;
    }
    //? Check for word replacements
    pInput = this.runWordReplacer(pInput);
    this.isThinking = true;
    this.speechService.botIsThinkking = true;
    if (nonPromtInput == null) {
      this.promptInput = "";
    }

    let body: {
      message: string,
      overrideConfig?: boolean,
      temperature?: number,
      model?: Model,
      bypass: boolean
    } = { message: pInput, bypass: bypass };
    if (this.multiBotMode) {
      body = {
        bypass: bypass,
        message: pInput,
        overrideConfig: true,
        temperature: this.botConfig.temperature,
        model: this.botConfig.model
      };
    }
    const threadAndRun: { runId: string, threadId: string } = await this.bot.membotUserChat(this.botConfig._id, body);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.RAGAI.lastRunId = threadAndRun.runId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    window.RAGAI.lastThreadId = threadAndRun.threadId;
    this.currentThreadRun = threadAndRun;

    void this.bot.scrollDown();
  }

  public getColorFromConfig(background: boolean = false): string {
    return this.bot.getColorFromConfig(this.botConfig, background);
  }

  private getCustomCssUrl(botId: string) {
    const url = window.location.origin + "/api/general/css/" + botId + "/custom.css";
    return this.domSanatizer.bypassSecurityTrustResourceUrl(url);
  }

  public openBotConfig() {
    const options: ModalOptions = {
      class: 'modal-lg',
      initialState: {
        botConfig: { ...this.botConfig }
      }
    };
    this.botConfigModalRef = this.modalService.show(BotConfigModalComponent, options);
    this.botConfigModalRef.onHide?.subscribe((reason: string) => {
      if (typeof reason !== 'string') {
        const content = (this.botConfigModalRef?.content as { botConfig: ChatbotConfig });
        this.botConfig.temperature = content.botConfig.temperature;
        this.botConfig.model = content.botConfig.model;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    if (this.currentDebugState == 'none') return;
    if (window.innerWidth < 660) {
      this.currentDebugState = 'overlay';
    } else {
      this.currentDebugState = 'fullsize';
    }
  }

  protected thinkingAnimaitionShouldBeShown(): boolean {
    const checkStates: string[] = [...blockedStatesArr];
    return checkStates.includes(this.botState);
  }

  public toggleDebugLog() {
    if (this.currentDebugState != 'none') {
      this.currentDebugState = 'none';
      return;
    }
    if (window.innerWidth < 660) {
      this.currentDebugState = 'overlay';
    } else {
      this.currentDebugState = 'fullsize';
    }
  }
}