import { Injectable } from '@angular/core';
import { removeFromArray } from '@reflact/tsutil';

@Injectable({
  providedIn: 'root'
})
export class InactiveBotServiceService {
  private registeredActions: InactiveAction[] = [];

  public get(seconds: number): InactiveAction {
    const action = new InactiveAction(seconds, this);
    this.registeredActions.push(action);
    return action;
  }
  public botWasActive(): void {
    this.registeredActions.forEach(action => {
      action.reset(true);
    });
  }
  public removeAction(action: InactiveAction): void {
    //console.log(`Destroy ${"" + action.seconds} Seconds Timer`);

    if (action.timeoutId) {
      clearTimeout(action.timeoutId);
    }
    removeFromArray(this.registeredActions, a => a === action);
  }
}

class InactiveAction {

  private _seconds: number = Number.MAX_VALUE;
  private _timeoutId: undefined | ReturnType<typeof setTimeout> = undefined;
  public executionCountTotal = 0;
  public executionCountSinceReset = 0;
  constructor(seconds: number, private service: InactiveBotServiceService) {
    this._seconds = seconds;
    this.restartTimer();
  }

  public get seconds(): number {
    return this._seconds;
  }
  public get timeoutId(): undefined | ReturnType<typeof setTimeout> {
    return this._timeoutId;
  }


  private restartTimer(resetExecutionTimer: boolean = false): void {
    //console.log(`Restart ${"" + this._seconds} Seconds Timer`);

    if (resetExecutionTimer) {
      this.executionCountSinceReset = 0;
    }

    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this._timeoutId = setTimeout(() => {
      try {
        //console.log(`Execute ${"" + this._seconds} Seconds Timer`);

        this.executionCountTotal++;
        this.executionCountSinceReset++;
        this.action(this.executionCountSinceReset, this.executionCountTotal);
        this.restartTimer();
      } catch (e) {
        console.warn(`Unable to execute timeout action for ${"" + this.seconds} seconds timer`, e);
      }
    }
      , this._seconds * 1000);
  }
  public action(countSinceReset: number, totalcount: number): void {
    console.log('InactiveAction no function defined', { countSinceReset, totalcount });
  }
  public reset(resetExecutionTimer: boolean = false): void {
    this.restartTimer(resetExecutionTimer);
  }
  public destroy(): void {
    this.service.removeAction(this);
  }


}
