import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GptVoiceArrWithRandom } from '@reflact/ai-types';
import { AudioBtnComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/audio-btn/audio-btn.component';
import { FormForBooleanComponent } from 'src/app/shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, FormForBooleanComponent, AudioBtnComponent],
  templateUrl: './voicebot-tab.component.html',
  styleUrl: './voicebot-tab.component.scss'
})
export class VoicebotTabComponent {
  public gptVoiceModels = GptVoiceArrWithRandom;
  //@ViewChild('voicebot') public voicebot!: ElementRef<HTMLIFrameElement>;

  constructor(public rss: RouteShareService) { }

  protected reloadIframe(): void {
    //this.voicebot.nativeElement.src = 'bot/' + this.rss.botConfig._id + '/audio';
  }
}
