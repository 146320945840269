<div
  class="row"
  [ngClass]="{'highlight': this.isHighlighted()}"
>
  <div
    class="col-1 cursor-pointer "
    style="width: 32px; height: 32px; border-radius: 5px; margin-top: -4px;"
    [ngStyle]="getActionStyle(logEntry.action)"
    id="symbol"
    (click)="this.toggleDetails(logEntry)"
  >
    <ng-container *ngTemplateOutlet="actionIcon; context: logEntry"></ng-container>
  </div>
  <div class="col-11">
    <div
      (click)="this.toggleDetails(logEntry)"
      class="cursor-pointer"
    >
      <i
        class="ri-lg"
        [ngClass]="{
                  'ri-arrow-down-s-line': this.selectedEntryiesForDetails.includes(logEntry.created),
                  'ri-arrow-right-s-line': !this.selectedEntryiesForDetails.includes(logEntry.created)
                }"
      ></i><span [innerHTML]="logEntry.title"></span><br class="d-md-none d-inline">
      <small class="text-muted float-none float-md-end">{{logEntry.created | date: 'dd/MM/YYYY HH:mm'}}</small>
    </div>
    <ng-container *ngIf="this.selectedEntryiesForDetails.includes(logEntry.created)">
      <div>{{logEntry.action}}</div>
      <small [innerHTML]="logEntry.reason"></small><br>
      <!--
          @if(logEntry.requestId){<b>Request ID: </b> {{logEntry.requestId}}<br>}
          -->
      <b>Dauer:</b> {{logEntry.executionDuration | msFormat}}<br>
      <ng-container *ngTemplateOutlet="explanationParser; context: {$implicit: logEntry}"></ng-container>
    </ng-container>
    <hr class="d-md-block d-none">
  </div>
</div>

<ng-template
  #actionIcon
  let-action="action"
>
  <ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'avatarbotNewSession'">
      <i class="ri-add-circle-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'chatcompletion'">
      <i class="ri-loader-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'heygen_streaming_avatar'">
      <i class="ri-account-circle-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'Membot call to chat/botid done'">
      <i class="ri-database-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'texttospeech'">
      <i class="ri-speak-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceCreatedThread'">
      <i class="ri-add-circle-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceUpdatedAssistent'">
      <i class="ri-user-settings-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotService'">
      <i class="ri-service-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceCreatedAssistent'">
      <i class="ri-user-star-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ToolServiceToolNotFound'">
      <i class="ri-user-unfollow-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'tool has Contents from url'">
      <i class="ri-file-text-line"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'tool Has outputs'">
      <i class="ri-download-cloud-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'toolrunerror'">
      <i class="ri-error-warning-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'tool runsMarkdown'">
      <i class="ri-markdown-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'tool runstart'">
      <i class="ri-play-circle-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'toolAddDatatrunkTool'">
      <i class="ri-tools-line"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'CustomTool triggered'">
      <i class="ri-exchange-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'CustomTool finish'">
      <i class="ri-swap-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'skillenabled'">
      <i class="ri-puzzle-2-fill"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'skilldisabled'">
      <i class="ri-puzzle-2-line"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'ToolsAvailable'">
      <i class="ri-tools-line"></i>
    </ng-container>


    <p *ngSwitchDefault>
      ICON NOT FOUND: {{action}}
    </p>
  </ng-container>
</ng-template>


<ng-template
  #explanationParser
  let-entry
>
  <ng-container [ngSwitch]="entry.action">
    <ng-container *ngSwitchCase="'avatarbotNewSession'">
      <b>Heygen-Response: </b>
      <ul>
        <li><b>Code: </b>{{entry.explanation.heygenResponse.code}}</li>
        <li><b>Message: </b>{{entry.explanation.heygenResponse.message}}</li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'chatcompletion'">
      <b>Model:</b> {{entry.model}}<br>
      <b>Token:</b>
      <ul>
        <li><b>Prompt Token:</b> {{entry.usage.prompt_tokens}}</li>
        <li><b>Completion Token:</b> {{entry.usage.completion_tokens}}</li>
        <li><b>Insgesamt:</b> {{entry.usage.total_tokens}}</li>
        <li></li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'heygen_streaming_avatar'">
      <b>Model:</b> {{entry.model}}<br>
      <b>Konvertierung:</b> {{entry.conversionMS | msFormat}}<br>
      <b>Response-Code:</b> {{entry.explanation.heygenResponse.code}}<br>
      <b>Response-Message:</b> {{entry.explanation.heygenResponse.message}}<br>
      <b>Text:</b> {{entry.explanation.text}}<br>
    </ng-container>
    <ng-container *ngSwitchCase="'Membot call to chat/botid done'">
      <!--
      <b>Run-ID:</b> {{entry.explanation?.runId}}<br>
      <b>Thread:</b> {{entry.explanation?.threadId}}
      -->
    </ng-container>
    <ng-container *ngSwitchCase="'texttospeech'">
      <b>Model:</b> {{entry.model}}<br>
      <b>Characters:</b> {{entry.characters}}<br>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceCreatedThread'">
      <!--
      <b>Thread: </b>{{entry.explanation.createdThread.id}}
      -->
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceUpdatedAssistent'">
      <b>Bot: </b>
      <ul>
        <li><b>Name:</b> {{entry.explanation.updater.name}}</li>
        <li><b>Temperature:</b> {{entry.explanation.updater.temperature}}</li>
        <li><b>Model:</b> {{entry.explanation.updater.model}}</li>
        <li *ngIf="entry.explanation.updater.instructions"><b>Instructions:</b>
          {{entry.explanation.updater.instructions}}</li>
        <li *ngIf="entry.explanation.updater.tools.lenth > 0"><b>Tools:</b> @for(tool of
          entry.explanation.updater.tools; track
          $index; let i = $index){
          {{tool.type}} @if(i <
            entry.explanation.updater.tools.length-1){,}
            }
            </li
          >
        <li><b>Assistant ID:</b> {{entry.explanation.assistant?.id}}</li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotService'">
      <b>Explanation: </b>{{entry.explanation}}
    </ng-container>
    <ng-container *ngSwitchCase="'ThreadbotServiceCreatedAssistent'">
      <b>Bot: </b>
      <ul>
        <li><b>Name:</b> {{entry.explanation.createParams.name}}</li>
        <li><b>Temperature:</b> {{entry.explanation.createParams.temperature}}</li>
        <li><b>Model:</b> {{entry.explanation.createParams.model}}</li>
        <li *ngIf="entry.explanation.createParams.instructions"><b>Instructions:</b>
          {{entry.explanation.createParams.instructions}}</li>
        <li *ngIf="entry.explanation.createParams.tools.lenth > 0"><b>Tools:</b> @for(tool of
          entry.explanation.updater.tools;
          track
          $index; let i = $index){
          {{tool.type}} @if(i <
            entry.explanation.createParams.tools.length-1){,}
            }
            </li
          >
            <!--
        <li><b>Assistant ID:</b> {{entry.explanation.assistant?.id}}</li>
        -->
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'ToolServiceToolNotFound'">
      <!--
      <b>Run-ID:</b> {{entry.explanation.run.id}}<br>
      -->
      <b>Bot: </b>
      <ul>
        <li><b>Name:</b> {{entry.explanation.botConfig.name}}</li>
        <li><b>Temperature:</b> {{entry.explanation.botConfig.temperature}}</li>
        <li><b>Model:</b> {{entry.explanation.botConfig.model}}</li>
      </ul>
    </ng-container>
    <ng-container *ngSwitchCase="'tool has Contents from url'">
      <b>URL:</b> {{entry.explanation.calledUrl}}<br>
      <b>Content:</b> {{entry.explanation.content}}
    </ng-container>
    <ng-container *ngSwitchCase="'tool Has outputs'">
      <b>Output:</b> <span [innerHTML]="entry.explanation"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'toolrunerror'">
      <b>Error:</b> {{entry.explanation}}
    </ng-container>
    <ng-container *ngSwitchCase="'tool runsMarkdown'">
      <b>URL:</b> {{entry.explanation.calledUrl}}<br>
      <b>Markdown:</b> {{entry.explanation.markdown}}
    </ng-container>
    <ng-container *ngSwitchCase="'tool runstart'">
      <b>Output:</b> {{entry.explanation}}
    </ng-container>
    <ng-container *ngSwitchCase="'toolAddDatatrunkTool'"> </ng-container>
    <ng-container *ngSwitchCase="'CustomTool triggered'">
      <b>Output:</b> {{entry.explanation}}
    </ng-container>
    <ng-container *ngSwitchCase="'CustomTool finish'">
      <b>Output:</b> {{entry.explanation}}
    </ng-container>
    <ng-container *ngSwitchCase="'skillenabled'">
      <b>Aktiviert:</b><span [innerHTML]="entry.explanation"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'skilldisabled'">
      <b>Deaktiviert:</b><span [innerHTML]="entry.explanation"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'ToolsAvailable'">

    </ng-container>
    <p *ngSwitchDefault>
      {{entry.action}} IS NOT IMPLEMENTED

    </p>
  </ng-container>
</ng-template>