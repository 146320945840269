<div class="input-group mb-3 input-group-ai-suggest">
  <ng-content #name></ng-content>
  <div class="input-group-append">
    <div
      class="btn btn-primary btn-sm mt-lg-1 btn-suggestion"
      (click)="this.suggestName()"
      i18n="@@ai-suggestion"
    >
      <i class="ri-sparkling-line"></i>
      KI-Vorschlag
    </div>
  </div>
</div>