<ng-template
  #listItem
  let-label="label"
  let-insert="insert"
  let-description="description"
>
  <li
    [containerClass]="'helpTooltip'"
    [tooltipHtml]="helpTooltip"
    [placement]="'end'"
  >
    <ng-template #helpTooltip>
      <div class="callout callout-info m-0">
        <code>{{insert}}</code>
      </div>
      <span [innerHtml]="description"></span>
    </ng-template>
    <button
      type="button"
      class="dropdown-item cursor-pointer"
      (click)="this.insertInEditor.emit(insert)"
    >
      <span class="fs-6">{{label}}</span>
    </button>
  </li>
</ng-template>
<div class="row">
  <!-- AKTION-->
  <div class="col-auto">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle p-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@add-action"
      >
        <i class="ri-aed-fill"></i> Aktion einfügen
      </button>
      <ul class="dropdown-menu">
        @for (key of ragAiFunctions.keys(); track $index) {
        <ng-container
          *ngTemplateOutlet="listItem; context {description: ragAiFunctions.get(key)?.description,  label:key, insert:  ragAiFunctions.get(key)?.codeTemplate+';\n'}"
        ></ng-container>
        }
      </ul>
    </div>
  </div>
  <!-- Variable-->
  <div class="col-auto">
    <div class="dropdown">
      <button
        [disabled]="!this.variables.length"
        class="btn dropdown-toggle p-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@add-variable"
      >
        <i class="ri-code-fill"></i> Variable einfügen
      </button>
      <ul class="dropdown-menu">
        @for (varName of this.variables; track $index) {
        <ng-container
          *ngTemplateOutlet="listItem; context {label:varName, insert:  'vars.'+varName+' '}"></ng-container>
        }
      </ul>
    </div>
  </div>
  <!-- skillID -->
  <div class="col-auto">
    <div class="dropdown">
      <button
        [disabled]="!this.skills.length"
        class="btn dropdown-toggle p-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@add-skill"
      >
        <i class="ri-puzzle-2-fill"></i> Skill-ID einfügen
      </button>
      <ul class="dropdown-menu">
        @for (skillName of this.skills; track $index) {
        <ng-container
          *ngTemplateOutlet="listItem; context {label:'\''+skillName+'\'', insert:  '\''+skillName+'\''}"></ng-container>
        }
      </ul>
    </div>
  </div>
  <!-- skillID überprüfen -->
  <div class="col-auto">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle p-1"
        type="button"
        [disabled]="!this.skills.length"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@check-skill"
      >
        <i class="ri-puzzle-2-fill"></i> Skill-ID überprüfen
      </button>
      <ul class="dropdown-menu">
        @for (skillName of this.skills; track $index) {
        <ng-container
          *ngTemplateOutlet="listItem; context {description:'Prüft ob <b>'+skillName+'</b> aktiv ist' ,label:'wenn: '+skillName+' is aktiv', insert: 'if(skill.'+skillName+'){\n}'}"
        ></ng-container>
        }
      </ul>
    </div>
  </div>
  <!-- skillID Ausführungsanzahl -->
  <div class="col-auto">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle p-1"
        type="button"
        [disabled]="!this.skills.length"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@check-skill-count"
      >
        <i class="ri-puzzle-2-fill"></i> Skill-ID Ausführungsanzahl
      </button>
      <ul class="dropdown-menu">
        @for (skillName of this.skills; track $index) {
        <ng-container
          *ngTemplateOutlet="listItem; context {description:'Anzahl Ausführungen von <b>'+skillName+'</b>' ,label:'Ausführungsanzahl: '+skillName+'', insert: 'skill.'+skillName+'_count\n'}"
        ></ng-container>
        }
      </ul>
    </div>
  </div>
  <!-- Variable-->
  <div class="col-auto">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle p-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        i18n="@@add-global-variable"
      >
        <i class="ri-code-fill"></i> Globale Variablen
      </button>
      <ul class="dropdown-menu">
        <ng-container *ngTemplateOutlet="listItem; context {label:'botName', insert: 'conf.BotName '  }"></ng-container>
        <ng-container
          *ngTemplateOutlet="listItem; context {label:'botColor', insert:  'conf.botColor '}"></ng-container>
      </ul>
    </div>
  </div>
</div>