import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@coreui/angular';
import { LoginService } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  selector: 'app-chunk-contingent-bot',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule
  ],
  templateUrl: './chunk-contingent-bot.component.html',
  styleUrl: './chunk-contingent-bot.component.scss'
})
export class ChunkContingentBotComponent {
  @Input({ required: true }) public chunkCount!: number;

  constructor(
    public loginService: LoginService,
    public rss: RouteShareService

  ) { }
}
