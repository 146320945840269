import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  selector: 'app-chunk-contigent-individual',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './chunk-contigent-individual.component.html',
  styleUrl: './chunk-contigent-individual.component.scss'
})
export class ChunkContigentIndividualComponent {
  @Input({ required: true }) public chunkCount!: number;
  constructor(
    public rss: RouteShareService
  ) { }
}
