import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import StreamingAvatar from '@heygen/streaming-avatar';
import { firstValueFrom } from 'rxjs';
import { LoginService } from '../../../shared/services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarFrameService {


  constructor(private http: HttpClient, public loginService: LoginService) { }

  public async getAvatar(botId: string): Promise<StreamingAvatar> {
    console.log("loggedinuser", this.loginService.loggedInUser);
    await this.loginService.loadMyUser();

    const result = await firstValueFrom(this.http.get<{ data: { token: string }, error: "" }>(`/api/toolbox/heygen/token/${botId}`));
    const token = result.data.token;
    const avatar = new StreamingAvatar({
      token
    });
    console.log("avatar", avatar);
    return avatar;
  }



}

