import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { LoginService, logout } from "../services/login.service";

@Injectable()
export class PortalLoginResolver {
  constructor(public loginService: LoginService, private router: Router) { }

  public async resolve(route: ActivatedRouteSnapshot) {
    const token = route.queryParamMap.get('token') ?? '';
    const deeplink = route.paramMap.get('deeplink');
    const allowed = await this.loginService.loginFromPortal(token);
    if (!allowed) {
      logout();
      return;
    }
    if (deeplink) {
      await this.router.navigate([deeplink]);
    } else {
      await this.router.navigate(['/']);
    }
  }

}