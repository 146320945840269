<ng-template #myTT>
  <div class="text-center">
    @if(this.browserHasWebkitSpeechRecognition){
    Zum Aufnehmen klicken<br>oder gedrückt halten
    }
    @if(!this.browserHasWebkitSpeechRecognition){
    Ihr Browser unterstützt keine Spracherkennung
    }



  </div>
</ng-template>


<button
  [containerClass]="(this.botState ==='idle' &&   !this.isRecording ) ? 'tooltipStylePushToTalk' : 'd-none'"
  [tooltipHtml]="myTT"
  class="record-button"
  [ngClass]="{

          'ri-spinner': (this.botState === 'thinking' || this.botState === 'requesting_tools'),
          'bg-danger text-white': this.isRecording,
          'bg-white text-danger': !this.isRecording,
          'record-by-hold': this.isRecordingByHold
          }"
  title="Audioaufnahme starten"
  (mousedown)="this.onMouseDown()"
  (mouseup)="this.onMouseUp()"
  (mouseleave)="this.onMouseUp()"
>

  <i
    class="ri-mic-2-fill"
    *ngIf="this.botState === 'idle'"
  ></i>
  <i
    class="ri-loader-4-line ri-spinner larger"
    *ngIf="this.botState === 'thinking' || this.botState === 'requesting_tools'"
  ></i>
</button>