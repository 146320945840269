/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalStat } from '@reflact/ai-types';
import { NgxLayoutModule, OverlayAsideComponent } from '@reflact/ngx-layout';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MSFormatPipe } from 'src/app/shared/pipes/msFormat.pipe';
import { DebugEntryComponent } from "./debug-entry/debug-entry.component";

@Component({
  selector: 'app-bot-debug',
  standalone: true,
  imports: [CommonModule, NgxLayoutModule, TooltipModule, MSFormatPipe, DebugEntryComponent],
  templateUrl: './bot-debug.component.html',
  styleUrl: './bot-debug.component.scss'
})

export class BotDebugComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) public logEntries!: GlobalStat[];
  @Input({ required: true }) public type!: "overlay" | "fullsize";
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('overlay') public overlay?: OverlayAsideComponent;

  public selectedEntryiesForDetails: Date[] = [];
  public initialTimestamp = new Date().getTime();
  public showOlderLogEntries: boolean = false;
  public isResizing = false;
  public lastOffset = 0;

  public ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.['type']) {
      setTimeout(() => {
        this.overlay?.setSelected({});
        this.overlay?.opened$.subscribe((opened) => {
          if (!opened) {
            this.closed.emit();
          }
        });
      }, 200);
    }
  }

  public ngAfterViewInit(): void {

    const divider = document.getElementById('divider')!;
    const content = document.getElementById('content')!;
    divider.addEventListener('mousedown', (e) => {
      this.isResizing = true;
      this.lastOffset = window.innerWidth - e.clientX - content.clientWidth;
    });
    document.addEventListener('mousemove', (e) => {
      if (!this.isResizing) return;
      const minWidth = 25;
      const maxWidth = 50;

      const width = (window.innerWidth - e.clientX - this.lastOffset);
      const percent = width / (window.innerWidth / 100);
      content.style.width = Math.max(minWidth, Math.min(percent, maxWidth)) + 'vw';
    });
    document.addEventListener('mouseup', () => {
      this.isResizing = false;
    });
  }



  public get hasHiddenEntries(): boolean {
    return this.oldEntries.length > 0;
  }

  public get oldEntries(): GlobalStat[] {
    return this.logEntries.filter(c => c.created.getTime() < this.initialTimestamp);
  }

  public get newEntries(): GlobalStat[] {
    return this.logEntries.filter(c => c.created.getTime() >= this.initialTimestamp);
  }





}
