import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatisticService } from '../statistic.service';


export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type MonthOfYear = {
  year: number;
  month: Month
};


@Component({
  selector: 'app-statistics-month-selector',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './statistics-month-selector.component.html',
  styleUrl: './statistics-month-selector.component.scss'
})
export class StatisticsMonthSelectorComponent {

  @Input() public minMonth?: MonthOfYear;
  @Input() public maxMonth?: MonthOfYear;
  @Input({ required: true }) public value!: MonthOfYear;
  @Output() public valueChange: EventEmitter<MonthOfYear> = new EventEmitter<MonthOfYear>();


  constructor(private statService: StatisticService) { }

  public get isMinMonth(): boolean {
    if (this.minMonth == undefined) return true;
    return this.value.year === this.minMonth.year && this.value.month === this.minMonth.month;
  }

  public get isMaxMonth(): boolean {
    if (this.maxMonth == undefined) return true;
    return this.value.year === this.maxMonth.year && this.value.month === this.maxMonth.month;
  }

  public addMonth() {
    if (this.isMaxMonth) return;
    if (this.value.month === 12) {
      this.value = { year: this.value.year + 1, month: 1 };
    } else {
      this.value = { year: this.value.year, month: (this.value.month + 1) as Month };
    }
    this.valueChange.emit(this.value);
  }

  public substractMonth() {
    if (this.isMinMonth) return;
    if (this.value.month === 1) {
      this.value = { year: this.value.year - 1, month: 12 };
    } else {
      this.value = { year: this.value.year, month: (this.value.month - 1) as Month };
    }
    this.valueChange.emit(this.value);
  }

  public getSelectedMonthString(): string {
    return `${this.statService.getMonth(this.value.month)} ${this.value.year.toString()}`;
  }
}
