import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BotLoginService {

  constructor(private http: HttpClient) { }

  public async loginBot(botId: string, pw: string) {
    return await firstValueFrom(this.http.post<{ status: string, message?: string }>("/api/toolbox/bot/user/checkbotpw/" + botId, { pw }));
  }
}
