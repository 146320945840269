import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { languages } from '@codemirror/language-data';
import { EditorView } from "@codemirror/view";
import { GptChatModelTypeArr, GptVoiceArrWithRandom } from '@reflact/ai-types';
import { CodeEditorCssComponent } from 'src/app/shared/components/ai-bot/utils/code-editor-css/code-editor-css.component';
import { CodeEditorDannComponent } from 'src/app/shared/components/ai-bot/utils/code-editor-dann/code-editor-dann.component';
import { RouteShareService } from 'src/app/shared/services/route-share.service';


@Component({
  templateUrl: './configurations-tab.component.html',
  styleUrls: ['./configurations-tab.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgxSliderModule, CodeEditorCssComponent, CodeEditorDannComponent]
})
export class ConfigurationsTabComponent implements OnInit {
  public iconList: string[] = ["ri-brain-line", "ri-robot-line", "ri-robot-2-line", "ri-live-line", "ri-mic-line", "ri-home-line", "ri-building-line", "ri-global-line", "ri-shake-hands-line", "ri-chat-1-line", "ri-database-2-line", "ri-file-line", "ri-user-line"];
  public colorList: string[] = ["#a5bfbe", "#f1e5cd", "#ecc290", "#d4beb1", "#bec1c6"];
  public gptModels = GptChatModelTypeArr;
  public gptVoiceModels = GptVoiceArrWithRandom;
  protected languages = languages;
  public customCssOpen: boolean = false;
  public customJsOpen: boolean = false;
  public wordReplacementOpen: boolean = false;
  public extensions = [];
  public rows: number = 5;
  constructor(public rss: RouteShareService) { }

  public ngOnInit(): void {
    //Erste Codezeile ist immer 30,39px hoch (Zeile -> 22,3906px + Space -> 8px)
    //Jede weitere Zeile fügt 22,3906px hinzu
    const heightToRows = 8 + this.rows * 22.3906;
    //@ts-ignore
    this.extensions = [EditorView.theme({
      ".cm-content": {
        minHeight: heightToRows + "px"
      },
      ".cm-gutter": {
        minHeight: heightToRows + "px"
      }
    })];
  }
}
