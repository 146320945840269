import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { ActiveToast, IndividualConfig, ToastrService as NgxToastrService } from 'ngx-toastr';

export enum ToastSuccessTitle {
  CREATED = "Erfolgreich erstellet",
  DELETED = "Erfolgreich gelöscht",
  HISTORY_DELETED = "History erfolgreich gelöscht",
  SAVED = "Erfolgreich gespeichert",
  IMPORTED = "Erfolgreich importiert",
  LINK_GENERATED = "Link erfolgreich generiert",
  GENERAL = "Erfolgreich",
};

export enum ToastErrorTitle {
  CREATED = "Fehler beim Erstellen",
  SAVED = "Fehler beim Speichern",
  LOADED = "Fehler beim Laden",
  DELETED = "Fehler beim Löschen",
  SENT = "Fehler beim Senden",
  OPENAI_ERROR = "Fehler beim ausführen der Anfrage",
  IMPORTED = "Fehler beim Importieren",
  LINK_GENERATED = "Fehler beim generieren des Links",
  ERROR_404 = "Es ist ein Fehler aufgetreten (404)",
  ERROR_403 = "Es ist ein Fehler aufgetreten (403)",
  ERROR_401 = "Es ist ein Fehler aufgetreten (401)",
  ERROR_400 = "Es ist ein Fehler aufgetreten (400)",
  ERROR_500 = "Es ist ein Fehler aufgetreten (500)",
  ERROR = "Es ist ein Fehler aufgetreten",
  GENERAL = "Fehler",
};

export enum ToastInfoTitle {
  CHAT_DELETING = "Chatverlauf wird gelöscht...",
  REM_FEEDBACK = "Feedback wurde entfernt",
  ADD_FEEDBACK = "Danke für dein Feedback",
  LINK_COPIED = "Link in Zwischenablage kopiert",
  TEXT_COPIED = "Text in Zwischenablage kopiert",
  SKILLID_COPIED = "SkillId in Zwischenablage kopiert",
  SAVED = "Erfolgreich gespeichert",
  BOT_UPDATED = "Bot wurde aktualisiert",
};

export enum ToastWarningTitle {
  // Den eintrag anpassen, sobald wir einen anwendungsfall für warning haben
  a = ""
};

export type ToastTitle = ToastSuccessTitle | ToastErrorTitle | ToastInfoTitle | ToastWarningTitle;

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(public baseToastr: NgxToastrService) { }

  /** show toast */
  public show<ConfigPayload = unknown>(title: ToastTitle, message?: string, override?: Partial<IndividualConfig<ConfigPayload>>, type?: string): ActiveToast<unknown> {
    return this.baseToastr.show(message, title, override, type);
  };

  /** show successful toast */
  public success<ConfigPayload = unknown>(title: ToastSuccessTitle, message?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<unknown> {
    return this.baseToastr.success(message, title, override);
  };

  /** show error toast */
  public error<ConfigPayload = unknown>(title: ToastErrorTitle, message?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<unknown> {
    return this.baseToastr.error(message, title, override);
  };

  /** show info toast */
  public info<ConfigPayload = unknown>(title: ToastInfoTitle, message?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<unknown> {
    return this.baseToastr.info(message, title, override);
  };

  /** show warning toast */
  public warning<ConfigPayload = unknown>(title: ToastWarningTitle, message?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<unknown> {
    return this.baseToastr.warning(message, title, override);
  };

  /**
   * Remove all or a single toast by id
   */
  public clear(toastId?: number): void {
    this.baseToastr.clear(toastId);
  };

  /**
   * Remove and destroy a single toast by id
   */
  public remove(toastId: number): boolean {
    return this.baseToastr.remove(toastId);
  };




}
