<h3 i18n="@@bot-task">Bot-Aufgabe</h3>
<div
  class="callout callout-info"
  i18n="@@bot-task-callout"
>Mit der Bot-Aufgabe konfigurieren Sie die Identität des Bots. Hier legen
  Sie fest, welche Rolle der Bot
  einnimmt und welche spezifischen Aufgaben er übernehmen soll.</div>
<div class="card border-0">
  <div class="card-body">
    <div class="row  align-items-center">
      <div class="col-3">
        <span i18n="@@bot-public">Bot öffentlich</span>
        <app-hint
          *ngIf="this.rss.botConfig.isPublic"
          i18n="@@bot-public-on-hint"
        ><b>Jeder</b> mit dem Link kann den Bot nutzen
        </app-hint>
        <app-hint
          *ngIf="!this.rss.botConfig.isPublic"
          i18n="@@bot-public-off-hint"
        >Nur <b>angemeldete Benutzer</b> können den Bot nutzen</app-hint>
      </div>
      <div class="col">
        <div class="btn-group btn-group-sm">
          <div
            class="btn btn-primary"
            [ngClass]="{
                      'btn-light':this.rss.botConfig.isPublic,
                      'btn-primary':!this.rss.botConfig.isPublic
                    }"
            (click)="this.rss.botConfig.isPublic = false; this.rss.botConfig.isPwProtected = false; this.rss.botConfig.botPassword = ''; this.rss.saveBotConfig()"
            i18n="@@off"
          >Aus</div>
          <div
            class="btn btn-primary"
            [ngClass]="{
                      'btn-light':!this.rss.botConfig.isPublic,
                      'btn-primary':this.rss.botConfig.isPublic
                    }"
            (click)="this.rss.botConfig.isPublic = true; this.rss.saveBotConfig()"
            i18n="@@on"
          >An</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="this.rss.botConfig.isPublic">
      <div class="row align-items-center mt-3">
        <div class="col-3">
          <span i18n="@@protect-with-password">Bot mit Passwort schützen</span>
          <app-hint
            *ngIf="this.rss.botConfig.isPwProtected"
            i18n="@@protect-with-password-on-hint"
          >
            Es liegt kein Passwortschutz vor. <b>Jeder</b> mit dem Link kann den Bot nutzen.
          </app-hint>
          <app-hint
            *ngIf="!this.rss.botConfig.isPwProtected"
            i18n="@@protect-with-password-off-hint"
          >
            Nur <b>angemeldete Benutzer und Nutzer mit Passwort</b> können den Bot nutzen
          </app-hint>
        </div>
        <div class="col-auto">
          <div class="btn-group btn-group-sm">
            <div
              class="btn btn-primary"
              [ngClass]="{
                'btn-light':this.rss.botConfig.isPwProtected,
                'btn-primary':!this.rss.botConfig.isPwProtected
              }"
              (click)="this.rss.botConfig.isPwProtected = false; this.rss.botConfig.botPassword = ''; this.rss.saveBotConfig()"
              i18n="@@off"
            >Aus</div>
            <div
              class="btn btn-primary"
              [ngClass]="{
                'btn-light':!this.rss.botConfig.isPwProtected,
                'btn-primary':this.rss.botConfig.isPwProtected
              }"
              (click)="this.rss.botConfig.isPwProtected = true; this.enableBotPw()"
              i18n="@@on"
            >An</div>
          </div>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control form-control-sm"
            [ngClass]="{ 'invisible': !this.rss.botConfig.isPwProtected }"
            [(ngModel)]="this.rss.botConfig.botPassword"
            [ngModelOptions]="{updateOn: 'blur'}"
            (ngModelChange)="this.saveCustomPw()"
          >
        </div>
      </div>
    </ng-container>

    <div
      class="row align-items-center mt-3"
      style="margin-bottom: 10px;"
    >
      <div class="col-3">
        <span i18n="@@edit-in-org">Bearbeitbar durch</span>
      </div>
      <div class="col-3">
        <select
          class="form-select "
          [ngClass]="{'lock-insensitive': this.rss.botConfig.creator === this.loginService.loggedInUser?._id}"
          [(ngModel)]="this.rss.botConfig.editMode"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.rss.saveEditModeToBotConfig()"
        >
          <option
            value="none"
            i18n="@@no-editing"
          >Niemand</option>
          <option
            value="creator"
            i18n="@@only-creator"
          >Ersteller</option>
          <option
            value="everyone"
            i18n="@@everyone"
          >Jeder in Organistation</option>
        </select>
      </div>
    </div>

    <div class="row align-items-center mt3">
      <div class="col-3">
        <span i18n="@@share-in-org">Mit Organistation Teilen</span>
      </div>

      <div class="col-3">
        <select
          class="form-select"
          [(ngModel)]="this.rss.botConfig.orgShort"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.rss.saveBotConfig()"
        >
          <option
            value=""
            i18n="@@no-sharing"
          >Nicht geteilt</option>
          <option
            *ngFor="let model of this.loginService.myOrgs"
            [value]="model.short"
          >{{ model.name }}</option>
        </select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <span i18n="@@bot-instructions">Bot-Instruktionen</span>
        <app-hint i18n="@@bot-instructions-hint">
          Hier können Sie <b>Instruktionen</b> zur Funktion/Aufgabe des Bots verfassen.
        </app-hint>
      </div>
      <div class="col">
        <div class="position-relative">
          <app-code-editor-ai
            [placeholder]="'Du bist ein Bot, der ...'"
            [instruction]="this.rss.botConfig.botInstruction"
            [rows]="13"
            (finish)="this.rss.botConfig.botInstruction = $event; this.rss.saveBotConfig()"
          ></app-code-editor-ai>
          <small i18n="@@memory-function-hint">
            Bitte beachten Sie, dass Ihr Bot möglicherweise aufgrund von Vorwissen voreingenommene Ergebnisse liefern
            könnte. Um dem entgegenzuwirken, starten Sie bitte den privaten Modus in Ihrem Browser.
          </small>
          <div
            class="position-absolute top-0 end-0"
            style="margin-right: 4em; margin-top: 1em;"
          >
            <span
              class="position-absolute top-0 start-0 translate-middle badge text-bg-light cursor-pointer"
              (click)="this.openICOWizard()"
            >
              <span i18n="@@prompt-wiz">Prompt-Wiz</span><app-hint i18n="@@prompt-wiz-hint">
                Verwenden Sie unseren Prompt-Wiz um Ihre Bot-Instruktionen zu konkretisieren.
              </app-hint>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="col-3">
        <span i18n="@@greeting-mode">Modus der Begrüßung</span>
      </div>
      <div class="col">
        <div class="btn-group btn-group-sm">
          <div
            class="btn"
            [ngClass]="{
              'btn-light': this.rss.botConfig.welcomeType !== 'None',
              'btn-primary':this.rss.botConfig.welcomeType === 'None'
            }"
            (click)="this.rss.botConfig.welcomeType = 'None'; this.rss.saveBotConfig()"
            i18n="@@off"
          >Aus</div>
          <div
            class="btn"
            [ngClass]="{
              'btn-light': this.rss.botConfig.welcomeType !== 'Bot',
              'btn-primary':this.rss.botConfig.welcomeType === 'Bot'
            }"
            (click)="this.rss.botConfig.welcomeType = 'Bot'; this.rss.saveBotConfig()"
            i18n="@@bot-instruction"
          >Bot-Instruktion</div>
          <div
            class="btn"
            [ngClass]="{
              'btn-light': this.rss.botConfig.welcomeType !== 'Static',
              'btn-primary':this.rss.botConfig.welcomeType === 'Static'
            }"
            (click)="this.rss.botConfig.welcomeType = 'Static'; this.rss.saveBotConfig()"
            i18n="@@static-text"
          >Statischer Text</div>
        </div>
      </div>
    </div>
    <div
      class="row mt-2"
      *ngIf="this.rss.botConfig.welcomeType === 'Static'"
    >
      <div class="col-3">
        <span i18n="@@greeting">Begrüßung</span>
        <app-hint i18n="@@greeting-hint">
          Es wird zu Beginn die unten eingetragene <b>statische</b> Begrüßungsnachricht angezeigt.
        </app-hint>
        <app-hint
          [customIcon]="'ri-alert-fill text-danger'"
          *ngIf="this.rss.botConfig.welcomeMessageStatic === ''"
          i18n="@@ai-greeting-warning"
        >
          Das Textfeld darf nicht leer sein. Aktuell wird dadurch keine Begrüßungsnachricht generiert.
        </app-hint>
      </div>
      <div class="col">
        <textarea
          class="form-control"
          rows="3"
          [(ngModel)]="this.rss.botConfig.welcomeMessageStatic"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.rss.saveBotConfig()"
        ></textarea>
      </div>
    </div>
    <div
      class="row mt-2"
      *ngIf="this.rss.botConfig.welcomeType === 'Bot'"
    >
      <div class="col-3">
        <span i18n="@@ai-greeting">KI-Begrüßung</span>
        <app-hint i18n="@@ai-greeting-hint">
          Der Bot <b>generiert</b> anhand der unten eingetragenen Formulierung eine Begrüßungsnachricht.
        </app-hint>
        <app-hint
          [customIcon]="'ri-alert-fill text-danger'"
          *ngIf="this.rss.botConfig.welcomeMessageBot === ''"
          i18n="@@ai-greeting-warning"
        >
          Das Textfeld darf nicht leer sein. Aktuell wird dadurch keine Begrüßungsnachricht generiert.
        </app-hint>
      </div>
      <div class="col">
        <app-code-editor-ai
          [instruction]="this.rss.botConfig.welcomeMessageBot"
          (finish)="this.rss.botConfig.welcomeMessageBot = $event; this.rss.saveBotConfig()"
        ></app-code-editor-ai>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <h5>Verhalten beim Wiedereinstieg:</h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <span i18n="@@clear-chat-description">Chatbot immer von Anfang starten</span>
        <app-hint i18n="@@clear-chat-hint">
          Wenn ein Nutzer alle geöffneten Browserfenster zu einem Bot schließt, gilt der Chat nach wenigen Sekunden als
          beendet. Beim nächsten Öffnen des Bots startet der Chat ohne vorherigen Verlauf.
        </app-hint>
      </div>
      <div class="col">
        <div class="btn-group btn-group-sm">
          <div
            class="btn btn-primary"
            [ngClass]="{
             'btn-light':this.rss.botConfig.createEmptyThreadOnVisit,
             'btn-primary':!this.rss.botConfig.createEmptyThreadOnVisit
            }"
            (click)="this.rss.botConfig.createEmptyThreadOnVisit = false ; this.rss.saveBotConfig()"
            i18n="@@off"
          >Aus</div>
          <div
            class="btn btn-primary"
            [ngClass]="{
             'btn-light':!this.rss.botConfig.createEmptyThreadOnVisit,
             'btn-primary':this.rss.botConfig.createEmptyThreadOnVisit
            }"
            (click)="this.rss.botConfig.createEmptyThreadOnVisit = true; this.rss.saveBotConfig()"
            i18n="@@on"
          >An</div>
        </div>
      </div>
    </div>
    <div
      class="row mt-2"
      *ngIf="this.rss.botConfig.createEmptyThreadOnVisit"
    >
      <div class="col-3">
        <span i18n="@@clear-chat-time">Verzögerung</span>
      </div>
      <div class="col">
        <div class="row ">
          <div class="col-auto">
            <input
              type="number"
              class="form-control form-control-sm text-end"
              style="width: 70px;"
              min="1"
              [(ngModel)]="this.rss.botConfig.secondsToWaitForThreadDeleting"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="this.saveAutoDeleteTime()"
            >
          </div>

        </div>

      </div>
    </div>
  </div>
</div>