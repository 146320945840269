import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  standalone: true,
  imports: [CommonModule, TooltipModule]
})
export class HintComponent {
  @Input() public customIcon: string = 'ri-information-2-fill';
  @Input() public customColor: string = '#D09A3C';
}
