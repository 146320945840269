import { Injectable, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { waitSeconds } from '@reflact/tsutil';


//autoredirectseconds = -1 means no auto redirect
export type EndBotOptions = {
  autoRedirectSeconds: number,
  redirectUrl?: string,
  redirectTitle: string,
  title: string,
  message: string,
  restartUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class EndbotService {

  public endOpts: EndBotOptions;
  public remainingSecondsBeforeRedirect: WritableSignal<number> = signal(-1);

  constructor(private router: Router, private aRoute: ActivatedRoute) {
    this.endOpts = {
      title: 'Der Bot ist beendet',
      message: '',
      autoRedirectSeconds: -1,
      redirectTitle: 'reflact'
    };
    void this.startWaitingForRedirect();
  }

  public isAutoRedirectActive() {
    return this.endOpts.redirectUrl != null && this.endOpts.autoRedirectSeconds != -1;
  }

  public redirect() {
    this.remainingSecondsBeforeRedirect.set(-1);
    if (this.endOpts.redirectUrl) {
      window.location.href = this.endOpts.redirectUrl;
    }
  }

  public restart() {
    if (this.endOpts.restartUrl != null) {
      window.location.href = this.endOpts.restartUrl;
    }
  }

  public endBot(botId: string, opts: EndBotOptions) {
    void this.router.navigate(['bot', botId, 'end'], { queryParams: opts });
  }

  public endPageComponentLoaded(opts: EndBotOptions) {
    this.endOpts = opts;
    if (this.isAutoRedirectActive()) {
      this.remainingSecondsBeforeRedirect.set(this.endOpts.autoRedirectSeconds);
    }
  }


  public async startWaitingForRedirect() {
    await waitSeconds(1);
    if (this.remainingSecondsBeforeRedirect() === 0) {
      this.redirect();
    }
    if (this.remainingSecondsBeforeRedirect() > 0) {
      this.remainingSecondsBeforeRedirect.update(s => s - 1);
    }
    void this.startWaitingForRedirect();
  }
}