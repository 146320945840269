import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatbotConfig } from '@reflact/ai-types';

@Component({
  selector: 'app-bot-word-stop',
  standalone: true,
  imports: [
    CommonModule, FormsModule, NgSelectModule
  ],
  templateUrl: './bot-word-stop.component.html',
  styleUrl: './bot-word-stop.component.scss'
})
export class BotWordStopComponent {

  @Input({ required: true }) public botConfig!: ChatbotConfig;
  @Output() public botConfigChange: EventEmitter<ChatbotConfig> = new EventEmitter<ChatbotConfig>();

  public saveStopWords(): void {
    this.botConfig.stopWords = this.botConfig.stopWords.map((s: string) => {
      //TODO: Fix this in HTML ng-select bind label & value
      //@ts-expect-error Passiert durch das NG-Select
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      if (s.label) s = s.label;
      return s.trim();
    }).filter(s => s.length > 0).filter((value, index, self) => self.indexOf(value) === index);
    this.botConfigChange.emit(this.botConfig);
  }
}
