import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { RouteShareService } from '../../services/route-share.service';
import { WebscrapeService } from '../../services/webscrape.service';

export const webscrapeResolver: ResolveFn<boolean> = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const webscrapeSerivce: WebscrapeService = inject(WebscrapeService);
  const rss: RouteShareService = inject(RouteShareService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }

  const id = route.parent?.params['id'] as string;

  rss.webscrapes = (await webscrapeSerivce.getWebscrapesForBot(id)).webscrapes;
  const webscrapeid = route.firstChild?.params['webscrapeid'] as string;
  if (webscrapeid) {
    rss.webscrape = rss.webscrapes.find(scrape => scrape._id === webscrapeid);
    for (const keyURL of rss.webscrape!.distinctPages) {
      rss.webscrapeMap.set(keyURL, rss.webscrape!.pages.filter(page => keyURL == page.url));
    }
  } else {
    rss.webscrape = undefined;
    rss.webscrapeMap.clear();
  }
  return true;
};
