<ng-template #htmlTT>
  <table>
    <tr>
      <td i18n="@@admin-user">Admin Nutzer</td>
      <td>{{socketAdminService.connectedAdminsCount()}}</td>
    </tr>
    <tr>
      <td i18n="@@admin-connection">Admin Verbindungen</td>
      <td>{{socketAdminService.connectedAdminSockets()}}</td>
    </tr>
    <tr>
      <td i18n="@@user-connection">Nutzer Verbindungen</td>
      <td>{{ socketAdminService.connectedUserSockets()}}</td>
    </tr>
  </table>
</ng-template>

<ng-template
  #menuEntry
  let-item="item"
>
  <div style="cursor: pointer;">
    <span
      class="nav-link"
      [ngClass]="{'active': this.currTab === item.href}"
      (click)="this.searchString = item.href; this.currTab = item.href"
    >
      <span class="ms-3 fw-bold">
        <i
          *ngIf="item.icon"
          [ngClass]="'mx-2 ri-xl ' + item.icon"
          class="fw-normal"
        ></i>
        {{item.name}}
      </span>
    </span>
  </div>
</ng-template>

<ng-template #logo>
  <div class="align-items-center my-5 w-100 text-center">
    <img
      [tooltip]="htmlTT"
      src="assets/AI_Logo.svg"
      width="32"
      alt="logo"
    >
    <span
      style="color:#a5848f"
      class="fw-bold fs-6 mx-3"
      i18n="@@reflact-ki-toolbox"
    >reflact KI Toolbox</span>
  </div>
</ng-template>

<rag-body-layout
  [navItems]="navItems"
  [sidebarHeaderTpl]="logo"
  [logoutFunction]="this.logout"
>
  <div class="mt-4">
    <div class="row mb-4 gx-3 align-items-center">
      <div class="col-auto">
        <div
          class="btn-group"
          role="group"
        >
          <button
            type="
            button"
            class="btn btn-outline-primary"
            (click)="addBot()"
            i18n="@@btn-add-bot"
          >
            Neuer Bot <i class="ri-add-line ri-xl"></i>
          </button>

          <button
            appDnd
            type="button"
            class="btn btn-outline-primary"
            (fileDropped)="this.onImportFileDropped($event)"
            (click)="importFileInput.click()"
          >
            <ng-template #supportedFiles>
              <b i18n="@@supported-formats-title">Unterstützte Dateiformate:</b>
              <span *ngFor="let type of this.allowedImportTypes; last as isLast">
                <ng-container *ngIf="isLast">{{ type.prefix }}</ng-container>
                <ng-container *ngIf="!isLast">{{ type.prefix }}, </ng-container>
              </span>
            </ng-template>
            <i class="ri-file-upload-line ri-xl"></i>
            <input
              type="file"
              class="file-input"
              #importFileInput
              (change)="this.onImportFileSelected($event)"
            />
          </button>
        </div>
      </div>
      <div class="col">
        <app-bot-admin-query-search
          [items]="this.bots"
          [sortMode]="this.sortMode"
          [(search)]="this.searchString"
          (searchResultChange)="this.listedBots = $event"
        ></app-bot-admin-query-search>
      </div>
      <div class="col-auto">
        <div class="dropdown">
          <button
            class="btn btn-outline-primary rounded-2"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            @switch (this.sortMode) {
            @case ("asc-alphabet") {<i class="ri-sort-alphabet-asc"></i>}
            @case ("desc-updated") {<i class="ri-sort-desc"></i>}
            @case ("desc-created") {<i class="ri-sort-desc"></i>}
            }
          </button>
          <ul class="dropdown-menu">
            <li>
              <div
                class="dropdown-item cursor-pointer"
                [ngClass]="{'bg-primary text-white': this.sortMode==='asc-alphabet'}"
                (click)="this.sortMode = 'asc-alphabet'"
                i18n="@@sort-alphabet"
              >
                <span>Alphabetisch</span>
              </div>
            </li>
            <li>
              <div
                class="dropdown-item cursor-pointer"
                [ngClass]="{'bg-primary text-white': this.sortMode === 'desc-updated'}"
                (click)="this.sortMode = 'desc-updated'"
                i18n="@@sort-last-edited"
              >
                <span>Zuletzt bearbeitet</span>
              </div>
            </li>
            <li>
              <div
                class="dropdown-item cursor-pointer"
                [ngClass]="{'bg-primary text-white': this.sortMode === 'desc-created'}"
                (click)="this.sortMode = 'desc-created'"
                i18n="@@sort-last-created"
              >
                <span>Zuletzt erstellt</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2 align-items-center">
    <ng-container *ngIf="this.searchString ===''">
      <div class="col">
        <h3
          class="mb-0"
          i18n="@@my-bots"
        >Meine Bots</h3>
      </div>
      <div class="col-2">
        <app-chunk-contingent></app-chunk-contingent>
      </div>
    </ng-container>
    <ng-container *ngIf="this.searchString !==''">
      <div class="col">
        <h3
          class="mb-0"
          i18n="@@search-results"
        >Treffer: {{this.listedBots.length}}</h3>
      </div>
    </ng-container>
    <div
      class="col-auto"
      *ngIf="this.searchString.startsWith('trashed:true') && this.listedBots.length > 0"
    >
      <button
        type="button"
        class="btn btn-sm btn-outline-danger"
        (click)="this.deleteAllTrashed()"
        i18n="@@empty-trash"
      >
        <i class="ri-delete-bin-line"></i> Papierkorb leeren
      </button>
    </div>
  </div>
  <app-bot-admin-dashboard-table
    [searchString]="this.searchString"
    [bots]="this.listedBots"
    (deleteBot)="this.deleteBot($event)"
    (duplicateTrigger)="this.loadBots()"
    (reloadTrigger)="this.loadBots()"
    (tagClickTrigger)="this.onTagClick($event)"
  >
  </app-bot-admin-dashboard-table>
  <div
    class="text-center mt-5"
    *ngIf=" this.bots.length ===  0"
  >
    <h2 i18n="@@empty-here">Ganz schön leer hier ...</h2>
    <p i18n="@@empty-here-text">
      Starten Sie jetzt mit Ihrem ersten Bot, indem Sie auf
      <a
        href="#"
        (click)="addBot()"
      >
        "Neuer Bot" </a> links neben der Suche klicken!
    </p>
  </div>
  <div
    class="text-center mt-5"
    *ngIf=" this.bots.length >  0 && this.listedBots.length === 0"
  >
    <h2 i18n="@@no-results">Keine Treffer ...</h2>
    <p i18n="@@no-results-text">
      Bei der Suche nach <u><b>{{this.searchString}}</b></u> wurde nichts gefunden.
    </p>
  </div>
</rag-body-layout>