import { Route } from "@angular/router";
import { BotAdminComponent } from "./routes/ai-bot/admin/admin.component";
import { BotTaskTabComponent } from "./routes/ai-bot/admin/tabs/general/bot-task-tab/bot-task-tab.component";
import { AvatarbotTabComponent } from "./routes/ai-bot/admin/tabs/general/configurations-tab/avatarbot-tab/avatarbot-tab.component";
import { ChatbotTabComponent } from "./routes/ai-bot/admin/tabs/general/configurations-tab/chatbot-tab/chatbot-tab.component";
import { ConfigurationsTabComponent } from "./routes/ai-bot/admin/tabs/general/configurations-tab/configurations-tab.component";
import { VoicebotTabComponent } from "./routes/ai-bot/admin/tabs/general/configurations-tab/voicebot-tab/voicebot-tab.component";
import { EmbedTabComponent } from "./routes/ai-bot/admin/tabs/general/embed-tab/embed-tab.component";
import { InformationTabComponent } from "./routes/ai-bot/admin/tabs/general/information-tab/information-tab.component";
import { MiscTabComponent } from "./routes/ai-bot/admin/tabs/general/misc-tab/misc-tab.component";
import { PrivacyPolicyTabComponent } from "./routes/ai-bot/admin/tabs/general/privacy-policy-tab/privacy-policy-tab.component";
import { FeedbackTabComponent } from "./routes/ai-bot/admin/tabs/insights/feedback-tab/feedback-tab.component";
import { MemoryTabComponent } from "./routes/ai-bot/admin/tabs/insights/memory-tab/memory-tab.component";
import { StatisticsTabComponent } from "./routes/ai-bot/admin/tabs/insights/statistics-tab/statistics-tab.component";
import { DatabasesTabDetailComponent } from "./routes/ai-bot/admin/tabs/skills/databases-tab/databases-tab-detail/databases-tab-detail.component";
import { DatabasesTabComponent } from "./routes/ai-bot/admin/tabs/skills/databases-tab/databases-tab.component";
import { DocumentsTabDetailComponent } from "./routes/ai-bot/admin/tabs/skills/documents-tab/documents-tab-detail/documents-tab-detail.component";
import { DocumentsTabComponent } from "./routes/ai-bot/admin/tabs/skills/documents-tab/documents-tab.component";
import { IfthenTabDetailComponent } from "./routes/ai-bot/admin/tabs/skills/ifthen-tab/ifthen-tab-detail/ifthen-tab-detail.component";
import { IfthenTabComponent } from "./routes/ai-bot/admin/tabs/skills/ifthen-tab/ifthen-tab.component";
import { OfflineWebsitesTabDetailComponent } from "./routes/ai-bot/admin/tabs/skills/offline-websites-tab/offline-websites-tab-detail/offline-websites-tab-detail.component";
import { OfflineWebsitesTabComponent } from "./routes/ai-bot/admin/tabs/skills/offline-websites-tab/offline-websites-tab.component";
import { OnlineSourcesTabDetailComponent } from "./routes/ai-bot/admin/tabs/skills/online-sources-tab/online-sources-tab-detail/online-sources-tab-detail.component";
import { OnlineSourcesTabComponent } from "./routes/ai-bot/admin/tabs/skills/online-sources-tab/online-sources-tab.component";
import { BotEditorComponent } from "./shared/components/ai-bot/bot-editor/bot-editor.component";
import { authGuard } from "./shared/guards/auth.guard";
import { allbotconfigResolver } from "./shared/resolver/allbotconfig.resolver";
import { botconfigadminResolver } from "./shared/resolver/botconfigadmin.resolver";
import { datatrunkResolver } from "./shared/resolver/skills/datatrunk.resolver";
import { documentResolver } from "./shared/resolver/skills/document.resolver";
import { webscrapeResolver } from "./shared/resolver/skills/webscrape.resolver";

export const botAdminRoute: Route =
{
  path: "bot-admin",
  pathMatch: "prefix",
  canActivate: [authGuard],
  resolve: {
    botConfigs: allbotconfigResolver
  },
  children: [
    {
      path: "",
      component: BotAdminComponent
    },
    {
      path: ":id",
      component: BotEditorComponent,
      resolve: {
        selectedBot: botconfigadminResolver
      },
      runGuardsAndResolvers: "paramsChange",
      children: [
        {
          path: "",
          pathMatch: "prefix",
          redirectTo: "task"
        },
        {
          path: "information",
          component: InformationTabComponent
        },
        {
          path: "task",
          component: BotTaskTabComponent
        },
        {
          path: "config",
          component: ConfigurationsTabComponent
        },
        {
          path: "chatbot",
          component: ChatbotTabComponent
        },
        {
          path: "voicebot",
          component: VoicebotTabComponent
        },
        {
          path: "avatarbot",
          component: AvatarbotTabComponent
        },
        {
          path: "privacy-policy",
          component: PrivacyPolicyTabComponent
        },
        {
          path: "embed",
          component: EmbedTabComponent
        },
        {
          path: "misc",
          component: MiscTabComponent
        },
        {
          path: "if-then",
          children: [
            {
              path: "",
              component: IfthenTabComponent
            },
            {
              path: ":ifthenid",
              component: IfthenTabDetailComponent
            }
          ]
        },
        {
          path: "documents",
          runGuardsAndResolvers: "always",
          resolve: {
            documents: documentResolver
          },
          children: [
            {
              path: "",
              component: DocumentsTabComponent
            },
            {
              path: ":docid",
              component: DocumentsTabDetailComponent
            }
          ]
        },
        {
          path: "online-sources",
          children: [
            {
              path: "",
              component: OnlineSourcesTabComponent
            },
            {
              path: ":toolid",
              component: OnlineSourcesTabDetailComponent
            }
          ]
        },
        {
          path: "databases",
          runGuardsAndResolvers: "always",
          resolve: {
            dataTrunks: datatrunkResolver
          },
          children: [
            {
              path: "",
              component: DatabasesTabComponent
            },
            {
              path: ":trunkid",
              component: DatabasesTabDetailComponent
            }
          ]
        },
        {
          path: "offline-websites",
          runGuardsAndResolvers: "always",
          resolve: {
            webscrapes: webscrapeResolver
          },
          children: [
            {
              path: "",
              component: OfflineWebsitesTabComponent
            },
            {
              path: ":webscrapeid",
              component: OfflineWebsitesTabDetailComponent
            }
          ]
        },
        {
          path: "memory",
          component: MemoryTabComponent
        },
        {
          path: "feedback",
          component: FeedbackTabComponent
        },
        {
          path: "statistics",
          component: StatisticsTabComponent
        }
      ]
    }
  ]
};