<ng-template
  #menuEntry
  let-item="item"
>
  <div style="cursor: pointer;">
    <a
      class="nav-link"
      [routerLink]="['/bot-admin', this.rss.botConfig._id, item.href]"
      [routerLinkActive]="'active'"
    >
      <span class="ms-3 fw-bold">
        <i
          *ngIf="item.icon"
          [ngClass]="'mx-2 ri-xl ' + item.icon"
          style="vertical-align: middle;"
          class="fw-normal"
        ></i>
        {{item.name}}
      </span>

      @if((item.name === 'Dokumente' || item.name === 'Documents') && this.counter.getFiles(this.rss.botConfig._id) >
      0){
      <div class="ms-2 badge bg-primary">
        {{this.counter.getFiles(this.rss.botConfig._id)}}
      </div>
      }
      @if((item.name === 'Wenn-Dann' || item.name === 'If-Then') && this.counter.getWennDann(this.rss.botConfig._id)
      > 0){
      <div class="ms-2 badge bg-primary">
        {{this.counter.getWennDann(this.rss.botConfig._id)}}
      </div>
      }
      @if(item.name === 'Offline-Websites' && this.counter.getOfflineWebsiteCount(this.rss.botConfig._id) > 0){
      <div class="ms-2 badge bg-primary">
        {{this.counter.getOfflineWebsiteCount(this.rss.botConfig._id)}}
      </div>
      }
      @if((item.name === 'Datenbanken' || item.name === 'Databases') &&
      this.counter.getTrunks(this.rss.botConfig._id) > 0){
      <div class="ms-2 badge bg-primary">
        {{this.counter.getTrunks(this.rss.botConfig._id)}}
      </div>
      }
      @if((item.name === 'Online-Quellen' || item.name === 'Online sources') &&
      this.counter.getTools(this.rss.botConfig._id) >
      0){
      <div class="ms-2 badge bg-primary">
        {{this.counter.getTools(this.rss.botConfig._id)}}
      </div>
      }
    </a>
  </div>
</ng-template>

<ng-template #quickinfo>
  <div [ngStyle]="{
    'background-color': this.rss.botConfig.hasImage ? 'transparent' : this.rss.botConfig.color,
  'color':getContrastColor(this.rss.botConfig.color),
  'background-image': this.rss.botConfig.hasImage?  'url(/api/toolbox/membot/public/botimg/'+this.rss.botConfig._id+'/256)' : '',
    'background-size': 'cover',
  }">
    <app-bot-admin-card-body-content
      id="adminBodySidebar"
      [botConfig]="this.rss.botConfig"
    >
    </app-bot-admin-card-body-content>
    <div class="row mt-n3">
      <div class="col text-end  ">
        <div
          class="btn"
          [routerLink]="['/bot-admin', this.rss.botConfig._id, 'config']"
        >
          <i
            class="ri-settings-2-fill ri-1x mt-n2 c"
            [ngStyle]="{'color':getContrastColor(this.rss.botConfig.color)}"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div
    class="btn btn-primary btn d-block m-2"
    [routerLink]="['/','bot-admin']"
    i18n="@@my-bots"
  >Meine Bots</div>

</ng-template>

<rag-body-layout
  [classList]="this.rss.userCanEditBotConfig() ? [''] : ['bot-locked-for-user']"
  [sidebarHeaderTpl]="quickinfo"
  [navItems]="this.adminAreasToNavItems(this.adminAreas)"
  [logoutFunction]="this.logout"
>

  <div
    style="position: sticky;top:0px; z-index: 120;background-color: white; top:0px;border-bottom: 1px solid #e0e0e0;"
    class="mb-5 mx-n5 px-5 py-2 "
  >
    <div class="row ">
      <div class="col">
        <div
          class="input-group mb-2"
          dropdown
          container="body"
          #botSelect
        >
          <span
            class="input-group-text bg-primary"
            id="bot-change-button"
            type="button"
            dropdownToggle
            aria-controls="bot-change-dropdown"
          >
            <i class="ri-expand-up-down-line text-white"></i>
          </span>
          <ul
            [ngStyle]="{'min-width': botSelect.offsetWidth + 'px'}"
            id="bot-change-dropdown"
            *dropdownMenu
            class="dropdown-menu w-100 p-0 "
            role="menu"
            style="max-height: 300px; overflow-y: auto;"
            aria-labelledby="bot-change-button"
          >
            <li
              *ngFor="let bot of this.rss.botConfigs; let i = index"
              role="menuitem"
              style="overflow: hidden;"
              [ngClass]="bot._id === this.rss.botConfig._id ? 'bg-primary' : ''"
              [ngStyle]="bot._id === this.rss.botConfig._id ? {'--bs-dropdown-link-hover-bg': '#26b3a8'} : {}"
            >
              <a
                class="dropdown-item"
                (click)="this.changeSelectedBot(bot._id)"
                [ngClass]="bot._id === this.rss.botConfig._id ? 'text-white' : ''"
                *ngIf="!bot.thisIsTrash && !bot.archived"
              >
                <i
                  class="ri-circle-fill"
                  [ngStyle]="{'color': bot.color}"
                ></i>
                <i
                  class="ms-1 mr-2"
                  [ngClass]="bot.icon"
                ></i>
                {{bot.name}}
              </a>
            </li>
          </ul>
          <input
            *ngIf="this.rss.botConfig"
            type="text"
            class="form-control hover-effect bg-transparent ps-1"
            [(ngModel)]="this.rss.botConfig.name"
            [ngModelOptions]="{updateOn: 'blur'}"
            (ngModelChange)="this.saveNameToBotConfig()"
            id="botName"
          >
        </div>
      </div>
      <div class="col">
        <div class="input-group mb-2">
          <span class="input-group-text">
            <i class="ri-hashtag"></i>
          </span>
          <ng-select
            *ngIf="this.rss.botConfig"
            #ngSelect
            [(ngModel)]="separatedTagList"
            [addTag]="true"
            [multiple]="true"
            [hideSelected]="true"
            [clearable]="false"
            [items]="this.tags"
            (add)="this.saveTagsToBotConfig()"
            (remove)="this.saveTagsToBotConfig()"
            id="botTags"
            class="form-control hover-effect bg-transparent p-0"
            notFoundText="Gib den Tag ein, welchen du hinzufügen möchtest"
            addTagText="Tag hinzufügen:"
            placeholder="tag 1, tag 2, tag 3, ..."
          ></ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-end me-n1">
        <a
          href="{{this.langService.language}}/bot/{{this.rss.botConfig._id}}?debug=true"
          class="btn-primary btn btn-sm m-1 lock-insensitive"
          target="_bottest"
          i18n="@@bot-test"
        >
          Bot testen <i class="ri-external-link-line"></i>
        </a>
        <a
          href="{{this.langService.language}}/multi-bot?id={{this.rss.botConfig._id}}"
          class="btn-primary btn btn-sm m-1 lock-insensitive"
          target="_bottestmulti"
          i18n="@@multi-bot-test"
        >Multi-Bot testen <i class="ri-external-link-line"></i></a>
      </div>
      <div class="col-auto text-end me-n1 ">
        <a
          href="{{this.langService.language}}/bot/{{this.rss.botConfig._id}}"
          class="btn-primary btn btn-sm m-1 lock-insensitive"
          target="_blank"
          i18n="open-chat-bot"
        >
          <i class="ri-chat-ai-line"></i>
          Chatbot öffnen
        </a>
        <a
          href="{{this.langService.language}}/bot/{{this.rss.botConfig._id}}/audio"
          class="btn-primary btn btn-sm m-1 lock-insensitive"
          target="_bottestaudio"
          i18n="open-voice-bot"
        >
          <i class="ri-voice-ai-line"></i>
          Voicebot öffnen</a>

        <a
          *ngIf="this.rss.botConfig.heyGenEnabled"
          class="btn-primary btn btn-sm m-1"
          href="{{this.langService.language}}/bot/{{this.rss.botConfig._id}}/avatar"
          target="_bottesavatar"
          i18n="open-avatar-bot"
        >
          <i class="ri-video-ai-line"></i>
          Avatar öffnen</a>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</rag-body-layout>