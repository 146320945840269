import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatbotConfig, isIdleState } from '@reflact/ai-types';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PushToTalkRecordButtonComponent } from 'src/app/shared/components/ai-bot/utils/push-to-talk-record-button/push-to-talk-record-button.component';
import { DisconnectOverlayComponent } from 'src/app/shared/components/disconnect-overlay/disconnect-overlay.component';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BotService } from 'src/app/shared/services/bot.service';
import { IframeCommunicationService } from 'src/app/shared/services/iframe-communication/iframe-communication.service';
import { getTokenContents, LoginService } from 'src/app/shared/services/login.service';
import { PlayAudio } from 'src/app/shared/services/playaudio.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { SpeechService } from 'src/app/shared/services/speech.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { BotPromptComponent } from "../../../shared/components/ai-bot/utils/bot-prompt/bot-prompt.component";
import { StatisticService } from '../admin/tabs/insights/statistics-tab/statistic.service';
import { BotBasedComponent } from '../bot-based/bot-based.component';

@Component({
  templateUrl: './audio.component.html',
  standalone: true,
  styleUrls: ['./audio.component.scss'],
  imports: [CommonModule, PushToTalkRecordButtonComponent, BotPromptComponent, DisconnectOverlayComponent],
  providers: []
})
export class AudioComponent extends BotBasedComponent implements OnInit {
  public override botConfig!: ChatbotConfig;
  public whichPage: 'landing' | 'recording' | 'speaking' = 'landing';
  public isSpeaking: boolean = false;
  public hideBall = true;
  public hideBotWords = true;
  public hideWaves = true;
  public hideRecButton = false;
  public startWords = '';
  public myWords = '';
  public botWords = '';
  private isAudioAlreadyCreated = false;

  constructor(
    public override route: ActivatedRoute,
    public override loginService: LoginService,
    public override speechService: SpeechService,
    public override statisticsService: StatisticService,
    public override iframeCommunication: IframeCommunicationService,
    public override playService: PlayAudio,
    public override bot: BotService,
    public override socketService: SocketService,
    public override toastr: ToastrService,
    public override modalService: BsModalService,
    protected override botMessages: BotMessagesService
  ) {
    super(route, loginService, speechService, statisticsService, iframeCommunication, playService, bot, socketService, toastr, modalService, botMessages);

    route.data.subscribe(data => {
      const botConfig = data['botConfig'] as ChatbotConfig | null;
      if (!botConfig) return;
      this.botConfig = botConfig;
    });

    this.subscriptions.push(
      this.socketService.onCreateMessagesInThread.subscribe(data => {
        for (const message of data.messages) {
          this.isThinking = true;
          this.botMessages.addMessage(message);
        }
      }),
      this.socketService.onRunUpdate.subscribe(data => {
        this.isThinking = true;
        this.speechService.botIsThinkking = true;
        let lastIndex = this.botMessages.getMessageCount() - 1;
        const current = this.botMessages.getMessage(lastIndex);
        if (current) {
          if (current.openAiMessageId != data.openAiMessageId) {
            this.botMessages.addMessage(data);
            this.botWords = data.content;
            const newCurrent = this.botMessages.getMessage(lastIndex + 1);
            if (newCurrent) {
              lastIndex += 1;
            }
          }
          this.botMessages.updateContent(lastIndex, data.content);
        } else {
          this.botMessages.addMessage(data);
          this.botWords = data.content;
        }
      }),
      this.socketService.onStateUpdate.subscribe(async (data) => {
        if (isIdleState(data.sessionState)) {
          if (this.prevBotState != "unknown") {
            const lastIndex = this.botMessages.getMessageCount() - 1;
            const current = this.botMessages.getMessage(lastIndex);
            if (current) {
              await this.broadCastMSG(current);
              this.iframeCommunication.runComplete(current.content);
              this.botWords = current.content;
              void this.playBotWords(this.botWords);
            }
          }

          this.isThinking = false;
          if (this.whichPage === 'speaking' && this.botWords !== '' && !this.isAudioAlreadyCreated) {
            this.isAudioAlreadyCreated = true;
            void this.playService.playAudio(this.botWords, this.botConfig._id);
          }
        } else {
          this.isThinking = true;
        }
        this.prevBotState = data.sessionState;
      })
    );
  }

  public async playBotWords(words: string) {
    // CAHCTEN OB WIR AUF DER START SEITE SIND
    if (this.getLayoutName() === 'landing') {
      this.startWords = words;
      return;
    }

    if (words.length === 0) return;
    // VERSUCHEN ZU PLAYEN, (GEHT ERST NACH INTERACTION DAHER DAS TRY)
    try {
      await this.playService.playAudio(words, this.botConfig._id);
      this.view_set_ballview();
      this.startWords = "";
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e: unknown) {
      this.startWords = words;
    }
  }

  private view_set_ballview() {
    this.hideWaves = true;
    this.hideRecButton = true;
    this.hideBall = false;
    this.whichPage = 'speaking';
    this.hideBotWords = true;
    this.speechService.isListening = false;
  }

  public override async ngOnInit() {
    this.botMessages.setMessages((await this.bot.getHistoryMessages(this.botConfig._id)).messageHistory);
    this.playService.currentAudioStatus.subscribe((status: [string, string]) => {
      if (status[0] === 'idle') {
        this.isSpeaking = false;
        this.hideBall = true;
        this.hideRecButton = false;
        this.hideBotWords = false;
        this.hideWaves = false;
        this.whichPage = 'recording';
        this.isAudioAlreadyCreated = false;
        this.myWords = '';
      } else if (status[0] === 'play') {
        this.isSpeaking = true;
      }
    });

    this.initIFrameService();

    const userToken = this.loginService.getToken();
    if (userToken == null) {
      await this.loginService.loginAsGuest();
    }

    if (this.botConfig as ChatbotConfig | null) {
      await this.loginService.loadMyUser().then(() => {
        let localStorageKey = 'token';
        if (this.route.snapshot.queryParamMap.has('uniqueSession')) {
          localStorageKey = this.route.snapshot.queryParamMap.get('uniqueSession') ?? '';
        }
        getTokenContents(localStorageKey);
        this.socketService.connectToThread(this.botConfig._id, "" + localStorage.getItem(localStorageKey));
      });
      this.showGDPR();
    }
  }

  public startVoiceBot() {
    this.whichPage = 'recording';
    if (this.startWords !== '') {
      this.botWords = this.startWords;
      this.startWords = '';
    }
    if (this.botConfig.welcomeType === 'None') {
      this.whichPage = 'recording';
      this.hideBall = true;
      this.hideWaves = false;
      // WENN WIR BEREITS IN EINEM DIALOG WAREN
    } else {
      //WENN DER BOT EINE INITIAL BOTSCHAFT HAT
      if (this.botWords !== '') {
        void this.playBotWords(this.botWords);
      }
    }
  }

  public getLayoutName() {
    return this.whichPage;
  }

  public waveVideoPlay() {
    const video = document.getElementById("wave-video");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (video !== null) video.play();
  }

  public waveVideoPause() {
    const video = document.getElementById("wave-video");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (video !== null) video.pause();
  }

  public startRecording() {
    this.whichPage = 'recording';
    this.waveVideoPlay();
  }

  public async killAllBotCommunicationImidiatly() {
    if (this.botState === 'thinking') await this.cancelThinking();
    if (this.isSpeaking) this.playService.stopAudio();
    this.whichPage = 'recording';
  }

  public onResultRecording(words: string) {
    this.myWords = words;
  }

  public sendMsg(words: string) {
    this.waveVideoPause();
    this.myWords = words;
    void this.add(words);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public override async add(words: string) {
    this.view_set_ballview();
    void super.add(words);
  }
}