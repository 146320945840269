<app-scoreboard></app-scoreboard>
<app-disconnect-overlay></app-disconnect-overlay>

<ng-container *ngIf="this.botConfig else botNotFoundTpl">
  <div
    *ngIf="this.multiBotMode"
    id="menuBar"
    class="row m-0 small align-items-center"
    [ngStyle]="{
      'background-color': this.getColorFromConfig(true),
      'color': this.getContrastColor(this.getColorFromConfig(true))
    }"
  >
    <div class="col-auto">
      <i class="ri-robot-2-line"></i> {{this.botConfig.model}}
    </div>
    <div class="col-auto ps-0">
      <i class="ri-temp-cold-line"></i> {{this.botConfig.temperature}}
    </div>
    <div class="col-auto ps-0">
      <i
        class="ri ri-settings-2-line cursor-pointer"
        (click)="this.openBotConfig()"
      ></i>
    </div>
    <div class="col-auto ps-0">
      <i
        class="ri-guide-line cursor-pointer"
        (click)="this.toggleDebugLog()"
      ></i>
    </div>
  </div>
  <div
    id="chatbot-wrapper"
    class="h-100"
    [ngClass]="{'d-flex align-items-stretch': this.currentDebugState === 'fullsize', 'multibot-iframe': this.multiBotMode}"
  >
    <div
      id="chat-container"
      [ngClass]="{
        'multi-bot-mode': this.multiBotMode,
        'flex-fill': this.currentDebugState === 'fullsize'
      }"
    >

      <nav
        id="bot-header"
        class="navbar navbar-expand-lg navbar-light"
        [ngStyle]="{
                'background-color': this.getColorFromConfig(true),
                'color': this.getContrastColor(this.getColorFromConfig(true))
              }"
      >

        <div class="container">

          <div class="row align-items-center mx-1">
            <div class="col-auto">
              <div
                class="border-1 border-white border rounded-circle position-absolute"
                style="height: 10px; width: 10px; background-color: #30b37e; margin-left: 32px"
              ></div>
              <i
                [ngStyle]="{
                        'background-color': this.getColorFromConfig(),
                        'color': this.getContrastColor(this.getColorFromConfig())
                      }"
                class="ri-2x p-2 rounded-circle"
                [class]="this.botConfig.icon"
              >
              </i>
            </div>
            <div class="col align-middle text-black">

              <a class="navbar-brand fs-6 fw-bold">{{this.botConfig.name}}</a>

              <small class="d-block text-muted">
                <div
                  class="d-inline-block border-1 border-white border rounded-circle"
                  style="height: 10px; width: 10px; background-color: #30b37e;"
                ></div>
                <span i18n="@@always-online">immer online</span>
              </small>
            </div>
          </div>
          <div *ngIf="!this.multiBotMode">
            <ng-container *ngIf="debugMode">
              <div
                class="mx-2 btn btn-outline-danger"
                (click)="deleteHistory()"
                i18n="@@btn-delete-history"
              >Chatverlauf löschen</div>

              <button
                class="btn btn-outline-secondary"
                (click)="this.toggleDebugLog()"
              >
                <i class="ri-guide-line"></i>
                <span i18n="@@log">
                  Log
                </span>
              </button>
            </ng-container>
          </div>
        </div>
      </nav>
      <div
        id="bot-content"
        style="height: 100%;overflow-y: scroll;"
      >
        <div
          id="messages"
          style="height: 1px;"
          class="mt-4 container"
        >
          <app-bot-chat-dialog
            *ngIf="this.botConfig"
            id="myDialog"
            [isThinking]="this.thinkingAnimaitionShouldBeShown()"
            [botConfig]="this.botConfig"
            [botState]="this.prevBotState"
            [enableDeletionOverride]="true"
            [enableFeedbackOverride]="true"
            [showHistory]="true"
            [debugMode]="this.debugMode"
          ></app-bot-chat-dialog>
        </div>
      </div>

      <div id="bot-footer">
        <div class="container">
          <ng-container *ngIf="!this.multiBotMode">
            <app-bot-prompt
              *ngIf="this.botConfig.promptbarChatActive"
              [botConfig]="this.botConfig"
              [cancelThinking]="this.botConfig.cancelBotThinkingChatActive"
              [voiceInput]="this.botConfig.voiceInputChatActive"
              [switchToAvatar]="this.botConfig.switchToAvatarFromChat"
              [switchToVoice]="this.botConfig.switchToVoiceFromChat"
              [isThinking]="thinkingAnimaitionShouldBeShown() "
              [(promptInput)]="promptInput"
              (sendPrompt)="add()"
              (stfu)="this.cancelThinking()"
            ></app-bot-prompt>
          </ng-container>
        </div>
      </div>
    </div>
    <app-bot-debug
      [logEntries]="this.statisticsService.debugLog"
      [type]="this.currentDebugState"
      (closed)="this.currentDebugState = 'none'"
      *ngIf="this.currentDebugState !== 'none'"
    ></app-bot-debug>
  </div>
  <link
    [href]="this.customCssUrl"
    rel="stylesheet"
  >


</ng-container>

<ng-template #botNotFoundTpl>
  <div class="d-flex h-100 w-100 align-items-center justify-content-center flex-column">
    <h1 i18n="@@bot-not-found">
      Dieser Bot ist nicht öffentlich oder existiert nicht!
    </h1>
    <span i18n="@@bot-not-found-hint">
      Wenn du das für einen Fehler hältst, melde dich beim Besitzer des Bots!
    </span>
  </div>
</ng-template>