
import { Injectable } from '@angular/core';
import { Score } from './Score';
import { KeyValService } from '../../key-val.service';
import { isKeyValEntryScoreData } from '@reflact/ai-types/dist/KeyValStore';
export type AchieveMent = {
  value: number;
  skillId: string;
  message: string;
};
@Injectable({ providedIn: 'root' })
export class ScoreService {
  public scores: Map<string, Score> = new Map<string, Score>();
  public initialsLoaded: boolean = false;
  constructor(public keyValService: KeyValService) {
    void this.loadInitialValues();
  }

  public async waitForInitials() {
    if (!this.initialsLoaded) {
      await this.loadInitialValues();
    }
  }

  public async loadInitialValues() {
    const initials = await this.keyValService.getInitialValues();
    initials.forEach((entry) => {
      if (isKeyValEntryScoreData(entry)) {
        this.db(entry.key).updateFromServer(entry);
      }
    });
    this.initialsLoaded = true;
  }


  public db(scoreName: string = ""): Score {
    let store = this.scores.get(scoreName);
    if (store == null) {
      store = new Score(this.keyValService, scoreName);
      this.scores.set(scoreName, store);
    }
    return store;
  }

  public achieve(value: number, skillId: string, message: string, autoMessage: boolean = false) {
    this.db().achieve(value, skillId, message, autoMessage);
  }

  public getAchieveMents(asJson: boolean = false) {
    return this.db().getAchieveMents(asJson);
  }
  public add(value: number) {
    return this.db().add(value);
  }
  public get() {
    return this.db().get();
  }
  public reset() {
    return this.db().reset();
  }
  public set(value: number) {
    return this.db().set(value);
  }
  public sub(value: number) {
    return this.db().sub(value);
  }

}
