import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../../../services/login.service';

@Component({
  standalone: true,
  imports: [RouterModule],
  templateUrl: './file-citation.component.html',
  styleUrl: './file-citation.component.scss'
})
export class FileCitationComponent {

  constructor(public router: Router, aRoute: ActivatedRoute, loginService: LoginService) {
    const botId = aRoute.snapshot.paramMap.get("botid");
    const fileId = aRoute.snapshot.paramMap.get("fileid");
    const token = loginService.getToken();
    const url = `/api/toolbox/bot/user/fileCitation/${botId ?? ""}/${fileId ?? ""}?token=${token ?? ""}`;
    window.open(url, '_self');
  }

}
