<div class="modal-header">
  <i class="ri ri-settings-2-line me-1"></i>Konfiguration
</div>

<div class="modal-body small">
  <div class="row mt-2 align-items-center">
    <div class="col-3">
      Temperatur
    </div>
    <div
      class="col-auto pe-0 text-end"
      i18n="@@temperature-precise"
    >Präzise</div>
    <div class="col-4">
      <ngx-slider
        [(ngModel)]="this.botConfig.temperature"
        [options]="{floor: 0, ceil: 1.5, step: .1}"
      ></ngx-slider>
    </div>
    <div
      class="col-auto ps-0"
      i18n="@@temperature-creative"
    >Kreativ</div>
  </div>
  <div class="row mt-2 align-items-center">
    <div class="col-2">
      Model
    </div>
    <div class="col-auto">
      <select
        class="form-select form-select-sm"
        [(ngModel)]="this.botConfig.model"
      >
        <option
          *ngFor="let model of this.gptModels"
          [value]="model"
        >{{ model }}</option>
      </select>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div
    class="btn btn-primary"
    (click)="this.updateConfig()"
  >Übernehmen</div>
</div>