import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OutputTriggerTypeArr, ParserTypeArr, TriggerActionArr } from '@reflact/ai-types';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { INavData } from '@reflact/ngx-layout/lib/body-layout/sidebar-nav/sidebar-nav.component';
import { getContrastColor } from '@reflact/tsutil';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { filter, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { CounterService } from 'src/app/shared/services/counter.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { LoginService, logout } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { TrunkService } from 'src/app/shared/services/trunk.service';
import { WebscrapeService } from 'src/app/shared/services/webscrape.service';
import { BotAdminCardBodyContentComponent } from './bot-admin-card-body-content/bot-admin-card-body-content.component';
//import { BotImageReloadComponent } from '../../bot-image-reload/bot-image-reload.component';

export type MenuItem = {
  type: 'link';
  text: string;
  href: string;
  hidden?: () => boolean;
  icon: string;
  cssClass?: string;
} | {
  type: 'separator';
  text: string;
}

@Component({
  selector: 'app-bot-editor',
  templateUrl: './bot-editor.component.html',
  styleUrls: ['./bot-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TabsModule,
    BotAdminCardBodyContentComponent,
    BsDropdownModule,
    RouterModule,
    NgSelectModule,
    NgxLayoutModule,
    RouterModule,
    ProgressbarModule,
    TooltipModule
  ]
})
export class BotEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menuEntry', { static: true }) public menuEntry!: TemplateRef<unknown>;
  public selectedAdminArea: string = $localize`:@@bot-task:Bot-Aufgabe`;
  public triggerTypes = OutputTriggerTypeArr;
  public triggerActions = TriggerActionArr;
  public triggerParser = ParserTypeArr;
  public separatedTagList: { label: string }[] = [];
  public navItems: INavData[] = [];
  public getContrastColor = getContrastColor;
  public tags: { label: string }[] = [];
  protected botConfigSmall: { name: string, id: string }[] = [];
  public adminAreas: MenuItem[] = [
    { type: 'separator', text: $localize`:@@general:Allgemein` },
    { type: 'link', text: $localize`:@@information:Information`, hidden: (() => this.rss.botConfig.notes === ''), icon: 'ri-information-2-fill', href: "information" },
    { type: 'link', text: $localize`:@@bot-task:Bot-Aufgabe`, icon: 'ri-lightbulb-flash-fill', href: "task" },
    { type: 'link', text: $localize`:@@configuration:Konfiguration`, icon: 'ri-tools-fill', href: "config" },
    { type: 'link', text: $localize`:@@chat-bot:Chat-Bot`, icon: 'ri-chat-ai-fill ps-3', href: "chatbot" },
    { type: 'link', text: $localize`:@@voice-bot:Voice-Bot`, icon: 'ri-voice-ai-fill ps-3', href: "voicebot" },
    { type: 'link', text: $localize`:@@avatar-bot:Avatar-Bot`, icon: 'ri-video-ai-fill ps-3', href: "avatarbot" },
    { type: 'link', text: $localize`:@@privacy:Datenschutz`, icon: 'ri-shield-user-fill', href: "privacy-policy" },
    { type: 'link', text: $localize`:@@embedding:Einbetten`, icon: 'ri-share-fill', href: "embed" },
    { type: 'link', text: $localize`:@@misc:Sonstiges`, icon: 'ri-more-fill', href: "misc" },
    { type: 'separator', text: $localize`:@@skills:Skills` },
    { type: 'link', text: $localize`:@@if-then:Wenn-Dann`, icon: 'ri-swap-2-fill', href: "if-then" },
    { type: 'link', text: $localize`:@@documents:Dokumente`, icon: 'ri-article-fill', href: "documents" },
    { type: 'link', text: $localize`:@@online-sources:Online-Quellen`, icon: 'ri-cloud-fill', href: "online-sources" },
    { type: 'link', text: $localize`:@@databases:Datenbanken`, icon: 'ri-database-2-fill', href: "databases" },
    { type: 'link', text: $localize`:@@offline-websites:Offline-Websites`, hidden: (() => this.loginService.systemConf == 'kitoolbox'), icon: 'ri-cloud-off-fill', href: "offline-websites" },
    { type: 'separator', text: $localize`:@@insights:Insights` },
    { type: 'link', text: $localize`:@@memory:Gedächtnis`, icon: 'ri-brain-fill', href: "memory" },
    { type: 'link', text: $localize`:@@feedback:Feedback`, icon: 'ri-feedback-fill', href: "feedback" },
    { type: 'link', text: $localize`:@@statistics:Statistik`, icon: 'ri-bar-chart-fill', href: "statistics" }
  ];

  private routerSubscription: Subscription;
  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public fileUpload: FileUploadService,
    private trunkService: TrunkService,
    public counter: CounterService,
    private loginService: LoginService,
    public langService: LanguageService,
    public rss: RouteShareService,
    public wss: WebscrapeService,
    public adminService: AdminService
  ) {
    this.routerSubscription = this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe(() => {
        this.navItems = [];
        this.adminAreasToNavItems(this.adminAreas);
      });

  }

  public async ngOnInit(): Promise<void> {
    this.separatedTagList = this.rss.botConfig.tagList.map(tag => ({ label: tag }));
    await this.fileUpload.getFiles(this.rss.botConfig._id);
    await this.trunkService.getTrunks(this.rss.botConfig._id);
    await this.wss.getWebscrapesForBot(this.rss.botConfig._id);
    this.counter.setTools(this.rss.botConfig._id, this.rss.botConfig.toolConfigs.filter((toolConfig) => toolConfig.enabled && (toolConfig.tool === 'schnittstelle' || toolConfig.tool === 'websitesearch')).length);
    this.counter.setWennDann(this.rss.botConfig._id, this.rss.botConfig.toolConfigs.filter((toolConfig) => toolConfig.enabled && toolConfig.tool === 'customtoolkit').length);
    this.adminAreasToNavItems(this.adminAreas);

    const tags: { label: string }[] = [];
    this.rss.botConfigs.forEach((botConfig) => {
      this.botConfigSmall.push({ name: botConfig.name, id: botConfig._id });
      botConfig.tagList.forEach(tag => {
        if (!tags.find(t => t.label == tag)) {
          tags.push({ label: tag });
        }
      });
    });
    this.tags = tags;
  }

  public ngAfterViewInit(): void {
    this.adminAreasToNavItems(this.adminAreas);
  }


  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public menuIsActive(item: INavData): boolean {
    return localStorage.getItem('selectedAdminArea') === item.name;
  }

  public logout(): void {
    logout();
  }

  public changeSelectedBot(botId: string) {
    void this.router.navigateByUrl(this.router.url.replace(this.rss.botConfig._id, botId));
  }

  public adminAreasToNavItems(areas: MenuItem[]): INavData[] {
    if (this.navItems.length > 0) {
      return this.navItems;
    }
    areas.forEach((area: MenuItem) => {
      const newItem = {} as INavData & { hidden: boolean };
      if (area.type == "separator") {
        newItem.title = true;
        newItem.name = area.text;
        newItem.template = this.menuEntry;
      }
      if (area.type == "link") {
        newItem.href = area.href;
        newItem.name = area.text;
        newItem.template = this.menuEntry;
        newItem.hidden = area.hidden ? area.hidden() : false;
        newItem.icon = area.icon;
      }
      if (!newItem.hidden) {
        this.navItems.push(newItem);
      }
    });
    return this.navItems;
  }

  public async saveTagsToBotConfig() {
    this.rss.botConfig.tagList = this.separatedTagList.map((tag: {
      label: string;
    }) => tag.label);
    this.separatedTagList = this.rss.botConfig.tagList.map(tag => ({ label: tag }));
    await this.rss.saveBotConfig();
  }

  public async saveNameToBotConfig() {
    if (this.rss.botConfig.name.trim() == "") {
      this.toastr.error(ToastErrorTitle.SAVED, 'Der Name darf nicht leer sein!');
    } else {
      this.rss.botConfig.name = this.rss.botConfig.name.trim();
      await this.rss.saveBotConfig();
    }
  }


}
