<ng-container *ngIf="rss.webscrape">


  <app-admin-back-button i18n="@@avaliable-weppages">Verfügbare Websites im Überblick</app-admin-back-button>
  <div class="row align-items-baseline">
    <div class="col">
      <h3
        class="mt-4"
        i18n="@@edit-webpage"
      >
        Website bearbeiten
      </h3>
    </div>
    <div class="col-auto text-end">
      <app-skill-id-input
        [value]="this.rss.webscrape.skillId"
        (changed)="this.rss.webscrape.skillId = $event; this.service.updateWebscrapeConfig(this.rss.webscrape)"
      ></app-skill-id-input>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-3">
      <span> <ng-container i18n="@@website-usage-description">Nutzen der Website:</ng-container>
        <app-hint i18n="@@website-usage-hint">
          Bitte erläutern Sie der KI, wofür diese Webseite genutzt werden soll.
        </app-hint>
      </span>
    </div>
    <div class="col">
      <input
        class="form-control form-control-sm rag-aitext"
        [(ngModel)]="this.rss.webscrape.description"
        (blur)="this.service.updateWebscrapeConfig(this.rss.webscrape)"
      >
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3">
      <span i18n="@@functionname">Funktionsname</span>
    </div>
    <div class="col">
      <app-function-name-suggest
        [descriptionString]="this.rss.webscrape.description"
        (finish)="this.rss.webscrape.name = $event; this.service.updateWebscrapeConfig(this.rss.webscrape)"
      >
        <input
          type="text"
          class="form-control"
          i18n-placeholder="@@name"
          placeholder="Name"
          maxlength="30"
          [(ngModel)]="this.rss.webscrape.name"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.service.updateWebscrapeConfig(this.rss.webscrape)"
        >
      </app-function-name-suggest>
    </div>
  </div>
  <h5
    class="mt-4"
    *ngIf="this.rss.webscrape.distinctPages.length > 0"
    i18n="@@existing-subpages"
  >Vorhandene Unterseiten</h5>
  <div class="full-width-table-wrapper">
    <table class="table table-hover">
      <tbody>
        @for (scrapePage of this.rss.webscrape.distinctPages; track $index) {
        <tr>
          <td>
            <span>{{scrapePage}}</span>
            <a
              href="{{scrapePage}}"
              target="_blank"
              class="ms-1"
            >
              <i class="ri-external-link-line"></i>
            </a>
          </td>
          <td class="text-end">
            <button
              class="btn btn-outline-danger btn-sm lock-sensitive"
              (click)="this.deleteWebscrapePages(this.rss.webscrape, this.rss.webscrapeMap.get(scrapePage)!)"
              tooltip="Diese Unterseite löschen"
            >
              <i class="ri-close-large-fill"></i>
            </button>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</ng-container>