import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatbotConfig } from '@reflact/ai-types';
import { BotChatDialogComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/bot-chat-dialog.component';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { BotService } from 'src/app/shared/services/bot.service';

@Component({
  selector: 'app-ico-wizard',
  standalone: true,
  imports: [
    CommonModule,
    BotChatDialogComponent,
    FormsModule
  ],
  templateUrl: './ico-wizard.component.html',
  styleUrl: './ico-wizard.component.scss'
})
export class IcoWizardComponent implements OnInit {
  @ViewChild('myDialog') public myDialog?: BotChatDialogComponent & HTMLElement;
  public botConfig!: ChatbotConfig;
  public promptInput: string = '';
  public isThinking = false;

  constructor(private bot: BotService, private botMessages: BotMessagesService) { }

  public ngOnInit() {
    this.botConfig.memoryEnabled = false;
    const icoBotPrompt = $localize`:@@ico-wizard-prompt:
Chatbot Builder ist eine fortschrittliche Plattform, die Benutzern bei der Erstellung und Optimierung von Chatbots hilft. Diese interaktive Anwendung führt die Benutzer durch einen detaillierten und strukturierten Prozess, um einen effektiven und effizienten Chatbot zu entwickeln. Der Prozess beginnt damit, dass der Chatbot Builder gezielte Fragen stellt, um ein tiefes Verständnis der Ziele und Anforderungen des Benutzers zu erlangen. Diese Informationen werden verwendet, um maßgeschneiderte Empfehlungen für die Gestaltung des Chatbots zu geben.
Während der Entwicklungsphase bietet der Chatbot Builder fachkundige Ratschläge zur Auswahl der richtigen Algorithmen und Tools, berät bei der Gestaltung der Benutzerinteraktion und hilft bei der Integration von relevanten APIs und Diensten. Der Builder schlägt proaktiv Verbesserungen vor, basierend auf Best Practices und den neuesten Trends in der Technologie.
Zusätzlich legt der Chatbot Builder großen Wert darauf, nur relevante Informationen und Ratschläge zu liefern, um die Effizienz zu maximieren. Technische Details werden in einer zugänglichen Sprache präsentiert, und nur vertieft, wenn der Benutzer dies ausdrücklich wünscht. Dies stellt sicher, dass Benutzer aller Erfahrungsstufen von der Nutzung profitieren können, ohne von komplexen Details überwältigt zu werden.
Der Chatbot Builder legt auch einen starken Fokus auf die Benutzerfreundlichkeit. Durch eine intuitive Benutzeroberfläche und klare Anleitungen wird sichergestellt, dass Benutzer die notwendigen Schritte leicht verstehen und ausführen können. Eine kontinuierliche Feedback-Schleife ermöglicht es den Benutzern, jederzeit Anpassungen vorzunehmen und sicherzustellen, dass der endgültige Chatbot genau ihren Bedürfnissen entspricht.
Am Ende des Prozesses bietet der Chatbot Builder einen fertigen Prompt an, den der Benutzer kopieren kann.

WICHTIG, der Chatbot Builder hängt an jede Nachrichten den Text "[[Prompt generieren]]" an sobald er genügend informationen hat.

Hinweise:
Der Benutzer bekommt den Hinweis, dass er weitere informationen im reiter "Dokumente" hochladen kann, dies kann er optional tun sobald er den Prompt erhalten hat
Der Chatbot Builder übernimmt keine anderen Aufgaben.
Der Chatbot Builder antwortet auf keine anderen fragen.
Der Chatbot Builder hilft ausschließlich dabei einen Prompt zu erstellen.`;
    this.botMessages.addMessage({
      _id: "",
      openAiMessageId: "",
      context: {
        botid: this.botConfig._id,
        groupid: "",
        user: ""
      },
      openAiThreadId: "",
      memoryEnabled: false,
      created: new Date(),
      role: 'system',
      content: icoBotPrompt
    });
    this.botMessages.addMessage({
      _id: "",
      openAiMessageId: "",
      context: {
        botid: this.botConfig._id,
        groupid: "",
        user: ""
      },
      memoryEnabled: false,
      openAiThreadId: "",
      created: new Date(),
      role: 'assistant',
      content: $localize`:@@ico-wizard-initialmessage:Hallo! Ich werde Ihnen helfen, einen Prompt für Ihren Bot zu erstellen. Sie können etwas mitteilen wie: "Erstelle einen kreativen Bot, welcher beim Texten hilft" oder "Erstelle einen Assistent, der beim Verstehen einer Dokumentation hilft". Sobald genügend informationen vorhanden sind, können Sie [[Prompt generieren]] klicken. Was würden Sie gerne machen?`
    });
  }

  @HostListener('window:click', ['$event'])
  public autoMessage(event: MouseEvent) {
    if ((event.target as HTMLElement).className.includes('message-button') && !this.isThinking) {
      this.promptInput = (event.target as HTMLElement).innerText;
      void this.add();
    }
  }

  public onKey(e: KeyboardEvent) {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        void this.add();
      }
    }
  }

  public async add() {
    this.isThinking = true;
    if (this.promptInput != '') {
      this.botMessages.addMessage({
        _id: "",
        openAiMessageId: "",
        context: {
          botid: this.botConfig._id,
          groupid: "",
          user: ""
        },
        memoryEnabled: false,
        openAiThreadId: "",
        created: new Date(), role: 'user', content: this.promptInput
      });
      void this.bot.scrollDown();
    }
    this.promptInput = '';
    const result = await this.bot.icoWizard(this.botConfig._id, { prompts: this.botMessages.getMessages() });
    const choice = result.choices[0];
    if (choice) this.botMessages.addMessage({
      ...choice.message,
      _id: "",
      openAiMessageId: "",
      context: {
        botid: this.botConfig._id,
        groupid: "",
        user: ""
      },
      openAiThreadId: "",
      memoryEnabled: false,
      created: new Date()
    });
    this.isThinking = false;
    void this.bot.scrollDown();
  }
}