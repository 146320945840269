<rag-overlay-aside
  #overlay
  [skipQueryParam]="true"
  class="text-black"
  *ngIf="this.type === 'overlay'"
>
  <h1 header>Debug-Log</h1>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</rag-overlay-aside>


<div class="d-flex flex-row">
  <div
    id="divider"
    class="bg-light text-center d-flex align-items-center"
    style="border-left: 1px solid rgba(33, 37, 41, 0.25); border-right: 1px solid rgba(33, 37, 41, 0.25); cursor:col-resize"
  >
    <i class="ri-draggable"></i>
  </div>
  <div
    id="content"
    style="width: 30vw;"
    class="d-flex align-items-stretch flex-column"
  >
    <div
      #header
      class="bg-white"
      style="z-index: 100;"
    >
      <div class="row m-0 p-0">
        <div class="col-3 p-0"></div>
        <div class="col-6 p-0">
          <h3 class="text-center">Debug-Log</h3>
        </div>
        <div class="col-3 p-0"> <button class="btn float-end">
            <i
              class="ri-close-line ri-xl text-primary"
              style=" z-index: 150;"
              (click)="this.closed.emit()"
            ></i>
          </button></div>
      </div>
    </div>
    <div
      class="bg-white overflow-scroll px-5 py-3"
      resize="vertical"
      [ngStyle]="{'height': 'calc(100vh - ' + header.clientHeight + 'px)'}"
      *ngIf="this.type === 'fullsize'"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</div>

<ng-template #content>
  @if (this.showOlderLogEntries) {
  <ng-container *ngTemplateOutlet="entryDisplay; context: {$implicit: oldEntries}"></ng-container>
  }
  <div
    class="row text-muted cursor-pointer"
    *ngIf="hasHiddenEntries"
    (click)="this.showOlderLogEntries = !this.showOlderLogEntries"
  >
    <div class="col">
      <hr>
    </div>
    <div class="col-auto">
      @if (this.showOlderLogEntries) {
      Ältere Einträge ausblenden
      }
      @else {
      Ältere Einträge anzeigen
      }
    </div>
    <div class="col">
      <hr>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="entryDisplay; context: {$implicit: newEntries}"></ng-container>
</ng-template>

<ng-template
  #entryDisplay
  let-entries
>
  <div *ngFor="let logEntry of entries; let i = index">
    <ng-container
      class="row"
      *ngIf="logEntry.created.getTime() > initialTimestamp || this.showOlderLogEntries"
    >
      <app-debug-entry [logEntry]="logEntry">
      </app-debug-entry>
    </ng-container>
  </div>
</ng-template>