import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trunk, TrunkItem } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';
import { CounterService } from './counter.service';

export type EnumTestEntry = { name: string, bewertung: string, grund: string };

@Injectable({
  providedIn: 'root'
})
export class TrunkService {
  constructor(private httpClient: HttpClient, private counterService: CounterService) { }

  public async createTrunk(trunk: Omit<Trunk, "_id">) {
    const res = await firstValueFrom(this.httpClient.put<{ status: "ok" | "error", message?: string, trunk: Trunk }>("/api/toolbox/bot/admin/trunk", trunk));
    this.counterService.addTrunk(trunk.botId);
    return res;
  }

  public async createTrunkItems(trunkId: string, trunkItems: Omit<TrunkItem, "trunkId">[]) {
    const res = await firstValueFrom(this.httpClient.put<{ status: "ok" | "error", message?: string, trunkItems: Omit<TrunkItem, "key_embedding">[] }>("/api/toolbox/bot/admin/trunkitem/" + trunkId, trunkItems));
    return res;
  }

  public async getTrunks(botId: string) {
    const res = await firstValueFrom(this.httpClient.get<{ status: "ok" | "error", trunks: (Trunk & { items: TrunkItem[] })[] }>("/api/toolbox/bot/admin/trunk/" + botId));
    this.counterService.setTrunks(botId, res.trunks.filter(t => t.active).length);
    return res;
  }

  public async updateTrunk(trunk: Trunk) {
    const res = await firstValueFrom(this.httpClient.post<{ status: "ok" | "error", message?: string, trunk: Trunk }>("/api/toolbox/bot/admin/trunk", trunk));
    return res;
  }

  public async updateTrunkItem(trunkItem: Omit<TrunkItem, "key_embedding" | "trunkId">) {
    const res = await firstValueFrom(this.httpClient.post<{ status: "ok" | "error", message?: string, trunkItem: TrunkItem }>("/api/toolbox/bot/admin/trunkitem", trunkItem));
    return res;
  }

  public async deleteTrunk(trunkId: string) {
    const res = await firstValueFrom(this.httpClient.delete<{ status: "ok" | "error", deleted: number }>("/api/toolbox/bot/admin/trunk/" + trunkId));
    return res;
  }

  public async deleteTrunkItem(trunkId: string) {
    const res = await firstValueFrom(this.httpClient.delete<{ status: "ok" | "error", deleted: number }>("/api/toolbox/bot/admin/trunkitem/" + trunkId));
    return res;
  }

  public async enumTest(botId: string, trunkId: string, enumTestString: string): Promise<EnumTestEntry[]> {
    const response = await firstValueFrom(this.httpClient.post<{ result: 'string' }>("/api/toolbox/bot/admin/trunk/enumTest", { botId, trunkId, enumTestString }));
    return JSON.parse(response.result) as EnumTestEntry[];
  }
}
