
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { GeneralToolConfig, ToolConfigParam, ToolConfigWebsiteSearch } from '@reflact/ai-types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SkillIdInputComponent } from 'src/app/shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';
import { AdminService } from 'src/app/shared/services/admin.service';
import { CounterService } from 'src/app/shared/services/counter.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastErrorTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';

@Component({
  selector: 'app-bot-toolconfig',
  standalone: true,
  templateUrl: './online-sources-tab.component.html',
  styleUrl: './online-sources-tab.component.scss',
  imports: [SkillIdInputComponent, CommonModule, FormsModule, TooltipModule, RouterModule]
})
export class OnlineSourcesTabComponent {
  public editIndex: number = -1;
  public toolDescription: string = '';
  public toolUrl: string = 'https://';
  public toolType = 'websitesearch' as const;
  private toolConfigParamList: ToolConfigParam[] = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public counter: CounterService,
    private adminService: AdminService,
    public rss: RouteShareService
  ) { }

  public get toolConfigs(): ToolConfigWebsiteSearch[] {
    return this.rss.botConfig.toolConfigs.filter(tc => tc.tool === 'websitesearch' || tc.tool === 'schnittstelle') as ToolConfigWebsiteSearch[];
  }

  // findet heraus ob alle parameter die konfiguriert sind auch in der url vorkommen
  public allParametersInUrl(tc: ToolConfigWebsiteSearch) {
    return tc.params.find(param => !tc.url.includes(`{${param.key}}`));
  }

  public async addToolConfig() {
    const toolConfig: GeneralToolConfig = {

      description: this.toolDescription.trim(),
      tool: "schnittstelle",
      id: '',
      skillId: '',
      enabled: true,
      params: this.toolConfigParamList
    };
    (toolConfig as ToolConfigWebsiteSearch).url = this.toolUrl.trim();

    await this.adminService.addWebsitesearch(this.rss.botConfig._id, toolConfig as ToolConfigWebsiteSearch);
    this.rss.botConfig.toolConfigs.unshift(toolConfig);
    this.counter.addTool(this.rss.botConfig._id);
    this.toastr.success(ToastSuccessTitle.SAVED);
    this.toolDescription = '';
    this.toolUrl = 'https://';
    this.toolConfigParamList = [];
  }


  public removeToolConfig(id: string) {
    const index = this.rss.botConfig.toolConfigs.findIndex((tc) => {
      return tc.id === id;
    });
    this.rss.botConfig.toolConfigs.splice(index, 1);
    void this.rss.saveBotConfig();
  }

  public isConfigValid(): boolean {
    if (this.toolUrl.trim() === '') return false;
    if (this.toolDescription.trim() === '') return false;
    return true;
  }

  public showMDPreview(toolId: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/mdpreview', this.rss.botConfig._id, toolId]));
    window.open(url, '_blank');
  }


  public updateToolConfigItem(id: string) {
    const index = this.rss.botConfig.toolConfigs.findIndex((tc) => {
      return tc.id === id;
    });
    const toolIndex = this.toolConfigs.findIndex((tc) => {
      return tc.id === id;
    });
    if (this.toolConfigs[toolIndex] && this.toolConfigs[toolIndex].url.trim() !== '' && this.toolConfigs[toolIndex].description.trim() !== '') {
      this.rss.botConfig.toolConfigs[index] = this.toolConfigs[toolIndex];
      void this.rss.saveBotConfig();
    } else {
      this.toastr.error(ToastErrorTitle.SAVED, 'Die neue Konfiguration ist nicht vollständig');
    }
  }

  public saveToolConfig(tool: ToolConfigWebsiteSearch) {
    this.updateToolConfigItem(tool.id);
  }


}
