import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FileUploadService } from '../../services/file-upload.service';
import { LoginService } from '../../services/login.service';
import { RouteShareService } from '../../services/route-share.service';

export const documentResolver: ResolveFn<boolean> = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const fileUploadService: FileUploadService = inject(FileUploadService);
  const rss: RouteShareService = inject(RouteShareService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  const id = route.parent?.params['id'] as string;

  rss.documents = (await fileUploadService.getFiles(id)).docs;

  const docId = route.firstChild?.params['docid'] as string;
  if (docId) {
    rss.document = rss.documents.find(doc => doc._id === docId);
    rss.documentContent = await fileUploadService.getFileContents(docId);
  } else {
    rss.document = undefined;
    rss.documentContent = undefined;
  }
  return true;
};
