
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import StreamingAvatar, { AvatarQuality, StreamingEvents, VoiceEmotion } from '@heygen/streaming-avatar';
import { waitSeconds } from '@reflact/tsutil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BotPromptComponent } from 'src/app/shared/components/ai-bot/utils/bot-prompt/bot-prompt.component';
import { DisconnectOverlayComponent } from 'src/app/shared/components/disconnect-overlay/disconnect-overlay.component';
import { AvatarService } from 'src/app/shared/services/avatar.service';
import { BOTEVENT, BotService } from 'src/app/shared/services/bot.service';
import { IframeCommunicationService } from 'src/app/shared/services/iframe-communication/iframe-communication.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { PlayAudio } from 'src/app/shared/services/playaudio.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { SpeechService } from 'src/app/shared/services/speech.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { PushToTalkRecordButtonComponent } from "../../../shared/components/ai-bot/utils/push-to-talk-record-button/push-to-talk-record-button.component";
import { BotBasedComponent } from "../bot-based/bot-based.component";
import { AvatarFrameService } from './avatar-frame.service';
//import { SpeechRecognition } from './avatar.component.types';
import { BotMessagesService } from 'src/app/shared/services/bot-messages.service';
import { StatisticService } from '../admin/tabs/insights/statistics-tab/statistic.service';
import { DeltaSpeak } from './DeltaSpeak';
import { SpeakWithPause } from './SpeakWithPause';
//declare let webkitSpeechRecognition: { prototype: SpeechRecognition; new(): SpeechRecognition };

@Component({
  styleUrl: './avatar.component.scss',
  templateUrl: './avatar.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, BotPromptComponent, PushToTalkRecordButtonComponent, DisconnectOverlayComponent],
  providers: []
})
export class AvatarComponent extends BotBasedComponent implements OnInit {
  @ViewChild("mediaElement") private mediaElement!: ElementRef<HTMLVideoElement>;
  public viewState: "INITIAL" | "CONNECTING" | "CONNECTED" = "INITIAL";
  public avatarState: "INITIAL" | "LISTENING" | "IDLE" | "ERROR" = "INITIAL";
  public botWords: string = '';
  public myWords: string = '';
  public hasMsgBeenSend = false;
  public avatarPreviewUrl: string | undefined = '';
  public lastStatusMesage = '';
  public istalking = false;
  public isRecordingByHold = false;
  public deltaSpeak?: DeltaSpeak | null;
  public avatar: StreamingAvatar | null = null;
  public speakWithPauseUtil?: SpeakWithPause;

  public isInitialMessageHandlingDoneForAvatarConnect: boolean = false;

  constructor(
    public override route: ActivatedRoute,
    public override loginService: LoginService,
    public override speechService: SpeechService,
    public override statisticsService: StatisticService,
    public override iframeCommunication: IframeCommunicationService,
    public override playService: PlayAudio,
    public override bot: BotService,
    public override socketService: SocketService,
    public override toastr: ToastrService,
    public override modalService: BsModalService,
    private cdRef: ChangeDetectorRef,
    private avatarFrameService: AvatarFrameService,
    public avatarService: AvatarService,
    protected override botMessages: BotMessagesService
  ) {
    super(route, loginService, speechService, statisticsService, iframeCommunication, playService, bot, socketService, toastr, modalService, botMessages);
    void this.avatarService.getAvatarPreviewImage(this.botConfig.heyGenAvatarId).then((url) => {
      this.avatarPreviewUrl = url;
    });

  }

  public override ngOnInit(): Promise<void> {
    console.log("subscribung welcome MSG AAAAA");
    this.bot.botEvents.subscribe((event) => {
      if (event.type === BOTEVENT.WelcomeAndHistoryFinished) {
        void this.initAfterWelcomeAndHistory();
      }
    });
    return super.ngOnInit();
  }

  public async initAfterWelcomeAndHistory() {
    const candidateMsg = this.botMessages.findLatestAssistantMessage();
    this.isInitialMessageHandlingDoneForAvatarConnect = true;

    if (candidateMsg != undefined) {
      const content = candidateMsg.content;
      await this.repeatRecursiveIfNotConnectedYet(content);
    }
    this.socketService.onRunUpdate.subscribe((event) => {
      if (this.deltaSpeak != null) {
        if (this.speakWithPauseUtil != undefined) {
          this.speakWithPauseUtil.destroy();
          this.speakWithPauseUtil = undefined;
        }
        this.deltaSpeak.deltaRecieved(event.deltaStr);
      }
    });

    this.socketService.onStateUpdate.subscribe((event) => {
      if (event.sessionState === "idle") {
        const candidateMsg = this.botMessages.findLatestAssistantMessage();
        if (candidateMsg?.role == "assistant") { // Letzte Nachricht fertig. zu ende vorlesen
          this.deltaSpeak?.messageComplete();
        }
      }
    });
  }
  // Create a new WebRTC session when clicking the "New" button
  public async createNewSession() {
    this.viewState = "CONNECTING";

    if (!this.isInitialMessageHandlingDoneForAvatarConnect) {
      // Hier warten bis Welcome und initialZeuch verabrbeitet wurde
      await waitSeconds(1);
      console.log("Wait for session untilbot is ready ");
      return void this.createNewSession();
    }
    this.avatar = await this.avatarFrameService.getAvatar(this.botConfig._id);
    this.deltaSpeak = new DeltaSpeak(this.avatar);
    this.lastStatusMesage = $localize`:@@avatarConnecting:Verbindungsaufbau... Bitte warten`;

    this.avatar.createStartAvatar({
      avatarName: this.botConfig.heyGenAvatarId,
      quality: AvatarQuality.High,
      voice: {
        voiceId: this.botConfig.heyGenVoiceId,
        emotion: VoiceEmotion.SOOTHING,
        rate: 1
      }
    }).then(async (sessionId: string) => {
      (await this.socketService.getIo()).emit("registerAvatarSession", { sessionId });

      this.getAvatar().on(StreamingEvents.AVATAR_START_TALKING, () => {
        this.istalking = true;
        this.hasMsgBeenSend = true;
      });

      this.getAvatar().on(StreamingEvents.AVATAR_STOP_TALKING, () => {
        this.istalking = false;
        this.hasMsgBeenSend = false;
      });

      this.getAvatar().on(StreamingEvents.STREAM_DISCONNECTED, () => {
        this.lastStatusMesage = $localize`:@@avatarStreamDisconnected:Verbindung zum Avatar unterbrochen`;
        this.viewState = 'INITIAL';
        this.avatarState = 'INITIAL';
      });

      this.getAvatar().on(StreamingEvents.STREAM_READY, () => {
        this.mediaElement.nativeElement.srcObject = this.getAvatar().mediaStream;
        this.lastStatusMesage = $localize`:@@avatarStreamReady:Sitzungserstellung abgeschlossen`;

        if (this.botConfig.welcomeType == 'None') {
          this.viewState = 'CONNECTED';
          this.avatarState = 'IDLE';
        }
      });
    }).catch(() => {
      this.lastStatusMesage = $localize`:@@avatarHeygenError:Fehler bei der Heygen Verbindung`;
    });
  }

  public onResultRecording(str: string) {
    this.myWords = str;
  }

  public async closeConnectionHandler() {
    await this.getAvatar().stopAvatar();
    window.location.reload();
  }

  public layoutInitial(): void {
    this.viewState = "INITIAL";
    this.lastStatusMesage = '';
  }

  public async repeatRecursiveIfNotConnectedYet(content: string) {
    if (this.avatar != null && this.getAvatar().mediaStream != null) {
      this.viewState = "CONNECTED";
      this.istalking = true;
      this.speakWithPauseUtil = new SpeakWithPause(this.avatar, content);
    } else {
      await waitSeconds(1);
      await this.repeatRecursiveIfNotConnectedYet(content);
    }
  }

  private showBotMessage(msg: string) {
    this.botWords = msg;
    this.myWords = '';
    this.cdRef.detectChanges();
  }

  public sendMsg(words: string) {
    this.botState = "thinking";
    this.myWords = words;
    this.hasMsgBeenSend = true;
    void this.add(this.myWords);
    setTimeout(() => {
      this.myWords = '';
    }, 1000);
  }

  public async killAllBotCommunicationImidiatly() {
    await this.cancelThinking();
    this.deltaSpeak?.destroy();
    if (this.istalking) {
      void this.getAvatar().interrupt();
    }
    this.hasMsgBeenSend = false;
  }

  public getAvatar() {
    if (this.avatar != null) {
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      window.avatar = this.avatar;
      return this.avatar;
    }
    throw new Error("Avatar not ready yet");
  }

  public getHeaderFooterWidth(maxHeight: number) {
    return Math.min(this.getContentWidth(), maxHeight * 16 / 9);
  }

  public getContentWidth(): number {
    return Math.min(document.body.clientWidth, 1280) - 32;
  }

  public getContentMaxHeight(contentOffset: number): number {
    return document.body.clientHeight - contentOffset - 32;
  }
}