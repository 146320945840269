import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, OnInit, Output, signal, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BotMessageFeedback, ChatbotConfig, HistoryItem } from '@reflact/ai-types';
import { MarkdownModule } from 'ngx-markdown';
import { HighlightService } from 'src/app/shared/services/highlight.service';
import { ToastErrorTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { ICON_PAUSE } from '../../../../../../routes/ai-bot/avatar/SpeakWithPause';
import { LoginService } from '../../../../../services/login.service';
import { SocketService } from '../../../../../services/socket.service';
import { AudioBtnComponent } from '../audio-btn/audio-btn.component';

export type AiAnnotation = {
  type: "file_citation",
  text: string,
  start_index: number,
  end_index: number,
  file_citation?: {
    file_id: string
  }
}

export type Remark = BotMessageFeedback["remark"] | "";
@Component({
  selector: 'app-message-bubble',
  standalone: true,
  imports: [
    AudioBtnComponent,
    MarkdownModule,
    CommonModule,
    FormsModule
  ],
  templateUrl: './message-bubble.component.html',
  styleUrl: './message-bubble.component.scss'
})
export class MessageBubbleComponent implements OnInit {
  @Input({ required: true }) public message!: HistoryItem;
  @Input({ required: true }) public bot!: ChatbotConfig;
  @Input() public remark: Remark = '';
  @Input() public annotations: AiAnnotation[] = [];
  @Input() public isMsgFromHistory: boolean = false;
  @Input() public botChatMsgSourceOption: 'hidden' | 'visible' | 'linking' = 'hidden';
  @Input() public voiceOutputActive: boolean = false;
  @Input() public enableFeedback: boolean = false;
  @Input() public enableDeletion: boolean = false;
  @Input() public isThinking: boolean = false;
  @Output() public sendFeedback = new EventEmitter<{ remark: Remark, msgId: string }>();
  @Output() public deleteMessage = new EventEmitter<HistoryItem>();
  @Input() public debugMode: boolean = false;


  public isHighlighted: Signal<boolean> = computed(() => { return this.message.openAiMessageId == this.highlightService.messageIdSource(); });
  public deleting: boolean = false;
  public buttons: string[] = [];
  public hasButtonsAtEnd: boolean = false;
  public messageContentWithLinks: string = '';

  public cleanMessageContent = signal("");
  constructor(public loginService: LoginService, private highlightService: HighlightService, private toastr: ToastrService, private socketService: SocketService) {
    this.socketService.onRunUpdate.subscribe((data) => {
      if (data.openAiMessageId === this.message.openAiMessageId) {
        this.updateMessageContent(data);
      }
    });
  }

  public ngOnInit(): void {
    this.updateMessageContent(this.message);
  }

  public highlightEntry() {
    if (!this.isHighlighted()) {
      this.highlightService.highlightMessage(this.message.openAiMessageId);
    } else {
      this.highlightService.highlightMessage("None");
    }
  }

  public updateMessageContent(message: HistoryItem) {
    this.cleanMessageContent.set(this.message.content.replaceAll(ICON_PAUSE, ''));

    this.buttons = this.extractButtonsFromMessage(message.content);
    this.hasButtonsAtEnd = this.hasButtonsAtTheEnd(message.content);
    this.messageContentWithLinks = this.extractMessageWithInnerLinks(message.content);
    this.messageContentWithLinks = this.messageContentWithLinks.replaceAll(ICON_PAUSE, '');
    //    console.log("Update Message content", this.cleanMessageContent());


  }

  public extractMessageWithInnerLinks(message: string) {
    message = message.trim();
    let regex = this.getRegexForButtons();
    let buttons: string = "";
    while (regex.test(message)) {
      const match = regex.exec(message);
      if (match?.[1]) {
        const extractedText = "[[" + match[1] + "]]";
        buttons = extractedText + " " + buttons;
        message = message.slice(0, -extractedText.length).trim();
      }
    }
    if (this.isMsgFromHistory) {
      let sourcing: string = "";
      regex = this.getRegexForSource();
      while (regex.test(message)) {
        const match = regex.exec(message);
        if (match) {
          const extractedText = "【" + match[1] + ":" + match[2] + "†source】";
          sourcing = extractedText + " " + sourcing;
          if (this.botChatMsgSourceOption === 'hidden') {
            message = message.replace(extractedText, '');
          } else if (this.botChatMsgSourceOption === 'visible') {
            message = message.replace(extractedText, ' <i class="ri-chat-quote-line"></i>');
          } else {
            message = message.replace(extractedText, ' <i class="ri-chat-quote-fill"></i>');
          }
        }
      }
    }
    const matches = Array.from(this.getButtonMatches(message));
    for (const match of matches) {
      message = message.replace(match[0], '<span class="text-primary text-decoration-underline cursor-pointer message-button">' + match[1] + '</span>');
    }
    return message;
  }

  public extractButtonsFromMessage(message: string): string[] {
    message = message.trim();
    const regex = this.getRegexForButtons();
    const buttons: string[] = [];
    while (regex.test(message)) {
      const match = regex.exec(message);
      if (match?.[1]) {
        const extractedText = match[1];
        buttons.unshift(extractedText);
        message = message.slice(0, -(extractedText.length + 4)).trim();
      }
    }
    return buttons;
  }

  public hasButtonsAtTheEnd(message: string): boolean {
    const regexp = this.getRegexForButtons();
    return regexp.test(message.trim());
  }

  private getButtonMatches(message: string) {
    const regexp = /\[\[(.*?)]]/g;
    return message.matchAll(regexp);
  }

  public getRegexForButtons(): RegExp {
    return /[^[\]]*\[\[([^\]]*)]]$/;
  }

  public getRegexForSource(): RegExp {
    return /【([0-9]+):([0-9]+)†source】/;
  }

  public deleteMessageAndLoad(msg: HistoryItem) {
    this.deleting = true;
    this.deleteMessage.emit(msg);
  }

  protected copyMsgToClipboard(msg: string) {
    navigator.clipboard.writeText(msg).then(() => {
      this.toastr.success(ToastSuccessTitle.GENERAL, 'Nachricht kopiert');
    }).catch(() => {
      this.toastr.error(ToastErrorTitle.GENERAL, 'Nachricht konnte nicht kopiert werden');
    });
  }

}
