import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BotMessageFeedback, ChatApiResponse, ChatbotConfig, DialogMessage, HistoryItem, Model } from '@reflact/ai-types';
import { waitSeconds } from '@reflact/tsutil';
import { Dayjs } from 'dayjs';
import { ChatCompletion } from 'openai/resources';
import { Message } from 'openai/resources/beta/threads/messages';
import { firstValueFrom } from 'rxjs';
import { InactiveBotServiceService } from './inactive-bot-service.service';

export type ChatFinishResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  type: "spfcomplete",
  pushedMessage: Message,
  recievedMessage: Message,
  usage: unknown,
  choices: { message: { content: string, role: "assistant" } }[],
}

export enum BOTEVENT {
  ServerSideThreadReady,
  StartWelcomeRun,
  WelcomeAndHistoryFinished,
  FeedbackCreateStart,
  FeedbackCreateEnd,
  FeedbackDeleteStart,
  FeedbackDeleteEnd,
  HistoryMessagesLoadStart,
  HistoryMessagesLoadEnd,
  HistoryMessagesDeleteStart,
  HistoryMessagesDeleteEnd,
  CancelThinkingStart,
  CancelThinkingEnd,
  runStarted,
  runDelta,
  runFinished,
  avatarConnected,
}



@Injectable({
  providedIn: 'root'
})
export class BotService {

  public resetBotDoneEmitter: EventEmitter<void> = new EventEmitter<void>();



  public botEvents: EventEmitter<{ type: BOTEVENT, data: unknown }> = new EventEmitter<{ type: BOTEVENT, data: unknown }>();


  constructor(private http: HttpClient, public inActiveService: InactiveBotServiceService) { }

  public async scrollDown(scrollBehavior: ScrollBehavior = 'smooth') {
    const scrollSelector = '#bot-content', inputId = 'promptInput';
    await waitSeconds(0.1);
    const scrollElement = document.querySelector(scrollSelector);
    if (scrollElement) {
      scrollElement.scrollTo({ top: scrollElement.scrollHeight, behavior: scrollBehavior });
    }

    await this.focusInput(inputId);
  }

  private async focusInput(inputId: string) {
    await waitSeconds(0.1);
    document.getElementById(inputId)?.focus({ preventScroll: true });
  }

  public deleteHistory(botid: string): void {
    this.http.post("/api/toolbox/bot/admin/deleteMyHistory/" + botid, {}).subscribe(() => {
      window.location.reload();
    });
  }

  public async chatFinish(botId: string, runId: string): Promise<ChatFinishResponse> {
    return await firstValueFrom(this.http.post<ChatFinishResponse>("/api/toolbox/membot/user/chatfinish/" + botId + "/" + runId, {}));
  }

  public async runWelcome(botId: string): Promise<{ status: string }> {
    console.log("Trigger Welcome message");
    this.botEvents.emit({ type: BOTEVENT.StartWelcomeRun, data: { botId } });
    return await firstValueFrom(this.http.post<{ status: string }>("/api/toolbox/membot/user/runwelcome/" + botId, {}));
  }

  public async membotUserChat(botId: string, body: { message: string, overrideConfig?: boolean, temperature?: number, model?: Model, bypass?: boolean }) {
    this.inActiveService.botWasActive();
    this.botEvents.emit({ type: BOTEVENT.runStarted, data: { botId, body } });
    return await firstValueFrom(this.http.post<{ runId: string, threadId: string }>("/api/toolbox/membot/user/chat/" + botId, body));
  }

  public async deleteHistoryMessages(botId: string, messageIds: string[]) {
    this.botEvents.emit({ type: BOTEVENT.FeedbackDeleteStart, data: { botId, messageIds } });
    const result = await firstValueFrom(this.http.delete('/api/toolbox/membot/user/memory/messages/' + botId, { body: { messageIds } }));
    this.botEvents.emit({ type: BOTEVENT.FeedbackDeleteEnd, data: { botId, messageIds, result } });
    return result;
  }

  public async getHistoryMessages(botId: string): Promise<{ messageHistory: HistoryItem[] }> {
    this.botEvents.emit({ type: BOTEVENT.HistoryMessagesLoadStart, data: { botId } });
    const messageHistory = await firstValueFrom(this.http.get<{ messageHistory: HistoryItem[] }>('/api/toolbox/membot/user/memory/messages/' + botId));
    this.botEvents.emit({ type: BOTEVENT.HistoryMessagesLoadEnd, data: { botId, messageHistory } });
    console.log("HISTORYITEMS LOADED", messageHistory);
    return messageHistory;

  }

  public async deleteHistoryItems(botId: string, body: { groupIds: string[], fromDate?: Dayjs, toDate?: Dayjs, }): Promise<{ status: number, deletedCount: number }> {
    this.botEvents.emit({ type: BOTEVENT.HistoryMessagesDeleteStart, data: { botId, body } });
    const deleteResult = await firstValueFrom(this.http.post<{ status: number, deletedCount: number }>("/api/toolbox/bot/admin/deleteHistoryItems/" + botId, body));
    this.botEvents.emit({ type: BOTEVENT.HistoryMessagesDeleteEnd, data: { botId, body, deleteResult } });
    return deleteResult;

  }

  public async askSummary(botId: string, body: { prompts: DialogMessage[], groupIds: string[], fromDate?: Dayjs, toDate?: Dayjs, }): Promise<ChatCompletion> {
    return await firstValueFrom(this.http.post<ChatCompletion>("/api/toolbox/membot/user/askSummary/" + botId, body));
  }

  public async getDefaultComplianceText(): Promise<string> {
    return await firstValueFrom(this.http.get('/api/toolbox/bot/admin/defaultComplianceText', { responseType: 'text' }));
  }

  public async getGroupIdsForBot(botId: string): Promise<string[]> {
    return await firstValueFrom(this.http.get<string[]>("/api/toolbox/membot/user/groupsIds/" + botId));
  }

  public async getHistoryInfo(botId: string): Promise<{ status: string, items: { _id: string, count: number }[] }> {
    return await firstValueFrom(this.http.get<{ status: string, items: { _id: string, count: number }[] }>("/api/toolbox/bot/admin/historyInfo/" + botId));
  }

  public async countHistoryItemsByFilter(botId: string, body: { groupIds: string[], fromDate?: Dayjs, toDate?: Dayjs }): Promise<{ status: string, count: number }> {
    return await firstValueFrom(this.http.post<{ status: string, count: number }>("/api/toolbox/bot/admin/countHistoryItemsByFilter/" + botId, body));
  }

  public async icoWizard(botId: string, body: { prompts: DialogMessage[] }): Promise<ChatApiResponse> {
    return await firstValueFrom(this.http.post<ChatApiResponse>("/api/toolbox/bot/admin/icoWizard/" + botId, body));
  }

  public async sendFeedback(feedbackId: string | undefined, feedback: Omit<BotMessageFeedback, '_id' | 'created'>) {
    this.botEvents.emit({ type: BOTEVENT.FeedbackCreateStart, data: { feedbackId, feedback } });
    const result = await firstValueFrom(this.http.post<{ status: string, feedback?: BotMessageFeedback }>('/api/toolbox/feedback/add', { feedbackId, feedback }));
    this.botEvents.emit({ type: BOTEVENT.FeedbackCreateEnd, data: { feedbackId, feedback, result } });
    return result;

  }

  public async deleteFeedback(feedbackId: string) {
    this.botEvents.emit({ type: BOTEVENT.FeedbackDeleteStart, data: { feedbackId } });
    const result = await firstValueFrom(this.http.delete<{ status: string }>('/api/toolbox/feedback/' + feedbackId));
    this.botEvents.emit({ type: BOTEVENT.FeedbackDeleteEnd, data: { feedbackId, result } });
    return result;
  }

  public async getFeedback(botId: string) {
    return await firstValueFrom(this.http.get<{ status: string, feedbacks: BotMessageFeedback[] }>('/api/toolbox/feedback/' + botId));
  }

  public async getMyFeedback(botId: string) {
    return await firstValueFrom(this.http.get<{ status: string, feedbacks: BotMessageFeedback[] }>('/api/toolbox/feedback/' + botId + "/myfeedback"));
  }

  public async sendBotThinkingCancel(botId: string, runId: string, threadId: string) {
    this.botEvents.emit({ type: BOTEVENT.CancelThinkingStart, data: { botId, runId, threadId } });
    const result = await firstValueFrom(this.http.post<{ message: string }>('/api/toolbox/membot/user/cancelthinking/' + botId, { runId, threadId }));
    this.botEvents.emit({ type: BOTEVENT.CancelThinkingEnd, data: { botId, runId, threadId } });
    return result;
  }


  public async resetBot(botId: string) {
    //vorher Emitten sonst fehlt begrüßung
    this.resetBotDoneEmitter.emit();
    const result = await firstValueFrom(this.http.get<{ message: string }>('/api/toolbox/membot/user/resetbot/' + botId));
    return result;
  }


  public async getBotConfigAsUser(id: string) {
    try {
      const botConfig = await firstValueFrom(this.http.get<ChatbotConfig>("/api/toolbox/bot/user/botconfig/" + id));
      return botConfig;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  public async suggestName(description: string): Promise<string> {
    let answer = (await firstValueFrom(this.http.post<{ name: string }>('/api/toolbox/bot/admin/suggestName', { description }))).name;
    if (answer.length > 30) {
      answer = answer.substring(0, 30);
    }
    return answer;
  }



  public getColorFromConfig(botConfig: ChatbotConfig, background: boolean = false): string {
    if (background) {
      return botConfig.color ? botConfig.color + '33' : '#cccccc50';
    } else {
      return botConfig.color ? botConfig.color : '#cccccc';
    }
  }
}

