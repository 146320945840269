import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastErrorTitle, ToastrService } from '../services/toastr.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, public toastrService: ToastrService) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this.toastrService.error(ToastErrorTitle.ERROR_400);
            break;
          case 401:
            this.toastrService.error(ToastErrorTitle.ERROR_401);
            break;
          case 403:
            this.toastrService.error(ToastErrorTitle.ERROR_403);
            break;
          case 404:
            this.toastrService.error(ToastErrorTitle.ERROR_404);
            break;
          case 500:
          case 502:
          case 503:
          case 504:
            this.toastrService.error(ToastErrorTitle.ERROR_500);
            break;
          default:
            this.toastrService.error(ToastErrorTitle.ERROR);
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
}