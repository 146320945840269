import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ValidatorFn, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UploadStatusData } from '@reflact/ai-types';
import { removeFromArray } from '@reflact/tsutil';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { Subscription } from 'rxjs';
import { ChunkContigentIndividualComponent } from 'src/app/shared/components/ai-bot/chunk-contigent-individual/chunk-contigent-individual.component';
import { FileUploadComponent } from 'src/app/shared/components/ai-bot/utils/file-upload/file-upload.component';
import { SkillIdInputComponent } from 'src/app/shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { SocketAdminService } from 'src/app/shared/services/socketadmin.service';
import { ToastErrorTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';

@Component({
  standalone: true,
  templateUrl: './documents-tab.component.html',
  styleUrl: './documents-tab.component.scss',
  imports: [
    CommonModule,
    FormsModule,
    FileUploadComponent,
    SkillIdInputComponent,
    ProgressbarModule,
    RouterModule,
    ChunkContigentIndividualComponent
  ]
})
export class DocumentsTabComponent {
  protected processingDocuments: UploadStatusData[] = [];
  public requiredValidator: ValidatorFn[] = [Validators.required];
  protected subscriptions: Subscription[] = [];

  //Müsste das prefix hier nicht eigentlich suffix heißen?
  public allowedFileTypes: { prefix: string, mimetype: string }[] = [
    { prefix: ".c", mimetype: "text/x-c" },
    { prefix: ".cs", mimetype: "text/x-csharp" },
    { prefix: ".cpp", mimetype: "text/x-c++" },
    { prefix: ".docx", mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
    { prefix: ".html", mimetype: "text/html" },
    { prefix: ".java", mimetype: "text/x-java" },
    { prefix: ".json", mimetype: "application/json" },
    { prefix: ".md", mimetype: "text/markdown" },
    { prefix: ".pdf", mimetype: "application/pdf" },
    { prefix: ".php", mimetype: "text/x-php" },
    { prefix: ".pptx", mimetype: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { prefix: ".py", mimetype: "text/x-python" },
    { prefix: ".py", mimetype: "text/x-script.python" },
    { prefix: ".rb", mimetype: "text/x-ruby" },
    { prefix: ".tex", mimetype: "text/x-tex" },
    { prefix: ".txt", mimetype: "text/plain" },
    { prefix: ".css", mimetype: "text/css" },
    { prefix: ".js", mimetype: "text/javascript" },
    { prefix: ".sh", mimetype: "application/x-sh" },
    { prefix: ".ts", mimetype: "application/typescript" }
  ];

  constructor(
    private fileUploadService: FileUploadService,
    private toastr: ToastrService,
    private socketAdmin: SocketAdminService,
    public rss: RouteShareService,
    public loginService: LoginService
  ) {
    this.subscriptions.push(
      this.socketAdmin.onUploadStatus.subscribe(data => {
        if (data.status === 'processing') {
          if (!this.processingDocuments.find(doc => doc.file._id === data.file._id)) {
            this.processingDocuments.push(data);
          } else {
            const index = this.processingDocuments.findIndex(doc => doc.file._id === data.file._id);
            if (this.processingDocuments[index]?.status === 'complete') return;
            this.processingDocuments[index] = data;
          }
        } else if (data.status === 'complete') {
          const index = this.processingDocuments.findIndex(doc => doc.file._id === data.file._id);
          this.processingDocuments[index] = data;
          this.toastr.success(ToastSuccessTitle.SAVED, data.subStatus);
          void this.loadDocumentList();
        } else {
          this.processingDocuments = this.processingDocuments.filter(doc => doc.file._id !== data.file._id);
          this.toastr.error(ToastErrorTitle.SAVED, data.subStatus);
        }
      })
    );
  }

  protected documentFileName(name: string): string {
    const filenameArr = name.split('.');
    if (filenameArr.length === 2) return name.trim();
    return filenameArr.slice(0, filenameArr.length - 1).join('.').trim();
  }

  protected async loadDocumentList() {
    this.rss.documents = (await this.fileUploadService.getFiles(this.rss.botConfig._id)).docs;
    if (this.rss.document != undefined) {
      // Diese Zeile existiert weil der Typescript Compiler komisch ist
      const currentDocument = this.rss.document;
      this.rss.document = this.rss.documents.find(doc => doc._id === currentDocument._id);
      this.rss.documentContent = await this.fileUploadService.getFileContents(currentDocument._id);
    }
  }

  protected checkUserContigent(): boolean {
    if (!this.loginService.loggedInUser) return false;
    return (this.loginService.loggedInUser.chunkCount >= this.loginService.loggedInUser.chunkContingent) ? true : false;
  }

  protected async downloadDocument(id: string) {
    const doc = this.rss.documents.find(doc => doc._id === id);
    if (!doc) return;
    const docName = this.documentFileName(doc.filename);
    const docContent = await this.fileUploadService.getFileForDownload(doc.metadata.sourceFileId ?? doc._id);
    const docExtension = docName.split('.').pop();
    const docType = this.allowedFileTypes.find(type => type.prefix === '.' + docExtension);
    const blob = new Blob([docContent], { type: docType?.mimetype });
    const fileURL = URL.createObjectURL(blob);
    const element = document.createElement('a');

    element.setAttribute('href', fileURL);
    element.setAttribute('download', docName);
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
    URL.revokeObjectURL(fileURL);
  }

  protected async loadDocumentContent() {
    if (!this.rss.document) return;
    this.rss.documentContent = await this.fileUploadService.getFileContents(this.rss.document._id);
  }

  protected getProcessDocData(fileId: string): UploadStatusData | undefined {
    return this.processingDocuments.find(doc => doc.file._id === fileId);
  }

  protected getProcentFromDoc(fileId: string): string | undefined {
    const doc = this.processingDocuments.find(doc => doc.file._id === fileId);
    if (!doc) return undefined;
    return (doc.current / doc.total * 100).toFixed(2) + ' %';
  }

  protected async removeDocument(id: string): Promise<void> {
    await this.fileUploadService.removeFile(id, this.rss.botConfig._id);
    removeFromArray(this.rss.documents, doc => doc._id === id);
    this.toastr.success(ToastSuccessTitle.DELETED);
  }

  protected async saveDocument(id: string = '') {
    if (id === '') id = this.rss.document?._id ?? '';
    const doc = this.rss.documents.find(doc => doc._id === id);
    if (!doc) return;
    await this.fileUploadService.changeFileProperties(id, doc.metadata.enabledForKnowledge, doc.metadata.skillId, doc.metadata.name, doc.metadata.description, this.rss.botConfig._id);
    this.toastr.success(ToastSuccessTitle.SAVED);
  }
}
