import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { INavData } from '@reflact/ngx-layout/lib/body-layout/sidebar-nav/sidebar-nav.component';
import { getContrastColor } from '@reflact/tsutil';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BotAdminQuerySearchComponent } from 'src/app/shared/components/ai-bot/utils/bot-admin-query-search/bot-admin-query-search.component';
import { DraganddropDirective } from 'src/app/shared/directives/draganddrop.directive';
import { LoginService, logout } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ChunkContingentComponent } from "../../../shared/components/ai-bot/chunk-contingent/chunk-contingent.component";
import { BotAdminDashboardTableComponent } from "../../../shared/components/ai-bot/utils/bot-admin-dashboard-table/bot-admin-dashboard-table.component";
import { AdminService } from '../../../shared/services/admin.service';
import { SocketAdminService } from '../../../shared/services/socketadmin.service';

const sortModes = ["asc-alphabet", "desc-updated", "desc-created"] as const;
export type SortMode = typeof sortModes[number];

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: true,
  imports: [DraganddropDirective, CommonModule, RouterModule, TooltipModule, BsDropdownModule, NgxLayoutModule, BotAdminDashboardTableComponent, FormsModule, BotAdminQuerySearchComponent, ChunkContingentComponent]
})
export class BotAdminComponent implements OnInit {
  @ViewChild('menuEntry', { static: true }) public menuEntry!: TemplateRef<unknown>;
  public bots: ChatbotConfig[] = [];
  public searchString: string = "";
  public currTab: string = "";
  public listedBots: ChatbotConfig[] = [];
  public sortMode: SortMode = "desc-created";
  public navItems: INavData[] = [];
  public getContrastColor = getContrastColor;
  public allowedImportTypes: { prefix: string, mimetype: string }[] = [{ prefix: '.rai', mimetype: 'text/plain' }];
  public selectedImportFile: File | null = null;

  constructor(
    private aRoute: ActivatedRoute,
    private adminService: AdminService,
    private router: Router,
    public socketAdminService: SocketAdminService,
    public rss: RouteShareService,
    private loginservice: LoginService
  ) { }

  public async ngOnInit() {
    this.bots = [...this.rss.botConfigs];
    if (this.bots.length > 0) {
      const sortFromStorage = localStorage.getItem('adminSortingSelection');
      if (sortFromStorage == null || !sortModes.includes(sortFromStorage as SortMode)) {
        this.sortMode = "desc-created";
        localStorage.setItem('adminSortingSelection', this.sortMode);
      } else {
        this.sortMode = sortFromStorage as SortMode;
      }
      await new Promise(r => setTimeout(r, 25));
      this.buildNavFromBotConfigs();
    }
  }

  public logout(): void {
    logout();
  }

  public async loadBots() {
    this.bots = await this.adminService.getBots();
    this.buildNavFromBotConfigs();
  }

  public async deleteBot(id: string) {
    await this.adminService.deleteBot(id);
    this.bots = this.bots.filter(bot => bot._id !== id);
  }

  public async addBot() {
    const newBot: ChatbotConfig = await this.adminService.addBot();
    this.bots.push(newBot);
    await this.router.navigate(['/bot-admin/' + newBot._id]);
  }

  public testBotSheet(bot: ChatbotConfig) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/bot-sheet/' + bot._id]));
    window.open(url, '_blank');
  }

  public buildNavFromBotConfigs() {
    this.navItems = [];
    this.navItems.push({ name: $localize`:@@my-bots:Meine Bots`, href: "", title: false, template: this.menuEntry, icon: 'ri-robot-2-fill' });

    const addedOrgs: string[] = [];
    this.bots.forEach(bot => {
      if (bot.orgShort !== '' && !addedOrgs.includes(bot.orgShort)) {
        addedOrgs.push(bot.orgShort);
        this.navItems.push({ name: bot.orgShort, href: "org:" + bot.orgShort, title: false, template: this.menuEntry, icon: 'ri-organization-chart' });
      }
    });

    this.navItems.push({ name: $localize`:@@archive:Archiv`, href: "archived:true", title: false, template: this.menuEntry, icon: 'ri-archive-line' });
    this.navItems.push({ name: $localize`:@@trashbin:Papierkorb`, href: "trashed:true", title: false, template: this.menuEntry, icon: 'ri-delete-bin-line' });

    const allTags = this.bots.flatMap((bot) => (!bot.archived && !bot.thisIsTrash) ? bot.tagList : []);
    const uniqueTags = Array.from(new Set(allTags));
    if (uniqueTags.length > 0) {
      this.navItems.push({ name: $localize`:@@sort-by-tags:nach Tags`, title: true });
    }
    uniqueTags.sort((a, b) => a.localeCompare(b));
    uniqueTags.forEach(tag => {
      this.navItems.push({
        name: tag,
        icon: 'ri-hashtag',
        template: this.menuEntry,
        href: `tag:'${tag}'`,
        title: false
      });
    });
  }

  public onTagClick(tag: string) {
    this.searchString = `tag:'${tag}'`;
  }

  protected async deleteAllTrashed(): Promise<void> {
    const trashedBots = this.bots.filter(bot => bot.thisIsTrash && bot.creator === this.loginservice.loggedInUser?._id);
    for (const bot of trashedBots) {
      await this.deleteBot(bot._id);
    }
  }

  public onImportFileDropped(files: FileList) {
    if (files.length == 1) {
      const file = files.item(0);
      if (file) {
        this.importBotConfig(file);
      }
    }
  }

  public onImportFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.onImportFileDropped(fileList);
    }
  }

  private importBotConfig(file: File) {
    const fileReader = new FileReader();
    fileReader.onloadend = async () => {
      const botConfig = JSON.parse(fileReader.result as string) as ChatbotConfig;
      const newBot: ChatbotConfig = await this.adminService.importBot(botConfig);
      this.bots.push(newBot);
      await this.router.navigate(['/bot-admin/' + newBot._id]);
    };
    fileReader.readAsText(file);
  }
}
