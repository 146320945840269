<h3 i18n="@@offline-websites">Offline-Websites</h3>
<app-hint-memory-required
  *ngIf="!this.rss.botConfig.memoryEnabled"
  [botConfig]="this.rss.botConfig"
></app-hint-memory-required>
<div
  class="callout callout-info"
  i18n="@@offline-websites-description"
>
  Über Offline-Websites können Sie dem Bot Webseiten und inkludierte Unterseiten zur Verfügung stellen, die er bei der
  Beantwortung von Fragen berücksichtigen soll. Hierbei wird die Webseite und alle erfassten Unterseiten entsprechend
  der
  Seitentiefe zum Zeitpunkt des Hinzufügens abgerufen und gespeichert.
</div>
<div class="card border-0">
  <div class="card-body">
    <div style="max-width: 500px;">
      <span i18n="page-url-hint">
        Geben Sie hier eine Website für die Offline-Verfügbarkeit ein.
      </span>
      <input
        class="form-control "
        placeholder="URL"
        [(ngModel)]="this.offlineWebUrl"
        [ngClass]="{'is-invalid': !this.isUrlValid(this.offlineWebUrl)}"
      >
      <span i18n="page-depth-hint">
        Geben Sie hier die Seitentiefe ein, wie tief die Verlinkungen verfolgt werden sollen.
      </span>
      <input
        class="form-control"
        placeholder="Seitentiefe"
        type="number"
        min="0"
        max="10"
        step="1"
        [(ngModel)]="this.offlineWebDepth"
      >
      <span i18n="@@how-is-the-website-used">Bitte erläutern Sie der KI, wofür diese Webseite genutzt werden
        soll:</span>
      <input
        class="form-control rag-aitext"
        [(ngModel)]="this.offlineWebDescription"
      >
    </div>
    <div
      class="btn btn-primary btn-sm mt-2"
      [ngClass]="{'disabled': !this.isConfigValid()}"
      (click)="this.addOfflineWeb()"
    >
      <i class="ri-add-line"></i> <ng-container i18n="@@add">Hinzufügen</ng-container>
    </div>
  </div>
</div>
<h5
  class="mt-5"
  *ngIf=" this.rss.webscrapes.length > 0"
  i18n="@@avaliable-weppages"
>Verfügbare Websites im Überblick</h5>
<div class="full-width-table-wrapper">
  <table class="table table-hover">
    <tbody>
      @for (webscrape of this.rss.webscrapes; track $index) {
      <tr>
        <td style="width: 4rem; text-align: center; vertical-align: middle;">
          @if (webscrape.loadError) {
          <i
            class="ri-alert-fill text-danger"
            tooltip="Beim laden ist ein Fehler aufgetreten! Dieser Eintrag kann gelöscht werden."
          ></i>
          }@else if (this.scrapeIsRunning(webscrape._id)) {
          <span class="spinner-border spinner-border-sm ms-1"></span>
          }
          @else {
          <i
            class="ri-circle-fill text-primary"
            *ngIf="webscrape.active"
          ></i>
          <i
            class="ri-circle-line text-secondary"
            *ngIf="!webscrape.active"
          ></i>
          }
        </td>
        <td style="vertical-align: middle;">
          <span
            class="cursor-pointer text-decoration-underline text-primary"
            [routerLink]="[webscrape._id]"
            *ngIf="!this.scrapeIsRunning(webscrape._id)"
          >
            {{webscrape.url}}
          </span>
          <span *ngIf="this.scrapeIsRunning(webscrape._id)">
            {{webscrape.url}}
          </span>
        </td>
        <td
          style="vertical-align: middle;width:1px"
          class="text-end"
        >
          <app-chunk-contigent-individual [chunkCount]="webscrape.chunkCount"></app-chunk-contigent-individual>
        </td>
        <td style="vertical-align: middle;width:250px">

          <app-skill-id-input
            [value]="webscrape.skillId"
            (changed)="webscrape.skillId = $event; this.service.updateWebscrapeConfig(webscrape)"
          ></app-skill-id-input>
        </td>
        <td style="vertical-align: middle; width: 2rem;">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              (ngModelChange)="webscrape.active = !webscrape.active; this.service.updateWebscrapeConfig(webscrape)"
              [(ngModel)]="webscrape.active"
            >
          </div>
        </td>
        <td
          style="vertical-align: middle; width: 2rem;"
          class="text-end"
        >
          <div
            class="dropdown"
            style="width: 2rem;"
          >
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  [routerLink]="[webscrape._id]"
                  [disabled]="this.scrapeIsRunning(webscrape._id)"
                >
                  <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-danger"
                  (click)="this.deleteWebscrapes(webscrape)"
                  [disabled]="this.scrapeIsRunning(webscrape._id)"
                >
                  <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>