<div class="container alert alert-primary mt-3">
  <div class="row">
    <div class="col">
      <h1 i18n="@@clustering">
        Clustering
      </h1>
    </div>
  </div>

  <div class="row mt-2 align-items-center ">
    <div
      class="col-3 "
      i18n="@@temperature"
    >
      Temperatur
    </div>
    <div
      class="col-auto pe-0 text-end lock-sensitive"
      i18n="@@temperature-precise"
    >Präzise</div>
    <div class="col-3 lock-sensitive">
      <ngx-slider
        [(ngModel)]="this.data.temperature"
        [options]="{floor: 0, ceil: 1.5, step: .1}"
      ></ngx-slider>
    </div>
    <div
      class="col-auto ps-0 lock-sensitive"
      i18n="@@temperature-creative"
    >Kreativ</div>
  </div>

  <div class="row mt-2 align-items-center">
    <div
      class="col-3"
      i18n="@@model"
    >
      Model
    </div>
    <div class="col-auto">
      <select
        class="form-select"
        [(ngModel)]="this.data.model"
        [ngModelOptions]="{updateOn: 'blur'}"
      >
        <option
          *ngFor="let model of gptModels"
          [value]="model"
        >{{ model }}</option>
      </select>
    </div>
  </div>

  <div class="row mt-2 align-items-center">
    <div class="col-3">
      <span i18n="@@prompt">Prompt</span>
      <app-hint i18n="@@prompt-hint">
        Hier können Sie dem Bot Instruktionen zu seiner Funktion/Aufgabe eintragen.
      </app-hint>
    </div>
    <div class="col">
      <textarea
        [(ngModel)]="this.data.prompt"
        class="form-control"
        rows="5"
      ></textarea>
    </div>
  </div>

  <div class="row mt-2 align-items-center">
    <div class="col-3">
      <span i18n="@@cluster-message">
        Cluster Nachricht
      </span>
      <app-hint i18n="@@cluster-message-hint">
        Vorlage für die Chat-Nachricht an den Bot.<br><br>
        Der Platzhalter [thema] beinhaltet das jeweilige Thema.<br><br>
        Der Platzhalter [kommentare] beinhaltet die zu clusternden Texte.<br><br>
        Jedem Kommentar wird "Kommentar n:" vorangestellt, damit die KI den einzelnen Kommentar korrekt erkennt.
      </app-hint>
    </div>
    <div class="col">
      <textarea
        [(ngModel)]="this.data.chatTemplate"
        class="form-control"
        rows="5"
      ></textarea>
    </div>
  </div>

  <div class="row mt-2">
    <div
      class="col-3"
      i18n="@@text-source"
    >
      Textquelle (.xls, .xlsx)
      <app-hint>
        Pro Spalte wird 1 Cluster gebildet. Die erste Zeile beinhaltet die Spaltenüberschriften.
      </app-hint>
    </div>
    <div class="col">
      <rag-excel-import
        [config]="[]"
        [demoDownloadHidden]="true"
        [importButton]="{ label: 'Excel auswählen', icon: true, class: 'ri-import-fill' }"
        (import)="this.onExcelInput($event)"
      >
      </rag-excel-import>

      <ul>
        <li *ngFor="let key of this.data.clusterData.keys()">{{key}}</li>
      </ul>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-3">
      <span i18n="@@additional-files">
        Weitere Dateien
      </span>
      <app-hint i18n="@@additional-files-hint">
        Dateien, die das Wissen des Bots erweitern.
      </app-hint>
    </div>

    <div class="col">
      <div
        appDnd
        (fileDropped)="this.onFileDropped($event)"
      >
        <ng-template #supportedFiles>
          <b i18n="@@supported-file-formats">
            Unterstützte Dateiformate:</b>
          <span *ngFor="let type of this.allowedFileTypes; last as isLast">
            <ng-container *ngIf="isLast">{{ type.prefix }}</ng-container>
            <ng-container *ngIf="!isLast">{{ type.prefix }}, </ng-container>
          </span></ng-template>
        <div
          [tooltip]="supportedFiles"
          class="text-center upload btn"
          [ngClass]="{
            'btn-outline-primary': this.selectedFiles.length > 0,
            'btn-primary': this.selectedFiles.length === 0
            }"
          (click)="fileInput.click()"
        >
          <i class="ri-file-upload-line ri-5x"></i>
          <input
            type="file"
            class="file-input"
            multiple
            (change)="this.showSelectedFile($event)"
            [(ngModel)]="this.tempPath"
            [multiple]="true"
            #fileInput
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-2"
    *ngIf="this.selectedFiles.length"
  >
    <div class="col-9 offset-3">
      <div
        class="row mb-1"
        *ngFor="let file of this.selectedFiles"
      >
        <div class="col-5">{{file.name}}</div>
        <div class="col-2">{{file.size | convSize}}</div>
        <div class="col">
          <div
            class="btn btn-danger btn-sm"
            (click)="this.removeSelectedFile(file)"
          >
            <i class="ri-delete-bin-line"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col">
      <div
        [class]="{disabled:this.isLoading}"
        class="btn btn-primary"
        (click)="this.clustering()"
      >
        <span i18n="@@btn-cluster">Clustern</span>
        <i
          *ngIf="this.isLoading"
          class="ri ri-loader-2-line"
        ></i>
      </div>
    </div>
  </div>
</div>
