import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SkillState } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';


export type SkillTreeItem = {
  skillId: string;
  parent?: SkillTreeItem;
  ancestors: SkillTreeItem[];
  children: SkillTreeItem[];
  descendants: SkillTreeItem[];
  path: string[]
}

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  public skillUserApi = "/api/toolbox/skill/user";

  constructor(protected http: HttpClient) {

  }

  public async getSkills(botId: string) {
    return await firstValueFrom(this.http.get<SkillState[]>(`${this.skillUserApi}/state/${botId}`));
  }

  public async enableSkills(botId: string, skillIds: string[]) {
    skillIds = skillIds.filter((skillId) => skillId !== '');
    return await firstValueFrom(this.http.post<{ skills: SkillState[] }>(`${this.skillUserApi}/enable/${botId}`, { skillIds }));
  }

  public async disableSkills(botId: string, skillIds: string[]) {
    skillIds = skillIds.filter((skillId) => skillId !== '');
    return await firstValueFrom(this.http.post<{ skills: SkillState[] }>(`${this.skillUserApi}/disable/${botId}`, { skillIds }));
  }

  public async enableSkillAndChildren(botId: string, skillId: string, depth = Number.MAX_SAFE_INTEGER) {
    return await this.enableSkills(botId, await this.getSkillIdChildren(botId, skillId, depth));
  }

  public async disableSkillAndChildren(botId: string, skillId: string, depth = Number.MAX_SAFE_INTEGER) {
    return await this.disableSkills(botId, await this.getSkillIdChildren(botId, skillId, depth));

  }

  public async getSkillIdChildren(botId: string, skillId: string, depth: number = Number.MAX_SAFE_INTEGER): Promise<string[]> {
    const skills = await this.getSkills(botId);
    const skillTree = this.skillIdsToTreeItems(skills.map((skill) => skill.skillId));;
    const skillInTree = skillTree.find(ti => ti.skillId === skillId);
    if (!skillInTree) {
      return [];
    }
    const result = this.getChildSkillsRecursive(skillInTree, depth);
    return result;

  }

  private getChildSkillsRecursive(skillTree: SkillTreeItem, targetDepths: number, depth: number = 0): string[] {
    const result = [skillTree.skillId];
    if (targetDepths == depth) {
      return result;
    }
    result.push(...skillTree.children.map((child) => child.skillId),
      ...skillTree.children.map((child) => this.getChildSkillsRecursive(child, targetDepths, depth + 1)).flat()
    );
    return result;
  }

  public getTreeFromSkillIds(skillIds: string[]) {
    return this.skillIdsToTreeItems(skillIds).filter((skill) => skill.ancestors.length === 0);
  }

  public skillIdsToTreeItems(skillIds: string[]) {
    const treeItems: SkillTreeItem[] = skillIds.map((skillId) => {
      return {
        path: this.getSkillPath(skillId),
        children: [],
        descendants: [],
        ancestors: [],
        skillId: skillId
      };
    });

    for (const item of treeItems) {
      const parent = treeItems.find((parentItem) => {
        return this.isDirectParent(parentItem, item);
      });
      if (parent) {
        item.parent = parent;
        parent.children.push(item);
      }

      const ancestors = treeItems.filter((parentItem) => {
        console.log(`isAncestor ${parentItem.skillId} -> ${item.skillId} = ${"" + this.isDirectOrIndirectParent(parentItem, item)}`);

        return this.isDirectOrIndirectParent(parentItem, item);
      });
      for (const ancestor of ancestors) {
        item.ancestors.push(ancestor);
        ancestor.descendants.push(item);
      }
    }

    return treeItems;
  }

  private isDirectOrIndirectParent(parent: SkillTreeItem, child: SkillTreeItem) {
    if (parent.skillId === child.skillId || parent.path.length >= child.path.length) {
      return false;
    }
    for (let i = 0; i < parent.path.length; i++) {
      if (parent.path[i] !== child.path[i]) {
        return false;
      }
    }
    return true;
  }
  private isDirectParent(parent: SkillTreeItem, child: SkillTreeItem) {
    if (parent.skillId === child.skillId || parent.path.length >= child.path.length) {
      return false;
    }
    for (let i = 0; i < child.path.length - 1; i++) {
      if (parent.path[i] !== child.path[i]) {
        return false;
      }
    }
    return true;
  }
  public getSkillDepth(skillId: string) {
    return this.getSkillPath(skillId).length - 1;
  }
  public getSkillPath(skillId: string): string[] {
    const path: string[] = [];
    let lastWasNum = false;
    let lastChars = "";
    for (const c of skillId) {
      if (!this.isNum(c)) {
        lastWasNum = false;
        lastChars += c;
      } else {
        if (lastWasNum) {
          const lastValue = path.at(path.length - 1);
          if (lastValue !== undefined) {
            path[path.length - 1] = lastChars + "" + lastValue + c;
            lastChars = "";
          }
        } else {
          path.push(lastChars + c);
          lastChars = "";
        }
        lastWasNum = true;
      }
    }
    if (path.length === 0) {
      path.push(skillId);
    }
    console.log(skillId, path);

    return path;
  }
  private isNum(char: string) {
    return char >= '0' && char <= '9';
  }
}