import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  public messageIdSource = signal("None");
  public debugIdSource = signal("None");

  public highlightMessage(messageId: string) {
    this.messageIdSource.set(messageId);
  }
}
