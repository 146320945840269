import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { getToken } from '../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private route: ActivatedRoute) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has("portaltoken")) {
      return next.handle(request);
    }

    const headers: Record<string, string | string[]> = {};

    let localStorageKey = 'token';
    if (!request.url.includes("/api/toolbox/stats/debug/") && this.route.snapshot.queryParamMap.has('uniqueSession')) {
      localStorageKey = this.route.snapshot.queryParamMap.get('uniqueSession') ?? '';
    }

    const token = getToken(localStorageKey);
    if (token !== null) {
      headers['token'] = token;
    }

    if (this.route.snapshot.queryParamMap.has('groupid')) {
      headers['groupid'] = this.route.snapshot.queryParamMap.get('groupid') ?? '';
    }

    if (Object.keys(headers).length == 0) {
      return next.handle(request);
    }

    const authReq = request.clone({ setHeaders: headers });
    return next.handle(authReq);
  }
}