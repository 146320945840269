<div class="row">
  <div class="col">
    <div
      #maindiv
      [ngClass]="{
    'pulse ': this.speechService.isInConversationMode || this.speechService.isListening
    }"
      class="input-group input-group-prompt"
    >
      <input
        id="bot-prompt-input"
        (keyup)="onKey($event)"
        (focus)="maindiv.classList.add('glow')"
        (blur)="maindiv.classList.remove('glow')"
        [(ngModel)]="promptInput"
        (ngModelChange)="this.promptInputChange.emit($event)"
        type="text"
        [(ngModel)]="promptInput"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-default"
      >
      <ng-container *ngIf="this.voiceInput">
        <span class="input-group-text p-1 ">
          <!-- BTN FÜR MIC -->
          <ng-container *ngIf="this.promptInput.length === 0 && !this.speech2.isRecording()">
            <div
              [ngClass]="{ 'disabled': isThinking || this.speechService.isInConversationMode }"
              class="btn btn-primary-outline border-opacity-10"
              (click)="startSingleRec()"
            >
              <i class="ri-mic-line mx-n1"></i>
            </div>
          </ng-container>
          <ng-container *ngIf="this.speechService.isInConversationMode">
            <div
              class="btn btn-danger chat-rec"
              (click)="stopChatRec(); this.playService.stopAudio();"
            >
              <i class="ri-close-fill"></i>
            </div>
          </ng-container>
        </span>
      </ng-container>
      <!-- 2. button spalte-->
      <span class="input-group-text p-1">
        <ng-container *ngIf="!this.cancelThinking || (this.cancelThinking && !this.isThinking)">
          <div
            *ngIf="!this.speech2.isRecording()"
            [ngClass]="{'disabled':  promptInput.trim().length === 0}"
            class="btn btn-primary "
            (click)="this.sendPrompt.emit()"
          >
            <i [ngClass]="{
                  'ri-arrow-up-line mx-n1': promptInput.trim().length >= 0,
                  'ri-send-plane-2-fill mx-n0': promptInput.trim().length === 0
                }"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="this.cancelThinking">
          <button
            *ngIf="!this.speech2.isRecording() && this.isThinking"
            class="stfu-button bg-danger text-white"
            (click)="this.cancelMsgThinking()"
          >
            <i class="ri-stop-large-fill"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="!this.speechService.isInConversationMode">
          <ng-container *ngIf="this.speechService.isListening">
            <div
              class="btn btn-primary  recording"
              (click)="stopSingleRec()"
            >
              <i class="ri-check-fill mx-n1"></i>
            </div>
          </ng-container>
        </ng-container>
      </span>
    </div>
  </div>
  <!-- BUTTONS FÜR BOT MODUS WECHSEK-->
  <div class="col-auto p-1">
    <button
      class="btn switchto-btn ms-2"
      *ngIf="this.switchToChat"
      tooltip="Zum Chat wechseln"
      i18n-tooltip="@@switch-to-chat"
    >
      <a
        href="{{this.langService.language}}/bot/{{this.botConfig._id}}"
        class="text-decoration-none"
      >
        <i class="ri-chat-ai-fill"></i>
      </a>
    </button>
    <button
      class="btn switchto-btn ms-2"
      *ngIf="this.switchToVoice"
      tooltip="Zu Voice wechseln"
      i18n-tooltip="@@switch-to-voice"
    >
      <a
        href="{{this.langService.language}}/bot/{{this.botConfig._id}}/audio"
        class="text-decoration-none"
      >
        <i class="ri-voice-ai-fill"></i>
      </a>
    </button>
    <button
      class="btn switchto-btn ms-2"
      *ngIf="this.switchToAvatar"
      tooltip="Zum Avatar wechseln"
      i18n-tooltip="@@switch-to-avatar"
    >
      <a
        href="{{this.langService.language}}/bot/{{this.botConfig._id}}/avatar"
        class="text-decoration-none"
      >
        <i class="ri-video-ai-fill"></i>
      </a>
    </button>
  </div>
</div>