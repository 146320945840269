import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeyGenConf } from '@reflact/ai-types';
import { waitSeconds } from '@reflact/tsutil';
import { firstValueFrom } from 'rxjs';
import { LoginService } from './login.service';

export type Avatar = Omit<HeyGenConf, "apiKey">;

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  public avatars: Avatar[] = [];
  private isLoadedingAvatars = false;
  private avatarPreviewCache = new Map<string, string>();
  constructor(private http: HttpClient, public loginService: LoginService) { }

  public async getAvatars(): Promise<Avatar[]> {
    if (this.isLoadedingAvatars) {
      await waitSeconds(1);
      return await this.getAvatars();
    }
    if (this.avatars.length == 0) {
      this.isLoadedingAvatars = true;
      try {
        this.avatars = await firstValueFrom(this.http.get<Avatar[]>(`/api/toolbox/bot/admin/listavatars/`));
      } catch (e) {
      }
      this.isLoadedingAvatars = false;
      //console.log("aaa", avatars.map(a => a.id).join(","));
    }
    return this.avatars;
  }

  public async getAvatarById(avatarId: string) {
    const avatars = await this.getAvatars();
    return avatars.find(a => a.id == avatarId);
  }

  public async getAvatarPreviewImage(avatarId: string): Promise<string> {
    const u = this.avatarPreviewCache.get(avatarId);
    if (u) {
      return u;
    }
    const { url } = await firstValueFrom(this.http.get<{ url: string }>(`/api/toolbox/heygen/avatarimage/${avatarId}`));
    this.avatarPreviewCache.set(avatarId, url);
    return url;
  }


}
