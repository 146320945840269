import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalStat, UsageStat } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { BotService } from 'src/app/shared/services/bot.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Month } from './statistics-month-selector/statistics-month-selector.component';


@Injectable({
  providedIn: 'root'
})
export class StatisticService {


  public debugBotId: string = "";
  public debugUserId: string = "";

  public debugLog: GlobalStat[] = [];

  private loopRunning: boolean = false;

  constructor(private http: HttpClient, private loginService: LoginService, private socketService: SocketService, botService: BotService) {
    void this.init();
    botService.resetBotDoneEmitter.subscribe(() => {
      this.debugLog.splice(0, this.debugLog.length);
    });
  }
  private async init() {
    const io = await this.socketService.getIo();
    io.on("debugMsg", (data) => {
      data.stat.created = new Date(data.stat.created);
      this.debugLog.push(data.stat);
    });
  }


  public async stopDebug() {
    const io = await this.socketService.getIo();
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    io.emit("registerDebug", { register: false }, (data) => { });
  }

  public async startDebug() {
    const io = await this.socketService.getIo();
    io.emit("registerDebug", { register: true }, (data) => {
      data.currentStats.forEach((stat: GlobalStat) => {
        stat.created = new Date(stat.created);
        this.debugLog.push(stat);
      });
    });
  }

  public async getStatistics(botId: string) {
    return await firstValueFrom(this.http.get<UsageStat[]>("/api/toolbox/stats/get/" + botId));
  }

  public getMonth(month: Month): string {
    return dayjs().set("month", month - 1).format('MMMM');
  }
}
