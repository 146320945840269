<div
  class="overlay"
  *ngIf="this.showOverlay"
>
  <h3>Verbindung verloren</h3>
  <div class="description">Möglicherweise sind Sie offline</div>

  <div
    class="btn btn-sm"
    (click)="this.refresh()"
  ><i class="ri-restart-line"></i> Seite neu laden</div>
  <div
    class="btn btn-sm"
    (click)="this.saveChat()"
  ><i class="ri-chat-download-line"></i> Dialog als Text herunterladen</div>


</div>