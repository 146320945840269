import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { LoginService } from 'src/app/shared/services/login.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  selector: 'app-chunk-contingent',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule
  ],
  templateUrl: './chunk-contingent.component.html',
  styleUrl: './chunk-contingent.component.scss'
})

export class ChunkContingentComponent {

  constructor(
    public login: LoginService,
    public rss: RouteShareService
  ) { }

  public percentage() {
    if (this.login.loggedInUser) {
      return this.login.loggedInUser.chunkCount / this.login.loggedInUser.chunkContingent;
    }
    return 0;

  }

}
