import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoginService } from '../services/login.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loggedInUserResolver: ResolveFn<Promise<boolean>> = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  return true;
};
