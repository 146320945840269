import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminClientToServerEvents, AdminServerToClientEvents } from '@reflact/ai-types';
import { waitSeconds } from '@reflact/tsutil';
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

export type MySocket = Socket<AdminServerToClientEvents, AdminClientToServerEvents>;

@Injectable({
  providedIn: 'root'
})
export class SocketAdminService {
  private ioSocket?: MySocket;

  public connectedAdminSockets = signal(0);
  public connectedAdminsCount = signal(0);
  public connectedUserSockets = signal(0);
  public onUploadStatus: Subject<Parameters<AdminServerToClientEvents["uploadStatus"]>[0]> = new Subject<Parameters<AdminServerToClientEvents["uploadStatus"]>[0]>();

  constructor(public route: ActivatedRoute) { }

  public async getIo(): Promise<MySocket> {

    if (this.ioSocket) {
      return this.ioSocket;
    }
    await waitSeconds(1);
    return this.getIo();
  }

  public connect(token: string) {
    if (this.ioSocket != null) {
      return;
    }

    this.ioSocket = io({
      autoConnect: true, path: '/api/socketadmin.io', extraHeaders: {
        token
      }
    });

    this.ioSocket.on('botConfigChanged', (data) => {
      console.log('botConfigChanged', data);
    });

    this.ioSocket.on('botUserInteraction', (data) => {
      console.log('botUserInteraction', data);
    });

    this.ioSocket.on('socketStats', (data) => {
      this.connectedAdminSockets.set(data.connectedAdminSockets);
      this.connectedAdminsCount.set(data.connectedAdminsCount);
      this.connectedUserSockets.set(data.connectedUserSockets);
    });

    this.ioSocket.on('uploadStatus', (data) => {
      this.onUploadStatus.next(data);
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.ioSocket.on('connect', () => { });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.ioSocket.on('disconnect', () => { });

  }
}