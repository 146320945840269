
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { languages } from '@codemirror/language-data';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomToolKitTypeArr, isToolConfigEnumParam, isToolConfigParam, ToolConfigCustomToolKit } from '@reflact/ai-types';
import { removeFromArray } from "@reflact/tsutil";
import { BotService } from 'src/app/shared/services/bot.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { SkillService } from 'src/app/shared/services/skill.service';
import { FunctionNameSuggestComponent } from '../../../../../../shared/components/ai-bot/utils/function-name-suggest/function-name-suggest.component';
import { InPlaceValidatorComponent } from "../../../../../../shared/components/ai-bot/utils/in-place-validator/in-place-validator.component";
import { SkillIdInputComponent } from "../../../../../../shared/components/ai-bot/utils/skill-id-input/skill-id-input.component";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type VarsHeader = { botId: string, botColor: string, botName: string, botVoice: string, botIcon: string };
@Component({
  standalone: true,
  imports: [
    FunctionNameSuggestComponent,
    InPlaceValidatorComponent,
    CommonModule,
    FormsModule,
    NgSelectModule,
    InPlaceValidatorComponent,
    SkillIdInputComponent,
    RouterModule
  ],
  templateUrl: './ifthen-tab.component.html',
  styleUrl: './ifthen-tab.component.scss'
})
export class IfthenTabComponent {

  protected customToolKitName: string = '';
  protected customToolKitDescription: string = '';
  protected customToolKitContentTypes = CustomToolKitTypeArr;

  protected languages = languages;
  protected isToolConfigEnumParam = isToolConfigEnumParam;
  protected isToolConfigParam = isToolConfigParam;



  public requiredValidator: ValidatorFn[] = [Validators.required];

  constructor(
    public router: Router,
    private botService: BotService,
    public rss: RouteShareService,
    public skillService: SkillService
  ) {
  }

  public get customToolKits(): ToolConfigCustomToolKit[] {
    return this.rss.botConfig.toolConfigs.filter((tc) => tc.tool === 'customtoolkit') as ToolConfigCustomToolKit[];
  }

  protected addCustomToolKit(): void {
    const newCustomToolKit: ToolConfigCustomToolKit = {
      skillId: '',
      tool: 'customtoolkit',
      name: this.customToolKitName,
      description: this.customToolKitDescription,
      enabled: true,
      params: [],
      id: '',
      type: 'js',
      content: '',
      toolCallAnswer: [''],
      answerMode: 'sequence'
    };
    this.rss.botConfig.toolConfigs.unshift(newCustomToolKit);
    this.rss.saveBotConfig();
    this.customToolKitName = '';
    this.customToolKitDescription = '';
  }


  public copyCustomToolKit(id: string): void {
    const itemToCopy = this.rss.botConfig.toolConfigs.find(tc => tc.id === id);
    if (itemToCopy) {
      const itemWithoutId = structuredClone(itemToCopy);
      itemWithoutId.id = '';
      this.rss.botConfig.toolConfigs.unshift(itemWithoutId);
      this.rss.saveBotConfig();
    }
  }
  protected removeCustomToolKit(id: string): void {
    removeFromArray(this.rss.botConfig.toolConfigs, tc => tc.id === id);
    this.rss.saveBotConfig();
  }
}
