import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDate, NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatbotConfig } from '@reflact/ai-types';
import dayjs from 'dayjs';
import { BotChatDialogComponent } from 'src/app/shared/components/ai-bot/utils/bot-chat-dialog/bot-chat-dialog.component';
import { BotService } from 'src/app/shared/services/bot.service';
@Component({
  selector: 'app-bot-memory-browser-filter',
  templateUrl: './bot-memory-browser-filter.component.html',
  styleUrls: ['./bot-memory-browser-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, BotChatDialogComponent, NgbDatepicker, NgbDatepickerModule, NgSelectModule]
})


export class BotMemoryBrowserFilterComponent implements OnInit {

  @Input({ required: true }) public botConfig!: ChatbotConfig;

  public groupIds: string[] = [];
  public daysWithData: { _id: string, count: number }[] = [];
  public selectedGroupIds: string[] = [];
  public multiCopyHoveredDate: NgbDate | null = null;
  public multiCopyFromDate: NgbDate | null = null;
  public multiCopyToDate: NgbDate | null = null;
  public deleteCount: number = 0;

  constructor(private bot: BotService) { }


  public startEqualsEnd() {
    return this.multiCopyFromDate == this.multiCopyToDate;
  }

  public async ngOnInit() {
    this.groupIds = await this.bot.getGroupIdsForBot(this.botConfig._id);
    const res = await this.bot.getHistoryInfo(this.botConfig._id);
    if (res.status == 'ok') {
      this.daysWithData = res.items;
    }
    void this.updateDeleteCount();
  }

  public async updateDeleteCount() {
    let fromDate;
    let toDate;

    if (this.multiCopyFromDate) {
      fromDate = dayjs();
      fromDate = fromDate.set('date', this.multiCopyFromDate.day);
      fromDate = fromDate.set('month', this.multiCopyFromDate.month - 1);
      fromDate = fromDate.set('year', this.multiCopyFromDate.year);
      fromDate = fromDate.startOf('day');
    }

    if (this.multiCopyToDate) {
      toDate = dayjs();
      toDate = toDate.set('date', this.multiCopyToDate.day);
      toDate = toDate.set('month', this.multiCopyToDate.month - 1);
      toDate = toDate.set('year', this.multiCopyToDate.year);
      toDate = toDate.endOf('day');
    }

    const deleteCount = await this.bot.countHistoryItemsByFilter(this.botConfig._id, {
      groupIds: this.selectedGroupIds,
      fromDate,
      toDate
    });

    if (deleteCount.status == 'ok') {
      this.deleteCount = deleteCount.count;
    }
  }

  public onMultiCopyDateSelection(date: NgbDate) {
    if (!this.multiCopyFromDate && !this.multiCopyToDate) {
      this.multiCopyFromDate = date;
    } else if (this.multiCopyFromDate && !this.multiCopyToDate && !date.before(this.multiCopyFromDate)) {
      this.multiCopyToDate = date;
    } else {
      this.multiCopyToDate = null;
      this.multiCopyFromDate = date;
    }
    void this.updateDeleteCount();
  }

  public multiCopyIsHovered(date: NgbDate): boolean {
    return this.multiCopyFromDate != null && this.multiCopyToDate == null && this.multiCopyHoveredDate != null && date.after(this.multiCopyFromDate) && date.before(this.multiCopyHoveredDate);
  }

  public multiCopyIsInside(date: NgbDate): boolean {
    return this.multiCopyToDate != null && date.after(this.multiCopyFromDate) && date.before(this.multiCopyToDate);
  }

  public multiCopyIsRange(date: NgbDate): boolean {
    return date.equals(this.multiCopyFromDate) || (this.multiCopyToDate != null && date.equals(this.multiCopyToDate)) || this.multiCopyIsInside(date) || this.multiCopyIsHovered(date);
  }
  public dayHasData(inputDate: NgbDate) {
    let date = dayjs();
    date = date.set('date', inputDate.day);
    date = date.set('month', inputDate.month - 1);
    date = date.set('year', inputDate.year);

    return this.daysWithData.findIndex(dayData => { return dayData._id == date.format('YYYY-MM-DD'); }) > -1;
  }
}
