<div
  class="d-flex align-items-center justify-content-center w-100 h-100"
  *ngIf="this.invalidToken"
>
  <div class="w-50 text-center">
    <h2 i18n="@@bot-import">
      Bot Import
    </h2>
    <h5 i18n="@@bot-import-error">
      Dieser Import-Link ist fehlerhaft oder ungültig.
    </h5>
    <h5 i18n="@@bot-import-error-contact">
      Bitte wende dich an den Bot-Besitzer.
    </h5>
    <p class="card-text mx-3 text-center">
      <button
        class="btn btn-primary"
        [routerLink]="['/','bot-admin']"
        i18n="@@btn-back-to-bots"
      >Zurück zur Bot-Übersicht</button>
    </p>
  </div>

</div>




<div
  class="d-flex align-items-center justify-content-center w-100 h-100"
  *ngIf="!this.invalidToken"
>
  <div class="w-50">
    <h2
      class="card-title mx-3 text-center"
      i18n="@@bot-access"
    >Bot Freigabe</h2>
    <p
      class="card-text mx-3 text-center"
      i18n="@@bot-import-text"
    >Um den Bot in Ihr Konto zu übernehmen, klicken Sie bitte auf <u
        (click)="this.importBot()"
        class="cursor-pointer"
      >Importieren</u>.
    </p>
    <div class="row mx-3 mt-5">
      <div class="col">
        <div
          class=" card p-2 text-center w-75 m-auto"
          [ngStyle]="{'background-color': this.botConfig?.color, 'color':getContrastColor(this.botConfig?.color ? this.botConfig!.color : '#000000')}"
          [ngStyle]="{'background-color': this.botConfig?.color, 'color': getContrastColor(this.botConfig?.color ? this.botConfig!.color : '#000000')}"
        >
          <i [class]="this.botConfig?.icon +' ri-2x'"></i>
          <span> {{ this.botConfig?.name }}</span>
          <app-bot-quickinfo
            [botConfig]="this.botConfig!"
            [showIcon]="false"
            [colorMode]="false"
          >
          </app-bot-quickinfo>
        </div>
      </div>
    </div>
    <div class="row m-4">
      <div class="col text-center">
        <div
          *ngIf="this.importing"
          class="spinner-border text-primary"
          role="status"
        >
          <span
            class="visually-hidden"
            i18n="@@loading"
          >Loading...</span>
        </div>
      </div>
    </div>


    <div class="row m-3 ">
      <div class="col text-center">
        <button
          class="btn btn-primary btn-lg d-inline-block m-auto "
          (click)="this.importBot()"
          [disabled]="importing"
          i18n="@@btn-import-bot"
        >
          Diesen Bot Importieren
        </button>
      </div>
    </div>


  </div>
</div>