import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Webscrape } from '@reflact/ai-types';
import { AdminBackButtonComponent } from 'src/app/shared/components/ai-bot/utils/admin-back-button/admin-back-button.component';
import { FunctionNameSuggestComponent } from 'src/app/shared/components/ai-bot/utils/function-name-suggest/function-name-suggest.component';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { SkillIdInputComponent } from 'src/app/shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';
import { CounterService } from 'src/app/shared/services/counter.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { WebscrapeService } from 'src/app/shared/services/webscrape.service';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    AdminBackButtonComponent,
    SkillIdInputComponent,
    FunctionNameSuggestComponent,
    HintComponent
  ],
  templateUrl: './offline-websites-tab-detail.component.html',
  styleUrl: './offline-websites-tab-detail.component.scss'
})
export class OfflineWebsitesTabDetailComponent {


  constructor(
    public service: WebscrapeService,
    private toastr: ToastrService,
    private counterService: CounterService,
    public rss: RouteShareService
  ) { }

  public async deleteWebscrapePages(offlineWeb: Webscrape, scrapepageIds: { _id: string, url: string, chunk: number }[]) {
    await this.service.deleteWebscrapePageId(offlineWeb.botId, offlineWeb._id, scrapepageIds.map(e => e._id));
    const scrape = (await this.service.getWebscrape(this.rss.botConfig._id, offlineWeb._id)).scrape;
    const index = this.rss.webscrapes.findIndex(s => s._id == scrape._id);
    this.rss.webscrapes[index] = scrape;
    if (this.rss.webscrape?._id == scrape._id) {
      this.rss.webscrape = scrape;
    }
    this.toastr.success(ToastSuccessTitle.SAVED, "Offline Unterseiten wurden gelöscht");
  }
}
