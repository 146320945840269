import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ChatbotConfig } from '@reflact/ai-types';
import { LoginService } from '../services/login.service';
import { RouteShareService } from '../services/route-share.service';
import { BotService } from './../services/bot.service';

export const botconfigResolver: ResolveFn<ChatbotConfig | null> = async (route) => {
  const loginService: LoginService = inject(LoginService);
  const botService: BotService = inject(BotService);
  const rss: RouteShareService = inject(RouteShareService);
  const token = loginService.getToken();
  if (token == null) {
    await loginService.loginAsGuest();
  }
  const id = route.params['id'] as string;
  const botConfig = await botService.getBotConfigAsUser(id);
  if (botConfig) {
    rss.botConfig = botConfig;
  }
  return botConfig;
};
