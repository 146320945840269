import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToolConfigParam, ToolConfigWebsiteSearch } from '@reflact/ai-types';
import { AdminBackButtonComponent } from 'src/app/shared/components/ai-bot/utils/admin-back-button/admin-back-button.component';
import { SkillIdInputComponent } from 'src/app/shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AdminBackButtonComponent,
    SkillIdInputComponent
  ],
  templateUrl: './online-sources-tab-detail.component.html',
  styleUrl: './online-sources-tab-detail.component.scss'
})
export class OnlineSourcesTabDetailComponent {
  private selectedToolId?: string;
  public selectedParamIndex: number = -1;
  public paramForNewForm?: ToolConfigParam;

  constructor(
    private toastr: ToastrService,
    private aRoute: ActivatedRoute,
    public rss: RouteShareService
  ) {
    const toolid = this.aRoute.snapshot.paramMap.get("toolid");
    if (toolid) {
      this.selectedToolId = toolid;
    }
  }



  public selectEmptyParam() {
    this.selectedParamIndex = -1;
    this.paramForNewForm = { key: '', description: '', required: true, type: 'string' };
  }

  public addParamToList() {
    if (this.paramForNewForm === undefined) return;
    this.selectedTool?.params.unshift(this.paramForNewForm);
    this.rss.saveBotConfig();
    this.paramForNewForm = undefined;
  }

  public isParamValid(): boolean {
    if (this.selectedParam?.key.trim() == '') return false;
    if (this.selectedParam?.description.trim() == '') return false;
    return true;
  }


  public formularModelChange() {
    // soll nicht ausgeführ werden wenn wir ein "neues" Element hinzufügen
    // da tut das der button
    if (this.getSelectedParamState() === "new") return;
    if (!this.isParamValid()) { return; }
    this.rss.saveBotConfig();
  }

  public getSelectedParamState() {
    if (this.selectedTool && this.selectedParam) {
      if (this.selectedTool.params.includes(this.selectedParam)) {
        return "existing";
      } else {
        return "new";
      }
    }
    return "new";
  }

  public get selectedTool(): ToolConfigWebsiteSearch | undefined {
    return this.toolConfigs.find(tc => tc.id === this.selectedToolId);
  }

  public get selectedParam(): ToolConfigParam | undefined {
    return this.selectedTool?.params[this.selectedParamIndex];
  }

  public get toolConfigs(): ToolConfigWebsiteSearch[] {
    return this.rss.botConfig.toolConfigs.filter(tc => tc.tool === 'websitesearch' || tc.tool === 'schnittstelle') as ToolConfigWebsiteSearch[];
  }

  public deleteParamFromList(index: number) {
    this.selectedTool?.params.splice(index, 1);
    this.saveToolConfig();
  }

  public updateToolConfigItem(id: string) {
    const index = this.rss.botConfig.toolConfigs.findIndex((tc) => {
      return tc.id === id;
    });
    const toolIndex = this.toolConfigs.findIndex((tc) => {
      return tc.id === id;
    });
    if (this.toolConfigs[toolIndex] && this.toolConfigs[toolIndex].url.trim() !== '' && this.toolConfigs[toolIndex].description.trim() !== '') {
      this.rss.botConfig.toolConfigs[index] = this.toolConfigs[toolIndex];
      this.rss.saveBotConfig();
    } else {
      this.toastr.error(ToastErrorTitle.SAVED, 'Die neue Konfiguration ist nicht vollständig');
    }
  }

  public saveToolConfig() {
    if (this.selectedTool === undefined) return;
    const toolIndex = this.toolConfigs.findIndex((tc) => {
      return tc.id === this.selectedTool?.id;
    });
    this.toolConfigs[toolIndex] = this.selectedTool;
    this.updateToolConfigItem(this.selectedTool.id);
  }
}
