import { CodeEditor } from '@acrodata/code-editor';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { languages } from '@codemirror/language-data';
import { EditorView } from "@codemirror/view";
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  selector: 'app-code-editor-ai',
  standalone: true,
  imports: [CodeEditor, FormsModule],
  templateUrl: './code-editor-ai.component.html',
  styleUrl: './code-editor-ai.component.scss'
})
export class CodeEditorAiComponent implements OnInit {
  @Input() public placeholder: string = '';
  @Input() public rows: number = 5;
  @Input({ required: true }) public instruction: string = '';
  @Output() public finish: EventEmitter<string> = new EventEmitter<string>();
  protected languages = languages;
  public extensions = [];

  constructor(public rss: RouteShareService) {

  }
  public ngOnInit(): void {
    //Erste Codezeile ist immer 30,39px hoch (Zeile -> 22,3906px + Space -> 8px)
    //Jede weitere Zeile fügt 22,3906px hinzu
    const heightToRows = 8 + this.rows * 22.3906;
    //@ts-ignore
    this.extensions = [EditorView.theme({
      ".cm-gutters": {
        backgroundColor: "#99DBD6",
        color: "#fff"
      },
      ".cm-activeLineGutter": {
        backgroundColor: "#00A599"
      },
      ".cm-content": {
        minHeight: heightToRows + "px"
      },
      ".cm-gutter": {
        minHeight: heightToRows + "px"
      }
    })];
  }

  public saveToolConfig() {
    this.finish.emit(this.instruction);
  }
}
