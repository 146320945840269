import { KeyValEntry, ScoreData } from "@reflact/ai-types/dist/KeyValStore";
import { AchieveMent } from "./score.service";
import { KeyValService } from "../../key-val.service";

export class Score {
  private score = 0;
  private achieveMents: AchieveMent[] = [];


  constructor(private _keyvalservice: KeyValService, private key: string) {
    this.score = 0;
    this.achieveMents = [];
  }

  public updateFromServer(entry: KeyValEntry<ScoreData>) {
    this.score = entry.value.score;
    this.achieveMents = entry.value.achieveMents;
  }

  public achieve(value: number, skillId: string, message: string, autoMessage: boolean = false) {
    //@ts-ignore
    window.RAGAI.disableSkills([skillId]);
    this.achieveMents.push({ value, skillId, message });
    this.add(value);
    if (autoMessage && value > 0) {
      //@ts-ignore
      window.RAGAI.toastr.success(message);
    }
    if (autoMessage && value < 0) {
      //@ts-ignore
      window.RAGAI.toastr.error(message);
    }
    void this.storeUpdateToServer();
    return this;
  }

  public getAchieveMents(asJson: boolean = false) {
    if (asJson) {
      return this.achieveMents;
    } else {
      return this.achieveMents.map((a) => {
        return `(${a.value + ''})  ${a.message} `;
      }).join('\n');
    }
  }
  public add(value: number) {
    this.score += value;
    void this.storeUpdateToServer();
    return this;
  }
  public get() {
    return this.score;
  }
  public reset() {
    this.score = 0;
    this.achieveMents = [];
    void this.storeUpdateToServer();
    return this;
  }
  public set(value: number) {
    this.score = value;
    void this.storeUpdateToServer();
    return this;
  }
  public sub(value: number) {
    this.score -= value;
    void this.storeUpdateToServer();
    return this;
  }

  private async storeUpdateToServer() {
    await this._keyvalservice.storeKeyValEntry(
      this.key,
      {
        score: this.score,
        achieveMents: this.achieveMents
      },
      'score');
  }
}
