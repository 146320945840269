<h3 i18n="@@content-filter">Inhaltsfilter</h3>
<div class="card border-0">
  <div class="card-body">

    <div class="row mt-2">
      <div class="col-3">
        <span i18n="@@stop-words">Stoppwörter</span><app-hint i18n="@@stop-words-hint">
          Hier können Sie <b>individuelle</b> Stoppwörter für den Bot festlegen, die nicht an den Bot geschickt werden
          sollen.<br>Einzelne Stoppwörter können eingetippt und mit Enter hinzugefügt werden.
        </app-hint>
      </div>
      <div class="col">

        <app-bot-word-stop
          [botConfig]="this.rss.botConfig"
          (botConfigChange)="this.rss.saveBotConfig()"
        ></app-bot-word-stop>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-3"><span i18n="@@word-replacement">Wortersetzung</span><app-hint i18n="@@word-replacement-hint">
          Hier können Sie <b>individuelle</b> Wortersetzungen festlegen, die automatisch ersetzt werden, bevor sie an
          den Bot geschickt werden.</app-hint></div>
      <div class="col"><app-bot-word-replace
          [botConfig]="this.rss.botConfig"
          (botConfigChange)="this.rss.saveBotConfig()"
        ></app-bot-word-replace>
      </div>
    </div>
  </div>
</div>
<h3
  class=" mt-4"
  i18n="@@privacy-notice"
>Datenschutzhinweis</h3>
<div class="card border-0">
  <div class="card-body">
    <div class="row mt-2">
      <div class="col-3">
        <p><span i18n="@@custom-privacy-notice">Benutzerdefinierter Datenschutzhinweis</span><app-hint
            i18n="@@custom-privacy-notice-hint"
          >
            Hier können Sie einen <b>individuellen</b> Datenschutzbanner verfassen, der beim Aufruf des Bots angezeigt
            wird.
            <br>
            Über "Zurücksetzen" lässt sich die Standardvorlage wiederherstellen.
          </app-hint></p>
        <div
          class="btn btn-outline-primary btn-sm"
          (click)="this.reset()"
          i18n="@@reset"
        >
          Zurücksetzen
        </div>
        <div
          class="btn btn-outline-primary btn-sm ms-2"
          (click)="this.deleteText()"
          i18n="@@delete-text"
        >
          Text löschen
        </div>
      </div>
      <div class="col"><textarea
          type="text"
          class="form-control"
          [(ngModel)]="this.rss.botConfig.complianceText"
          (ngModelChange)="this.rss.saveBotConfig()"
          [ngModelOptions]="{updateOn: 'blur'}"
          rows="20"
        ></textarea></div>
    </div>
  </div>
</div>