<i
  [class]="this.customIcon + ' ms-1 text-info'"
  [tooltip]="content"
  containerClass="hint"
  [tooltipAnimation]="true"
  [tooltipFadeDuration]="1"
  [tooltipPlacement]="'right'"
  container="body"
  [style.color]="this.customColor"
></i>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>