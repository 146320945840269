import { EventEmitter, Injectable } from '@angular/core';
import { SpeechRecognitionEvent } from 'src/app/routes/ai-bot/avatar/avatar.component.types';
/* eslint-disable */
declare let webkitSpeechRecognition: any;


@Injectable({ providedIn: 'root' })


export class Speech2Service {
  private webkitRecognition: any;
  private _endOnSlience = false;
  private _forceEndOnSpeechend = false;
  private _isRecording = false;
  public result: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    if (typeof webkitSpeechRecognition === 'function') {
      this.webkitRecognition = new webkitSpeechRecognition();
      this.webkitRecognition.interimResults = true;
      this.webkitRecognition.continuous = true;
      this.webkitRecognition.lang = 'de-DE';
      this.webkitRecognition.addEventListener('result', (e: SpeechRecognitionEvent) => {
        const transcript = Array.from(e.results).map((result: SpeechRecognitionResult) => result[0]?.transcript).join('');
        this.result.emit(transcript);
      });

      this.webkitRecognition.addEventListener('speechend', () => {
        // WENN NICHT GEZWUNGEN WIRD AUF ZU HÄREN
        if (this._forceEndOnSpeechend == false) {
          // WENN ER NICHT BEI STILLE ENDEN SOLL
          if (this._endOnSlience == false) {
            setTimeout(() => {
              // DANN RECONNECT
              //@ts-ignore
              this._isRecording = true;
              this.webkitRecognition.start();
            }, 300); // 1
            return;
          }
          return;
        }

      });

    } else {

    }
  }

  public isRecording() {
    return this._isRecording;
  }

  public stop() {
    if (this.webkitRecognition) {
      this._forceEndOnSpeechend = true;
      this._isRecording = false;
      this.webkitRecognition.stop();
    }
  }

  public record(endOnSlience: boolean) {
    this._isRecording = true;
    this._endOnSlience = endOnSlience;
    if (endOnSlience) {
      this.webkitRecognition.continuous = false;
    } else {
      this.webkitRecognition.continuous = true;
    }

    if (this.webkitRecognition) {

      this.webkitRecognition.start();
    }
  }
}
