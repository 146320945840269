import { signal } from "@angular/core";
import StreamingAvatar, { StreamingEvents, TaskMode, TaskType } from "@heygen/streaming-avatar";

export class DeltaSpeak {

  public deltas: string[] = [];
  public avatarIsTalking: boolean = false;

  public deltaSpeakIdle = signal<boolean>(true);

  constructor(private avatar: StreamingAvatar) {
    avatar.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
      this.avatarIsTalking = false;
      this.submitDeltas();
    });
    avatar.on(StreamingEvents.AVATAR_START_TALKING, () => {
      this.avatarIsTalking = true;
    });
  }


  private submitDeltas() {
    const currentWaiting = this.deltas.join("");
    const seperatorindex = Math.max(
      currentWaiting.lastIndexOf("."),
      currentWaiting.lastIndexOf("!"),
      currentWaiting.lastIndexOf("?"),
      currentWaiting.lastIndexOf("\n"),
      currentWaiting.lastIndexOf(";"));
    if (seperatorindex < 0) {
      return;
    } else {
      this.deltas = [currentWaiting.substring(seperatorindex + 1)];
      this.speak(currentWaiting.substring(0, seperatorindex + 1));
      this.avatarIsTalking = true;
    }
  }

  public deltaRecieved(str: string) {
    this.deltaSpeakIdle.set(false);
    this.deltas.push(str);
    if (!this.avatarIsTalking) {
      this.submitDeltas();
    }

  }
  public messageComplete() {
    if (this.deltas.length > 0) {
      console.log("Message complete Deltas", this.deltas);
      this.speak(this.deltas.join(""));
      this.avatarIsTalking = true;
      this.deltas = [];
    } else {
      this.deltaSpeakIdle.set(true);
    }
  }

  private speak(str: string) {
    console.log("Submit Speaking", str);
    void this.avatar.speak({
      text: str,
      taskType: TaskType.REPEAT,
      taskMode: TaskMode.ASYNC
    });
  }

  public destroy() {
    this.deltas = [];
  }

}