import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { botAdminRoute } from './bot-admin.routes';
import { AudioComponent } from './routes/ai-bot/audio/audio.component';
import { AvatarComponent } from './routes/ai-bot/avatar/avatar.component';
import { BotLoginComponent } from './routes/ai-bot/bot-login/bot-login.component';
import { BotComponent } from './routes/ai-bot/bot/bot.component';
import { ImportComponent } from './routes/ai-bot/import/import.component';
import { MdPreviewComponent } from './routes/ai-bot/md-preview/md-preview.component';
import { MultiBotComponent } from './routes/ai-bot/multi-bot/multi-bot.component';
import { ClusteringComponent } from './routes/ai-tools/clustering/clustering.component';
import { LoginComponent } from './routes/login/login.component';
import { BotEndComponent } from './shared/components/ai-bot/bot-end/bot-end.component';
import { FileCitationComponent } from './shared/components/ai-bot/utils/file-citation/file-citation.component';
import { authGuard } from './shared/guards/auth.guard';
import { botprotect } from './shared/guards/botprotect.guard';
import { MyReflactLoginResolver } from './shared/resolver/MyReflactLoginResolver';
import { PortalLoginResolver } from './shared/resolver/PortalLogin.resolver';
import { botconfigResolver } from './shared/resolver/botconfig.resolver';
import { botoriginResolver } from './shared/resolver/botorigin.resolver';
import { botprotectResolver } from './shared/resolver/botprotect.resolver';
import { loggedInUserResolver } from './shared/resolver/logged-in-user.resolver';


const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "bot-admin"
  },
  {
    path: "portallogin/:deeplink",
    pathMatch: "full",
    component: LoginComponent,
    resolve: {
      portalLogin: PortalLoginResolver
    }
  },
  {
    path: "portallogin",
    pathMatch: "full",
    component: LoginComponent,
    resolve: {
      portalLogin: PortalLoginResolver
    }
  },
  {
    path: "myreflactlogin",
    pathMatch: "full",
    component: LoginComponent,
    resolve: {
      portalLogin: MyReflactLoginResolver
    }
  },
  {
    path: 'multi-bot',
    pathMatch: 'full',
    data: { isFullScreenLayout: true },
    component: MultiBotComponent,
    canActivate: [
      authGuard
    ]
  },
  {
    path: 'botlogin/:id',
    pathMatch: 'full',
    data: { isFullScreenLayout: true },
    component: BotLoginComponent,
    resolve: {
      botOwner: botprotectResolver,
      botConfig: botconfigResolver,
      botOrigin: botoriginResolver
    }
  },
  {
    path: 'botlogin/:id/:origin',
    pathMatch: 'full',
    data: { isFullScreenLayout: true },
    component: BotLoginComponent,
    resolve: {
      botOwner: botprotectResolver,
      botConfig: botconfigResolver,
      botOrigin: botoriginResolver
    }
  },
  {
    path: "bot",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: BotComponent
  },
  {
    path: "bot/:id",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: BotComponent,
    canActivate: [
      botprotect
    ],
    resolve: {
      botConfig: botconfigResolver
    }
  },
  {
    path: "bot/:id/audio",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: AudioComponent,
    canActivate: [
      botprotect
    ],
    resolve: {
      login: loggedInUserResolver,
      botConfig: botconfigResolver
    }
  },
  {
    path: "bot/:id/avatar",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: AvatarComponent,
    canActivate: [
      botprotect
    ],
    resolve: {
      login: loggedInUserResolver,
      botConfig: botconfigResolver
    }
  },
  {
    path: "bot/:id/end",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: BotEndComponent,
    resolve: {
      login: loggedInUserResolver,
      botConfig: botconfigResolver
    }
  },
  {
    path: "bot/:botid/fileCitation/:fileid",
    pathMatch: "full",
    data: { isFullScreenLayout: true },
    component: FileCitationComponent
  },
  //! HIER SIND DIE ADMIN ROUTES
  botAdminRoute,
  {
    path: "bot-import/:token",
    pathMatch: "full",
    component: ImportComponent,
    data: { isFullScreenLayout: true },
    canActivate: [
      authGuard
    ]
  },
  {
    path: "mdpreview/:docid",
    pathMatch: "full",
    component: MdPreviewComponent,
    data: { isFullScreenLayout: true },
    canActivate: [
      authGuard
    ]
  },
  {
    path: "mdpreview/:botid/:toolid",
    pathMatch: "full",
    component: MdPreviewComponent,
    data: { isFullScreenLayout: true },
    canActivate: [
      authGuard
    ]
  },
  {
    path: 'clustering',
    pathMatch: 'full',
    component: ClusteringComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
