import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, UrlSerializer } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { filter, Subscription } from 'rxjs';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  standalone: true,
  imports: [MarkdownModule],
  templateUrl: './information-tab.component.html',
  styleUrl: './information-tab.component.scss'
})
export class InformationTabComponent implements OnDestroy {
  private subscription: Subscription;
  constructor(public router: Router, public rss: RouteShareService, private urlSerializer: UrlSerializer) {
    this.subscription = this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe(event => {
        if (this.urlSerializer.parse(event.urlAfterRedirects).root.children['primary']?.segments[2]?.path == "information") {
          if (this.rss.botConfig.notes == '') {
            void this.router.navigate(['bot-admin', this.rss.botConfig._id, 'task']);
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
