import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-admin-back-button',
  templateUrl: './admin-back-button.component.html',
  styleUrl: './admin-back-button.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class AdminBackButtonComponent {
  public text: string = 'Zurück';
  public icon: string = 'ri-arrow-left-fill';
  // @HostBinding('class') protected readonly class = 'contents'; // Makes component host as if it was not there, can offer less css headaches. Assumes .contents{display:contents} css class exits
}
