import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToolKitParam, ToolConfigCustomToolKit, ToolConfigEnumParam, isToolConfigEnumParam } from '@reflact/ai-types';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { AdminBackButtonComponent } from '../../../../../../../shared/components/ai-bot/utils/admin-back-button/admin-back-button.component';
import { CodeEditorAiComponent } from '../../../../../../../shared/components/ai-bot/utils/code-editor-ai/code-editor-ai.component';
import { CodeEditorDannComponent } from '../../../../../../../shared/components/ai-bot/utils/code-editor-dann/code-editor-dann.component';
import { CodeEditorToolbarComponent } from '../../../../../../../shared/components/ai-bot/utils/code-editor-toolbar/code-editor-toolbar.component';
import { FunctionNameSuggestComponent } from '../../../../../../../shared/components/ai-bot/utils/function-name-suggest/function-name-suggest.component';
import { SkillIdInputComponent } from '../../../../../../../shared/components/ai-bot/utils/skill-id-input/skill-id-input.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AdminBackButtonComponent,
    CodeEditorToolbarComponent,
    CodeEditorDannComponent,
    CodeEditorAiComponent,
    SkillIdInputComponent,
    FunctionNameSuggestComponent
  ],
  templateUrl: './ifthen-tab-detail.component.html',
  styleUrl: './ifthen-tab-detail.component.scss'
})
export class IfthenTabDetailComponent {


  private selectedToolId?: string;
  public selectedParamIndex: number = -1;
  public toolCallAnswerShowIndex: number = 0;
  public paramForNewForm?: CustomToolKitParam = undefined;


  constructor(
    public router: Router,
    private aRoute: ActivatedRoute,
    public rss: RouteShareService
  ) {

    const ifthenid = aRoute.snapshot.paramMap.get("ifthenid");
    if (ifthenid) {
      this.selectedToolId = ifthenid;
    }
  }


  public get selectedParam(): CustomToolKitParam | undefined {
    return this.selectedTool?.params[this.selectedParamIndex];
  }

  public get selectedTool(): ToolConfigCustomToolKit | undefined {
    return this.customToolKits.find((tc) => tc.id === this.selectedToolId);
  }

  public get customToolKits(): ToolConfigCustomToolKit[] {
    return this.rss.botConfig.toolConfigs.filter((tc) => tc.tool === 'customtoolkit') as ToolConfigCustomToolKit[];
  }


  public selectPrevRule() {
    const currentIndex = this.customToolKits.findIndex(tc => tc.id === this.selectedToolId);
    let newIndex = currentIndex - 1;
    if (newIndex === -1) { newIndex = this.customToolKits.length - 1; }
    const newTool = this.customToolKits[newIndex];
    if (newTool) {
      void this.router.navigate(["../", newTool.id], { relativeTo: this.aRoute });
      this.selectedParamIndex = newIndex;
      this.selectedToolId = newTool.id;
    }
  }

  public selectNextRule() {
    const currentIndex = this.customToolKits.findIndex(tc => tc.id === this.selectedToolId);
    let newIndex = currentIndex + 1;
    if (newIndex >= this.customToolKits.length) { newIndex = 0; }
    const newTool = this.customToolKits[newIndex];
    if (newTool) {
      void this.router.navigate(["../", newTool.id], { relativeTo: this.aRoute });
      this.selectedParamIndex = newIndex;
      this.selectedToolId = newTool.id;
    }

  }


  public selectEmptyParam() {
    this.selectedParamIndex = -1;
    this.paramForNewForm = {
      description: '',
      required: true,
      type: 'string',
      key: ''
    };
  }



  public deleteParamFromList(index: number) {
    this.selectedTool?.params.splice(index, 1);
    this.rss.saveBotConfig();
  }


  public getSkillNames(): string[] {
    const toolsWithSkillId = this.rss.botConfig.toolConfigs.filter(toolConfig => toolConfig.skillId !== '');
    return toolsWithSkillId.map(toolConfig => toolConfig.skillId + '');

  }

  public getToolbarVariables(): string[] {
    const variableNames = this.selectedTool?.params.map((param) => param.key);
    if (variableNames) {
      return variableNames;
    }
    return [];

  }

  public addParamToList() {
    if (this.paramForNewForm === undefined) return;
    this.selectedTool?.params.unshift(this.paramForNewForm);
    this.rss.saveBotConfig();
    this.paramForNewForm = undefined;
  }

  public getSelectedParamState() {
    if (this.selectedTool && this.selectedParam) {
      if (this.selectedTool.params.includes(this.selectedParam)) {
        return "existing";
      } else {
        return "new";
      }
    }
    return "new";
  }

  public isToolValid(): boolean {
    if (this.selectedTool?.name.trim() == '') return false;
    if (this.selectedTool?.description.trim() == '') return false;
    return true;
  }

  public isParamValid(): boolean {
    if (this.selectedParam?.key.trim() == '') return false;
    if (this.selectedParam?.description.trim() == '') return false;
    return true;
  }



  public hasAtleastOneToolCallAnswer(): boolean {
    if (!this.selectedTool) { return false; }
    if (this.selectedTool.toolCallAnswer.length === 0) { return false; }
    if (this.selectedTool?.toolCallAnswer.length == 1 && this.selectedTool?.toolCallAnswer[0] === '') {
      return false;
    }


    return true;
  }


  public formularModelChange() {
    // soll nicht ausgeführ werden wenn wir ein "neues" Element hinzufügen
    // da tut das der button
    if (this.getSelectedParamState() === "new") return;
    if (!this.isParamValid()) { return; }
    this.rss.saveBotConfig();
  }


  protected changeParamType(): void {
    if (this.paramForNewForm && isToolConfigEnumParam(this.paramForNewForm)) {
      this.paramForNewForm.values = [];
    }
  }

  public deleteToolAnswerAtIndex(index: number) {
    if (this.selectedTool?.toolCallAnswer && this.selectedTool?.toolCallAnswer.length > 1) {
      this.selectedTool?.toolCallAnswer.splice(index, 1);

      if (this.toolCallAnswerShowIndex >= this.selectedTool?.toolCallAnswer.length) {
        this.toolCallAnswerShowIndex = this.selectedTool?.toolCallAnswer.length - 1;
      }
      this.rss.saveBotConfig();
    }
  }



  public enumValChanged(data: ToolConfigEnumParam, e: Event) {
    data.values = (e.target as HTMLInputElement).value.split(",");
    this.rss.saveBotConfig();
  }


}
