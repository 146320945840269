import { Injectable, signal } from '@angular/core';
import { ChatbotConfig, SpfBucketFileFrontend, Trunk, TrunkItem, Webscrape } from '@reflact/ai-types';
import { AdminService } from './admin.service';
import { CounterService } from './counter.service';
import { LoginService } from './login.service';
import { SocketAdminService } from './socketadmin.service';
import { ToastErrorTitle, ToastInfoTitle, ToastrService, ToastSuccessTitle } from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class RouteShareService {
  public botConfig!: ChatbotConfig;
  public botConfigs!: ChatbotConfig[];
  public botOwnerContingent!: { chunkContingent: number, userChunkCount: number };

  // overridden if documentsResolver is called
  public document?: SpfBucketFileFrontend;
  public documentContent?: string;
  public documents: SpfBucketFileFrontend[] = [];
  // overridden if datatrunkResolver is called
  public datatrunk?: (Trunk & { items: TrunkItem[] });
  public datatrunks: (Trunk & { items: TrunkItem[] })[] = [];
  // overridden if webscrapeResolver is called
  public webscrape?: Webscrape;
  public webscrapeMap = new Map<string, {
    _id: string;
    url: string;
    chunk: number;
  }[]>();
  public webscrapes: Webscrape[] = [];
  private effectActive = signal(true);

  constructor(
    private toastr: ToastrService,
    private adminService: AdminService,
    private counter: CounterService,
    private socketAdminService: SocketAdminService,
    private loginService: LoginService
  ) {
    void this.socketAdminService.getIo().then(io => {
      io.on('botConfigChanged', (data) => {
        this.effectActive.set(false);
        if (data.config._id === this.botConfig._id) {
          this.botConfig = data.config;
          if (data.by != this.loginService.loggedInUser?.name) {
            this.toastr.info(ToastInfoTitle.BOT_UPDATED, `durch ${data.by}`);
          }
        }
        this.botConfigs = this.botConfigs.map((bc) => {
          if (bc._id === data.config._id) {
            return data.config;
          }
          return bc;
        });
        this.effectActive.set(true);
      });
    });
  }

  /**
   * Saves the bot configuration by sending a POST request to the server.
   * @returns {Promise<void>} A promise that resolves when the bot configuration is successfully saved.
   */
  public async saveBotConfig() {
    const newConf: Partial<ChatbotConfig> = {
      //! DAS HIER IST ALPHABETISCH WEHE DAS ÄNDERT SICH
      botInstruction: this.botConfig.botInstruction,
      botPassword: this.botConfig.botPassword,
      cancelBotThinkingAvatarActive: this.botConfig.cancelBotThinkingAvatarActive,
      cancelBotThinkingChatActive: this.botConfig.cancelBotThinkingChatActive,
      cancelBotThinkingVoiceActive: this.botConfig.cancelBotThinkingVoiceActive,
      chatMsgSourceOption: this.botConfig.chatMsgSourceOption,
      color: this.botConfig.color,
      complianceText: this.botConfig.complianceText,
      createEmptyThreadOnVisit: this.botConfig.createEmptyThreadOnVisit,
      customCss: this.botConfig.customCss,
      customJs: this.botConfig.customJs,
      editMode: this.botConfig.editMode,
      enableMessageFeedback: this.botConfig.enableMessageFeedback,
      heyGenAvatarId: this.botConfig.heyGenAvatarId,
      heyGenEnabled: this.botConfig.heyGenEnabled,
      heyGenVoiceId: this.botConfig.heyGenVoiceId,
      icon: this.botConfig.icon,
      isPublic: this.botConfig.isPublic,
      isPwProtected: this.botConfig.isPwProtected,
      memoryEnabled: this.botConfig.memoryEnabled,
      messageCopyChatActive: this.botConfig.messageCopyChatActive,
      messageDeleteChatActive: this.botConfig.messageDeleteChatActive,
      model: this.botConfig.model,
      name: this.botConfig.name,
      notes: this.botConfig.notes,
      orgShort: this.botConfig.orgShort,
      promptbarAvatarActive: this.botConfig.promptbarAvatarActive,
      promptbarChatActive: this.botConfig.promptbarChatActive,
      promptbarAudioActive: this.botConfig.promptbarAudioActive,
      secondsToWaitForThreadDeleting: this.botConfig.secondsToWaitForThreadDeleting,
      stopWords: this.botConfig.stopWords,
      summarizePrompt: this.botConfig.summarizePrompt,
      switchToAvatarFromChat: this.botConfig.switchToAvatarFromChat,
      switchToAvatarFromVoice: this.botConfig.switchToAvatarFromVoice,
      switchToChatFromAvatar: this.botConfig.switchToChatFromAvatar,
      switchToChatFromVoice: this.botConfig.switchToChatFromVoice,
      switchToVoiceFromAvatar: this.botConfig.switchToVoiceFromAvatar,
      switchToVoiceFromChat: this.botConfig.switchToVoiceFromChat,
      tagList: this.botConfig.tagList,
      temperature: this.botConfig.temperature,
      toolConfigs: this.botConfig.toolConfigs,
      voiceChatbot: this.botConfig.voiceChatbot,
      voiceVoicebot: this.botConfig.voiceVoicebot,
      voiceAutoPlay: this.botConfig.voiceAutoPlay,
      voiceInputAvatarActive: this.botConfig.voiceInputAvatarActive,
      voiceInputChatActive: this.botConfig.voiceInputChatActive,
      voiceInputVoiceActive: this.botConfig.voiceInputVoiceActive,
      voiceOutputChatActive: this.botConfig.voiceOutputChatActive,
      welcomeMessageBot: this.botConfig.welcomeMessageBot,
      welcomeMessageStatic: this.botConfig.welcomeMessageStatic,
      welcomeType: this.botConfig.welcomeType,
      wordReplacements: this.botConfig.wordReplacements
    };


    if (this.botConfig.editMode === "none") {
      this.toastr.error(ToastErrorTitle.SAVED, 'Der Bot ist im Nur-Lesen-Modus');
      const res = await this.adminService.loadBotConfig(this.botConfig._id);
      if (res) {
        this.botConfig = res;
      }
      return;
    } else if (this.botConfig.editMode === "creator" && this.loginService.loggedInUser?._id !== this.botConfig.creator) {
      this.toastr.error(ToastErrorTitle.SAVED, 'Nutzer ist nicht der Ersteller des Bots! Bitte Bot dublizieren.');
      const res = await this.adminService.loadBotConfig(this.botConfig._id);
      if (res) {
        this.botConfig = res;
      }
      return;
    } else {
      const res = await this.adminService.updateBot(this.botConfig._id, newConf);
      this.botConfig = res;
      this.toastr.success(ToastSuccessTitle.SAVED);
      this.counter.setTools(this.botConfig._id, this.botConfig.toolConfigs.filter((toolConfig) => toolConfig.enabled && (toolConfig.tool === 'schnittstelle' || toolConfig.tool === 'websitesearch')).length);
      this.counter.setWennDann(this.botConfig._id, this.botConfig.toolConfigs.filter((toolConfig) => toolConfig.enabled && toolConfig.tool === 'customtoolkit').length);
      this.botConfigs = this.botConfigs.map((bc) => {
        if (bc._id === this.botConfig._id) {
          return this.botConfig;
        }
        return bc;
      });
    }
  }

  public userCanEditBotConfig(): boolean {
    if (this.botConfig.editMode === "everyone") { return true; }
    if (this.botConfig.editMode === "none") { return false; }
    return (this.loginService.loggedInUser?._id === this.botConfig.creator);
  }

  public async saveEditModeToBotConfig() {
    const res = await this.adminService.updateEditMode(this.botConfig._id, this.botConfig.editMode);
    this.botConfig = res;
    this.toastr.success(ToastSuccessTitle.SAVED);
  }
}
