<div>
  <div class="d-inline">
    <button
      *ngIf="this.getState() === 'idle'"
      class="btn  playT2S"
      (click)="this.playAudio()"
      [disabled]="this.isDisabled"
    >
      <i class="ri-play-circle-line ri-lg"></i>
    </button>
    <button
      *ngIf="this.getState() === 'load'"
      class='btn loadT2S'
    >
      <i class='ri-loader-4-line ri-lg'></i>
    </button>
    <button
      *ngIf="this.getState() === 'play'"
      class='btn  playT2S'
      (click)='this.stopAudio()'
      [disabled]="this.isDisabled"
    >
      <i class='ri-stop-circle-line ri-lg'></i>
    </button>
  </div>
</div>