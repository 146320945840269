import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatbotConfig } from '@reflact/ai-types';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InactiveBotServiceService } from 'src/app/shared/services/inactive-bot-service.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { PlayAudio } from 'src/app/shared/services/playaudio.service';
import { SpeechService } from 'src/app/shared/services/speech.service';
import { Speech2Service } from './../../../../services/speech2/speech2.service';
import { UploadModalComponent } from './upload-modal/upload-modal.component';

@Component({
  selector: 'app-bot-prompt',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipModule],
  templateUrl: './bot-prompt.component.html',
  styleUrl: './bot-prompt.component.scss'
})
export class BotPromptComponent implements OnInit {

  @Input({ required: true }) public botConfig!: ChatbotConfig;
  @Input({ required: true }) public isThinking!: boolean;
  @Input({ required: true }) public promptInput: string = '';
  @Input({ required: true }) public cancelThinking: boolean = false;
  @Input({ required: true }) public voiceInput: boolean = false;
  @Input() public switchToAvatar: boolean = false;
  @Input() public switchToChat: boolean = false;
  @Input() public switchToVoice: boolean = false;
  @Output() public promptInputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public sendPrompt: EventEmitter<void> = new EventEmitter<void>();
  @Output() public stfu: EventEmitter<void> = new EventEmitter<void>();
  public selectedImageFiles: { name: string, base64: string }[] = [];

  constructor(private modalService: BsModalService, public speechService: SpeechService, public speech2: Speech2Service, public playService: PlayAudio, private inactiveService: InactiveBotServiceService, public langService: LanguageService) {
    this.promptInputChange.subscribe((transcript: string) => {
      this.inactiveService.botWasActive();
    });
  }

  public ngOnInit(): void {
    this.speech2.result.subscribe((transcript: string) => {
      this.promptInput = transcript;
      this.promptInputChange.emit(transcript);
    });
  }

  public onKey(e: KeyboardEvent) {
    if (e.key === "Enter" && !e.shiftKey) {
      if (this.isThinking) return;
      this.sendPrompt.emit();
    }
  }

  public openUploadModal() {
    const modalRef = this.modalService.show(UploadModalComponent, {
      class: 'modal-lg',
      keyboard: false,
      initialState: {
        botID: this.botConfig._id,
        allowedFileTypes: [
          { prefix: 'jpeg', mimetype: 'image/jpeg' },
          { prefix: 'jpg', mimetype: 'image/jpg' },
          { prefix: 'png', mimetype: 'image/png' },
          { prefix: 'gif', mimetype: 'image/gif' }
        ]
      }
    });
    modalRef.content?.fileList.subscribe((fileList: { name: string, base64: string }[]) => {
      this.selectedImageFiles = fileList;
    });
  }

  public deleteImageFromSelection(index: number) {
    this.selectedImageFiles.splice(index, 1);
  }

  public startChatRec() {
    if (this.speechService.speechInputEnabled) {
      this.speechService.isInConversationMode = true;
      this.speechService.isListening = true;
      this.speechService.start();
    }
  }

  public stopChatRec() {
    this.speechService.isInConversationMode = false;
    this.speechService.stop();
  }

  public startSingleRec() {
    console.log('startSingleRec');
    if (this.speechService.speechInputEnabled) {
      this.speechService.isListening = true;
      this.speech2.record(false);
    }
  }

  public stopSingleRec() {
    this.speech2.stop();
    this.speechService.stop();
    this.speechService.isListening = false;
  }

  public cancelMsgThinking() {
    if (this.isThinking) {
      this.stfu.emit();
    }
  }
}
