<ng-select
  #ngSelect
  [(ngModel)]="this.botConfig.stopWords"
  [addTag]="true"
  [multiple]="true"
  (add)="this.saveStopWords()"
  (remove)="this.saveStopWords()"
  [hideSelected]="true"
  i18n-notFoundText="@@not-found-text"
  notFoundText="Gib das Wort ein, welches du hinzufügen möchtest"
  i18n-addTagText="@@add-tag-text"
  addTagText="Wort hinzufügen:"
  [clearable]="false"
></ng-select>