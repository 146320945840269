<h3 i18n="@@documents">Dokumente</h3>
<div
  class="callout callout-info"
  i18n="@@documents-description"
>
  In diesem Tab können Sie mit eigenen Dokumenten die Wissensbasis des Bots erweitern. Hochgeladene Dokumente
  können
  flexibel aktiviert und auch wieder deaktiviert werden, um das Antwortverhalten in die gewünschte Richtung zu
  beeinflussen. Die Daten gehen nicht in das Training des Large Language Models ein.
</div>

<div class="card border-0">
  <div class="card-body">
    <app-file-upload
      [botId]="this.rss.botConfig._id"
      [allowedFileTypes]="this.allowedFileTypes"
      [typeOfUpload]="'admin'"
      [isDisabled]="this.checkUserContigent()"
    >
    </app-file-upload>
  </div>
</div>

<h5
  class="mt-5"
  *ngIf="this.rss.documents.length > 0"
  i18n="@@documents-overview"
>Dokumente im Überblick</h5>
<div class="full-width-table-wrapper">
  <table class="table table-hover">
    <tbody>
      @for (doc of this.rss.documents; track $index) {
      <tr>
        <td style="width: 4rem; text-align: left; vertical-align: middle;">
          @if (this.getProcessDocData(doc._id)?.status === 'processing') {
          <span class="spinner-border spinner-border-sm ms-1"></span>
          }
          @else {
          <i
            class="ri-circle-fill text-primary"
            *ngIf="doc.metadata.enabledForKnowledge"
          ></i>
          <i
            class="ri-circle-line text-secondary"
            *ngIf="!doc.metadata.enabledForKnowledge"
          ></i>
          }
        </td>
        <td style="vertical-align: middle;">
          <span
            class="fakelink"
            [routerLink]="[doc._id]"
          >
            {{ this.documentFileName(doc.filename) }}
          </span>
          <span class="ps-3 text-muted"><i>{{doc.metadata.name}}</i></span>
        </td>
        <td style="vertical-align: middle;">
          <span *ngIf="this.getProcessDocData(doc._id)?.subStatus === 'upload done'">Upload:</span>
          <span *ngIf="this.getProcessDocData(doc._id)?.subStatus === 'create embeddings'">Verarbeitung:</span>
          <span *ngIf="this.getProcessDocData(doc._id)?.status === 'processing'">
            {{ this.getProcentFromDoc(doc._id) }}
          </span>
        </td>
        <td
          style="vertical-align: middle;width:1px"
          class="text-end"
        >
          <app-chunk-contigent-individual [chunkCount]="doc.metadata.chunkCount"></app-chunk-contigent-individual>
        </td>
        <td style="vertical-align: middle;width:250px">

          <app-skill-id-input
            [value]="doc.metadata.skillId"
            (changed)="doc.metadata.skillId = $event; this.saveDocument(doc._id)"
          ></app-skill-id-input>
        </td>
        <td style="vertical-align: middle; width: 0rem;">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              [(checked)]="doc.metadata.enabledForKnowledge"
              (change)="doc.metadata.enabledForKnowledge=!doc.metadata.enabledForKnowledge; this.saveDocument(doc._id)"
            >
          </div>
        </td>
        <td
          style="vertical-align: middle; width: 2rem;"
          class="text-end"
        >
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="ri-more-fill"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-primary"
                  [routerLink]="[doc._id]"
                >
                  <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer"
                  (click)="this.downloadDocument(doc._id)"
                >
                  <i class="ri-file-download-fill"></i>
                  <ng-container i18n="@@document-download">Download</ng-container>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item cursor-pointer text-danger"
                  (click)="this.removeDocument(doc._id)"
                >
                  <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>