//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
//!!!!!!!! DIESE DATEI NIEEEEEEEMAAAAAALLLSSSSS ÄNDERN!!!!!!!!!
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
/* eslint-disable */
enum PostType {
  // normal messages
  runMessage = 'runMessage', // deliver chatmessage to open ai
  runComplete = 'runComplete', // recieved chatmessage and post to iframe parent

  // bypass messages
  addMessage = 'addMessage', // bypass message to open ai as user
  userBypassComplete = "userBypassComplete", // bypass message to open ai as user completed
  addMessageBot = 'addMessageBot', // bypass message to open ai as bot
  botBypassComplete = "botBypassComplete", // bypass message to open ai as bot completed

  // simulate messages
  botMessage = 'botMessage', // send message as bot
  botMessageComplete = "botMessageComplete", // recieved message from iframe as bot
  userMessage = 'userMessage', // send message as user

  // audio player
  botSayMessage = "botSayMessage", // say message as bot
  botSayLoad = "botSayLoad", // bot is generating mp3 file
  botSayLoadComplete = "botSayLoadComplete", // bot has generated mp3 file
  botSayEnded = "botSayEnded", // mp3 file has played,

  // bot cards
  botcard = "botcard" // show youtube card from video id
}

enum BotCardType {
  youtubeCard = "youtubeCard",
  iframeCard = "iframeCard",
}

type MessageData = {
  type: PostType;
  payload: any;
}
export class BotApi {
  private bot?: HTMLIFrameElement;
  private debug: boolean = false;
  private audio: HTMLAudioElement;


  /**
   * Initializes a new instance of the BotApi class.
   * @param elementId - The ID of the HTML element containing the iframe.
   */
  constructor(elementId: string, debug: boolean = false) {
    this.debug = debug;

    const elem = document.getElementById(elementId) as HTMLIFrameElement;
    if (elem == null) {
      alert("Iframe not found");
      return;
    }
    this.bot = elem;
    window.addEventListener('message', this._onMessageReceived.bind(this), false);
  }

  /**
   * Handles the message received event.
   * @param event - The message event.
   */
  private _onMessageReceived(event: MessageEvent<MessageData>) {
    if (this.debug) console.log('aa message received', event.data);
    this.onEvent(event.data);
    if (event.data.type === "botSayLoadComplete") {
      document.getElementById('audioplayer')?.remove();
      this.audio = document.createElement('audio');
      this.audio.id = 'audioplayer';
      this.audio.autoplay = true;
      const blob = new Blob([event.data.payload], { type: 'audio/mp3' });
      const blobUrl = URL.createObjectURL(blob);
      this.audio.src = blobUrl;
      this.audio.onended = () => {
        this.onEvent({ type: "botSayEnded", payload: "" });
      }
      document.body.appendChild(this.audio);
    } else if (event.data.type === "runComplete") {
      this.onMessageReceived(event.data.payload);
    }
  }

  /**
   * Posts the message data to the iframe.
   * @param data - The message data to be posted.
   */
  private _post(data: MessageData) {
    if (this.bot == null) {
      alert("Iframe not found");
      return;
    }
    this.bot.contentWindow!.postMessage(data, "*");
  }

  /**
   * Sends a user message to the bot.
   *
   * @param text - The text of the user message.
   */
  public userMessage(text: string) {
    if (this.debug) console.log('API: userMessage ->', text);
    this._post({ type: PostType.userMessage, payload: text });
  }

  /** just a better method name */
  public userBypass(text: string) {
    if (this.debug) console.log('API: userBypass ->', text);
    this._post({ type: PostType.addMessage, payload: text });
  }

  public botBypass(text: string) {
    if (this.debug) console.log('API: botBypass ->', text);
    this._post({ type: PostType.addMessageBot, payload: text });
  }


  public botMessage(text: string) {
    if (this.debug) console.log('API: botMessage ->', text);
    this._post({ type: PostType.botMessage, payload: text });
  }

  public botSay(text: string) {
    if (this.debug) console.log('API: botSayMessage ->', text);
    this._post({ type: PostType.botSayMessage, payload: text });
  }

  public youtubeCard(id: string) {
    if (this.debug) console.log('API: PostType.botcard BotCardType.youtubeCard ->', id);
    this._post({
      type: PostType.botcard, payload: {
        type: BotCardType.youtubeCard,
        data: id
      }
    });
  }
  public iframeCard(url: string) {
    if (this.debug) console.log('API: PostType.botcard BotCardType.iframeCard ->', url);
    this._post({
      type: PostType.botcard, payload: {
        type: BotCardType.iframeCard,
        data: url
      }
    });
  }

  /**
   * Handles the received message.
   * @param message - The received message.
   */
  public onMessageReceived = (message: string) => {
    if (this.debug) console.log('API: message received', message)
  }

  /**
   * Handles the event when the audio play ended.
   * @param event - The received event.
   */
  public onEvent = (event: MessageData) => {
    if (this.debug) console.log('API: event received', event)
  }

}
