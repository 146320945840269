import { RagAI } from './../iframe-communication/iframe-communication.service';
import { Injectable } from '@angular/core';
import { IframeCommunicationService } from '../iframe-communication/iframe-communication.service';

@Injectable({ providedIn: 'root' })
export class ChartService {


  public radar(data: number[], labels?: string[], color?: string, options?: { cssClass: string }) {
    // generate a DUMMY Labels ARRAY, A,B,C,...
    const dummyLables = data.map((_, index) => String.fromCharCode(65 + index));
    const dummyColor = color ? color : '#c0c0c0';
    if (labels) {
      labels.forEach((label, index) => {
        //@ts-ignore
        dummyLables[index] = label;
      });

    }

    console.log(dummyLables);
    const chartData: any = {};
    data.forEach((value, index) => {
      //@ts-ignore
      chartData[dummyLables[index]] = value;
    });

    let className = "";
    if (options?.cssClass) {
      className = options.cssClass;
    }

    //@ts-ignore
    globalThis.RAGAI.imageCard('/api/chart/radar?' + globalThis.RAGAI.qs.stringify({
      data: [
        chartData
      ],
      features: dummyLables,
      colors: [dummyColor],
    }), "Ein Chart", className);
  }

}


