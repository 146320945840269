import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Voice } from '@reflact/ai-types';
import { Subject, firstValueFrom } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class PlayAudio {
  public unit: 'chat' | 'voice' | 'none' = 'none';
  public lastPlayedItem = { text: "", time: 0 };
  public currentPlayingHash: string = "";
  public currentLoadingHash: string = "";
  public isVoiceTester: boolean = false;
  private audioStatus: Subject<[string, string]> = new Subject<[string, string]>();
  public currentAudioStatus = this.audioStatus.asObservable();
  private voice: Voice | undefined;
  private audioDict: Record<string, { status: string, audio: ArrayBuffer }> = {};
  private audio: HTMLAudioElement = new Audio();
  private internalStatus: string = "idle";
  private lastUnit: 'chat' | 'voice' | 'none' = 'none';

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.audio.onended = () => {
      this.currentPlayingHash = "";
      this.audioStatus.next(["idle", ""]);
      this.internalStatus = "idle";
    };
    this.audio.onpause = () => {
      this.currentPlayingHash = "";
      this.audioStatus.next(["idle", ""]);
      this.internalStatus = "idle";
    };
  }

  public anyAudioIsLoading() {
    return this.currentLoadingHash !== "";
  }
  public anyAudioIsPlaying() {
    return this.currentPlayingHash !== "";
  }
  /*
  * This function generates a hash from the given text
  */
  public getHashFromText(string: string) {
    let hash = 0;
    if (string.length == 0) return hash.toString();
    for (let i = 0; i < string.length; i++) {
      const char = string.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash.toString();
  }
  /*
  * This function saves the hash given text to the dict and returns the hash
  */
  public storeHashFromText(text: string) {
    const hash = this.getHashFromText(text);
    if (!this.audioDict[hash]) {
      this.audioDict[hash] = { status: "created", audio: new ArrayBuffer(0) };
    }
    return hash;
  }

  public compareHashes(text: string) {
    return this.getHashFromText(text);
  }

  public removeHashFromDict(hash: string) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.audioDict[hash];
  }

  /*
  * This function plays the audio of the given text
  */
  public async playAudio(text: string, botId: string) {
    if (this.lastUnit !== this.unit) {
      this.lastUnit = this.unit;
      this.voice = undefined;
    }
    if (this.lastPlayedItem.text === text && Date.now() - this.lastPlayedItem.time < 500) return;
    this.lastPlayedItem = { text: text, time: Date.now() };
    const hash = this.storeHashFromText(text);
    if (this.voice === undefined && !this.isVoiceTester) {
      const res = await firstValueFrom(this.http.get<{ voice: Voice }>("/api/toolbox/audio/getvoice/" + botId + "/" + this.unit));
      this.voice = res.voice;
    }

    if (this.internalStatus === "play") {
      this.audio.pause();
      this.audioStatus.next(["idle", ""]);
      this.internalStatus = "idle";
    }

    this.audioStatus.next(["load", hash]);
    this.internalStatus = "load";

    if (this.audioDict[hash] && this.audioDict[hash].status === "created") {
      this.currentLoadingHash = hash;
      const httpCall = this.http.post("/api/toolbox/audio/create", { text, botId, userId: this.loginService.loggedInUser?._id, voice: this.voice, unit: this.unit }, { responseType: 'arraybuffer' });
      const res = await firstValueFrom(httpCall);
      this.currentLoadingHash = "";
      this.audioDict[hash].audio = res;
      this.audioDict[hash].status = "loaded";

    }
    if (this.internalStatus !== "idle") {
      this.audioStatus.next(["play", hash]);
      this.internalStatus = "play";
      const blobPart = this.audioDict[hash]?.audio;
      if (!blobPart) return;
      const blob = new Blob([blobPart], { type: 'audio/mp3' });
      this.audio.src = URL.createObjectURL(blob);
      await this.audio.play().catch(() => {
        throw new Error("Failed to play audio XYZ");

      });
      this.currentPlayingHash = hash;
    }
  }

  public stopAudio() {
    this.audio.pause();
    this.currentPlayingHash = "";
    this.audioStatus.next(["idle", ""]);
    this.internalStatus = "idle";
  }
}
