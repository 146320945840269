<div class="monthpicker bg-white">
  <i
    class="ri-arrow-left-s-line ri-xl cursor-pointer"
    [ngClass]="{'disabled-btn': this.isMinMonth}"
    (click)="this.substractMonth()"
  ></i>
  <span>{{this.getSelectedMonthString()}}</span>
  <i
    class="ri-arrow-right-s-line ri-xl cursor-pointer"
    [ngClass]="{'disabled-btn': this.isMaxMonth}"
    (click)="this.addMonth()"
  ></i>
</div>