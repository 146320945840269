import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClusteringService {

  constructor(private http: HttpClient) { }

  public async clustering(formData: FormData) {
    return await firstValueFrom(this.http.post('/api/clustering', formData));
  }
}
