import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpfBucketFileFrontend } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';
import { CounterService } from './counter.service';

export type MdPreview = {
  status: string;
  markdown: string;
};

export type MsgResponse = {
  status: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private httpClient: HttpClient, private counter: CounterService) { }

  public async uploadFiles(files: File[], botId: string) {
    try {
      const results: { file: File, response: MsgResponse }[] = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('botId', botId);
        try {
          const res = await firstValueFrom(this.httpClient.post<MsgResponse>('/api/toolbox/bot/admin/botdoc/upload', formData));
          results.push({ file, response: res });
          this.counter.addFile(botId);
        } catch (error) {
          console.error('Error while uploading file', error);
        }
      }
      return results;
    } catch (error) {
      console.error('Error while uploading files', error);
      return [];
    }
  }

  public async uploadDocument(file: File, botId: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('botId', botId);
    try {
      const res = await firstValueFrom(this.httpClient.post<MsgResponse>('/api/toolbox/bot/admin/botdoc/upload', formData));
      this.counter.addFile(botId);
      return { file, response: res };
    } catch (error) {
      console.error('Error while uploading file', error);
      return { response: 'nok' };
    }
  }

  public async removeFile(fileId: string, botId: string) {
    try {
      const res = await firstValueFrom(this.httpClient.delete(`/api/toolbox/bot/admin/botdoc/delete/${botId}/${fileId}`));
      this.counter.removeFile(botId);
      return res;
    } catch (error) {
      console.error('Error while deleting file', error);
      return false;
    }
  }

  private checkIfResIsFileRes(object: { docs: SpfBucketFileFrontend[] } | MsgResponse): object is { docs: SpfBucketFileFrontend[] } {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return (object as { docs: SpfBucketFileFrontend[] }).docs !== undefined;
  }

  public async getFiles(botId: string): Promise<{ docs: SpfBucketFileFrontend[] }> {
    try {
      const res = await firstValueFrom(this.httpClient.get<{ docs: SpfBucketFileFrontend[] } | MsgResponse>(`/api/toolbox/bot/admin/botdoc/files/${botId}`));
      if (this.checkIfResIsFileRes(res)) {
        this.counter.setFiles(botId, res.docs.filter((file: SpfBucketFileFrontend) => file.metadata.enabledForKnowledge).length);
        return res;
      } else {
        console.error('Error while fetching files', res);
        return { docs: [] };
      }
    } catch (error) {
      console.error('Error while fetching files', error);
      return { docs: [] };
    }
  }

  public async changeFileProperties(fileId: string, state: boolean, skillId: string, name: string, description: string, botId: string) {
    try {
      if (state) {
        this.counter.addFile(botId);
      } else {
        this.counter.removeFile(botId);
      }
      return await firstValueFrom(this.httpClient.post(`/api/toolbox/bot/admin/botdoc/update/${fileId}`, { enabledForKnowledge: state, skillId, name, description }));
    } catch (error) {
      console.error('Error while changing file state', error);
      return false;
    }
  }

  public async getFileContents(fileId: string): Promise<string> {
    try {
      const result = await firstValueFrom(this.httpClient.get(`/api/toolbox/bot/admin/botdoc/getfile/${fileId}`, { responseType: 'text' }));
      return result;
    } catch (error) {
      console.error('Error while changing file state', error);
      return "unable to fetch file contents";
    }
  }

  public async getFileForDownload(fileId: string) {
    try {
      const result = await firstValueFrom(this.httpClient.get(`/api/toolbox/bot/admin/botdoc/downloadfile/${fileId}`, { responseType: 'arraybuffer' }));
      return result;
    } catch (error) {
      console.error('Error while changing file state', error);
      return "unable to fetch file contents";
    }
  }

  public async getToolConfigPreview(botId: string, toolId: string) {
    try {
      return await firstValueFrom(this.httpClient.get<MdPreview>(`/api/toolbox/bot/admin/toolpreview/${botId}/${toolId}`));
    } catch (error) {
      console.error('Error while fetching tool config preview', error);
      return { 'status': 'nok', 'markdown': 'unable to fetch file contents' } as MdPreview;
    }
  }

}
