import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import dayjs from 'dayjs';
import { BotMessagesService } from '../../services/bot-messages.service';
import { SocketService } from '../../services/socket.service';
import { RagaibotviewhelperService } from './../../services/ragaibotviewhelper/ragaibotviewhelper.service';

@Component({
  selector: 'app-disconnect-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './disconnect-overlay.component.html',
  styleUrl: './disconnect-overlay.component.scss'
})
export class DisconnectOverlayComponent {
  protected showOverlay = false;
  constructor(public socketService: SocketService, private helper: RagaibotviewhelperService, private botMessages: BotMessagesService) {
    this.socketService.onDisconnect.subscribe((status) => {
      this.showOverlay = !status;
    });
  }

  public refresh() {
    window.location.reload();
  }

  public saveChat() {
    const chat = this.botMessages.getMessages().map(message =>
      `${message.role} (${dayjs(message.created).format('YYYY-MM-DDTHH:mm:ssZ')}) \n ${message.content}`
    ).join('\n\n');
    this.helper.downloadTextAsFile(chat, 'chatverlauf.txt');
  }
}