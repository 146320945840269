<ng-container *ngFor="let m of this.botMessagesService.getMessages(); let i = index">
  <ng-container *ngIf="!m.bypass && m.content.trim() !== ''">
    <div class="message-row message-row-{{m.role}} ">
      <app-message-card
        *ngIf="m.role === 'botcard'"
        [message]="m"
        (deleteMessage)="this.deleteMessage($event)"
      >
      </app-message-card>
      <app-message-bubble
        *ngIf="m.role !== 'botcard'"
        [message]="m"
        [bot]="this.botConfig"
        [botChatMsgSourceOption]="this.botConfig.chatMsgSourceOption"
        [voiceOutputActive]="this.botConfig.voiceOutputChatActive"
        [annotations]="this.annotations"
        [enableFeedback]="this.botConfig.enableMessageFeedback && enableFeedbackOverride"
        [enableDeletion]="this.botConfig.messageDeleteChatActive && this.enableDeletionOverride"
        [isThinking]="this.isThinking && i === this.botMessagesService.getMessageCount() - 1"
        [remark]="this.msgIdToFeedbackMap.get(m._id)?.remark ?? ''"
        [debugMode]="this.debugMode"
        (sendFeedback)="this.sendFeedback($event)"
        (deleteMessage)="this.deleteMessage($event)"
      ></app-message-bubble>
    </div>
  </ng-container>
</ng-container>
<small *ngIf="this.botState === 'init_assistant'">Assistant wird erstellt...</small>
<small *ngIf="this.botState === 'init_thread'">Thread wird erstellt...</small>
<small *ngIf="this.botState === 'init_files'">Dateien werden vektorisiert...</small>
<small *ngIf="this.botState === 'requesting_tools'">Tools werden angefragt...</small>
<ng-container *ngIf="this.isThinking">
  <div>
    <app-thinking-animation></app-thinking-animation>
  </div>
</ng-container>

<div
  id="scrollAnker"
  style="height: 30px;"
></div>