import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FileUploadService, MdPreview } from '../../../shared/services/file-upload.service';



@Component({
  standalone: true,
  imports: [MarkdownModule, CommonModule],
  providers: [],
  templateUrl: './md-preview.component.html',
  styleUrl: './md-preview.component.scss'
})
export class MdPreviewComponent implements OnInit {

  public contents: string = "";
  public preMode: boolean = false;
  private botId: string = "";
  private toolId: string = "";
  private docId: string = "";

  constructor(public fileUploadService: FileUploadService, private route: ActivatedRoute) {
    try {
      this.docId = "" + (route.snapshot.paramMap.get("docid") ?? '');
      this.botId = "" + (route.snapshot.paramMap.get("botid") ?? '');
      this.toolId = "" + (route.snapshot.paramMap.get("toolid") ?? '');
    } catch (e) {
      console.error(e);
    }
  }

  public async ngOnInit(): Promise<void> {
    if (this.docId !== "") {
      await this.loadContents(this.docId);
    }
    if (this.botId !== "" && this.toolId !== "") {
      await this.loadWebPreview(this.botId, this.toolId);
    }
  }

  public async loadContents(fileId: string) {
    this.contents = await this.fileUploadService.getFileContents(fileId);
  }

  public toggleView() {
    this.preMode = !this.preMode;
  }

  public async loadWebPreview(botId: string, toolId: string) {
    const res: MdPreview = await this.fileUploadService.getToolConfigPreview(botId, toolId);
    this.contents = res.markdown;
  }
}