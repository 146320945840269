import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatbotConfig } from '@reflact/ai-types';

@Component({
  selector: 'app-hint-memory-required',
  templateUrl: './hint-memory-required.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class HintMemoryRequiredComponent {
  @Input({ required: true }) public botConfig!: ChatbotConfig;



}
