<div class="card border-0 mx-n5">
  <div
    class="card-body "
    class="mx-n0"
  >
    <table class="table table-hover">
      <tbody>
        <ng-container *ngFor="let bot of this.bots">
          <tr>
            <td
              style="width: 4rem;text-align: center;vertical-align: middle;"
              class="ps-5"
            >
              <ng-container *ngIf="!bot.hasImage">
                <div
                  class="bot-icon cursor-pointer"
                  style=" width: 25px ;height: 25px;"
                  [routerLink]="['/','bot-admin', bot._id, this.getBotDeeplink(bot)]"
                  [ngStyle]="{
                    'background-color': bot.color,
                    'color': getContrastColor(bot.color),
                    'background-size': 'cover',
                  }"
                >
                  <span>
                    <i class="{{bot.icon}}"></i>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="bot.hasImage">
                <div
                  class="bot-icon bot-image cursor-pointer"
                  style=" width: 25px ;height: 25px;"
                  [routerLink]="['/','bot-admin', bot._id, this.getBotDeeplink(bot)]"
                  [ngStyle]="{
                  'background-image': 'url(/api/toolbox/membot/public/botimg/'+bot._id+'/128)',
                  'background-size': 'cover',
}"
                >
                </div>
              </ng-container>
            </td>
            <td
              style=" vertical-align: middle;"
              class="w-100"
            > <a
                href="#"
                [routerLink]="['/','bot-admin', bot._id, this.getBotDeeplink(bot)]"
              >{{bot.name}}</a>
              <span>
                <app-hint
                  *ngIf="!UserCanEditBotConfig(bot)"
                  [customIcon]="'ri-lock-fill'"
                  [customColor]="'#666 !important'"
                >Sie können diesen Bot nicht bearbeiten</app-hint>
              </span>
              <br>
              <span
                class="badge p-0"
                *ngIf="bot.creator !== this.loginService.loggedInUser?._id"
              >Owner: {{bot.creatorName}}</span>&nbsp;
              <span
                class="badge p-0"
                *ngIf="bot.orgShort !==''"
              >Organisation: {{bot.orgShort}}</span>
            </td>
            <!--  <td style="vertical-align: middle;">
              <span
                class="badge m-1 cursor-pointer"
                [ngClass]="{
                'bg-primary text-white': this.isTagSelectedBySearchString(tag),
                }"
                *ngFor="let tag of bot.tagList"
                (click)="this.tagClickTrigger.emit(tag)"
              >
                <i class="ri-hashtag"></i>
                {{tag}}
              </span>
            </td>
            <td style="vertical-align: middle;">
              <span><i class="ri-id-card-fill pe-1"></i>{{bot._id}}</span>
            </td> -->
            <td class="text-end">
              <app-chunk-contingent-bot
                *ngIf="bot.creator === this.loginService.loggedInUser?._id"
                [chunkCount]="bot.chunkCount"
              ></app-chunk-contingent-bot>
            </td>
            <td style="vertical-align: middle;">
              <span style="white-space: nowrap;">
                <ng-container *ngIf="bot.updator">
                  <div class="d-flex align-items-center">
                    <div class="me-2"><i class="ri-pencil-line"></i></div>
                    <small>
                      {{bot.updator}}<br>{{dayjs(bot.updated).fromNow()}}
                    </small>
                  </div>
                </ng-container>
              </span>
            </td>
            <td
              style="vertical-align: middle;"
              class="pe-5 text-end"
            >
              <div
                class="dropdown"
                style="width: 55px;"
              >
                <button
                  class="btn  dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="ri-more-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li *ngIf="!bot.thisIsTrash && !bot.archived">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.openBot(bot)"
                    >
                      <i class="ri-external-link-line"></i> Bot öffnen
                    </div>
                  </li>
                  <!-- DUPLIZIEREN-->
                  <li *ngIf="!bot.thisIsTrash && !bot.archived">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.duplicateBot(bot._id)"
                    >
                      <i class="ri-file-copy-2-line"></i> Bot duplizieren
                    </div>
                  </li>
                  <!-- ARCHIV -->
                  <li *ngIf="!bot.archived && !bot.thisIsTrash">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.archiveBot(bot._id)"
                    >
                      <i class="ri-archive-line"></i> Bot archivieren
                    </div>
                  </li>
                  <li *ngIf="bot.archived">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.unarchiveBot(bot._id)"
                    >
                      <i class="ri-arrow-go-back-line"></i> Wiederherstellen
                    </div>
                  </li>
                  <!-- PAPIERKORB -->
                  <li *ngIf="!bot.thisIsTrash && !bot.archived">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.trashBot(bot._id)"
                    >
                      <i class="ri-delete-bin-line"></i> In den Papierkorb verschieben
                    </div>
                  </li>
                  <li *ngIf="bot.thisIsTrash ">
                    <div
                      class="dropdown-item cursor-pointer"
                      (click)="this.untrashBot(bot._id)"
                    >
                      <i class="ri-arrow-go-back-line"></i> Wiederherstellen
                    </div>
                  </li>
                  <!-- LÖSCHEN -->
                  <li *ngIf="bot.thisIsTrash ">
                    <div
                      class="dropdown-item cursor-pointer text-danger"
                      (click)="this.deleteBot.emit(bot._id)"
                    >
                      <i class="ri-delete-bin-line"></i> Endgültig löschen
                    </div>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>