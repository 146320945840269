<ng-template
  #formular
  let-data
>
  <span i18n="@@param-name">Wählen Sie einen sprechenden Parameternamen, z.B.: <b>Suchwort</b>, <b>Person</b>,
    <b>Firma</b></span>
  <input
    class="form-control"
    placeholder="Name"
    [(ngModel)]="data.key"
    (ngModelChange)="formularModelChange()"
    [ngModelOptions]="{updateOn:  'blur'}"
  >
  <span i18n="@@param-description">
    Beschreiben Sie der KI wofür dieser Parameter verwendet wird. Z.B.: <b>Das Wort, nach dem gesucht werden
      soll</b>,
    <b>eine Person, die gefunden werden soll</b>, <b>Firma, zu der mehrere informationen geladen werden soll</b>
  </span>
  <input
    class="form-control border-2 rag-aitext"
    i18n-placeholder="@@description"
    placeholder="Beschreibung"
    [(ngModel)]="data.description"
    (ngModelChange)="formularModelChange()"
    [ngModelOptions]="{updateOn: 'blur'}"
  >
  <button
    class="btn btn-primary btn-sm mt-2"
    (click)="this.addParamToList();"
    *ngIf="this.getSelectedParamState() === 'new'"
    i18n="@@add"
  > <i class="ri-add-fill"></i>
    Hinzufügen
  </button>
</ng-template>



<ng-container *ngIf="this.selectedTool">

  <app-admin-back-button i18n="@@online-source-overview">Online-Quellen im Überblick</app-admin-back-button>
  <div class="row align-items-baseline">
    <div class="col">
      <h3
        class="mt-4"
        i18n="@@edit-search"
      >
        Suche bearbeiten
      </h3>
    </div>
    <div class="col-auto text-end">
      <app-skill-id-input
        [value]="this.selectedTool.skillId"
        (changed)="this.selectedTool.skillId = $event; this.saveToolConfig()"
      ></app-skill-id-input>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-3"><span i18n="@@url">URL</span></div>
    <div class="col">
      <input
        class="form-control"
        placeholder=""
        [(ngModel)]="this.selectedTool.url"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="this.saveToolConfig()"
      >
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-3"><span i18n="@@description">Beschreibung</span></div>
    <div class="col">
      <input
        class="form-control rag-aitext"
        [(ngModel)]="this.selectedTool.description"
        [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="this.saveToolConfig()"
      >
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-3"><span i18n="@@mode">Modus</span></div>
    <div class="col">
      <select
        class="form-select "
        [(ngModel)]="this.selectedTool.tool"
        [ngModelOptions]="{updateOn: 'change'}"
        (ngModelChange)="this.saveToolConfig()"
      >
        <option
          value="schnittstelle"
          i18n="@@process-unconverted"
        >unkonvertiert weiterverarbeiten</option>
        <option
          value="websitesearch"
          i18n="@@convert-to-md"
        >zu Markdown konvertieren</option>
      </select>
    </div>
  </div>
  <h3
    class="mt-5"
    i18n="@@param"
  >Parameter</h3>
  <div
    class="callout callout-info"
    i18n="@@param-description"
  >Hier können Sie Parameter konfigurieren, die an die aufgerufene Website übergeben
    werden. Diese Parameter ermöglichen
    es, dynamische Inhalte oder benutzerdefinierte Daten an die Zielseite zu übermitteln. </div>
  <div class="row ">
    <ng-container *ngIf="this.paramForNewForm">
      <div
        class="col p-5"
        style="border-left: 4px solid #00A599;"
      >
        <ng-template
          [ngTemplateOutlet]="formular"
          [ngTemplateOutletContext]="{ $implicit: this.paramForNewForm }"
        >
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!this.paramForNewForm">
      <div class="col">
        <button
          class="btn btn-primary btn-sm"
          (click)="this.selectEmptyParam()"
        >
          <i class="ri-add-fill"></i> <ng-container i18n="@@add">Hinzufügen</ng-container>
        </button>
      </div>
    </ng-container>
  </div>
  <h5
    class="mt-4"
    *ngIf="this.selectedTool.params.length > 0"
    i18n="@@entered-variables"
  >Eingetragene Variablen</h5>
  <div class="full-width-table-wrapper">
    <table class="table table-hover">
      <tbody>
        @for (param of this.selectedTool.params; track $index) {
        <tr [ngClass]="{'bg-selected-row': this.selectedParam === param}">
          <ng-container *ngIf="this.selectedParam !== param">
            <td style="vertical-align: middle;">
              <i class="ri-braces-line"></i> {{ param.key }}&nbsp;
            </td>
            <td style="vertical-align: middle;">
              <span><b i18n="@@example-title">Beispiel:</b> {{this.selectedTool.url}}?param={{'{'+ param.key
                +'}'}}</span>
            </td>
          </ng-container>
          <ng-container *ngIf="this.selectedParam === param">
            <td
              colspan="2"
              class="p-5"
            >
              <ng-template
                [ngTemplateOutlet]="formular"
                [ngTemplateOutletContext]="{ $implicit: this.selectedParam }"
              ></ng-template>
            </td>
          </ng-container>
          <td class="text-end">
            <div
              *ngIf="this.selectedParam === param"
              class="btn btn-primary"
              (click)="this.selectedParamIndex = -1 "
            >
              <i class="ri-checkbox-fill"></i>
            </div>
            <div
              class="dropdown"
              *ngIf="this.selectedParam !== param"
            >
              <button
                class="btn dropdown-toggle"
                (click)="this.selectedParamIndex = -1; this.paramForNewForm = undefined"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="ri-more-fill"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button
                    type="button"
                    class="dropdown-item cursor-pointer text-primary"
                    (click)="this.selectedParamIndex = $index;this.paramForNewForm = undefined"
                  >
                    <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item cursor-pointer text-danger"
                    (click)="this.deleteParamFromList($index)"
                  >
                    <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>

</ng-container>