import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GptChatModelTypeArr } from '@reflact/ai-types';
import { ExcelExportComponent, ExcelImportComponent, XlsxService } from '@reflact/ngx-xlsx';
import { XLSXExportConfig, XlsxImportResult } from '@reflact/ngx-xlsx/lib/types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { ToastErrorTitle, ToastrService } from 'src/app/shared/services/toastr.service';
import { ConvertFileSizePipe } from "../../../shared/pipes/convertFileSize.pipe";
import { ClusteringService } from './clustering.service';

export type ClusteringData = {
  cluster: string;
  summery: string;
  messageContent: string;
}

export type CommentEntry = {
  index: number,
  comment: string
};

@Component({
  selector: 'app-clustering',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule,
    HintComponent,
    ExcelImportComponent,
    ExcelExportComponent,
    TooltipModule,
    ConvertFileSizePipe
  ],
  templateUrl: './clustering.component.html',
  styleUrl: './clustering.component.scss'
})
export class ClusteringComponent {
  public isLoading = false;
  public gptModels = GptChatModelTypeArr;
  public selectedFiles: File[] = [];
  public tempPath: string = '';
  public allowedFileTypes = [
    { prefix: ".c", mimetype: "text/x-c" },
    { prefix: ".cs", mimetype: "text/x-csharp" },
    { prefix: ".cpp", mimetype: "text/x-c++" },
    { prefix: ".doc", mimetype: "application/msword" },
    { prefix: ".docx", mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
    { prefix: ".html", mimetype: "text/html" },
    { prefix: ".java", mimetype: "text/x-java" },
    { prefix: ".json", mimetype: "application/json" },
    { prefix: ".md", mimetype: "text/markdown" },
    { prefix: ".pdf", mimetype: "application/pdf" },
    { prefix: ".php", mimetype: "text/x-php" },
    { prefix: ".pptx", mimetype: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { prefix: ".py", mimetype: "text/x-python" },
    { prefix: ".py", mimetype: "text/x-script.python" },
    { prefix: ".rb", mimetype: "text/x-ruby" },
    { prefix: ".tex", mimetype: "text/x-tex" },
    { prefix: ".txt", mimetype: "text/plain" },
    { prefix: ".css", mimetype: "text/css" },
    { prefix: ".js", mimetype: "text/javascript" },
    { prefix: ".sh", mimetype: "application/x-sh" },
    { prefix: ".ts", mimetype: "application/typescript" }
  ];

  public data = {
    temperature: 0.5,
    model: 'gpt-4o-mini',
    prompt: '',
    chatTemplate: 'Bitte die folgenden Kommentare, zum Thema "[thema]", zusammenfassen und die Top 5 Punkte zusammenstellen: [kommentare]',
    clusterData: new Map<string, CommentEntry[]>()
  };

  constructor(private toastr: ToastrService, private xlxsService: XlsxService, private clusteringService: ClusteringService) { }

  public onExcelInput(event: XlsxImportResult<Record<string, string>>) {
    if (event.status === 'success') {
      this.data.clusterData.clear();
      let row = 1;
      event.data.forEach(rowData => {
        Object.keys(rowData).forEach(key => {
          if (!this.data.clusterData.has(key)) {
            this.data.clusterData.set(key, []);
          }
          if (rowData[key]) {
            this.data.clusterData.get(key)?.push({ index: row, comment: rowData[key] });
          }
        });
        row++;
      });
    } else {
      console.error(event.errors);
    }
  }

  private pushFilesToStorage(files: File[]) {
    const tempArray: File[] = [];
    for (const file of files) {
      if (this.allowedFileTypes.some((type) => file.name.endsWith(type.prefix))) {
        tempArray.push(file);
      } else {
        this.toastr.error(ToastErrorTitle.SAVED, 'Dateityp nicht erlaubt: ' + file.name);
      }
    }
    if (files.length > 0 && this.selectedFiles.length === 0) {
      this.selectedFiles = tempArray;
    } else {
      this.selectedFiles = this.selectedFiles.concat(tempArray);
    }
  }

  public onFileDropped(event: Event) {
    this.pushFilesToStorage(event as unknown as File[]);
  }

  public showSelectedFile(event: Event) {
    if (event.target) {
      this.pushFilesToStorage((event.target as HTMLInputElement).files as unknown as File[]);
    }
  }

  public removeSelectedFile(file: File) {
    if (this.selectedFiles.length) {
      if (this.selectedFiles.some((f) => f === file)) {
        this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
        this.tempPath = "";
      }
    }
  }

  public async clustering() {
    this.isLoading = true;
    const data = {
      ...this.data,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      clusterData: Object.assign({}, ...Array.from(this.data.clusterData.entries()).map(([k, v]) => ({ [k]: v })))
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('fileCount', this.selectedFiles.length.toString());

    let fileCounter = 1;
    for (const file of this.selectedFiles) {
      formData.append('file_' + fileCounter.toString(), file);
      fileCounter++;
    }

    const res = await this.clusteringService.clustering(formData);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const excelData: any = [[], []];
    const exportConfig: XLSXExportConfig[] = [];
    (res as []).forEach((entry: ClusteringData) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      excelData[0][entry.cluster] = entry.messageContent;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      excelData[1][entry.cluster] = entry.summery;
      exportConfig.push({ fieldName: entry.cluster });
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.xlxsService.exportXlsx(excelData, exportConfig);
    this.isLoading = false;
  }
}
