<div class="modal-header">
    <h4 class="modal-title pull-left">Disclaimer</h4>
</div>
<div class="modal-body">
    <ng-container *ngIf="this.text.trim() !== ''">
        <p style="white-space: pre-wrap">{{this.text}}</p>
    </ng-container>
    <ng-container *ngIf="this.text.trim() === ''">
        <p>
            Die reflact AG kann leider keine Garantie für die Richtigkeit der Fragen bzw. Antwortoptionen geben. Daher
            ist es wichtig, dass die tatsächlich übernommenen Fragen und Antworten von einem Fachexperten geprüft
            werden.
        </p>
        <p>
            Überlegen Sie, welche Inhalte Sie in den Generator eingeben: Derzeit gibt es noch keine automatischen
            Filter, die z.B. Produktnamen, automatisch anonymisieren. Wir arbeiten an einer entsprechenden Lösung.
        </p>
        <p>
            Die eingegebenen Inhalte werden derzeit bei der reflact gespeichert, parametrisiert und dann an eine
            KI-Engine übergeben. Vorteil für Sie ist, dass wir die Inhalte nicht Ihrem Account zuordnen und Sie nur
            den Zugang zum reflact Portal benötigen.
        </p>
        <p>
            Wir beobachten und bewerten laufend alle datenschutzrechtlichen Fragen im Zusammenhang mit dem Einsatz von
            KI und werden daraufhin unsere Werkzeuge anpassen und weiter optimieren.
        </p>
    </ng-container>
    <hr>
    <div class="row">
        <div class="col">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    [(ngModel)]="dontShowAgain"
                    id="flexCheckDefault"
                >
                <label
                    class="form-check-label"
                    for="flexCheckDefault"
                >
                    Nicht mehr anzeigen
                </label>
            </div>
        </div>
        <div class="col-auto">
            <div
                class="btn btn-primary"
                (click)="bsModalRef.hide()"
            >Bestätigen</div>
        </div>
    </div>

</div>
