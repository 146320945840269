import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatbotConfig, ToolConfigWebsiteSearch } from '@reflact/ai-types';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  public async getBotOwnersContingent(botId: string) {
    return await firstValueFrom(this.http.get<{ chunkContingent: number, userChunkCount: number }>("/api/toolbox/bot/admin/contingentbybotid/" + botId))
  }

  public async addBot() {
    return await firstValueFrom(this.http.post<ChatbotConfig>("/api/toolbox/bot/admin/createbotconfig", {}));
  }

  public async deleteImage(botId: string) {
    return await firstValueFrom(this.http.delete<ChatbotConfig>("/api/toolbox/bot/admin/image/" + botId));
  }
  public async reloadImage(botId: string, prompt: string) {
    return await firstValueFrom(this.http.post<ChatbotConfig>("/api/toolbox/bot/admin/generateImage/" + botId, { prompt }));
  }
  
  public async importBot(botConfig: ChatbotConfig) {
    return await firstValueFrom(this.http.post<ChatbotConfig>("/api/toolbox/bot/admin/importbotconfig", botConfig));
  }

  public async getBots() {
    const bots = await firstValueFrom(this.http.get<{ chatbotConfigs: ChatbotConfig[] }>("/api/toolbox/bot/admin/mybotconfigs"));
    return bots.chatbotConfigs;
  }

  public async copyBot(botId: string): Promise<ChatbotConfig> {
    return await firstValueFrom(this.http.get<ChatbotConfig>('/api/toolbox/bot/admin/copybot/' + botId));
  }

  public async unarchiveBot(botId: string): Promise<void> {
    await firstValueFrom(this.http.post('/api/toolbox/bot/admin/unarchivebotconfig/' + botId, {}));
  }

  public async archiveBot(botId: string): Promise<void> {
    await firstValueFrom(this.http.post('/api/toolbox/bot/admin/archivebotconfig/' + botId, {}));
  }

  public async untrashBot(botId: string): Promise<void> {
    await firstValueFrom(this.http.post('/api/toolbox/bot/admin/untrashbotconfig/' + botId, {}));
  }

  public async trashBot(botId: string): Promise<void> {
    await firstValueFrom(this.http.post('/api/toolbox/bot/admin/trashbotconfig/' + botId, {}));
  }

  public async deleteBot(botId: string): Promise<void> {
    await firstValueFrom(this.http.post('/api/toolbox/bot/admin/deletebotconfig/' + botId, {}));
  }

  public async updateBot(botId: string, botConfig: Partial<ChatbotConfig>): Promise<ChatbotConfig> {
    if (botConfig.tagList) {
      botConfig.tagList.sort((a: string, b: string) => a.localeCompare(b));
    }
    return await firstValueFrom(this.http.post<ChatbotConfig>('/api/toolbox/bot/admin/updatebotconfig/' + botId, botConfig));
  }

  public async updateEditMode(botId: string, editMode: "creator" | "none" | "everyone"): Promise<ChatbotConfig> {
    return await firstValueFrom(this.http.post<ChatbotConfig>('/api/toolbox/bot/admin/updateeditmode/' + botId, { editMode }));
  }

  public async addBotAsUrl(botId: string, url: string) {
    return await firstValueFrom(this.http.post("/api/toolbox/bot/admin/boturl/add/" + botId, { url }));
  }

  public async addWebsitesearch(botId: string, toolConfig: ToolConfigWebsiteSearch) {
    return await firstValueFrom(this.http.post("/api/toolbox/bot/admin/addWebsitesearch/" + botId, { toolConfig }));
  }

  public async setRandomPassword(botId: string) {
    return await firstValueFrom(this.http.post<{ status: string, pw: string }>("/api/toolbox/bot/admin/setrandompassword/" + botId, {}));
  }

  public async loadBotConfig(id: string) {
    try {
      const botConfig = await firstValueFrom(this.http.get<ChatbotConfig>("/api/toolbox/bot/admin/botconfig/" + id));
      return botConfig;
    } catch (e) {
      console.warn(e);
      return null;
    }
  }
}
