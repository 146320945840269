<div
  class="position-fixed server-tag bg-primary fs-5 text-white"
  *ngIf="this.loginService.systemConf === 'devkitoolbox'"
>DEV</div>

<div
  class="position-fixed server-tag fs-5 text-white"
  *ngIf="this.loginService.systemConf === 'stagingkitoolbox'"
  style="background-color: #1369a2"
>STAGING</div>
<ng-container *ngIf="!isFullScreenLayout">
  <div style="height: 100vh; display: flex; flex-direction: column;flex:1 1">
    <div id="main">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isFullScreenLayout">
  <router-outlet></router-outlet>
</ng-container>