import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, Signal } from '@angular/core';
import { TooltipModule } from '@coreui/angular';
import { GlobalStat } from '@reflact/ai-types';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { MSFormatPipe } from 'src/app/shared/pipes/msFormat.pipe';
import { HighlightService } from 'src/app/shared/services/highlight.service';

@Component({
  selector: 'app-debug-entry',
  standalone: true,
  imports: [CommonModule, NgxLayoutModule, TooltipModule, MSFormatPipe],
  templateUrl: './debug-entry.component.html',
  styleUrl: './debug-entry.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugEntryComponent {
  @Input({ required: true }) public logEntry!: GlobalStat;

  public selectedEntryiesForDetails: Date[] = [];
  public isHighlighted: Signal<boolean> = computed(() => { return this.highlightService.messageIdSource() == this.logEntry.openAiMessageId; });
  public initialTimestamp = new Date().getTime();




  constructor(private highlightService: HighlightService) { }

  public toggleDetails(entry: GlobalStat): void {
    if (this.selectedEntryiesForDetails.includes(entry.created)) {
      this.selectedEntryiesForDetails = this.selectedEntryiesForDetails.filter((created) => created !== entry.created);
    } else {
      this.selectedEntryiesForDetails.push(entry.created);
    }

    this.highlightEntry();
  }

  public highlightEntry() {
    if (!this.isHighlighted()) {
      this.highlightService.highlightMessage(this.logEntry.openAiMessageId);
    } else {
      this.highlightService.highlightMessage("None");
    }
  }

  public getActionStyle(action: string): { background: string, color: string } {
    switch (action) {
      case "toolrunerror":
        return { background: "#dc3545", color: 'white' };
      //Process
      //case "ThreadbotServiceUpdatedAssistent":
      case "Membot call to chat/botid done":
      case "chatcompletion":
        return { background: 'black', color: '#E5E5EA' };
      default:
        return { background: '#E5E5EA', color: 'black' };
    }

  }
}
