<div
  style="position: sticky;top:63px; z-index: 990;"
  class="  mx-n5 px-5 pb-1 d-inline "
>
  <div
    class="btn btn-outline-primary admin-back-button lock-insensitive"
    [routerLink]="['../']"
  >
    <i [class]="this.icon"></i> <ng-content #name></ng-content>

  </div>
</div>